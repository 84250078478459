import React, {Component} from 'react'
import { connect } from 'react-redux'
import { setLocale, getLocale, localizeKey } from 'react-redux-localization';
import { Cookies } from 'react-cookie' 
import '../../styles/App.css'
import '../../styles/home.css'
import {Col, Row,FormControl, Form, FormGroup, ControlLabel, Button} from 'react-bootstrap'
import { createElementsFromText } from 'html-text-to-react';
import ReactHtmlParser from 'react-html-parser';
import $ from 'jquery';
import {TOKEN_NAME, REDIRECT,HOME_PAGE_LOADED, APP_LOAD,LANGUAGE_KEY} from '../../constants/actionTypes'
import agent from '../../agent'
import { Link,withRouter, history} from 'react-router-dom'
import LeaguesOfToday from './leaguesOfToday'
import menu from '../common/menu.json'
import Lqhome from '../common/home.json'


const mapStateToProps = state => (
    Object.assign({}, state, {
		tab: state.common.tab,
		updateProps: state.common.updateProps,
		locale: getLocale(state),
		languageKey: state.common.languageKey
	}) 

);

const mapDispatchToProps = dispatch => ({               
	onLoad: (data) => {
		dispatch({ type: 'HOME_PAGE_LOADED', data})  
	},
	chooseLocale: locale => dispatch(setLocale(locale))
});



 


class Tips extends Component{ 	
	constructor(){
		super()
		this.state = {
			lguages:'1005',
			qcinfo:{},NUMTIP:'',TITLETIP:'',sport_tips:'',league_id:'',LeagueList:[]
		}
		//---
		this.getrandomad=()=>{
			let lang_id=this.state.lguages
			let param={lang_id:lang_id}
			agent.home.getrandomad2(param).then(data => {
				//console.log(data)
				if(data.result === 'good'){
					this.setState({qcinfo:data.info})
				}
			})
		}
		//---
		this.goAdView=this.goAdView.bind(this)
		//---
	}
	goAdView(ev){
		let id=$(ev.target).attr("dataid")
		/*let title=$(ev.target).attr("title")
		window.localStorage.setItem( 'ad_user_id', id);
		window.localStorage.setItem( 'ad_title', title);
		//console.log(id,title)
		window.location.href='/ad-list' */
		window.location.href='/new-info/'+id
	}
	componentWillMount(){	
		//-----
		
		let idLanguage=window.localStorage.getItem(LANGUAGE_KEY)
		if(idLanguage!='' && idLanguage!='null' && idLanguage!=null){
			this.setState({lguages:idLanguage},()=>{
				this.getrandomad();
			})
		}
		
		//-----
		let TITLETIP=window.localStorage.getItem('TITLETIP');
		let NUMTIP=window.localStorage.getItem('NUMTIP');
		let SPORT=window.localStorage.getItem('SPORT');
		let league_id=window.localStorage.getItem('league_id');
		if(TITLETIP!=null && TITLETIP!='null' && TITLETIP!=''){
			this.setState({TITLETIP:TITLETIP,NUMTIP:NUMTIP,sport_tips:SPORT,league_id:league_id},()=>{
				this.mobiletodaylisttobet();
			})
		}
		
		//console.log(TITLETIP,NUMTIP)
		//-----
	}
	componentDidMount(){
		this.getrandomad();
	}
	componentWillReceiveProps(nextProps){
		//console.log(nextProps)
		if(nextProps.languageKey && nextProps.languageKey!='' && nextProps.languageKey!=null && nextProps.languageKey!='null'){
			this.setState({lguages:nextProps.languageKey},()=>{
				this.getrandomad()
			});
			
		}
	}
	
	shouldComponentUpdate(nextProps, nextState){
		return nextState;
	}	
	
	goChoseTip(ev){
		//chose-tip
		this.props.history.push("/chose-tip")
	}
	mobiletodaylisttobet(){
		let sport_tips=this.state.sport_tips
		let league_id=this.state.league_id
		let param={sport_tips:sport_tips,league_id:league_id}
		agent.home.mobiletodaylisttobet(param).then(data => {
			//console.log(data)
			if(data.result === 'good'){
				this.setState({LeagueList:data.LeagueList})
			}
		})
	}
	changeOption(sport_tips,e){
		//console.log(sport_tips)
		$(".tool-ball").removeClass("active")
		window.localStorage.setItem('SPORT', sport_tips);
		$(e.target).closest(".tool-ball").addClass("active")
		this.props.history.push("/add-tip")
	}
	changeOption1(){
	}
	render(){   
		//---
		const qcinfo=this.state.qcinfo;
		let title=''
		let imageurl=''
		let url=''
		let description=''
		if(qcinfo!='' && qcinfo!=null && qcinfo!='null'){
			 title=qcinfo.ad_title?qcinfo.ad_title:'';
			 imageurl=qcinfo.ad_logo_url?qcinfo.ad_logo_url:'https://back-end.coopbet.com/gf/ywqBBMhT0U0022_no-logo.png';
			 url=qcinfo.ad_url?qcinfo.ad_url:'';
			 description=qcinfo.ad_description?qcinfo.ad_description:'';
			 description=(description.length<=85)?description:description.substr(0,85)+'...'
		}	
		//---
		let LeagueList=this.state.LeagueList
		//console.log(LeagueList)
        return (
			<div id="body"> 
			{
				(qcinfo && qcinfo!=null && qcinfo!='')?
				<Row className="randomad">
					<Col md={4} xs={4} sm={4} className="pd0">
						<div className="img-adv">
							<a href={url} target="_blank" className="rand-logo">
								<img alt="m88" src={imageurl} width="100" height="50"/>
							</a>
						</div>
					</Col>
					<Col md={8} xs={8} sm={8} className="pd0">
						<div className="desc-adv">									
						{
							ReactHtmlParser(description)
						}
							<span>
							{
								(qcinfo!='' && qcinfo!=null)?
								<a onClick={this.goAdView.bind(this)} dataid={qcinfo.id} title={title} className="v-more">{localizeKey(Lqhome)('viewmore', this.state.lguages)}</a>:''
							}
							</span>
						</div>
					</Col>
				</Row>:''
			}
				
				<Row className="mrgt7 mgbt3 mrgLR1">
					<Col md={4} xs={4} sm={4} className={(this.state.sport_tips==2)?"tool-ball active":"tool-ball"} onClick={this.changeOption.bind(this,2)}>
						
							<i className="far fa-futbol icon-tool" ></i>
							<div className="tool-ball-title">{localizeKey(menu)('soccer', this.state.lguages)}</div>
						
					</Col>
					<Col md={4} xs={4} sm={4} className={(this.state.sport_tips==1)?"tool-ball active":"tool-ball"} onClick={this.changeOption.bind(this,1)}>
					
							<i className="fas fa-basketball-ball icon-tool"></i>
							<div className="tool-ball-title">{localizeKey(menu)('basketball', this.state.lguages)}</div>
					
					</Col>
					<Col md={4} xs={4} sm={4} className={(this.state.sport_tips==3)?"tool-ball active":"tool-ball"} onClick={this.changeOption1.bind(this,3)}>
						
							<i className="fas fa-baseball-ball icon-tool"></i>
							<div className="tool-ball-title">{localizeKey(menu)('tennis', this.state.lguages)}</div>
						
					</Col>
				</Row>
				<Row className="line-ct"></Row>
				
				<Row className="mrgt7 mgbt3">
				{
					(LeagueList!='')?LeagueList.map((data,idx)=>{
						let count=(data.MatchList!=null && data.MatchList!='null')?data.MatchList.length:'0'
						let MatchList=data.MatchList
						return(
							<div key={idx} className="width100">
								<LeaguesOfToday dataleagues={data} sport_tips={this.state.sport_tips} index={idx}/>
								
							</div>
						)
					}):''
				}
					
				</Row>
				
				
			</div>
        );
    }
}

//export default connect(mapStateToProps, mapDispatchToProps)(Tips)
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Tips));