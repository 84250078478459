import React, {Component} from 'react'
import { connect } from 'react-redux'
import { setLocale, getLocale } from 'react-redux-localization';
import { Cookies } from 'react-cookie' 
import '../../styles/App.css'
import '../../styles/home.css'
import {Col, Row,FormControl, Form, FormGroup, ControlLabel, Button} from 'react-bootstrap'
import { createElementsFromText } from 'html-text-to-react';
import ReactHtmlParser from 'react-html-parser';
import $ from 'jquery';
import {TOKEN_NAME, REDIRECT,RUNNING_LOADER, APP_LOAD,LANGUAGE_KEY} from '../../constants/actionTypes'
import agent from '../../agent'
import HomeList from './homeList.js'
import { Link} from 'react-router-dom'

const mapStateToProps = state => (
    Object.assign({}, state, {
		dashboardData: state.common.dashboardData,
		tab: state.common.tab,
		updateProps: state.common.updateProps,
		locale: getLocale(state),
		languageKey: state.common.languageKey
	}) 

);

const mapDispatchToProps = dispatch => ({               
	onLoad: (data) => {
		dispatch({ type: 'RUNNING_LOADER', data})  
	},
	chooseLocale: locale => dispatch(setLocale(locale))
});



 


class Home extends Component{ 	
	constructor(){
		super()
		this.state = {
			lguages:'1005',
			qcinfo:{},user_id:'',PickCode:'',
			homebetList:[],userInfo:{},PickDatetimeSTR:''
		}
		//---
		this.getrandomad=()=>{
			let lang_id=this.state.lguages
			let param={lang_id:lang_id}
			agent.home.getrandomad2(param).then(data => {
				//console.log(data)
				if(data.result === 'good'){
					this.setState({qcinfo:data.info})
				}
			})
		}
		//---
		this.homePick=(user_id,PickCode)=>{
			agent.running.homePick({user_id:user_id,PickCode:PickCode}).then(data => {
				//console.log(data.data.PickDatetimeSTR)
				let PickDatetimeSTR=data.data.PickDatetimeSTR
				if(data.result === 'good'){
					this.setState({homebetList:data.data,PickDatetimeSTR:PickDatetimeSTR})
				}
			})
		}
		//---
		this.getUserInfo=(user_id)=>{
			agent.running.userInfo({user_id:user_id}).then(data => {
				//console.log(data.data)
				if(data.result === 'good'){
					this.setState({userInfo:data.data})
				}
			})
		}
		//---
	}
	
	componentWillMount(){	
		this.props.onLoad('running');
		//-----
		//this.getHomeList();
		this.getrandomad();
		let idLanguage=window.localStorage.getItem(LANGUAGE_KEY)
		if(idLanguage!='' && idLanguage!='null' && idLanguage!=null){
			this.setState({lguages:idLanguage})
		}
		
		//-----
		let prID=this.props.match.params.id
		if(prID!='' && prID!=null && prID!='null'){
			prID=prID.split("_")
			let user_id=prID[0]
			let PickCode=prID[1]
			this.setState({user_id:user_id,PickCode:PickCode})
			this.getUserInfo(user_id)
			this.homePick(user_id,PickCode)
			
		}	
		//-----
	}
	
	componentWillReceiveProps(nextProps){
		//console.log(nextProps)
		if(nextProps.languageKey && nextProps.languageKey!='' && nextProps.languageKey!=null && nextProps.languageKey!='null'){
			this.setState({lguages:nextProps.languageKey});
			this.getrandomad()
		}
	}
	
	shouldComponentUpdate(nextProps, nextState){
		return nextState;
	}	
	getHomeList(){
		let search=$(".txt-search").val();
		agent.home.mobilehomebetlist({search:search}).then(data => {
			//console.log(data)
			if(data.result === 'good'){
				this.setState({homebetList:data.list})
			}
		})
	}
	render(){   
		//---
		const qcinfo=this.state.qcinfo;
		let title=''
		let imageurl=''
		let url=''
		let description=''
		if(qcinfo!='' && qcinfo!=null && qcinfo!='null'){
			 title=qcinfo.ad_title?qcinfo.ad_title:'';
			 imageurl=qcinfo.ad_logo_url?qcinfo.ad_logo_url:'https://back-end.coopbet.com/gf/ywqBBMhT0U0022_no-logo.png';
			 url=qcinfo.ad_url?qcinfo.ad_url:'';
			 description=qcinfo.ad_description?qcinfo.ad_description:'';
			 description=(description.length<=70)?description:description.substr(0,70)+'...'
		}	
		//---
		//console.log(this.state.homebetList)
        return (
			<div id="body">   				
				<Row className="randomad">
					<Col md={4} xs={4} sm={4} className="pd0">
						<div className="img-adv">
							<a href={url} target="_blank" className="rand-logo">
								<img alt="m88" src={imageurl} width="100" height="50"/>
							</a>
						</div>
					</Col>
					<Col md={8} xs={8} sm={8} className="pd0">
						<div className="desc-adv">									
						{
							ReactHtmlParser(description)
						}
						</div>
					</Col>
				</Row>
				
				<Row className="addTip">
					<Col md={7} sm={7} xs={7} className="txt-r"> Do you want to post a tip ?</Col>
					<Col md={5} sm={5} xs={5}> 
						<Link className="btn-create-tip" to="/add-tip">Create tips</Link>
					</Col>
				</Row>
				<Row className="line-ct"></Row>
				<HomeList homebetList={this.state.homebetList} userInfo={this.state.userInfo} PickDatetimeSTR={this.state.PickDatetimeSTR}/>
			</div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Home)