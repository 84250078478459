import React, {Component} from 'react'
import { connect } from 'react-redux'
import { Cookies } from 'react-cookie' 
import '../../styles/App.css'
import '../../styles/home.css'
import {Col, Row,FormControl, Form, FormGroup, ControlLabel, Button} from 'react-bootstrap'
import { createElementsFromText } from 'html-text-to-react';
import ReactHtmlParser from 'react-html-parser';
import $ from 'jquery';
import {TOKEN_NAME, REDIRECT,HOME_PAGE_LOADED, APP_LOAD,LANGUAGE_KEY,NUMBER_NOTIFICATIONS} from '../../constants/actionTypes'
import agent from '../../agent'
import { Link,withRouter, history} from 'react-router-dom'
import { localize, localizeKey,setLocale, getLocale } from 'react-redux-localization';
import menu from '../common/menu.json';
import Lqhome from '../common/home.json'


const mapStateToProps = state => (
    Object.assign({}, state, {
		dashboardData: state.common.dashboardData,
		tab: state.common.tab,
		updateProps: state.common.updateProps,
		locale: getLocale(state),
		languageKey: state.common.languageKey
	}) 

);

const mapDispatchToProps = dispatch => ({               
	onLoad: (data) => {
		dispatch({ type: 'HOME_PAGE_LOADED', data})  
	},
	setNumberNotification: (data) => {
		dispatch({ type: 'NUMBER_NOTIFICATIONS', data})  
	},
	chooseLocale: locale => dispatch(setLocale(locale))
});



 


class Notification extends Component{ 	
	constructor(){
		super()
		this.state = {
			lguages:'1005',
			qcinfo:{},member_name:'',type:1,
			limit:15,offset:0,list:[],avartarDf:'https://www.coopbet.com/media/avatar_none.png',
			loadedAll:false,scrollM:false,
		}
		//---
		this.getrandomad=()=>{
			let lang_id=this.state.lguages
			let param={lang_id:lang_id}
			agent.home.getrandomad2(param).then(data => {
				//console.log(data)
				if(data.result === 'good'){
					this.setState({qcinfo:data.info})
				}
			})
		}
		//---
		this.goToOption=this.goToOption.bind(this)
		//----
		this.close=()=>{
			this.setState({isOpenModal:false})
		}
		//----
		this.onScrollM=()=>{
			const messgeContainer = document.getElementById("user-list-nf")
			const loadedAll = this.state.loadedAll

			if( !loadedAll && messgeContainer.scrollTop === (messgeContainer.scrollHeight - messgeContainer.offsetHeight)) {
				let offset = parseInt(this.state.offset) + this.state.limit
				const newState = Object.assign({}, this.state, { offset: offset, scrollM: true })
				this.setState(newState, () => {
					this.getList()
				})				
			}
		}
		this.readAll=()=>{
			agent.notification.readAll().then((datas) => {
				//console.log(datas)
				if(datas.result==='good'){
					this.newNumber();
				}
				
			})
		}
		this.readOne=(notification_id)=>{
			agent.notification.readOne({notification_id:notification_id}).then((datas) => {
				//console.log(datas)
				if(datas.result==='good'){
					this.newNumber();
				}
				
			})
		}
		//---
		this.newNumber=()=>{
			agent.notification.newNumber().then(data => {
				//console.log(data);                
				if(data.result==='good'){
					this.props.setNumberNotification(data.new_number)
					this.getList()
				}
			})
		}
		this.goAdView=this.goAdView.bind(this)
	}
	goAdView(ev){
		let id=$(ev.target).attr("dataid")
		/*let title=$(ev.target).attr("title")
		window.localStorage.setItem( 'ad_user_id', id);
		window.localStorage.setItem( 'ad_title', title);
		//console.log(id,title)
		window.location.href='/ad-list'*/
		window.location.href='/new-info/'+id
	}
	componentWillMount(){	
		//-----
		this.getrandomad();
		let idLanguage=window.localStorage.getItem(LANGUAGE_KEY)
		if(idLanguage!='' && idLanguage!='null' && idLanguage!=null){
			this.setState({lguages:idLanguage})
		}
		let user_id=window.localStorage.getItem('user_id')
		//alert(window.localStorage.getItem('user_id'))
		//if(user_id>0){
			this.getList();
		//}
		let login=window.localStorage.getItem(TOKEN_NAME);
		 
		if(login!='' && login!='null' && login!=null){
			 login=JSON.parse(login)
			 this.setState({idUserLogin:login.UserInfo.id,member_name:login.UserInfo.member_name})
		}
		//-----
	}
	componentDidMount(){
		//this.getrandomad();
	}
	componentWillReceiveProps(nextProps){
		//console.log(nextProps)
		if(nextProps.languageKey && nextProps.languageKey!='' && nextProps.languageKey!=null && nextProps.languageKey!='null'){
			this.setState({lguages:nextProps.languageKey});
			this.getrandomad()
		}
	}
	
	shouldComponentUpdate(nextProps, nextState){
		return nextState;
	}
	getList(){
		let offset=this.state.offset
		let limit=this.state.limit
		agent.notification.getList({offset:offset,limit:limit}).then((datas) => {
			//console.log(datas)
			if(datas.result==='good'){
				const list = this.state.scrollM ? this.state.list.concat(datas.list):datas.list
				const loadedAll = datas.list.length < this.state.limit ? true: false
				const newState = Object.assign({}, this.state, { list: list, loadedAll: loadedAll, scrollM: false})
				this.setState(newState)	
			}
			
		})
	}
	goToOption(type,url_username,pick_code,notification_id,user_id,e){
		//console.log(type)
		this.readOne(notification_id)
		if(type=='new_friend_suggestion'){
			
			window.localStorage.setItem('user_id',user_id )
			this.props.history.push("/profile")
		}else{
			this.props.history.push("/home")
			$(".txt-search").val(pick_code)
		}
	}
	
	
	
	
	
	render(){   
		//---
		const qcinfo=this.state.qcinfo;
		let title=''
		let imageurl=''
		let url=''
		let description=''
		if(qcinfo!='' && qcinfo!=null && qcinfo!='null'){
			 title=qcinfo.ad_title?qcinfo.ad_title:'';
			 imageurl=qcinfo.ad_logo_url?qcinfo.ad_logo_url:'https://back-end.coopbet.com/gf/ywqBBMhT0U0022_no-logo.png';
			 url=qcinfo.ad_url?qcinfo.ad_url:'';
			 description=qcinfo.ad_description?qcinfo.ad_description:'';
			 description=(description.length<=85)?description:description.substr(0,85)+'...'
		}	
		let list=this.state.list
		let avartarDf=this.state.avartarDf
		
        return (
			<div id="body">  
			{
				(qcinfo && qcinfo!=null && qcinfo!='')?
				<Row className="randomad mgbt5">
					<Col md={4} xs={4} sm={4} className="pd0">
						<div className="img-adv">
							<a href={url} target="_blank" className="rand-logo">
								<img alt="m88" src={imageurl} width="100" height="50"/>
							</a>
						</div>
					</Col>
					<Col md={8} xs={8} sm={8} className="pd0">
						<div className="desc-adv">									
						{
							ReactHtmlParser(description)
						}
							<span>
							{
								(qcinfo!='' && qcinfo!=null)?
								<a onClick={this.goAdView.bind(this)} dataid={qcinfo.id} title={title} className="v-more">{localizeKey(Lqhome)('viewmore', this.state.lguages)}</a>:''
							}
							</span>
						</div>
					</Col>
				</Row>:''
			}
				<Row className="line-ct" style={{'display':(qcinfo && qcinfo!=null && qcinfo!='')?'':'none'}}></Row>
				<Row className="mg0">
					<Col md={6} sm={6} xs={6}>
						<h3 className="header-notification">{localizeKey(menu)('yourNotification', this.state.lguages)}</h3>
					</Col>
					<Col md={6} sm={6} xs={6} className="rtool">
						<a href="javascript:void(0);" onClick={this.readAll}>{localizeKey(menu)('asRead', this.state.lguages)}</a>						
					</Col>
				</Row>
				<Row className="brd1px"></Row>
				<Row className="mg0 body-notification" id="notifi-content">
					<Col md={12} className="pd0 scroll-nt ft-items" id="user-list-nf" onScroll={this.onScrollM}>
						<div className="ctn">
						{
							(list!='')?list.map((data,key)=>{
								/*let type=data.type
								type=type.split('user_').join('');
								type=type.split('friend_').join('');
								type=type.split('_').join(' ');
								let pfile=data.data*/
								let pfile=data.data
								let msg='';
								let message=data.message.split(" ");
								message.map((it,key)=>{
									if(key>0){
										msg+=it+" "
									}
								})
								
								return(
									<div className={'ft-item row-ntf ft-item-'+key} key={key} style={{'backgroundColor':(data.read==0)?'aliceblue':'#fff'}} onClick={this.goToOption.bind(this,data.type,pfile.url_username,pfile.pick_code,data.notification_id,pfile.user_id)}>
										<figure className="pull-left">
												<img className="img-ntf" src={(pfile.imageurl!='' && pfile.imageurl!='null' && pfile.imageurl!=null)?pfile.imageurl:avartarDf} width="40" height="40"/>
										</figure>
										<div className="ct-user-nf">
											<a className="name-user">{pfile.tipster_name}</a>
											<span>{msg}.</span>
											<div>
												<i className="fa fa-clock-o ic-ntf"></i>
												<span className="time-ntf">{data.str_notification_time}</span>
											</div>
										</div>
										
									</div>
								)
							}):<div className="empty list-gray" style={{'display':''}} >
								{localizeKey(menu)('txtNoNotify', this.state.lguages)}
							</div>
						}
						</div>
					</Col>
				</Row>
				
			</div>
        );
    }
}

//export default connect(mapStateToProps, mapDispatchToProps)(Notification)
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Notification));