import React, {Component} from 'react'
import { connect } from 'react-redux'
import { localize, localizeKey,setLocale, getLocale } from 'react-redux-localization';
import { Cookies } from 'react-cookie' 
import '../../styles/App.css'
import '../../styles/home.css'
import {Col, Row, Button} from 'react-bootstrap'
//import { createElementsFromText } from 'html-text-to-react';
//import ReactHtmlParser from 'react-html-parser';
import $ from 'jquery';
import {TOKEN_NAME,LANGUAGE_KEY,BASE_IMAGE} from '../../constants/actionTypes'
import agent from '../../agent'
import translations from '../common/captionhome.json';
import Comment from './comment.js'
import ModalErorr from '../common/ModalErorr'
import ReactHtmlParser from 'react-html-parser';

const mapStateToProps = state => (
    Object.assign({}, state, {
		dashboardData: state.common.dashboardData,
		tab: state.common.tab,
		updateProps: state.common.updateProps,
		locale: getLocale(state),
		languageKey: state.common.languageKey
	}) 

);

const mapDispatchToProps = dispatch => ({               
	
	chooseLocale: locale => dispatch(setLocale(locale))
});



 


class Home extends Component{ 	
	constructor(){
		super()
		this.state = {
			lguages:'1005',
			homebetList:[],
			url:'https://www.coopbet.com/media/avartar_cm.jpg',
			img_default:'https://m.coopbet.com/img/no_avartar.jpg',
			url_like:'https://www.coopbet.com/media/likec.png',
			url_liked:'https://www.coopbet.com/media/likedc.png',
			url_dislike:'https://www.coopbet.com/media/dislikec.png',
			url_disliked:'https://www.coopbet.com/media/dislikedc.png',
			CommentList:[],TotalComments:'',numCM:'',pickcode_do:'',imageurl:'',
			UserInfo:{},isOpenModal:false,msgLogin:''
		}
		//---
		this.close=()=>{
			this.setState({isOpenModal:false})
		}
		//---
		this.callModalError=(message)=>{
			this.setState({isOpenModal:true,msgLogin:message})
		}
		//---
	}
	
	componentWillMount(){	
		//-----
		
		let login=window.localStorage.getItem(TOKEN_NAME);
		 
		if(login!='' && login!='null' && login!=null){
			 login=JSON.parse(login)
			 this.setState({member_name:login.UserInfo.member_name,id_user:login.UserInfo.id,imageurl:login.UserInfo.imageurl})
		}
		
		//-----
	}
	
	componentWillReceiveProps(nextProps){
		//console.log(nextProps)
		if(nextProps.languageKey && nextProps.languageKey!='' && nextProps.languageKey!=null && nextProps.languageKey!='null'){
			this.setState({lguages:nextProps.languageKey});
		}
		if(nextProps.homebetList && nextProps.homebetList!='' && nextProps.homebetList!=null && nextProps.homebetList!='null'){
			this.setState({homebetList:nextProps.homebetList});
		}
		if(nextProps.UserInfo && nextProps.UserInfo!='' && nextProps.UserInfo!=null && nextProps.UserInfo!='null'){
			this.setState({UserInfo:nextProps.UserInfo});
		}
	}
	
	shouldComponentUpdate(nextProps, nextState){
		return nextState;
	}	
	
	
	render(){   
		//---
		let homebetList=this.state.homebetList
		let CommentList=this.state.CommentList
		let TotalComments=this.state.TotalComments
		
		//---
		//console.log(homebetList)
		//console.log(localizeKey(translations)('Win', this.state.lguages))
        return (
			<div className="home-list">
			{
				(homebetList!='')?homebetList.map((data,index)=>{
					let userinfo=this.state.UserInfo
					let betlist=data.BetList
					var html = data.PickComment;
					if(html!='' && html!=null){
						html=html.replace(/(?:\r\n|\r|\n)/g, '<br />')
					}
					var div = document.createElement("div");
					div.innerHTML = html;
					var textmessge = html;
					return(
						<div key={index}>
							<Row className="title-user-info">
								<Col md={3} sm={3} xs={3} className="pd0">
									<figure>
										<a className="" href="">
											<img src={(userinfo.imageurl!='' && userinfo.imageurl!=null && userinfo.imageurl!='null')?userinfo.imageurl:this.state.img_default} width="60" height="60"/>
										</a>
									</figure>
								</Col>
								<Col md={6} sm={6} xs={6} className="pd0">
									<h4 className="name">{userinfo.tipster_name}</h4>
									<div className="">{data.PickDatetimeSTR}</div>
								</Col>
								<Col md={3} sm={3} xs={3} className="pd0 txtR timetip"></Col>
							</Row>
							<Row className="linect"></Row>
							<Row className="tipct">
							{
								(betlist!='')?betlist.map((dt,idx)=>{
									return (
										<Row className="list-tp" key={idx}>
											<Col md={12} xs={12} sm={12} className="tip-name">{dt.hometeam_name} - {dt.awayteam_name}</Col>
											<Col md={12} xs={12} sm={12} className="tiso">
												<span>{dt.tip_desc}</span>
												<button className="btn-gr">{parseInt(dt.amount)}/10</button>
												<button className="btn-ora">
												{
													(dt.home_goal!=null && dt.home_goal!='null')?
													<span>{dt.home_goal} - {dt.away_goal}</span>:
													<span> ? </span>
												}
												</button>
												
												{	//<span className="status won" title="Won">Win</span>
													(dt.bet_status=='WIN')?
													<span className="status won" title="Won">
														{localizeKey(translations)('Win', this.state.lguages)}
													</span>:(dt.bet_status=='DRAW')?
													<span className="status draw" title="Draw">
														{localizeKey(translations)('Draw', this.state.lguages)}
													</span>:(dt.bet_status=='WAIT')?
													<span className="status pending" title="Pending">
														{localizeKey(translations)('wait', this.state.lguages)}
													</span>:(dt.bet_status=='LOST')?
													<span className="status lost" title="Lost">
														{localizeKey(translations)('lost', this.state.lguages)}
													</span>:''
												}
											</Col>
											<Col md={12} xs={12} sm={12} className="time-tip">
												<img src={dt.sporttip_imageurl} style={{'display':'none'}}/>
												{
													(dt.sportid==1)?<i className="fas fa-basketball-ball icon-sport" aria-hidden="true"></i>:(dt.sportid==2)?<i className="far fa-futbol icon-sport" aria-hidden="true"></i>:<i className="fas fa-baseball-ball icon-sport" aria-hidden="true"></i>
												}
												<span className="txt-tm-tp">{dt.league_name} - {dt.start_date} {dt.start_time}</span>
											</Col>
										</Row>
									)
								}):''
							}
							</Row>
							
							<Row className="descp-tp">
								{
									//data.PickComment
									ReactHtmlParser(textmessge)
								}
							</Row>
							<Row className="linect"></Row>
							<div className="commet-tp">
								
								{//====comment====
								<Comment 
									TotalComments={data.TotalComments} 
									imageurl={this.state.imageurl} 
									PickCode={data.PickCode}
									//PickCode={this.state.pickcode_do}
									id_user={this.state.id_user}
									PickLikedNum={data.PickLikedNum}
									PickLikedUserList={data.PickLikedUserList}
									PickLikedUserIDList={data.PickLikedUserIDList}
									PickCodeRunning={data.PickCodeRunning}
									PickCodeComingNumber={data.PickCodeComingNumber}
									PickCodeComingUsers={data.PickCodeComingUsers}
									UserInfoId={this.state.UserInfo.id}
									callModalError={this.callModalError}
									/>
								
								//====end comment
								}
								
								
							</div>
							<Row className="line-ct"></Row>
						</div>
					)
				}):''
			}
				<ModalErorr isOpen={this.state.isOpenModal} close={this.close} text={this.state.msgLogin}/>
			</div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Home)