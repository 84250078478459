import React, {Component} from 'react'
import { connect } from 'react-redux'
import { setLocale, getLocale, localizeKey } from 'react-redux-localization';
import { Cookies } from 'react-cookie' 
import '../../styles/App.css'
import '../../styles/home.css'
import {Col, Row,FormControl, Form, FormGroup, ControlLabel, Button} from 'react-bootstrap'
import { createElementsFromText } from 'html-text-to-react';
import ReactHtmlParser from 'react-html-parser';
import $ from 'jquery';
import {TOKEN_NAME, REDIRECT,RUNNING_LOADER, APP_LOAD,LANGUAGE_KEY,COMMENT_REALTIME} from '../../constants/actionTypes'
import agent from '../../agent'
import HomeList from './homeList.js'
import { Link} from 'react-router-dom'
import Pusher from 'pusher-js';
import Lqhome from '../common/home.json'
import menu from '../common/menu.json';

var pusher = new Pusher('d75bec97873cadcd8a43', {
	cluster: 'ap1',
	forceTLS: true
});

const mapStateToProps = state => (
    Object.assign({}, state, {
		dashboardData: state.common.dashboardData,
		tab: state.common.tab,
		updateProps: state.common.updateProps,
		locale: getLocale(state),
		languageKey: state.common.languageKey
	}) 

);

const mapDispatchToProps = dispatch => ({               
	onLoad: (data) => {
		dispatch({ type: RUNNING_LOADER, data})  
	},
	realTimeCM: (data) => {
		dispatch({ type: COMMENT_REALTIME, data})  
	},
	chooseLocale: locale => dispatch(setLocale(locale))
});



 


class Home extends Component{ 	
	constructor(){
		super()
		this.state = {
			lguages:'1005',
			qcinfo:{},user_id:'',PickCode:'',
			homebetList:[],userInfo:{},PickDatetimeSTR:'',parent_id:''
		}
		//---
		this.getrandomad=()=>{
			 $("#myDropdown").removeClass("show");
			let lang_id=this.state.lguages
			let param={lang_id:lang_id}
			agent.home.getrandomad2(param).then(data => {
				//console.log(data)
				if(data.result === 'good'){
					this.setState({qcinfo:data.info})
				}
			})
		}
		//---
		this.homePick=(user_id,PickCode)=>{
			agent.running.homePick({user_id:user_id,PickCode:PickCode}).then(data => {
				//console.log(data.data.PickDatetimeSTR)
				
				if(data.result === 'good'){
					let PickDatetimeSTR=data.data.PickDatetimeSTR
					this.setState({homebetList:data.data,PickDatetimeSTR:PickDatetimeSTR})
				}
			})
		}
		//---
		this.getUserInfo=(user_id)=>{
			agent.running.userInfo({user_id:user_id}).then(data => {
				//console.log(data.data)
				if(data.result === 'good'){
					this.setState({userInfo:data.data})
				}
			})
		}
		//---
		this.goAdView=this.goAdView.bind(this)
	}
	goAdView(ev){
		let id=$(ev.target).attr("dataid")
		/*let title=$(ev.target).attr("title")
		window.localStorage.setItem( 'ad_user_id', id);
		window.localStorage.setItem( 'ad_title', title);
		//console.log(id,title)
		window.location.href='/ad-list' */
		window.location.href='/new-info/'+id
	}
	componentWillMount(){	
		this.props.onLoad('running');
		//-----
		//this.getHomeList();
		
		let idLanguage=window.localStorage.getItem(LANGUAGE_KEY)
		if(idLanguage!='' && idLanguage!='null' && idLanguage!=null){
			this.setState({lguages:idLanguage},()=>{
				this.getrandomad();
			})
		}
		
		//-----
		let prID=this.props.match.params.id
		/*if(prID!='' && prID!=null && prID!='null'){
			prID=prID.split("_")
			let user_id=prID[0]
			let PickCode=prID[1]
			this.setState({user_id:user_id,PickCode:PickCode})
			this.getUserInfo(user_id)
			this.homePick(user_id,PickCode)
			//this.getSocket(PickCode)
			
		}	end old*/
		if(prID!='' && prID!=null && prID!='null'){
			prID=prID.split("_")
			let user_id=prID[0]
			let PickCode=prID[1]
			this.setState({user_id:user_id,PickCode:PickCode})
			this.getUserInfo(user_id)
			this.homePick(user_id,PickCode)
			let parent_id=prID[2]
			if(parent_id!='' && parent_id!=null){
				this.setState({parent_id:parent_id})
			}
			//console.log(prID)
			this.getSocket(PickCode)
			this.getSocketForNum()
		}	
		//-----
	}
	
	componentWillReceiveProps(nextProps){
		//console.log(nextProps)
		if(nextProps.languageKey && nextProps.languageKey!='' && nextProps.languageKey!=null && nextProps.languageKey!='null'){
			this.setState({lguages:nextProps.languageKey},()=>{
				this.getrandomad();
			});
			
		}
	}
	componentDidMount() {
		this.getrandomad();
		//----
		window.onclick = function(event) {
		  if (!event.target.matches('.btn_log')) {
			var dropdowns = document.getElementsByClassName("dropdown-content");
			var i;
			for (i = 0; i < dropdowns.length; i++) {
			  $("#myDropdown").removeClass("show");
			  $("#myDropdown_1").removeClass("show");
			}
			//console.log(dropdowns.length)
		  }
		  if (!event.target.matches('.buton_icon')) {
			var dropdowns = document.getElementsByClassName("dropdown-content");
			var i;
			for (i = 0; i < dropdowns.length; i++) {
			  $("#mymenu").removeClass("show");
			}
		  }
		  if (!event.target.matches('.tfd-delete')) {
			var dropdowns = document.getElementsByClassName("dropdown-menu");
			var i;
			for (i = 0; i < dropdowns.length; i++) {
			  $(".dr-fr").hide();
			}
		  }
		}
	}
	shouldComponentUpdate(nextProps, nextState){
		return nextState;
	}	
	getHomeList(){
		let search=$(".txt-search").val();
		agent.home.mobilehomebetlist({search:search}).then(data => {
			//console.log(data)
			if(data.result === 'good'){
				this.setState({homebetList:data.list})
			}
		})
	}
	getSocket(PickCode){
		var pusher = new Pusher('d75bec97873cadcd8a43', {
			cluster: 'ap1',
			forceTLS: true
		});
		var thiss=this
		var pickcode=PickCode
		//var channelcode = 'coopbet-pick-' + pickcode;
		//var event = 'pick-changed';
		var channelcode = 'coopbet-pick';
		var event = 'pick-changed-'+pickcode;
		//console.log(pickcode)
		var channel = pusher.subscribe(channelcode);
		channel.bind(event, function(data) {
			console.log(data)
			if(data!='' && data!=null){
				thiss.setState({CommentList:data.CommentList})
				var newCommentList=data.CommentList
				var newTotalComments=data.TotalComments
				var homebetList=thiss.state.homebetList
				//console.log(homebetList)
				if(homebetList!=null && homebetList!=true){
					homebetList.CommentList=newCommentList
					homebetList.TotalComments=newTotalComments
					/*let newhomeList=homebetList.map(dt=>{
						//console.log(dt.CommentList,dt.TotalComments)
						dt.CommentList=newCommentList
						dt.TotalComments=newTotalComments
						return dt;
					})*/
					//thiss.setState({homebetList:newhomeList})
					thiss.setState({homebetList:homebetList})
					thiss.props.realTimeCM({CommentList:newCommentList,TotalComments:newTotalComments})
				}
			}
			
		});
	}
	getSocketForNum(){
		var pusher = new Pusher('d75bec97873cadcd8a43', {
			cluster: 'ap1',
			forceTLS: true
		});
		var thiss=this
		var homebetList=thiss.state.homebetList
		//console.log(homebetList)
		var channelcode = 'coopbet-pick';
		var event = 'pickcoming-changed';
		var channel = pusher.subscribe(channelcode);
		channel.bind(event, function(data) {
			//alert(JSON.stringify(data));
			//console.log(data)			
			var PickCodeComingNumber=data.PickCodeComingNumber
			var PickCodeComingUsers=data.PickCodeComingUsers
			let newhomeList=homebetList.map(dt=>{
				dt.PickCodeComingNumber=PickCodeComingNumber
				dt.PickCodeComingUsers=PickCodeComingUsers
				return dt;
			})
			//console.log(newhomeList)
			thiss.setState({homebetList:newhomeList})
		});
	}
	render(){   
		//---
		const qcinfo=this.state.qcinfo;
		let title=''
		let imageurl=''
		let url=''
		let description=''
		if(qcinfo!='' && qcinfo!=null && qcinfo!='null'){
			 title=qcinfo.ad_title?qcinfo.ad_title:'';
			 imageurl=qcinfo.ad_logo_url?qcinfo.ad_logo_url:'https://back-end.coopbet.com/gf/ywqBBMhT0U0022_no-logo.png';
			 url=qcinfo.ad_url?qcinfo.ad_url:'';
			 description=qcinfo.ad_description?qcinfo.ad_description:'';
			 description=(description.length<=85)?description:description.substr(0,85)+'...'
		}	
		//---
		//console.log(this.state.homebetList)
        return (
			<div id="body"> 
			{
				(qcinfo && qcinfo!=null && qcinfo!='')?
				<Row className="randomad">
					<Col md={4} xs={4} sm={4} className="pd0">
						<div className="img-adv">
							<a href={url} target="_blank" className="rand-logo">
								<img alt="m88" src={imageurl} width="100" height="50"/>
							</a>
						</div>
					</Col>
					<Col md={8} xs={8} sm={8} className="pd0">
						<div className="desc-adv">									
						{
							ReactHtmlParser(description)
						}
							<span>
							{
								(qcinfo!='' && qcinfo!=null)?
								<a onClick={this.goAdView.bind(this)} dataid={qcinfo.id} title={title} className="v-more">{localizeKey(Lqhome)('viewmore', this.state.lguages)}</a>:''
							}
							</span>
						</div>
					</Col>
				</Row>:''
			}
				
				<Row className="addTip">
					<Col md={7} sm={7} xs={7} className="txt-r">{localizeKey(Lqhome)('doywpost', this.state.lguages)}</Col>
					<Col md={5} sm={5} xs={5}> 
						<Link className="btn-create-tip" to="/add-tip">{localizeKey(menu)('createtip', this.state.lguages)}</Link>
					</Col>
				</Row>
				<Row className="line-ct"></Row>
				<HomeList homebetList={this.state.homebetList} userInfo={this.state.userInfo} PickDatetimeSTR={this.state.PickDatetimeSTR} parent_id={this.state.parent_id}/>
			</div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Home)