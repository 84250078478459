import React, {Component} from 'react'
import { connect } from 'react-redux'
import { Cookies } from 'react-cookie' 
import '../../styles/App.css'
import '../../styles/home.css'
import {Col, Row,FormControl, Form, FormGroup, ControlLabel, Button} from 'react-bootstrap'
import { createElementsFromText } from 'html-text-to-react';
import ReactHtmlParser from 'react-html-parser';
import $ from 'jquery';
import {TOKEN_NAME, REDIRECT,HOME_PAGE_LOADED, APP_LOAD,LANGUAGE_KEY} from '../../constants/actionTypes'
import agent from '../../agent'
//import { Link} from 'react-router-dom'
import { localize, localizeKey,setLocale, getLocale } from 'react-redux-localization';
import Lqhome from '../common/home.json'


const mapStateToProps = state => (
    Object.assign({}, state, {
		tab: state.common.tab,
		updateProps: state.common.updateProps,
		locale: getLocale(state),
		languageKey: state.common.languageKey
	}) 

);

const mapDispatchToProps = dispatch => ({               
	onLoad: (data) => {
		dispatch({ type: 'HOME_PAGE_LOADED', data})  
	},
	chooseLocale: locale => dispatch(setLocale(locale))
});



 


class Ad extends Component{ 	
	constructor(){
		super()
		this.state = {
			lguages:'1005',title:'',ad_id:'',ad_long_description:''
			
		}
		//---
		this.getAdlongdescription=(ad_id)=>{			
			agent.ads.adlongdescription({ad_id:ad_id}).then(data => {
				console.log(data)
				if(data.result === 'good'){
					this.setState({title:data.ad_title,ad_long_description:data.ad_long_description})
				}
			})
		}
		//---
	}
	
	componentWillMount(){	
		//-----
		let idLanguage=window.localStorage.getItem(LANGUAGE_KEY)
		let ad_user_id=window.localStorage.getItem( 'ad_user_id');
		let ad_title=window.localStorage.getItem( 'ad_title');
		this.setState({ad_title:ad_title,ad_user_id:ad_user_id})
		if(idLanguage!='' && idLanguage!='null' && idLanguage!=null){
			this.setState({lguages:idLanguage})
			//this.getbookMaker(idLanguage)
		}
		//console.log(idLanguage)
		//-----
		let ad_id=this.props.match.params.id
		if(ad_id>0){
			this.setState({ad_id:ad_id},()=>{
				this.getAdlongdescription(ad_id);
			})
		}
		
	}
	 componentDidMount() {
	 }
      
	componentWillReceiveProps(nextProps){
		//console.log(nextProps)
		if(nextProps.languageKey && nextProps.languageKey!='' && nextProps.languageKey!=null && nextProps.languageKey!='null'){
			this.setState({lguages:nextProps.languageKey});
			
		}
	}
	
	shouldComponentUpdate(nextProps, nextState){
		return nextState;
	}	
	
	render(){   
		//---
		
		//---
		//console.log(this.state.homebetList)
        return (
			<div id="body">   	
			
				
				<Row className="mrgt7 mgbt3">
					<Col md={12} xs={12} sm={12} className="add-title active">
						{this.state.title}
						<a className="icr" href="/home"><i className="fas fa-long-arrow-alt-right"></i></a>
					</Col>
					
				</Row>
				<Row className="line-ct"></Row>
				
				<Row className="mg0 mrgt7 mgbt3">
					<Col md={12}>
						{ReactHtmlParser(this.state.ad_long_description)}
					</Col>
				</Row>
				
				
			</div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Ad)