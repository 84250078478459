import React, {Component} from 'react'
import { connect } from 'react-redux'
import { setLocale, getLocale } from 'react-redux-localization';
import { Cookies } from 'react-cookie' 
import '../../styles/App.css'
import '../../styles/home.css'
import {Col, Row,FormControl, Form, FormGroup, ControlLabel, Button} from 'react-bootstrap'
import { createElementsFromText } from 'html-text-to-react';
import ReactHtmlParser from 'react-html-parser';
import $ from 'jquery';
import {TOKEN_NAME, REDIRECT,HOME_PAGE_LOADED, APP_LOAD,LANGUAGE_KEY} from '../../constants/actionTypes'
import agent from '../../agent'
import { Link,withRouter, history} from 'react-router-dom'


const mapStateToProps = state => (
    Object.assign({}, state, {
		tab: state.common.tab,
		updateProps: state.common.updateProps,
		locale: getLocale(state),
		languageKey: state.common.languageKey
	}) 

);

const mapDispatchToProps = dispatch => ({               
	onLoad: (data) => {
		dispatch({ type: 'HOME_PAGE_LOADED', data})  
	},
	chooseLocale: locale => dispatch(setLocale(locale))
});



 


class Leagues extends Component{ 	
	constructor(){
		super()
		this.state = {
			lguages:'1005',
			sport_tips:'',league_id:'',Leagues:{},MatchList:[],index:''
		}
		//---
		
		//---
		
		//---
	}
	
	componentWillMount(){	
		//-----
		
		let Leagues=this.props.dataleagues
		let sport_tips=this.props.sport_tips
		let index=this.props.index
		//let MatchList=(Leagues.MatchList!='null' && Leagues.MatchList!=null)?Leagues.MatchList:[]
		//console.log(sport_tips)
		this.setState({Leagues:Leagues,sport_tips:sport_tips,index:index})
		//-----
	}
	
	componentWillReceiveProps(nextProps){
		console.log(nextProps)
		if(nextProps.languageKey && nextProps.languageKey!='' && nextProps.languageKey!=null && nextProps.languageKey!='null'){
			this.setState({lguages:nextProps.languageKey});
			
		}
	}
	
	shouldComponentUpdate(nextProps, nextState){
		return nextState;
	}	
	
	goChoseTip(odd_id,bet_type,ev){
		//chose-tip
		let sport_tips=this.state.sport_tips
		let value={odd_id:odd_id,bet_type:bet_type,sport_tips:sport_tips}
		window.localStorage.setItem("ODDPARAMS",JSON.stringify(value))
		window.localStorage.setItem("url_befor","detail-tip-today")
		this.props.history.push("/chose-tip")
	}
	mobiletodaylisttobet(){
		let sport_tips=this.state.sport_tips
		let league_id=this.state.league_id
		let param={sport_tips:sport_tips,league_id:league_id}
		agent.home.mobiletodaylisttobet(param).then(data => {
			//console.log(data)
			if(data.result === 'good'){
				let Leagues=data.LeagueList
				this.setState({Leagues:Leagues[0]})
			}
		})
	}
	changeLeagues(league_id,e){
		this.setState({league_id:league_id},()=>{
			this.mobiletodaylisttobet();
		})
	}
	chooseColor () {
		let min=197
		let max=205
		for (var i = 0, random = []; i < 3; i++) {
			let rd = Math.floor(Math.random() * (max - min + 1)) + min;
			//random.push(Math.floor(Math.random()*256));
			random.push(rd);
		  
		}
	  //console.log(random)
		//return random; 
		return 'rgb(' + random.join(', ') + ')';
	}
	CollapseList(e){
		let thiss=e.target.closest(".cha-leagues");
		//console.log(thiss)
		$(thiss).find(".league-list").hide();
		$(thiss).find(".fa-angle-down").hide();
		$(thiss).find(".fa-angle-up").show();
	}
	ExpandList(e){
		let thiss=e.target.closest(".cha-leagues");
		//console.log(thiss)
		$(thiss).find(".league-list").show();
		$(thiss).find(".fa-angle-down").show();
		$(thiss).find(".fa-angle-up").hide();
	}
	render(){   
		//---
		
		//---
		let Leagues=this.state.Leagues
		let index=this.state.index
		let count=(Leagues.MatchList!=null && Leagues.MatchList!='null')?Leagues.MatchList.length:'0'
		let MatchList=Leagues.MatchList
		//console.log(index)
        return (
			<div className="width100 cha-leagues">
				<Row className="mg0 width100 mrgLR1 mrgbt5" onClick={this.changeLeagues.bind(this,Leagues.id)}>
				
					<Col md={10} xs={10} sm={10} className="btn-tip tip-bagr">{Leagues.league_name} ({Leagues.MatchCount})</Col>
					<Col md={2} xs={2} sm={2} className="btn-num-tip">
						{
							(index==0)?
							<span>
								<i className="fa fa-angle-down" onClick={this.CollapseList.bind(this)}></i>
								<i className="fa fa-angle-up" style={{'display':'none'}} onClick={this.ExpandList.bind(this)}></i>
							</span>:
							<span>
								<i className="fa fa-angle-down"  style={{'display':'none'}} onClick={this.CollapseList.bind(this)}></i>
								<i className="fa fa-angle-up" onClick={this.ExpandList.bind(this)}></i>
							</span>
						}
						
						
					</Col>
				
				</Row>
				<div className="league-list">
					{
						(MatchList!='' && MatchList!=null)?MatchList.map((dt,ik)=>{
							let color=this.chooseColor()
							return(
							<Row className="mg0 width100 mrgbt5" key={ik}>
								<Row className="mg0 width100 mrgLR1">
									<Col md={4} sm={4} xs={4} className="pd0 left-tip" style={{backgroundColor:color}}>
										<div>{dt.str_start_date}</div>
										<div>{dt.str_start_time}</div>
									</Col>
									<Col md={8} sm={8} xs={8} className="pd0 right-tip" style={{backgroundColor:color}}>
										<div className="width100">{dt.home_team_name} - {dt.away_team_name}</div>
									</Col>
								</Row>
								<Row className="mg0 width100 mrgLR1 div-title-tip">
									<Col md={3} sm={3} xs={3} className="pd0 col-title-tip">
										<div>FT. 1x2</div>
									</Col>
									<Col md={3} sm={3} xs={3} className="pd0 col-title-tip">
										<div>FT. HDP</div>
									</Col>
									<Col md={3} sm={3} xs={3} className="pd0 col-title-tip">
										<div>FT. O/U</div>
									</Col>
									<Col md={3} sm={3} xs={3} className="pd0 col-title-tip">
										<div>FT. O/E</div>
									</Col>
								</Row>
								<Row className="mg0 width100 mrgLR1 div-nm-tip">
									<Col md={3} sm={3} xs={3} className="num-tip">
										<div >{(dt.odd_1x2_home!='' && dt.odd_1x2_home!=null)?<a className="bet-link" onClick={this.goChoseTip.bind(this,dt.odd_1x2_id,dt.odd_1x2_home_bet_type)}>{dt.odd_1x2_home}</a>:''}</div>
									</Col>
									<Col md={3} sm={3} xs={3} className="num-tip" >
										<span className="fl_l"></span>
										<span className="fl_r">{(dt.odd_handicap_home!='' && dt.odd_handicap_home!=null)?<a className="bet-link" onClick={this.goChoseTip.bind(this,dt.odd_handicap_id,dt.odd_handicap_home_bet_type)}>{dt.odd_handicap_home}</a>:''}</span>
									</Col>
									<Col md={3} sm={3} xs={3} className="num-tip" >
										<span className="fl_l">{dt.odd_overunder_draw}</span>
										<span className="fl_r">{(dt.odd_overunder_home!='' && dt.odd_overunder_home!=null)?<a className="bet-link" onClick={this.goChoseTip.bind(this,dt.odd_overunder_id,dt.odd_overunder_home_bet_type)}>{dt.odd_overunder_home}</a>:''}</span>
									</Col>
									<Col md={3} sm={3} xs={3} className="num-tip" >
										<span className="fl_l">o</span>
										<span className="fl_r">{(dt.odd_oddeven_home!='' && dt.odd_oddeven_home!=null)?<a className="bet-link" onClick={this.goChoseTip.bind(this,dt.odd_oddeven_id,dt.odd_oddeven_home_bet_type)}>{dt.odd_oddeven_home}</a>:''}</span>
									</Col>
								</Row>
								<Row className="mg0 width100 mrgLR1 div-nm-tip">
									<Col md={3} sm={3} xs={3} className="num-tip" >
										<div>{(dt.odd_1x2_away!='' && dt.odd_1x2_away!=null)?<a className="bet-link" onClick={this.goChoseTip.bind(this,dt.odd_1x2_id,dt.odd_1x2_away_bet_type)}>{dt.odd_1x2_away}</a>:''}</div>
									</Col>
									<Col md={3} sm={3} xs={3} className="num-tip" >
										<span className="fl_l">{dt.odd_handicap_draw}</span>
										<span className="fl_r">{(dt.odd_handicap_away!='' && dt.odd_handicap_away!=null)?<a className="bet-link" onClick={this.goChoseTip.bind(this,dt.odd_handicap_id,dt.odd_handicap_away_bet_type)}>{dt.odd_handicap_away}</a>:''}</span>
									</Col>
									<Col md={3} sm={3} xs={3} className="num-tip" >
										<span className="fl_l">u</span>
										<span className="fl_r" >{(dt.odd_overunder_away!='' && dt.odd_overunder_away!=null)?<a className="bet-link" onClick={this.goChoseTip.bind(this,dt.odd_overunder_id,dt.odd_overunder_away_bet_type)}>{dt.odd_overunder_away}</a>:''}</span>
									</Col>
									<Col md={3} sm={3} xs={3} className="num-tip" >
										<span className="fl_l">e</span>
										<span className="fl_r">{(dt.odd_oddeven_away!='' && dt.odd_oddeven_away!=null)?<a className="bet-link" onClick={this.goChoseTip.bind(this,dt.odd_oddeven_id,dt.odd_oddeven_away_bet_type)}>{dt.odd_oddeven_away}</a>:''}</span>
									</Col>
								</Row>
								<Row className="mg0 width100 mrgLR1 div-nm-tip">
									<Col md={3} sm={3} xs={3} className="num-tip" >
										<div>{(dt.odd_1x2_draw!='' && dt.odd_1x2_draw!=null)?<a className="bet-link" onClick={this.goChoseTip.bind(this,dt.odd_1x2_id,dt.odd_1x2_draw_bet_type)}>{dt.odd_1x2_draw}</a>:''}</div>
									</Col>
									<Col md={3} sm={3} xs={3} className="num-tip" >
										<span className="fl_l"></span>
										<span className="fl_r"></span>
									</Col>
									<Col md={3} sm={3} xs={3} className="num-tip" >
										<span className="fl_l"></span>
										<span className="fl_r"></span>
									</Col>
									<Col md={3} sm={3} xs={3} className="num-tip" >
										<span className="fl_l"></span>
										<span className="fl_r"></span>
									</Col>
								</Row>
							</Row>
							)
						}):''
						
					}
				</div>			
			</div>
        );
    }
}

//export default connect(mapStateToProps, mapDispatchToProps)(Leagues)
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Leagues));