import axios from 'axios'
import {TOKEN_NAME,BASE_API} from './constants/actionTypes'
import 'url-search-params-polyfill'
import $ from 'jquery';

let token = null   
let check_token=null;
const checktoken = () => {
	let api = BASE_API + 'checktoken';
   
	let userinfo = window.localStorage.getItem(TOKEN_NAME)	
	const data = (userinfo)?JSON.parse(userinfo):''
	//console.log(data);
	let access_token = ''
	let expires_in = ''
	let refresh_token = ''
	let token_type = ''
	if(data != '' && typeof data !== 'undefined' && data != null ){
		 access_token = data.access_token
		 expires_in = data.expires_in
		 refresh_token = data.refresh_token
		 token_type = data.token_type
	}
	
		
	return axios({
	  method: 'POST',
	  headers : {
		'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8',
		'Authorization': 'Bearer ' + access_token ,
		'Accept': 'application/json'	
	  },          
	  url: api,
	  data: '',         

	}).then(response => {
		//console.log(response.data.result)
		check_token=response.data.result
		if(response.data.result=="bad"){
			setToken(null)
			window.localStorage.setItem(TOKEN_NAME, '')
			window.location.href = 'login'
		}
		//return check_token
	}).catch(e => {
		
		if(e.response && e.response.status == 401){
			setToken(null)
			window.localStorage.setItem(TOKEN_NAME, '')
			window.location.href = 'login'					
		}
	}) 

};
const requests = (url, body) => {

    let api = BASE_API + url;

    var params = new URLSearchParams();
  
    for(let key in body)
    {
        if(body[key]){
            params.set(key, body[key]);    
        }
    }
          
    
    return axios({
      method: 'POST',
      headers : {
        //'Content-Type' : 'multipart/form-data', 
        'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8',      
      },          
      url: api,
      data: params,         

    }).then(response => response.data);  

};
const requestNew = (url, body) => {
	//checktoken();
	//if(check_token=='good')
		let api = BASE_API + url;
    
		var params = new URLSearchParams();

		for(let key in body)
		{
			if(body[key]){
				params.set(key, body[key]);    
			}
		}
		
		let userinfo = window.localStorage.getItem(TOKEN_NAME)	
		//console.log(userinfo)
		if(userinfo!=''){
			const data =JSON.parse(userinfo)
			//console.log(data);
			let access_token = ''
			let expires_in = ''
			let refresh_token = ''
			let token_type = ''
			if(data != '' && typeof data !== 'undefined' && data != null ){
				 access_token = data.access_token
				 expires_in = data.expires_in
				 refresh_token = data.refresh_token
				 token_type = data.token_type
			}
			return axios({
			  method: 'POST',
			  headers : {
				'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8',
				'Authorization': 'Bearer ' + access_token ,
				//'Accept': 'application/json'	
			  },          
			  url: api,
			  data: params,         

			}).then(response => response.data).catch(e => {
				//if(e.response && e.response.status === 401){
					//setToken(null)
					//window.localStorage.setItem(TOKEN_NAME, '')
					//window.location.href = 'login'					
				//}
			}) 
		
		}else{
			window.location.href = 'login'	
		}
};
const requestforArray = (url, params) => {
	//checktoken();
	let api = BASE_API + url;
    
    const formData  = new FormData()
	  for(let name in params) {
		let param = params[name]
		if (typeof param === 'object') {
		  param = JSON.stringify(params[name])
		}
		formData.append(name, param)
	  }
	//console.log(params)
	let userinfo = window.localStorage.getItem(TOKEN_NAME)	
	if(userinfo!=''){
		const data = JSON.parse(userinfo)
		let access_token = ''
		let expires_in = ''
		let refresh_token = ''
		let token_type = ''
		if(data != '' && typeof data !== 'undefined' && data != null ){
			 access_token = data.access_token
			 expires_in = data.expires_in
			 refresh_token = data.refresh_token
			 token_type = data.token_type
		}
		
			
		return axios({
		  method: 'POST',
		  headers : {
			'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8',
			'Authorization': 'Bearer ' + access_token ,
			'Accept': 'application/json'	
		  },          
		  url: api,
		  data: formData,         

		}).then(response => response.data).catch(e => {
			//if(e.response && e.response.status === 401){
				setToken(null)
				window.localStorage.setItem(TOKEN_NAME, '')
				window.location.href = 'login'					
			//}
		}) 
	}else{
		window.location.href = 'login'	
	}
	

};
const submitform = (url, body) => {
   //checktoken();
   let api = BASE_API + url;
   let fromData = new FormData();
   //var fromData = new URLSearchParams();
   //let fromData = {}
		for(let key in body){
			if( body[key] != null && body[key].isArray ){  
				for(let i in body[key]){
					fromData.append(key + '[]', body[key][i]);                       
				}        
			} else {
				fromData.append(key, body[key]);    
			}  
		} 
   let userinfo = window.localStorage.getItem(TOKEN_NAME)	
   //if(userinfo!=''){
	   //const data = JSON.parse(userinfo)
		//console.log(data);
		let access_token = ''
		let expires_in = ''
		let refresh_token = ''
		let token_type = ''
		/*if(data != '' && typeof data !== 'undefined' && data != null ){
			 access_token = data.access_token
			 expires_in = data.expires_in
			 refresh_token = data.refresh_token
			 token_type = data.token_type
		}
		*/
		return axios({
		  method: 'POST',
		  headers : {
			'Content-Type' : 'multipart/form-data', 
			'Authorization': 'Bearer ' + access_token      
		  },          
		  url: api,
		  data: fromData,         

		}).then(response => response.data).catch(e => {
			if(e.response && e.response.status === 401){
				//setToken(null)
				window.localStorage.setItem(TOKEN_NAME, '')
				window.location.href = 'login'					
			}
		})
  // }else{
	  // window.location.href = 'login'
 //  }
	  

};
const submitformNew = (url, body) => {
	
	let api = BASE_API + url;
   let fromData = new FormData();
   //var fromData = new URLSearchParams();
   //let fromData = {}
		for(let key in body){
			if( body[key] != null && body[key].isArray ){  
				for(let i in body[key]){
					fromData.append(key + '[]', body[key][i]);                       
				}        
			} else {
				fromData.append(key, body[key]);    
			}  
		} 
   
	let userinfo = window.localStorage.getItem(TOKEN_NAME)	
	if(userinfo!=''){
	   const data = JSON.parse(userinfo)
		//console.log(data);
		let access_token = ''
		let expires_in = ''
		let refresh_token = ''
		let token_type = ''
		if(data != '' && typeof data !== 'undefined' && data != null ){
			 access_token = data.access_token
			 expires_in = data.expires_in
			 refresh_token = data.refresh_token
			 token_type = data.token_type
		}
		
		return axios({
		  method: 'POST',
		  headers : {
			'Content-Type' : 'multipart/form-data', 
			'Authorization': 'Bearer ' + access_token      
		  },          
		  url: api,
		  data: fromData,         

		}).then(response => response.data).catch(e => {
			if(e.response && e.response.status === 401){
				//setToken(null)
				//window.localStorage.setItem(TOKEN_NAME, '')
				//window.location.href = 'login'					
			}
		})
	}
};

const header = {
	getlangs: () => 
		requests('getlangs', {}),
	
};

const home = {
	getListData: params => 
		requests('dashboard/data', params),
	saveDashboard: params =>
		requests('dashboard/save', params),
	getrandomad: params =>
		requests('getrandomad', params),
	getrandomad2: params =>
		requests('getrandomad2', params),
	gethomebetlist: params =>
		requests('gethomebetlist', params),
	gethomebetlistNew: params =>
		requestNew('gethomebetlist', params),
	togglelikebet: params =>
		requestNew('togglelikebet', params),
	tipLookup: params =>
		requestNew('tipster/lookup', params),
	likeList: params =>
		requestNew('like/userlist', params),
	mobilehomebetlist: params =>
		requestNew('mobilehomebetlist', params),
	togglelikepick: params =>
		requestNew('togglelikepick', params),
	mobiletipsummarylist: params =>
		requestNew('mobiletipsummarylist', params),
	mobiletodaylisttobet: params =>
		requestNew('mobiletodaylisttobet', params),
	mobilematchlistinleaguetobet: params =>
		requestNew('mobilematchlistinleaguetobet', params),
	mobileBetList: params =>
		requestNew('mobileuser/bet-list', params),
	mobileUserInfo: params =>
		requestNew('mobileuser/user-info', params),
	mobileUserStatistic: params =>
		requestNew('mobileuser/statistic', params),
	mobileUserStatisticMonth: params =>
		requestNew('mobileuser/statistic-month', params),
	mobileUserStatisticQuarter: params =>
		requestNew('mobileuser/statistic-quarter', params),
	mobileUserStatisticYear: params =>
		requestNew('mobileuser/statistic-year', params),
	mobileUserStatisticFriends: params =>
		requestNew('mobileuser/friends', params),
	edit_in_own_topic: params =>
		requestNew('comment/edit-in-own-topic', params),	
	priceMonthSave: params =>
		requestNew('price-month-save', params),
	createOrder: params =>
		requestNew('order/create', params),
	getOrderInfo: params =>
		requestNew('order/info', params),
	getBankTypes: params =>
		requestNew('bank-types', params),
	orderProofofpayment: params =>
		requestNew('order/proofofpayment', params),
};

const auth = {
	doLogin: params =>
		requests('login', params),
	gsignin: params =>
		requests('gsignin', params),
	fsignin: params =>
		requests('fsignin', params),
	uploadAvartar: params=>
		submitform('upload-avatar', params),
	uploadLogoAd: params=>
		submitform('upload-adlogo', params),
	uploadBanner: params=>
		submitform('upload-banner', params),		
	sporttip: params =>
		requests('sporttip/shortlist', params),
	timezone: params =>
		requests('timezone/shortlist', params),
	country: params =>
		requests('country/shortlist', params),
	signup: params =>
		requests('signup', params),
	getProfile: params =>
		requestNew('profile/get', params),
	editProfile: params =>
		requestNew('profile/edit', params),
	editPersonal: params =>
		requestNew('mobileeditprofilepersonalinfo', params),
	editBetting: params =>
		requestNew('mobileeditprofilebettinginfo', params),
	defaultcountryandtimezone: params =>
		requests('defaultcountryandtimezone', params),
	emailRecoverycode: params =>
		requests('forgotpw/email-recoverycode', params),
	checkRecoverycode: params =>
		requests('forgotpw/check-recoverycode', params),
	newpw: params =>
		requests('forgotpw/newpw', params),
	contactCreate: params =>
		requests('contact/create', params),
	sendotpsms: params =>
		requestNew('sendotpsms', params),
	validateotpsms: params =>
		requestNew('validateotpsms', params),
	getbankrolls: params =>
		requestNew('getbankrolls', params),
	addbankroll: params =>
		requestNew('addbankroll', params),
	setDefaultBankRoll: params =>
		requestNew('setdefaultbankroll', params),
	activeaccount: params =>
		requests('activeaccount', params),
	uploadProofofpayment: params=>
		submitformNew('upload-proofofpayment', params),	
	getaddlogos: params =>
		requests('getaddlogos', params),
}
const admin={
	userList: params =>
		requestNew('user/list', params),
	userinfo: params =>
		requestNew('user/info', params),
  matchList: params =>
    requestNew('match/list', params),
	matchapproved: params =>
    requestNew('matchapproved', params),
  allmatchapproved: (params) =>
		requestforArray('allmatchapproved', params),
	adminrolelist: (params) =>
		requestNew('admin/role-list', params),
	admincreataduser: (params) =>
		requestNew('admin/create-sportsbook-rep', params),
	adminedituser: (params) =>
		requestNew('admin/edit-user', params),
	tipsterListIncontest: params =>
		requestNew('statistic/tipster-list-incontest', params),	
	contestInfo: params =>
		requestNew('contest-info', params),	
}
const tips={
	listtobet: params =>
		requestNew('tip/listtobet', params),
	//infotobet: params =>
		//requestNew('tip/infotobet', params),
	choosetobet: params =>
		requestNew('tip/choosetobet', params),
	deletebet: params =>
		requestNew('tip/deletebet', params),
	createtips: params =>
		requestNew('tip/createtips', params),
	leagueList: params =>
		requestNew('league/list', params),
	timemenuData: params =>
		requestNew('tip/timemenu-data', params),
	leagueListtobet: params =>
		requestNew('league/listtobet', params),
	infotobet: params =>
		requestNew('mobiletip/infotobet', params),
}
const friends={
	createrequest: params =>
		requestNew('friend/create-request', params),
	requestlistandcanknowlist: params =>
		requestNew('friend/requestlistandcanknowlist', params),
	deleteRequest: params =>
		requestNew('friend/delete-request', params),
	confirmRequest: params =>
		requestNew('friend/confirm-request', params),
	friendList: params =>
		requestNew('friend/list', params),
	deleteFriend: params =>
		requestNew('friend/delete', params),
	createFollow: params =>
		requestNew('follow/create', params),
	removeFollow: params =>
		requestNew('follow/remove', params),
}
const comment={
	createComment: params =>
		//requestNew('comment/create', params),
		requestNew('comment/running-create', params),
	listComment: params =>
		requestNew('comment/list', params),
	togglelike: params =>
		requestNew('comment/togglelike', params),
	toggledislike: params =>
		requestNew('comment/toggledislike', params),
	deleteInOwnTopic: params =>
		requestNew('comment/delete-in-own-topic', params),
}
const profile={
	profileInfo: params =>
		requestNew('profile/info', params),
	friends: params =>
		requestNew('profile/friends', params),
	timeline: params =>
		requestNew('profile/timeline', params),
	statistic: params =>
		requestNew('profile/statistic', params),
	statistic_month: params =>
		requestNew('profile/statistic/month', params),
	statistic_year: params =>
		requestNew('profile/statistic/year', params),
	statistic_quarter: params =>
		requestNew('profile/statistic/quarter', params),
	check_url: params =>
		requests('check/urlusername', params),
	check_url_friendly: params =>
		requests('check/checkurlfriendly', params),
	getTipsterListInmonth: params =>
		requestNew('statistic/tipster-list-inmonth', params),
	getTipsterListInYear: params =>
		requestNew('statistic/tipster-list', params),
}
const running={
	homePick: params =>
		requestNew('running/home-pick', params),
	userInfo: params =>
		requestNew('running/user-info', params),
	createComment: params =>
		requestNew('comment/running-create', params),
	blockUser: params =>
		requestNew('block/user', params),
	toblockList: params =>
		requestNew('userlist-toblock', params),
	blockedusers: params =>
		requestNew('block/blockedusers', params),
	unBlock: params =>
		requestNew('block/un-block', params),
}
const upload={
	importallleagueevent: params =>
		submitform('importallleagueevent', params),
	importmatchresult: params =>
		submitform('importmatchresult', params),
}
const ads={
	adList: params =>
		requestNew('admin/ad-list', params),
	adUserListforAdmin: params =>
		requestNew('admin/ad-users-list', params),
	createAd: params =>
		requestNew('admin/create-ad', params),
	editAd: params =>
		requestNew('admin/edit-ad', params),
	adInfo: params =>
		requestNew('admin/ad-info', params),
	deleteAd: params =>
		requestNew('admin/delete-ad', params),
	adUserList: params =>
		requestNew('aduser/ad-list', params),
	adUserEdit: params =>
		requestNew('aduser/edit-ad', params),
	adUserInfo: params =>
		requestNew('aduser/ad-info', params),
	bookMaker: params =>
		requestNew('bookmaker-news', params),
	bookMakerAd: params =>
		requestNew('bookmaker/ad-list', params),
	adlongdescription: params =>
		requests('adlongdescription', params),
}
const notification={
	newNumber: params =>
		requestNew('notification/new-number', params),
	readAll: params =>
		requestNew('notification/read-all', params),
	getList: params =>
		requestNew('notification/list', params),
	readOne: params =>
		requestNew('notification/read-one', params),
}
const orders={
	getListForBuyer: params =>
		requestNew('order/listforbuyer', params),
	getListForSeller: params =>
		requestNew('order/listforseller', params),
	sellerapprove: params =>
		requestNew('order/sellerapprove', params),
	getOrdersofAdmin: params =>
		requestNew('admin/order-list', params),
	ordersBuyerReport: params =>
		requestNew('order/buyer-report', params),
	getOrderReportInfo: params =>
		requestNew('admin/order-report-info', params),
	getExchangeTips: params =>
		requestNew('admin/exchange-tips', params),
	getSummaryForBuyer: params =>
		requestNew('order/summaryforbuyer', params),
	getBuyertips: params =>
		requestNew('order/buyertips', params),
	getOrderPrepare: params =>
		requestNew('order/prepare', params),
		
}
const setToken = _token => {
	token = _token
}

export default {
  header,
  home,
  auth,
  admin,
  tips,
  friends,
  comment,
  profile,
  running,
  upload,
  ads,
  notification,
  orders,
  setToken
};
