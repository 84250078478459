import React from 'react';
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import agent from '../../agent'
import {Col, Row,FormControl, Form, FormGroup, ControlLabel, Button} from 'react-bootstrap'
import '../../styles/App.css'
import '../../styles/Friends.css'
import $ from 'jquery';
import {TOKEN_NAME, REDIRECT,LANGUAGE_KEY,CHANGE_TAB} from '../../constants/actionTypes'
import {withRouter, history} from 'react-router-dom'
import { localize, localizeKey,setLocale, getLocale } from 'react-redux-localization';
import { Cookies } from 'react-cookie' 
import translations from '../common/captionhome.json';
import ModalErorr from '../common/ModalErorr'

const mapStateToProps = state => (
    Object.assign({}, state, {
		paramsSave: state.home,
		token: state.common.token,
		tab: state.common.tab,
		languageKey: state.common.languageKey,
		languages:state.common.languageslist,
		//active: state.common.active,
		//user_fiend: state.common.user_fiend,
	})      
);

const mapDispatchToProps = dispatch => ({               
	
	onRedirect: () =>
		dispatch({ type: REDIRECT }),
	
	onTabClick: (tab) => 
		dispatch({ type: CHANGE_TAB, tab }),
	
});

class FriendsRequest extends React.Component {
	constructor(){
		super()
		this.state = {
			url:'https://www.coopbet.com/media/user_none.jpg',
			list:[],msgLogin:'',user_id:'',PickCodeComingNumber:'',url_back:'',
			lguages:'1005',isOpenModal:false,msgLogin:''
		}
		//---
		this.close=()=>{
			this.setState({isOpenModal:false})
		}
		//----
		
		//----
		
		
	}
	
	
	//---------------------------
    
	componentWillReceiveProps(nextProps) {
		//console.log(nextProps)
		if(nextProps.languageKey){
			this.setState({lguages:nextProps.languageKey});
		}
	}	

	componentWillMount() {
		//---
		const cookies = new Cookies()
		let language=(cookies.get('language')!='' && cookies.get('language') !=null && cookies.get('language')!== 'undefined')?cookies.get('language'):'1005';
		this.setState({'lguages':language})
		//----
		let login=window.localStorage.getItem(TOKEN_NAME);
		 
		if(login!='' && login!='null' && login!=null){
			 login=JSON.parse(login)
			 this.setState({id_user:login.UserInfo.id})
		}
		this.friendlist();
		
	}	
	componentDidMount() {
		
	}
	
	
	showBoxFriend(ev){
		let $this=ev.target.closest(".g-btn")
		$($this).find(".dr-fr").show();
		//console.log($this)
	}
	hideBoxFriend(ev){
		let $this=ev.target.closest(".g-btn")
		$($this).find(".dr-fr").hide();
	}
	deleteFriend(ev){
		let $this=ev.target
		let id=$($this).attr("data-id")
		agent.friends.deleteFriend({friend_user_id:id}).then((datas) => {
			//console.log(datas)
			if(datas.result=='good'){
				let newList=this.state.list.map(item=>{
					if(item!='' && item!='null' && item!=null && item.user_id != id){
						return item;
					}else{
						return null;
					}
				})
				this.setState({list:newList})
			}
			
		  })	
	}
	deleteRequest(ev){
		let $this=ev.target
		let id=$($this).attr("data-id")
		agent.friends.deleteRequest({friend_user_id:id}).then((datas) => {
			//console.log(datas)
			if(datas.result=='good'){
				let newList=this.state.list.map(item=>{
					if(item!='' && item!='null' && item!=null && item.user_id != id){
						return item;
					}else{
						return null;
					}
				})
				this.setState({list:newList})
			}
			
		  })	
	}
	removeFollow(ev){
		let thiss=ev.target;
		let id=$(thiss).attr("data-id");
		agent.friends.removeFollow({followed_user_id:id}).then(data => {
			if(data.result=="good"){
				
			}
		})
	}
	
	myFunction() {
	  $("#myDropdown").addClass("show");
	}
	goBackPage(e){
		window.location.href="./"+this.state.url_back;
	}
	goProfile(id,e){
		window.localStorage.setItem('user_id',id )
		this.props.history.push("/profile")
	}
	createrequest(ev){
		
		let thiss=ev.target.closest('.add-friend');
		$(thiss).find('.loading').show();
		const to_user_id=$(thiss).attr("data-id")
		agent.friends.createrequest({to_user_id:to_user_id}).then(data => {
			//console.log(data)
			if(data.result === 'good'){
				$(thiss).find('.loading').hide();
				const list=this.state.list.map(item=>{
					if(item.user_id==to_user_id){
						item.FriendStatusWithLoginUser='Pending'
					}
					return item;
				})
				this.setState({isOpenModal:false,msgLogin:'',list:list})
			}else{
				this.setState({isOpenModal:true,msgLogin:data.message})
				$(thiss).find('.loading').hide();
			}
		})
	}
	friendlist(){
		agent.friends.requestlistandcanknowlist({limit:0}).then((datas) => {
			//console.log(datas)
			if(datas.result=='good'){
				
				this.setState({list:datas.PendingFriendRequestList})
			}
			
		  })	
	}
	confirmRequest(ev){
		let thiss=ev.target;
		let friend_request_id=$(thiss).attr("data-id");
		agent.friends.confirmRequest({friend_request_id:friend_request_id}).then((datas) => {
			//console.log(datas)
			if(datas.result=='good'){
				const PendingFriendRequestList=this.state.list.map(item=>{
					if(item!='' && item!='null' && item!=null && item.request_id!=friend_request_id){
						return item;
					}else{
						return null;
					}
					
				})
				this.setState({isOpenModal:false,msgLogin:'',list:PendingFriendRequestList})
				//$(thisss).remove();
			}else{
				this.setState({isOpenModal:true,msgLogin:datas.message})
			}
			
		  })	
	}
	render() {
		$(".body").css({'background':'#e9ebee'})
		$(window).hover(function(e){
			if($(e.target).closest(".g-btn").length==0 && $(e.target).closest(".dr-fr").length==0 ){
				$(".dr-fr").hide();
			}
		})
		
		let url=this.state.url
		const PickCodeComingNumber=this.state.PickCodeComingNumber
		const list=this.state.list
		
		//console.log(list)
		//---------
			return (				
					
					<Row>
						<Col md={12} className="friend-body">
							<div className="fiend-box-top" id="friend-request">
								<div className="frd-top">
									<div className="ft-items">
										<Col md={12} className="ctn">
											{
												(list!='' && list!='null' && list!=null)?list.map((data,index)=>{
													if(data!='' && data!='null' && data!=null){
														return(
															<Col md={6} className="ft-item ft-item-0 ft-item-bst" key={index}>
																<figure className="pull-left"><img src={(data.imageurl!='' && data.imageurl!=null)?data.imageurl:url} width="50" height="50" className="_rv"/></figure>
																<div className="fr-it-body">	
																	<div className="name name-fr pull-left">
																		<span className="fwb" onClick={this.goProfile.bind(this,data.user_id)}>{data.tipster_name}</span>
																		<div className="count-fr">{data.friendcount} {(data.friendcount>1)?'friends':''}</div>
																	</div>
																	
																	
																	<div className="g-btn pull-right mrgt5px">
																		<a className="btn-2 pull-right add-friend" data-id={data.user_id} onClick={this.confirmRequest.bind(this)}>
																			Confirm
																			<img className="loading" src="https://www.coopbet.com/media/loading.gif" width="20" style={{'display':'none'}}/>
																		</a>
																		<a className="btn-2 pull-right tfd-delete tfd-delete-7" data-id={data.user_id} onClick={this.deleteRequest.bind(this)}>Delete</a>
																	</div>
																	
																</div>
															</Col>
														)
													}
													
												}):<div></div>
											}
											
											
											<ModalErorr isOpen={this.state.isOpenModal} close={this.close} text={this.state.msgLogin}/>	
										</Col>
									</div>
								</div>
							</div>
						</Col>
					</Row>
						
						
			)	
	}
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FriendsRequest));
