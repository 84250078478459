import React, {Component} from 'react'
import { connect } from 'react-redux'
import { localize, localizeKey,setLocale, getLocale } from 'react-redux-localization';
import { Cookies } from 'react-cookie' 
import '../../styles/App.css'
import '../../styles/home.css'
import {Col, Row, Button, OverlayTrigger, Tooltip} from 'react-bootstrap'
//import { createElementsFromText } from 'html-text-to-react';
//import ReactHtmlParser from 'react-html-parser';
import $ from 'jquery';
import {TOKEN_NAME,LANGUAGE_KEY,BASE_IMAGE} from '../../constants/actionTypes'
import agent from '../../agent'
import Lhome from '../common/home.json';
import ReplyList from './ReplyList'
import { Link} from 'react-router-dom'
import TextareaAutosize from 'react-textarea-autosize';
import ModalEditComment from './ModalEditComment';

import '@fortawesome/fontawesome-free/css/fontawesome.css'
import '@fortawesome/fontawesome-free/css/brands.css'
import '@fortawesome/fontawesome-free/css/solid.css'
import '@fortawesome/fontawesome-free/css/all.css'
import ReactHtmlParser from 'react-html-parser';

const mapStateToProps = state => (
    Object.assign({}, state, {
		dashboardData: state.common.dashboardData,
		tab: state.common.tab,
		updateProps: state.common.updateProps,
		locale: getLocale(state),
		languageKey: state.common.languageKey
	}) 

);

const mapDispatchToProps = dispatch => ({               
	
	chooseLocale: locale => dispatch(setLocale(locale))
});



 


class Comment extends Component{ 	
	constructor(){
		super()
		this.state = {
			lguages:'1005',
			
			CommentList:[],TotalComments:'',numCM:'',PickCode:'',
			url:'https://www.coopbet.com/media/avartar_cm.jpg',
			img_default:'https://www.coopbet.com/media/avartar_cm.jpg',
			url_like:'https://www.coopbet.com/media/likec.png',
			url_liked:'https://www.coopbet.com/media/likedc.png',
			url_dislike:'https://www.coopbet.com/media/dislikec.png',
			url_disliked:'https://www.coopbet.com/media/dislikedc.png',
			icon_send:"https://m.coopbet.com/img/send_32.png",
			icon_error:"https://m.coopbet.com/img/cancel.png",
			for_pick_code:'',parent_id:'',backoffset:0,numlike:'',likeList:[],id_user:'',
			PickLikedNum:'',PickLikedUserList:[],PickLikedUserIDList:[],PickCodeRunning:'',
			UserInfoId:'',PickCodeComingNumber:'',PickCodeComingUsers:[],rows:1,
			isOpenModalEditCm:false,comment_id:'',txt_comment:'',txt_title:''
		}
		//---
		this.listComment=this.listComment.bind(this)
		this.listCommentAfterRely=this.listCommentAfterRely.bind(this)
		this.createCommentChild=this.createCommentChild.bind(this)
		this.createComment=this.createComment.bind(this)
		this.updateTotalRely=this.updateTotalRely.bind(this)
		//---
		this.updateTotalCM=(TotalComments)=>{
			this.setState({TotalComments:TotalComments})
		}
		this.handleKeyPress = (event) => {
		  if(event.key === 'Enter'){
			this.autosize();
		  }
		}
		//---
		this.showModalEditCM=(comment_id,for_pick_code,comment)=>{
			this.setState({isOpenModalEditCm:true,comment_id:comment_id,
						   txt_comment:comment,for_pick_code:for_pick_code,txt_title:'Edit Comment'})
		}
		this.updateCommentList=(comment_id,comment)=>{
			let CommentList=this.state.CommentList.map(dt=>{
				if(dt.comment_id==comment_id){
					dt.comment=comment
					dt.edit_cm=1
				}
				return dt;
			})
			this.setState({CommentList:CommentList})
		}
		this.close=()=>{
			this.setState({isOpenModalEditCm:false})
		}
	}
	
	componentWillMount(){	
		//-----
		const cookies = new Cookies()
		let language=(cookies.get('language')!='' && cookies.get('language') !=null && cookies.get('language')!== 'undefined')?cookies.get('language'):'1005';
		this.setState({'lguages':language})
		//-----
		let TotalComments=this.props.TotalComments
		let PickCode=this.props.PickCode
		const url=(this.props.imageurl!='' && this.props.imageurl!=null && this.props.imageurl!='null')?this.props.imageurl:this.state.url
		const PickLikedNum=this.props.PickLikedNum;
		const PickLikedUserList=this.props.PickLikedUserList;
		const PickLikedUserIDList=this.props.PickLikedUserIDList;
		const id_user=this.props.id_user;
		const PickCodeRunning=this.props.PickCodeRunning;
		const PickCodeComingNumber=this.props.PickCodeComingNumber;
		const PickCodeComingUsers=this.props.PickCodeComingUsers;
		const UserInfoId=this.props.UserInfoId;
		this.setState({TotalComments:TotalComments,PickCode:PickCode,url:url,PickLikedNum:PickLikedNum,
						PickLikedUserList:PickLikedUserList,PickLikedUserIDList:PickLikedUserIDList,
						id_user:id_user,PickCodeRunning:PickCodeRunning,UserInfoId:UserInfoId,
						PickCodeComingNumber:PickCodeComingNumber,PickCodeComingUsers:PickCodeComingUsers})
	}
	componentDidMount(){
		
		//console.log(this.props.PickCode)
		//var textarea = document.querySelector('textarea');
		//textarea.addEventListener('keydown', this.autosize);
	}
	
	componentWillReceiveProps(nextProps){
		//console.log(nextProps)
		if(nextProps.languageKey && nextProps.languageKey!='' && nextProps.languageKey!=null && nextProps.languageKey!='null'){
			this.setState({lguages:nextProps.languageKey});
		}
		if(nextProps.PickCode!='' && nextProps.PickCode!=null && nextProps.PickCode!=this.state.PickCode){
			this.setState({PickCode:nextProps.PickCode},(e)=>{
				//this.listComment();
			})
		}
		if(nextProps.PickCodeComingNumber && nextProps.PickCodeComingNumber!='' && nextProps.PickCodeComingNumber!=null){
			this.setState({PickCodeComingNumber:nextProps.PickCodeComingNumber,PickCodeComingUsers:nextProps.PickCodeComingUsers});
		}
		
	}
	autosize(){
	 /* var el = this;
	  setTimeout(function(){
		el.style.cssText = 'height:auto; padding:0';
		// for box-sizing other than "content-box" use:
		// el.style.cssText = '-moz-box-sizing:content-box';
		el.style.cssText = 'height:' + el.scrollHeight + 'px';
	  },0);*/
	  this.setState({rows:this.state.rows+1})
	}
	shouldComponentUpdate(nextProps, nextState){
		//console.log(nextProps, nextState)
		return nextState;
	}	
	componentDidUpdate(prevProps, prevStat) {
		//console.log(prevProps, prevStat)
	}
	listComment(for_pick_code,parent_id){
		//let for_pick_code=this.state.PickCode
		//let parent_id=0;
		let backoffset=parseInt(this.state.backoffset+5);
		agent.comment.listComment({for_pick_code:for_pick_code,parent_id:parent_id,limit:5,offset:0}).then(data => {
			//console.log(data)
			if(data.result=="good"){
				let CommentList=data.CommentList.map(dt=>{
					dt.show_rely=0
					return dt;
				})
				this.setState({CommentList:CommentList,TotalComments:data.TotalComments,numCM:data.TotalComments,backoffset:0})
			}
		})
	}
	listCommentAfterRely(for_pick_code,comment_id){
		//let limit=(this.state.backoffset+5);
		agent.comment.listComment({for_pick_code:for_pick_code,parent_id:0,limit:5,offset:0}).then(data => {
			
			if(data.result=="good"){
				let CommentList=data.CommentList.map(dt=>{
						if(dt!=null && dt.comment_id==comment_id){
							dt.show_rely=1
						}
						return dt;
					})
				this.setState({CommentList:CommentList,TotalComments:data.TotalComments,numCM:data.TotalComments})
			}
		})
	}
	docm(ev){
		//ev.preventDefault();
		let thiss=ev.target.closest(".commet-tp")
		let pickcode=$(ev.target).attr("pickcode")
		//console.log(pickcode)
		let ot=$(thiss).attr("option")
		if(ot==0){
			$(ev.target).closest(".commet-tp").find(".cmt-list").hide();
			$(thiss).attr("option",1)
		}else{
			$(ev.target).closest(".commet-tp").find(".cmt-list").show();
			$(thiss).attr("option",0)
			this.listComment(pickcode,0)
		}
		if(this.state.for_pick_code!=pickcode){
			this.setState({for_pick_code:pickcode})
		}
		//
	}
	handleRely(e){
		let thiss=e.target.closest(".ct-cmt")
		$(thiss).find(".area_cha").show();
		//$(thiss).find(".t-reply").show();
	}
	togglelike(comment_id,ev){
		agent.comment.togglelike({comment_id:comment_id}).then(data => {
			//console.log(data)
			if(data.result=="good"){
				const CommentList=this.state.CommentList.map(item=>{
					if(item.comment_id==comment_id){
						item.LikedNum=data.LikedNum
						item.IsLiked=data.IsLiked
						item.IsDisliked=data.IsDisliked
					}
					return item
				})
				this.setState({CommentList:CommentList})
			}
		})
	}
	toggledislike(comment_id,ev){
		agent.comment.toggledislike({comment_id:comment_id}).then(data => {
			//console.log(data)
			if(data.result=="good"){
				const CommentList=this.state.CommentList.map(item=>{
					if(item.comment_id==comment_id){
						item.LikedNum=data.LikedNum
						item.IsDisliked=data.IsDisliked
						item.IsLiked=data.IsLiked
					}
					return item
				})
				this.setState({CommentList:CommentList})
			}
		})
	}
	hideCM(for_pick_code,parent_id,e){
		/*let thiss=e.target.closest(".reply-List");
		$(thiss).find(".view-cm").hide();
		$(thiss).find(".hide-cm").show();
		this.setState({for_pick_code:for_pick_code,parent_id:parent_id})*/
		let CommentList=this.state.CommentList.map(dt=>{
			if(dt!=null && dt.comment_id==parent_id){
				dt.show_rely=1
			}
			return dt;
		})
		this.setState({CommentList:CommentList,for_pick_code:for_pick_code,parent_id:parent_id})
	}
	viewCM(comment_id,e){
		/*let thiss=e.target.closest(".reply-List");
		$(thiss).find(".view-cm").show();
		$(thiss).find(".hide-cm").hide();
		//this.setState({for_pick_code:'',parent_id:''})*/
		let CommentList=this.state.CommentList.map(dt=>{
			if(dt!=null && dt.comment_id==comment_id){
				dt.show_rely=0
			}
			return dt;
		})
		this.setState({CommentList:CommentList})
	}
	hideRyply(e){
		let thiss=e.target.closest(".t-reply")
		$(thiss).hide();
	}
	createCommentChild(parent_id,for_pick_code,e){
		let thiss=e.target.closest(".t-reply")
		let comment=$(thiss).find(".area-cmt").val();
		let UserInfoId=this.state.UserInfoId
		//---
		//$(thiss).find('.do_cm').hide()
		//$(thiss).find('.ot-btn').hide()
		//$(thiss).find('.loading_dad').show()
		//---
		agent.comment.createComment({for_pick_code:for_pick_code,comment:comment,parent_id:parent_id}).then(data => {
			//console.log(data)
			if(data.result=="good"){
				//this.props.listComment(for_pick_code,0)
				this.listComment(for_pick_code,0)
				$(thiss).hide()
				$(thiss).find(".area-cmt").val('');
				window.location.href="./comment/"+UserInfoId+"_"+for_pick_code+"_"+parent_id;
			}
		})
	}
	createComment(parent_id,e){
		let thiss=e.target.closest(".row")
		let comment=$(thiss).find(".txt-comment").val();
		let for_pick_code=this.state.PickCode
		let UserInfoId=this.state.UserInfoId
		//---
		//$(thiss).find('.do_cm').hide()
		//$(thiss).find('.ot-btn').hide()
		//$(thiss).find('.loading_dad').show()
		//---
		agent.comment.createComment({for_pick_code:for_pick_code,comment:comment,parent_id:parent_id}).then(data => {
			//console.log(data)
			if(data.result=="good"){
				this.listComment(for_pick_code,0)
				//$(thiss).find('.do_cm').show()
				//$(thiss).find('.loading_dad').hide()
				$(thiss).find(".txt-comment").val('');	
				window.location.href="./comment/"+UserInfoId+"_"+for_pick_code
			}
		})
	}
	viewComment(for_pick_code){
		let backoffset=parseInt(this.state.backoffset+5);
		agent.comment.listComment({for_pick_code:for_pick_code,parent_id:0,limit:5,offset:backoffset}).then(data => {
			//console.log(data)
			if(data.result=="good"){
				let Comments=data.CommentList.map(dt=>{
					if(dt!=null){
						dt.show_rely=0
						return dt;
					}
				})
				//let cm_list=this.state.CommentList.concat(data.CommentList)
				let cm_list=this.state.CommentList.concat(Comments)
				this.setState({CommentList:cm_list,TotalComments:data.TotalComments,numCM:data.TotalComments,backoffset:backoffset})
			}
		})
	}
	togglelikepick(ev){
		let pick_code=$(ev.target).attr("pick_code")
		agent.home.togglelikepick({pick_code:pick_code}).then(data => {
			//console.log(data)
			if(data.result=="good"){
				let PickLikedNum=data.PickLikedNum;
				let PickLikedUserIDList=data.PickLikedUserIDList;
				let PickLikedUserList=data.PickLikedUserList;
				this.setState({PickLikedNum:PickLikedNum,PickLikedUserIDList:PickLikedUserIDList,PickLikedUserList:PickLikedUserList})
			}
		})
	}
	goFriendList(e){
		let PickLikedUserIDList=this.state.PickLikedUserList
		let PickLikedNum=this.state.PickLikedNum
		let pick_code=this.state.PickCode
		window.localStorage.setItem('PickLikedUserIDList',JSON.stringify(PickLikedUserIDList) ) 
		window.localStorage.setItem('PickLikedNum',PickLikedNum ) 
		window.localStorage.setItem('pick_code',pick_code ) 
		window.localStorage.setItem('url_back',window.location.pathname) 
		window.location.href='./like-list'
	}
	goRunningList(e){
		let PickCodeComingUsers=this.state.PickCodeComingUsers
		let PickCodeComingNumber=this.state.PickCodeComingNumber
		window.localStorage.setItem('PickCodeComingUsers',JSON.stringify(PickCodeComingUsers) ) 
		window.localStorage.setItem('PickCodeComingNumber',PickCodeComingNumber ) 
		window.localStorage.setItem('url_back',window.location.pathname) 
		window.location.href='./running-list'
	}
	goProfile(id,e){
		window.localStorage.setItem('user_id',id )
		//this.props.history.push("/profile")
		window.location.href='./profile'
	}
	deleteInOwnTopic(comment_id,for_pick_code,e){
		agent.comment.deleteInOwnTopic({for_pick_code:for_pick_code,comment_id:comment_id}).then(data => {
			//console.log(this.state.CommentList)
			if(data.result=="good"){				
				this.listCommentAfterRely(for_pick_code,0)
			}else{
				this.props.callModalError(data.message)
			}
			$(".dr-fr").hide();
		})
	}
	blockUser(blocked_user_id,for_pick_code,e){
		agent.running.blockUser({blocked_user_id:blocked_user_id}).then(data => {
			//console.log(data)
			if(data.result=="good"){
				this.listCommentAfterRely(for_pick_code,0)
			}else{
				this.props.callModalError(data.message)
			}
			$(".dr-fr").hide();
		})
	}
	showBoxFriend(ev){
		$(".dr-fr").hide();
		let $this=ev.target.closest(".div-block-ct")
		$($this).find(".dr-fr").show();
		//console.log($this)
	}
	hideBoxFriend(ev){
		let $this=ev.target.closest(".div-block-ct")
		$($this).find(".dr-fr").hide();
	}
	updateTotalRely(comment_id,e){
		let CommentList=this.state.CommentList.map(dt=>{
			if(dt!=null && dt.comment_id==comment_id){
				dt.total_replies=dt.total_replies-1
			}
			return dt;
		})
		this.setState({CommentList:CommentList})
	}
	render(){   
		//---
		let CommentList=this.state.CommentList
		let TotalComments=this.state.TotalComments
		let url_like=this.state.url_like
		let url_liked=this.state.url_liked
		let url_dislike=this.state.url_dislike
		let url_disliked=this.state.url_disliked
		let url=this.state.url
		//---
		let PickLikedNum=this.state.PickLikedNum
		let PickLikedUserList=this.state.PickLikedUserList
		let PickLikedUserIDList=this.state.PickLikedUserIDList
		let PickCodeRunning=this.state.PickCodeRunning
		let PickCodeComingNumber=this.state.PickCodeComingNumber
		let UserInfoId=this.state.UserInfoId
		let user_id_login=this.state.id_user
		//---------
		var likeLst=this.state.PickLikedUserList;
		var pesons='';
		var _status='';
		for( let i=0;i<likeLst.length;i++){
			if(i==likeLst.length-1){
				pesons+=likeLst[i].tipster_name;
			}else{
				pesons+=likeLst[i].tipster_name+', ';
			}
			if(likeLst[i].user_id==this.state.id_user){
				_status="liked"
			}
			
		}
		//---------
		//console.log(PickCodeComingNumber)
        return (
		<div>
			<Row className="rw-like">
				<Col md={4} sm={4} xs={4} className="pd0">
					<a className="like-bet" data-id="2191" title="Like" style={{'display':'none'}}></a>
					<span className="num-like" style={{'display':'none'}}>Like (3)</span>
					<a className={(PickLikedNum>0 && _status=='liked')?"like-bet-active":"like-bet"} title="Like" pick_code={this.state.PickCode} >	
						{
							(PickLikedNum>0 && _status=='liked')?<i className="fas fa-thumbs-up" pick_code={this.state.PickCode} onClick={this.togglelikepick.bind(this)}></i>:<i className="far fa-thumbs-up" pick_code={this.state.PickCode} onClick={this.togglelikepick.bind(this)}></i>
						}
						<span className="num-like" onClick={this.goFriendList.bind(this)}>{localizeKey(Lhome)('like', this.state.lguages)} ({(PickLikedNum>0)?PickLikedNum:0})</span>
					</a>
					
				</Col>
				<Col md={4} sm={4} xs={4} className="pd0 txtct">
				{
					(PickCodeRunning==1)?
					<div>
						<Link to={"/comment/"+UserInfoId+"_"+this.state.PickCode}>
							<i className="fas fa-running icon-running" title="running"></i>							
						</Link>
						{(PickCodeComingNumber>0)?<span className="num-running" onClick={this.goRunningList.bind(this)}>Running ({PickCodeComingNumber})</span>:''}
					</div>:''
				}
				</Col>
				<Col md={4} sm={4} xs={4} className="fl-r pd0">
					{
						//<Link to={"/comment/"+UserInfoId+"_"+this.state.PickCode}>
							//<span className="num-like">Comments ({this.state.TotalComments})</span>
						//</Link>
					//
					}
					{
						(this.state.TotalComments>0)?
						<span className="num-like txt-caz" pickcode={this.state.PickCode} option={1} onClick={this.docm.bind(this)}>{localizeKey(Lhome)('comments', this.state.lguages)} ({this.state.TotalComments})</span>:
						<span className="num-like txt-caz" pickcode={this.state.PickCode} option={1}>{localizeKey(Lhome)('comments', this.state.lguages)}({this.state.TotalComments})</span>
						
					}
					
				</Col>
			</Row>
			<Row className="linect pd0"></Row>
			<div className="cmt-list"  style={{'display':''}}>
			{
				(TotalComments>0 && CommentList!='' && CommentList!=null)?CommentList.map((data,idx)=>{
				//console.log(UserInfoId,user_id_login,data.user_id)
				var html = data.comment;
				html=html.replace(/(?:\r\n|\r|\n)/g, '<br />')
				var div = document.createElement("div");
				div.innerHTML = html;
				var textmessge = html;
				return(
				<Row key={idx}>
					<Col md={1} xs={2} sm={2} className="pd0 cm-fit-1" >
						<figure>
							<a className="" href="">
								<img src={data.imageurl} width="50" height="50" className="img-icon-sm"/>
							</a>
						</figure>
					</Col>
					<Col md={11} xs={10} sm={10} className="pd0 ct-cmt cm-fit-2">
						<Row className="mg0 pd0">
							<h4 className="name-cmt">
								<span onClick={this.goProfile.bind(this,data.user_id)}>{(data.blocked==true)?<i className="fa fa-ban" style={{'color':'red','paddingRight':'3px'}}></i>:''}{data.tipster_name}</span>
								<span className="time-cmt">{data.str_comment_time}</span>
							</h4>
						</Row>
						<Row className="mg0 pd0">
							{
								//data.comment
								ReactHtmlParser(textmessge)
							}
						</Row>
						<Row className="pd0">
							<div className="r-like-reply" style={{'width':'100%'}}>
								<img src={(data.IsLiked==true)?url_liked:url_like} 
									width="35" height="35" className="img-icon-like"
									onClick={this.togglelike.bind(this,data.comment_id)}
								/>
								<span className="count-like">{(data.LikedNum>0)?data.LikedNum:''}</span>
								<img src={(data.IsDisliked==true)?url_disliked:url_dislike}
									width="35" height="35" className="img-icon-dislike"
									onClick={this.toggledislike.bind(this,data.comment_id)}
								/>										
								<a className="reply-cmt" onClick={this.handleRely.bind(this)}>{localizeKey(Lhome)('reply', this.state.lguages)}</a>
								{
									(UserInfoId==user_id_login || user_id_login==data.user_id)?
									<div className="g-btn mrgt5px div-block-ct" style={{'display':'inline','marginLeft':'20px'}}>
										<a className="btn-2 tfd-delete" onMouseOver={this.showBoxFriend.bind(this)}>MORE</a>
										<ul role="menu" className="dropdown-menu dr-fr" style={{'position':'sticky'}} onMouseLeave={this.hideBoxFriend.bind(this)}>
											{
												(user_id_login==data.user_id)?
												<li><a className="text-cmt-tl" onClick={this.showModalEditCM.bind(this,data.comment_id,data.for_pick_code,data.comment)}><i className="fas fa-pencil-alt"></i> edit comment</a></li>:''
											}
											<li><a className="text-cmt-tl" onClick={this.deleteInOwnTopic.bind(this,data.comment_id,data.for_pick_code)}><i className="far fa-trash-alt"></i> delete comment</a></li>
											{
												(user_id_login!=data.user_id && data.blocked!=true)?
												<li><a className="text-cmt-tl" onClick={this.blockUser.bind(this,data.user_id,data.for_pick_code)}><i className="fa fa-minus-circle"></i> block user</a></li>	:''
											}	
										</ul>
									</div>	:''
									
								}
							</div>
						</Row>
						<Row className="pd0 t-reply area_cha" style={{'display':'none'}}>
							{
								//<textarea name="txt-comment" className="txt-comment form-control lh50 area-cmt" placeholder="Add a public reply..." ></textarea>
							}
							<TextareaAutosize className="txt-comment form-control lh50 area-cmt" placeholder="Add a public reply..." />
							<img src={this.state.icon_send} className="post-reply-img" onClick={this.createCommentChild.bind(this,data.comment_id,data.for_pick_code)}/>
							<img src={this.state.icon_error} style={{'display':'none'}} className="post-cancel-img" onClick={this.hideRyply.bind(this)}/>
						</Row>
					{
					//---Reply list
					}
					{
						(data.total_replies>0)?
						<Row className="mg0 reply-List pd10_0">
						{
							(data.show_rely==1 && (data.comment_id==this.state.parent_id))?
							<div className="width100">
								<h4 className="name-cmt hide-cm mgbt0" onClick={this.viewCM.bind(this,data.comment_id)}>
									<span>{localizeKey(Lhome)('hideReplies', this.state.lguages)}</span>
									<i className="fa fa-chevron-up"></i>
								</h4>
								<Col md={12} className="pd0 hide-cm">
									<ReplyList 
										imageurl={url} 
										for_pick_code={this.state.for_pick_code} 
										parent_id={this.state.parent_id}
										listComment={this.listComment}
										member_name={this.state.member_name}
										updateTotalCM={this.updateTotalCM}
										UserInfoId={this.state.UserInfoId}
										user_id_login={user_id_login}
										updateTotalRely={this.updateTotalRely}
										callModalError={this.props.callModalError}
									/>
								</Col>
							</div>:
							<h4 className="name-cmt view-cm"  onClick={this.hideCM.bind(this,data.for_pick_code,data.comment_id)}>
								<span>{localizeKey(Lhome)('view_replies', this.state.lguages)} ({data.total_replies})</span>
								<i className="fa fa-chevron-down"></i>
							</h4>
							
						}
							
							
						</Row>:''
					}
					
					{
						//---end
					}
					</Col>
				</Row>
				)
				}):''
				
			}
			{
				(TotalComments>5 && CommentList!='' && (TotalComments-parseInt(CommentList.length))>0)?
				<Row className="mg0 pd_t_bt0">
					<Col md={12}>
						<a className="view-more" onClick={this.viewComment.bind(this,this.state.for_pick_code)}>{localizeKey(Lhome)('viewmore', this.state.lguages)}</a>
					</Col> 
				</Row>:''
			}	
			</div>
			
			<Row style={{'display':'none'}}>
				<textarea type="text" name="txt-comment" className="txt-comment form-control lh50" placeholder="write a comments..."></textarea>
				<button type="button" className="btn-cmt" onClick={this.createComment.bind(this,0)}>Post Comment</button>
				<textarea name="somename" className="txt-comment form-control lh50" placeholder="write a comments..." rows={this.state.rows} onKeyPress={this.handleKeyPress}></textarea>
			</Row>
			<Row className="mg0" style={{'margin':'0 2px'}}>
				<div className="wrapper">
					
					<TextareaAutosize className="txt-comment form-control lh50" placeholder={localizeKey(Lhome)('add_a_comment', this.state.lguages)} />
					<div className="controls">
						<button className="btn-cmt-ar txt-caz" onClick={this.createComment.bind(this,0)}>{localizeKey(Lhome)('postcm', this.state.lguages)}</button>
					</div>
				</div>
			</Row>
			<ModalEditComment isOpen={this.state.isOpenModalEditCm} close={this.close}
						txt_comment={this.state.txt_comment} PickCode={this.state.for_pick_code}
						txt_title={this.state.txt_title} callModalError={this.props.callModalError}
						updateCommentList={this.updateCommentList} comment_id={this.state.comment_id}/>
		</div>	
			
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Comment)
//export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Comment));