import React from 'react'
import ReactDOM from 'react-dom';
import {FormControl, Row, Col, Button, ControlLabel, FormGroup, Radio, Checkbox } from 'react-bootstrap';
//import InputMask from 'react-input-mask'; 
import $ from 'jquery';
import { Link } from 'react-router-dom'
import agent from '../../agent'
import '../../styles/modal.css'; 

import Modal from 'react-bootstrap-modal';
import { connect } from 'react-redux';
import ModalConfirmBlock from './ModalConfirmBlock'
import { 
  LANGUAGE_KEY
  
}from '../../constants/actionTypes'; 
import { localize, localizeKey } from 'react-redux-localization';
import Lqhome from '../common/home.json'
import cahome from '../common/captionhome.json';

const mapStateToProps = state => (
    Object.assign({}, state, {
		languages:state.common.languageslist,
	
    })      
);
const mapDispatchToProps = dispatch => ({
  /*loadCustomers: (data) => {
      dispatch({ type: CUSTOMER_BUILDING_LOADED, payload: data })  
  },
  */
});

class ModalBlock extends React.Component {
	
    constructor() {
        super();
		
        this.state = {			
			isOpen: false,url:'https://www.coopbet.com/media/user_none.jpg',search:'',list:[],isOpenModalConfirm:false,
			user_id:'',lguages:'1005'
			
        }
		
		this.hideModal = ev => {
		  this.setState({
			isOpen: false,
		  });
		 this.props.close();
			
		};
		this.close=()=>{
			this.setState({isOpenModalConfirm:false})
			this.props.close();
		}
		this.toblockList=()=>{
			agent.running.toblockList({search:this.state.search}).then(data => {
				//console.log(data)
				if(data.result=="good"){
					this.setState({list:data.list})
				}
			})
		}
		this.showModalConfirm=(user_id,e)=>{
			this.setState({isOpenModalConfirm:true,isOpen:false,user_id:user_id})
		}
		//---
				
    } 
	
	
	
    componentWillReceiveProps(nextProps) {
		//console.log(nextProps)
		if(nextProps){
			this.setState({isOpen:nextProps.isOpen,search:nextProps.search,},()=>{
				if(nextProps.isOpen==true){
					this.toblockList();
				}
			});
			if(nextProps.languageKey){
				this.setState({lguages:nextProps.languageKey});
			}
			
		}
		
		
    }	
	componentWillUpdate(nextProps, nextState){
		//console.log(nextState)
	}
	componentDidMount() {
		
		
	}
	componentWillMount() {
		let idLanguage=window.localStorage.getItem(LANGUAGE_KEY)
		if(idLanguage!='' && idLanguage!='null' && idLanguage!=null){
			this.setState({lguages:idLanguage})
		}
	}
	
	render() {
	   const url=this.state.url;
	   const list=this.state.list;
	   //console.log(likeList)
	
        return (
		<div>
			<Modal
			  show={this.state.isOpen}
			  onHide={this.hideModal}
			  aria-labelledby="ModalHeader"
			  id="small"
			  className="w3-animate-zoom"
			>
			  <Modal.Header closeButton>
				<Modal.Title id='ModalHeader'>
					<div className="modal-worker-title">
						<span className="modal-detail-order-title">{localizeKey(cahome)('block', this.state.lguages)}</span>
					</div>
				</Modal.Title>
			  </Modal.Header>
			  <Modal.Body className="">
					<Row id="modal-like-list">	
						<div className="scroller friens-top"  tabIndex={0} id="test">
							<div className="frd-top">
								<div className="ft-items">
									<Col md={12} className="ctn bd-bt">
										<p>
										{localizeKey(Lqhome)('txtpupblock', this.state.lguages)}
										</p>
									</Col>
									<Col md={12} className="ctn">
									{
										(list!='' && list!=null)?list.map((dt,indx)=>{
											var url_username=dt.url_username
											let prName='';
											let url_user='';
											if(url_username!='' && url_username!='null' && url_username!=null){
												prName=url_username.split("/");
												url_user=prName[prName.length-1];
											}
											return(
											<div className="ft-item ft-item-7" key={indx}>
												<figure className="pull-left">
													<img src={(dt.imageurl!='' && dt.imageurl!=null)?dt.imageurl:url} width="50" height="50"/>
												</figure>
												<div className="name pull-left" style={{'marginTop':'12px'}}>
													<Link className="profile-name" to={"/"+url_user}>{dt.tipster_name}</Link>
												</div>
												<div className="g-btn pull-right" style={{'marginRight':'0px'}}>
													<a className="btn-1 pull-right tfd-addfriend-gray" onClick={this.showModalConfirm.bind(this,dt.user_id)}>
														<i className="fa fa-minus-circle fw600" style={{'paddingRight': '5px'}} ></i>{localizeKey(cahome)('block', this.state.lguages)}</a>
												</div>
											</div>
											)
										}):''
									}
										
										
										
									</Col>
								</div>
							</div>
						</div>
						<Col md={12} className="col-lg-12 lblMessage" id="lblMessage" style={{'color':'red'}}></Col>
					</Row>
			  </Modal.Body>
			  <Modal.Footer style={{'display':'none'}}>
					<div className="col-lg-12" style={{'textAlign':'left','padding':'0'}}> 						
						<button type="button" className="btn btn-primary" onClick={this.props.close}>Close</button>
					</div>
			  </Modal.Footer>			 
			</Modal>
			 <ModalConfirmBlock isOpen={this.state.isOpenModalConfirm} close={this.close} user_id={this.state.user_id}
			                    callModalError={this.props.callModalError} blockedusers={this.props.blockedusers}/>
		</div>
        ); 
	 //}
	//return null;
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(ModalBlock); 