import React, {Component} from 'react'
import { connect } from 'react-redux'
import { setLocale, getLocale, localizeKey } from 'react-redux-localization';
import { Cookies } from 'react-cookie' 
import '../../styles/App.css'
import '../../styles/home.css'
import {Col, Row,FormControl, Form, FormGroup, ControlLabel, Button} from 'react-bootstrap'
//import { createElementsFromText } from 'html-text-to-react';
//import ReactHtmlParser from 'react-html-parser';
import $ from 'jquery';
import {TOKEN_NAME,LANGUAGE_KEY} from '../../constants/actionTypes'
import agent from '../../agent'
import translations from '../common/captionhome.json';
import ReactHtmlParser from 'react-html-parser';
import Lqhome from '../common/home.json'
import Avatar from 'react-avatar';
import ModalErorr from '../common/ModalErorr'
import {CopyToClipboard} from 'react-copy-to-clipboard';

const mapStateToProps = state => (
    Object.assign({}, state, {
		tab: state.common.tab,
		updateProps: state.common.updateProps,
		locale: getLocale(state),
		languageKey: state.common.languageKey
	}) 

);

const mapDispatchToProps = dispatch => ({               
	onLoad: (data) => {
		dispatch({ type: 'HOME_PAGE_LOADED', data})  
	},
	chooseLocale: locale => dispatch(setLocale(locale))
});



 


class SummaryTips extends Component{ 	
	constructor(){
		super()
		this.state = {
			lguages:'1005',qcinfo:{},idUser:'',isOpenModal:false,msg:'',
			isLoading:false,seller_id:'',PaymentMethods:[],PriceTips:'',
			PriceTips_VND:'',buyer_info:{},from_date:'',seller_info:{},
			to_date:'',seller_userbankid:'',isOpenModal:false,accept:0,stk:'',
			copied:false,isOpenModal:false,msg:'',numCoppy:''
		}
		//---
		this.getrandomad=()=>{
			let lang_id=this.state.lguages
			let param={lang_id:lang_id}
			agent.home.getrandomad2(param).then(data => {
				//console.log(data)
				if(data.result === 'good'){
					this.setState({qcinfo:data.info})
				}
			})
		}
		//---
		this.goAdView=this.goAdView.bind(this)
		//---
		this.close=()=>{
			this.setState({isOpenModal:false})
		}
		this.buyTip=()=>{
			this.setState({isLoading:true})
			let date=new Date();
			let mt=date.getMonth()+1;
			let year=date.getFullYear();
			let month=mt+"/"+year;
			let seller_id=this.state.seller_id
			let seller_userbankid=this.state.seller_userbankid
			let PriceTips=this.state.PriceTips
			agent.home.createOrder({PriceTips:PriceTips,month:month,
										   seller_id:seller_id,seller_userbankid:seller_userbankid}).then(data => {
				if(data.result=="good"){					
					this.setState({isLoading:false})
					window.location="/invoice-tips/"+data.order_info.order_id
				}else{
					this.setState({msg:data.message,isOpenModal:true,isLoading:false})
				}
			})
		}
		this.getOrderPrepare=(id)=>{
			let date=new Date();
			let mt=date.getMonth()+1;
			let year=date.getFullYear();
			let month=mt+"/"+year;
			agent.orders.getOrderPrepare({seller_id:id,month:month}).then(data => {
				if(data.result=="good"){					
					this.setState({PaymentMethods:data.PaymentMethods,
								   PriceTips:data.PriceTips,PriceTips_VND:data.PriceTips_VND,
								   buyer_info:data.buyer_info,from_date:data.from_date,
								   seller_info:data.seller_info,to_date:data.to_date})
				}else{
					this.setState({msg:data.message,isOpenModal:true,isLoading:false})
				}
			})
		}
	}
	getBuyertips(){
		let month=this.state.month
		agent.orders.getBuyertips({month:month}).then(data => {
			if(data.result === "good"){	
				//console.log(data)
				this.setState({list:data.list})
			}else{
				
			}
		})
	}	
	goAdView(ev){
		let id=$(ev.target).attr("dataid")
		window.location.href='/new-info/'+id
	}
	componentWillMount(){	
		//-----
		let idLanguage=window.localStorage.getItem(LANGUAGE_KEY)
		if(idLanguage!='' && idLanguage!='null' && idLanguage!=null){
			this.setState({lguages:idLanguage},()=>{
				this.getrandomad();
			})
		}
		//console.log(idLanguage)
		let id=this.props.match.params.id
		this.getOrderPrepare(id)
		this.setState({seller_id:id})
		//-----
	}
	
	componentDidMount(){
		
		this.getrandomad();
	}
	componentWillReceiveProps(nextProps){
		//console.log(nextProps)
		if(nextProps.languageKey && nextProps.languageKey!='' && nextProps.languageKey!=null && nextProps.languageKey!='null'){
			this.setState({lguages:nextProps.languageKey},()=>{
				this.getrandomad()
			});
			
		}
		
	}
	
	shouldComponentUpdate(nextProps, nextState){
		return nextState;
	}	
	
	numberFormat(x) {
		return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
	}
	generateColor () {
		return '#' +  Math.random().toString(16).substr(-6);
	}
	handleChangeField(field,e){
		if(e.target.value!==''){
			//console.log(e.target,$("#selec-tt option:selected").attr("bank_no"))
			let bank_name=$("#selec-tt option:selected").attr("bank_name")
			let name=(bank_name==='Bitcoin')?'btc':(bank_name==='Paypal')?'paypal':'stk'
			let stk=name+": "+$("#selec-tt option:selected").attr("bank_no");
			let numCoppy=$("#selec-tt option:selected").attr("bank_no");
			this.setState({[field]:e.target.value,stk:stk,numCoppy:numCoppy})
		}
	}
	handleCheck(ev){
		let check=(ev.target.checked===true)?1:0;
		this.setState({accept:check})
	}
	render(){   
		const qcinfo=this.state.qcinfo;
		let title=''
		let imageurl=''
		let url=''
		let description=''
		if(qcinfo!='' && qcinfo!=null && qcinfo!='null'){
			 title=qcinfo.ad_title?qcinfo.ad_title:'';
			 imageurl=qcinfo.ad_logo_url?qcinfo.ad_logo_url:'https://back-end.coopbet.com/gf/ywqBBMhT0U0022_no-logo.png';
			 url=qcinfo.ad_url?qcinfo.ad_url:'';
			 description=qcinfo.ad_description?qcinfo.ad_description:'';
			 description=(description.length<=85)?description:description.substr(0,85)+'...'
		}	
		let PaymentMethods=this.state.PaymentMethods
        return (
			<div id="body"> 
			{
				(qcinfo && qcinfo!=null && qcinfo!='')?
				<Row className="randomad">
					<Col md={4} xs={4} sm={4} className="pd0">
						<div className="img-adv">
							<a href={url} target="_blank" className="rand-logo">
								<img alt="m88" src={imageurl} width="100" height="50"/>
							</a>
						</div>
					</Col>
					<Col md={8} xs={8} sm={8} className="pd0">
						<div className="desc-adv">									
						{
							ReactHtmlParser(description)
						}
							<span>
							{
								(qcinfo!='' && qcinfo!=null)?
								<a onClick={this.goAdView.bind(this)} dataid={qcinfo.id} title={title} className="v-more">{localizeKey(Lqhome)('viewmore', this.state.lguages)}</a>:''
							}
							</span>
						</div>
					</Col>
				</Row>:''
			}
				<Row className="mg0 mrgt7 mgbt7">
					
					<Col md={7} xs={7} sm={7}>
						<i className="far fa-list-alt " style={{'fontSize':'20px','verticalAlign':'top'}}></i>
						<div className="rigister-title" style={{'textTransform':'uppercase'}}>BUY TIPS</div>
					</Col>
					<Col md={5} xs={5} sm={5} className="rank-mth date-tips">
						
					</Col>
									
				</Row>
				<Row className="line-ct"></Row>
				<Row className="title-user-info">
					<Col md={2} sm={3} xs={3} className="pd0 fit-bl-2">
						<figure>
							<a>
								{
									(this.state.seller_info.imageurl!=null && this.state.seller_info.imageurl!='')?<img src={this.state.seller_info.imageurl} width="60" height="60"/>:
									<Avatar size="60" round={true} className="avatar-title" color={this.generateColor()}
											name={(this.state.seller_info!==null && this.state.seller_info!=='')?this.state.seller_info.tipster_name:'?'}/> 
								}
							</a>
						</figure>
					</Col>
					<Col md={7} sm={5} xs={5} className="pd0">
						<h4 className="name" >{this.state.seller_info.tipster_name}</h4>
						<div className="lh22"><b>Lợi nhuận:</b> {this.state.seller_info.profit}</div>
						<div className="lh22"><b>Lợi nhuận %:</b> {this.state.seller_info.profitpercentage}</div>
					</Col>
					<Col md={3} sm={4} xs={4} className="pd0 txtR fit-bl-3">
						
					</Col>
				</Row>
				<Row className="list-tp" >
					<Col md={12} className="fl-r">
						<h4 className="txt-cst">{this.state.PriceTips}$</h4>
						<h4 className="txt-cst pr-txt-r">{this.numberFormat(this.state.PriceTips_VND)} VNĐ</h4>
						<div className="name-buyer">Buyer: {this.state.buyer_info.first_name+" "+this.state.buyer_info.last_name}</div>
					</Col>
				</Row>
				<Row className="mg0">
					<Col md={12} className="txt-time-see-tip">Thời gian xem tisp từ: {this.state.from_date} đến {this.state.to_date}.</Col>
				</Row>
				<Row className="mg0">
					<Col md={12} className="pttt">Chọn phương thức thanh toán:</Col>
					<Col md={12} className="" style={{'marginBottom':'6px'}}>
						
						<select placeholder="select" className="form-control" id="selec-tt" name="time_zone" value={this.state.seller_userbankid} onChange={this.handleChangeField.bind(this,'seller_userbankid')}>
							<option value=""> Method transfer </option>
							{
							(PaymentMethods!='')?PaymentMethods.map((it,idx)=>{
								return(
							<option value={it.userbankid} key={idx} bank_no={it.bank_no} bank_name={it.bank_name}> {it.bank_desc} </option>
								)
							}):''
							}
						 </select>
						{
							(this.state.stk!=='')?
							<div  className="copi-stk">
								{this.state.stk}
								<CopyToClipboard text={this.state.numCoppy}
								  onCopy={() => this.setState({copied: true})}>
								  <i className="far fa-copy copied" title={(this.state.copied===true)?'Copied':'Copy'}></i>
								</CopyToClipboard>
							</div>:''
						}
						
					</Col>
				</Row>
				<Row className="mg0">
					<Col md={12}>
					
						  <div className="custom-control custom-checkbox pl-3 div-custom-checkbox check-buy-tip">
							  <input
								className="custom-control-input check-edit-pass"
								type="checkbox"
								value=""
								id="invalidCheck"
								onChange={this.handleCheck.bind(this)}
							  />
							  <label className="custom-control-label lbl-ck-buy" htmlFor="invalidCheck">
								 <b> Tôi chấp nhận các quy định của coopbet:</b>
									  <div>- coopbet không chịu bất cứ trách nhiệm nào trong giao dịch này</div>
									  <div>- coopbet không giải quyết các tranh chấp và khiếu nại liên quan</div>
							  </label>
							  
							</div>
					</Col>
				</Row>
				<Row className="mg0">
					<Col md={12} className="tx-r-by-tip">
						<Button
							id="btn-save-buy-tip"
							bsStyle="primary"
							disabled={(this.state.isLoading===true || this.state.accept <1)?true:false}
							onClick={!this.state.isLoading ? this.buyTip : null}>
							{this.state.isLoading ? <span style={{'fontSize':'14px'}}><i className='fa fa-spinner fa-spin '></i> BUYING</span> :<span>BUY </span>}
						</Button>
					</Col>
				</Row>
				<ModalErorr isOpen={this.state.isOpenModal} close={this.close} text={this.state.msg}/>
			</div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SummaryTips)