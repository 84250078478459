import React from 'react';
import { Link,withRouter, history} from 'react-router-dom'
import { connect } from 'react-redux'
import agent from '../agent'
//import { Cookies } from 'react-cookie'
import {CHANGE_TAB, TOKEN_NAME,LANGUAGE_LIST,LANGUAGE_KEY,REDIRECT,ONLY_FIENDS} from '../constants/actionTypes'
//import { MDBCol, MDBFormInline, MDBIcon} from "mdbreact";
import {Col, Row, ListGroup} from 'react-bootstrap'
import SearchPage from './common/SearchPage'
import SearchCountry from './common/SearchCountry'
import { localize, localizeKey,setLocale, getLocale, } from 'react-redux-localization';
import $ from 'jquery';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
//import { faSearch } from '@fortawesome/free-solid-svg-icons'
import '@fortawesome/fontawesome-free/css/fontawesome.css'
import '@fortawesome/fontawesome-free/css/brands.css'
import '@fortawesome/fontawesome-free/css/solid.css'
import '@fortawesome/fontawesome-free/css/all.css'
import '../styles/App.css'


import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem } from 'reactstrap';

//import languages from '../languages'
import menu from './common/menu.json'
//import header from './common/header.json'
import Pusher from 'pusher-js';


var pusher = new Pusher('d75bec97873cadcd8a43', {
 cluster: 'ap1',
 encrypted: true
});

const mapStateToProps = state => (
    Object.assign({}, state, {
		token: state.common.token,
		tab: state.common.tab,
		languageslist:state.common.languageslist,
		languageKey: state.common.languageKey,
		only_friend: state.common.only_friend,
		number_notifications: state.common.number_notifications,
	})
		
);

const mapDispatchToProps = dispatch => ({   
	onTabClick: (tab) => 
		dispatch({ type: CHANGE_TAB, tab }),
	setToken: (data)=>{ 
		dispatch({ type: TOKEN_NAME, data })
	},
	onloadLangues: (data) => {
		dispatch({ type: LANGUAGE_LIST, data})  
	},
	onResetLanguesKey: (data) => {
		dispatch({ type: LANGUAGE_KEY, data})  
	},
	onRedirect: () =>
		dispatch({ type: REDIRECT }),
	setFiendMode: (data) =>{
		dispatch({ type: 'ONLY_FIENDS', data})  
	},
});

class App extends React.Component {
	constructor(){
		super()
		this.state = {
			language:1005,lngText:'Vietnam',showlogout:true,locales:[],isOpen: false,only_friend:'0',old_only_friend:'0',
			NumberofNewNotifications:0,name_contest:''
		}
		//---
		
		//---
		this.myFunction=this.myFunction.bind(this)
		this.myMenu=this.myMenu.bind(this)
		this.goLogout=this.goLogout.bind(this)
		this.toggle = this.toggle.bind(this);
		this.getSocket = this.getSocket.bind(this);
		//----
		this.changeLG=(id)=>{
			this.setState({language:id})
			this.props.onResetLanguesKey(id); 
			window.localStorage.setItem(LANGUAGE_KEY,id)
		}
		this.viewToolSearch=()=>{
			$(".tool-header").hide();
			$(".to-search").show();
			$("#ic-search").removeClass("fa").addClass("fas");
		}
		this.viewToolHeader=()=>{
			$(".tool-header").show();
			$(".to-search").hide();
		}
		this.goToNofication=()=>{
			window.location="/notifications"
		}
		this.handleWindowSizeChange = () => {
		 // this.setState({ width: window.innerWidth });
			 let width=window.innerWidth;
			 if(width<=1024){
				
			 }else{
				window.location='https://coopbet.com/home' 
			 }
		// console.log(width)
		 
		};
		this.gohome=()=>{
			window.location.href="/home"
		}
		this.contestInfo=()=>{
			agent.admin.contestInfo({}).then((datas) => {
				if(datas!==undefined && datas.result==='good'){
					let info=datas.info;					
					let name_contest=info.name;
					
					this.setState({name_contest:name_contest})
				}
			})
			
		}
	}
	
	toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }
	componentWillMount(){		
		this.getLanguage();
		let only_friend=window.localStorage.getItem("only_priend");
		this.setState({old_only_friend:only_friend})
		let login=window.localStorage.getItem(TOKEN_NAME);
		if(login!='' && login!='null' && login!=null){
			login=JSON.parse(login)
			this.getSocket(login.UserInfo.id)
			this.newNumber();
			this.contestInfo();
		}else{
			window.localStorage.setItem( TOKEN_NAME, '');
			this.setState({showlogout:false}) 
			window.location="/login"
		}
		//--------------
		window.addEventListener('resize', this.handleWindowSizeChange);
		//--------------
		let idLanguage=window.localStorage.getItem(LANGUAGE_KEY)
		if(idLanguage!='' && idLanguage!='null' && idLanguage!=null){
			this.setState({language:idLanguage})
		}
		
	}
	componentWillUnmount() {
	  window.removeEventListener('resize', this.handleWindowSizeChange);
	}
	componentWillReceiveProps(nextProps){
		//console.log(nextProps)
		if (nextProps.common.redirectTo) {
		  
		  nextProps.history.push(nextProps.common.redirectTo)
		  this.props.onRedirect();
		}
		if(nextProps.number_notifications!=null){
			this.setState({NumberofNewNotifications:nextProps.number_notifications})
		}
		this.setState({locales:nextProps.languageslist,only_friend:nextProps.only_friend})
		if(nextProps.languageKey && nextProps.languageKey!='' && nextProps.languageKey!=null && nextProps.languageKey!='null'){
			this.setState({language:nextProps.languageKey});
		}
	}
	componentDidMount() {
		window.onclick = function(event) {
		  if (!event.target.matches('.btn_log')) {
			var dropdowns = document.getElementsByClassName("dropdown-content");
			var i;
			for (i = 0; i < dropdowns.length; i++) {
			  $("#myDropdown").removeClass("show");
			  $("#myDropdown_1").removeClass("show");
			}
			//console.log(dropdowns.length)
		  }
		  if (!event.target.matches('.buton_icon')) {
			var dropdowns = document.getElementsByClassName("dropdown-content");
			var i;
			for (i = 0; i < dropdowns.length; i++) {
			  $("#mymenu").removeClass("show");
			}
		  }
		  if (!event.target.matches('.tfd-delete')) {
			var dropdowns = document.getElementsByClassName("dropdown-menu");
			var i;
			for (i = 0; i < dropdowns.length; i++) {
			  $(".dr-fr").hide();
			}
		  }
		}
	}
	myFunction() {
	  $("#myDropdown").addClass("show");
	}
	myFunction_1() {
	  $("#myDropdown_1").addClass("show");
	}
	myMenu() {
	  $("#mymenu").addClass("show");
	}
	goLogout() {
	  $(".myDropdown").addClass("show");
	}
	hideMyFunction(ev){
		//console.log($(ev.target).closest('a'))
		let id=$(ev.target).closest('a').attr("dataid")
		let lngname=$(ev.target).closest('a').attr("lngname")
		//console.log(id)
	    this.setState({lng:id,lngText:lngname})
		this.changeLG(id)
	}
	hidegoLogout(ev){
		$(".myDropdown").removeClass("show");
		window.localStorage.setItem( TOKEN_NAME, '');
		this.setState({showlogout:false}) 
		this.props.onTabClick('login')
		//window.location.href = 'login'
		this.props.history.push("/login")
	}
	getLanguage(){
		agent.header.getlangs().then((datas) => {
			//console.log(datas)
			if(datas.result==='good'){
				this.props.onloadLangues(datas.list);
				let lngText=this.state.lngText
				let idLanguage=window.localStorage.getItem(LANGUAGE_KEY)
				datas.list.map(dt=>{
					if(dt.id==idLanguage){
						lngText=dt.display_name
					}
				})
				const newState = Object.assign({}, this.state, {locales:datas.list,lngText:lngText })
				this.setState(newState)
			}
			
		})
	}
	handleFriendMode(e){
		let only_priend=(e.target.checked==true)?1:'0'	
		this.setState({only_friend:only_priend,old_only_friend:only_priend},()=>{
			this.props.setFiendMode(only_priend)
			window.localStorage.setItem('only_priend',only_priend)
			this.props.history.push("/home")
			//this.searchHome();
		})
		
	}
	getSocket(user_id){
		var thiss=this		
		//var user_id = '5';
		
		var channelcode = 'coopbet-' + user_id;
		var event = 'new-notification';
		
		var channel = pusher.subscribe(channelcode);
		channel.bind(event, function(data) {
			//alert(JSON.stringify(data));
			console.log(data.NewNoticationCount)
			thiss.setState({NumberofNewNotifications:data.NewNoticationCount},()=>{
				//thiss.getList()
			})
			
		});
	}
	newNumber(){
		agent.notification.newNumber().then(data => {
			//console.log(data);                
			if(data!= null && data.result==='good'){
				this.setState({NumberofNewNotifications:data.new_number},()=>{
					//this.getList()
				})
			}
		})
	}
	goProfile(id,e){
		window.localStorage.setItem('user_id',id )
		window.location.href="profile"
		//this.props.history.push("/profile")
	}
	render() {
		const token = window.localStorage.getItem(TOKEN_NAME);
		const tab = this.props.tab
		//console.log(tab)
		let name_tip=''
		let imageurl='https://www.coopbet.com/media/no_avatar.png';
		let userid_login=0;
		if(token!='' && token!='null' && token!=null){
			const tokenLg = JSON.parse(token)
			name_tip=tokenLg.UserInfo.tipster_name
			imageurl=(tokenLg.UserInfo.imageurl!='' && tokenLg.UserInfo.imageurl!=null && tokenLg.UserInfo.imageurl!="null")?tokenLg.UserInfo.imageurl:imageurl
			userid_login=tokenLg.UserInfo.id
			//console.log(tokenLg)
		}
		
		let locales=this.state.locales
		
		if(token!='' && tab!='' && tab!=null){
			return(
			<Row className="mg0">
				<Col md={12} className="head-page">
					<div className="menu-top-fix">
						<div className="container">
							<Row className="new-logo-top">
								<Col md={12} style={{'textAlign':'center'}}>
									<Link to="#" onClick={this.gohome}>
										<img src="/img/mobile_coopbet_logo.png"/>
									</Link>
								</Col>
							</Row>
							<Row className="tool-header" style={{'display':''}}>						
								<Col md={1} xs={2} sm={2} lg={2} className="member-avatar pd0">
									<div className="dropdown" >
										<Link to="#" onClick={this.gohome}>
											<figure className="" >
												<img src={imageurl}/>
											</figure>
										</Link>
									</div>
								</Col>	
								<Col md={2} xs={3} sm={3} lg={3}  className="pd0 pdtl10">
									<label className="switch lb-l" title="View only fiends" >
										<input type="checkbox" className="friend-mode"
											onChange={this.handleFriendMode.bind(this)}
											checked={(this.state.only_friend==1 || this.state.old_only_friend==1)?true:false}
										/>
									  <span className="slider round"></span>
									</label>
									<label className="lb-r">
										<i className="fas fa-search icon-view-search" aria-hidden="true" onClick={this.viewToolSearch} ></i>
										{
											//<FontAwesomeIcon icon={faSearch} className="icon-view-search" onClick={this.viewToolSearch} style={{'display':'none'}}/>
										}
									</label>
								</Col>
								<Col md={6} xs={2} sm={2} lg={2}  className="pd0 mgt7"></Col>
								<Col md={1} xs={2} sm={2} lg={2}  className="pd0 mgt7 txtr">									
									<i className="fa fa-bell" onClick={this.goToNofication}></i>
									{
										(this.state.NumberofNewNotifications>0)?
										<span className="badge badge-default badge-notification">{this.state.NumberofNewNotifications}</span>:''
									}
								</Col>
								
								<Col md={2} xs={3} sm={3} lg={3}  className="pd0 mgt7 lng">
									
									<div className="dropdown hamburger">
										<a href="#" onClick={this.myMenu} className="mobileMenu-toggle buton_icon" id="mobileMenu-toggle" >
											<span className="mobileMenu-toggleIcon buton_icon">Toggle menu</span>
										</a>
										<div id="mymenu" className="dropdown-content">											
											<div className="scrollere scrol-menu">
												<ListGroup>
													<ListGroup.Item>
														<Link to="/home"><i className="fa fa-home" style={{'marginRight':'5px'}}></i>{localizeKey(menu)('menu1', this.state.language)}</Link>
													</ListGroup.Item>
													<ListGroup.Item>
														<Link to="/bookmakers"><i className="fas fa-bookmark" style={{'marginRight':'5px'}}></i>{localizeKey(menu)('bookmaker', this.state.language)}</Link>
													</ListGroup.Item>
													<ListGroup.Item>
														<Link to="/friends-request"><i className="fa fa-users" style={{'marginRight':'5px'}}></i>{localizeKey(menu)('frdRequest', this.state.language)}</Link>
													</ListGroup.Item>
													<ListGroup.Item>
														<Link to="/bankroll"><i className="fas fa-money-check-alt" style={{'marginRight':'5px'}}></i>BANKROLL</Link>
													</ListGroup.Item>
													<ListGroup.Item>
														<Link to="/tipster-contest"><i className="fas fa-euro-sign" style={{'marginRight':'5px'}}></i>{this.state.name_contest}</Link>
													</ListGroup.Item>
													<ListGroup.Item>
														<Link to="/tipster"><i className="fas fa-lira-sign" style={{'marginRight':'5px'}}></i>TIPSTERS</Link>
													</ListGroup.Item>
													<ListGroup.Item>
														<Link to="/tips-management"><i className="far fa-building" style={{'marginRight':'5px'}}></i>TIPS MANAGEMENT</Link>
													</ListGroup.Item>
													<ListGroup.Item>
														<Link to="/tips-investment"><i className="far fa-building" style={{'marginRight':'5px'}}></i>TIPS INVESTMENT</Link>
													</ListGroup.Item>
													<ListGroup.Item>
														<Link to="/setting"><i className="fas fa-cog" style={{'marginRight':'5px'}}></i>{localizeKey(menu)('subdrdw4', this.state.language)}</Link>
													</ListGroup.Item>
													<ListGroup.Item>
														<a href="javascript:void(0);" onClick={this.goProfile.bind(this,userid_login)}><i className="fa fa-user" style={{'marginRight':'5px'}}></i>Me - {name_tip}</a>
													</ListGroup.Item>
													<ListGroup.Item>
														<Link to="/contact"><i className="fa fa-info" style={{'marginRight':'5px'}}></i>{localizeKey(menu)('contact', this.state.language)}</Link>
													</ListGroup.Item>
													<ListGroup.Item>
														<a onClick={this.hidegoLogout.bind(this)} href=""><i className="fa fa-key" style={{'marginRight':'5px'}}></i>LOG OUT</a>
													</ListGroup.Item>
												</ListGroup>
											</div>	
										  </div>
									</div>
									
									<div className="dropdown drdw lngDropdow">
									  <button onClick={this.myFunction} className="dropbtn btn_log">{this.state.lngText.substr(0,3)}</button>
									  <div id="myDropdown" className="dropdown-content">
										<header>
											<h4>LANGUAGE</h4>
										</header>
										<div className="scrollere">
										  {
											  (locales!='' && locales!='null' && locales!=null)?locales.map((data,index)=>{
												  return(
													<a dataid={data.id} lngname={data.display_name} onClick={this.hideMyFunction.bind(this)} key={index}>
														<img src={data.imageurl}/>
														<span className="lg">{data.display_name}</span>
													</a>
												  )
											  }):''
										  }
										</div>	
									  </div>
									</div>
								</Col>
							</Row>
							<Row className="mg0 to-search" style={{'display':'none'}} >
								<Col md={12} xs={12} sm={12} lg={12}  className="pd0 mgt7">
									<label className="lb-l1">
										<i className="fas fa-arrow-left albt" aria-hidden="true" onClick={this.viewToolHeader}></i>
									</label>
									<label className="lb-r1">
										<SearchPage/>
									</label>
								</Col>
							</Row>
						</div>
					</div>
				</Col>
			</Row>
			)
		}else{
		return(
			<Row className="mg0">
				<Col md={12} className="head-page">
					<div className="menu-top-fix">
						<div className="container">
							
							<Row className="mg0">
								<Col md={1} xs={1} sm={1} lg={1} className="member-avatar pd0">
									<div className="dropdown" >
										<Link to="/login">
											<figure className="" >
												<img src={imageurl}/>
											</figure>teex
										</Link>
									</div>
								</Col>
								<Col md={8} xs={8} sm={8} lg={8}  className="pd0 mgt7">
									
								</Col>
								<Col md={3} xs={3} sm={3} lg={3}  className="pd0 mgt7 lng">
									
									<div className="dropdown hamburger">
										<a href="#" className="mobileMenu-toggle" id="mobileMenu-toggle" >
											<span className="mobileMenu-toggleIcon">Toggle menu</span>
										</a>
										
									</div>
									
									<div className="dropdown drdw lngDropdow">
									  <button onClick={this.myFunction_1} className="dropbtn btn_log">{this.state.lngText.substr(0,3)}</button>
									  <div id="myDropdown_1" className="dropdown-content">
										<header>
											<h4>LANGUAGE</h4>
										</header>
										<div className="scrollere">
										  {
											  (locales!='' && locales!='null' && locales!=null)?locales.map((data,index)=>{
												  return(
													<a dataid={data.id} lngname={data.display_name} onClick={this.hideMyFunction.bind(this)} key={index}>
														<img src={data.imageurl}/>
														<span className="lg">{data.display_name}</span>
													</a>
												  )
											  }):''
										  }
										</div>	
									  </div>
									</div>
								</Col>
							</Row>
							<Row>

							</Row>
						</div>
					</div>
				</Col>
			</Row>
		)
		
		}
		//return null;		
	}
}

//export default connect(mapStateToProps, mapDispatchToProps)(App)
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
