import React, {Component} from 'react'
import { connect } from 'react-redux'
import { setLocale, getLocale } from 'react-redux-localization';
import { Cookies } from 'react-cookie' 
import '../../styles/App.css'
import '../../styles/home.css'
import {Col, Row,FormControl, Form, FormGroup, ControlLabel, Button} from 'react-bootstrap'
import { createElementsFromText } from 'html-text-to-react';
import ReactHtmlParser from 'react-html-parser';
import $ from 'jquery';
import {TOKEN_NAME, REDIRECT,HOME_PAGE_LOADED, APP_LOAD,LANGUAGE_KEY} from '../../constants/actionTypes'
import agent from '../../agent'
import { withRouter, history} from 'react-router-dom'
import Lqhome from '../common/home.json'
import { localize, localizeKey } from 'react-redux-localization';

const mapStateToProps = state => (
    Object.assign({}, state, {
		tab: state.common.tab,
		updateProps: state.common.updateProps,
		locale: getLocale(state),
		languageKey: state.common.languageKey
	}) 

);

const mapDispatchToProps = dispatch => ({               
	onLoad: (data) => {
		dispatch({ type: 'HOME_PAGE_LOADED', data})  
	},
	chooseLocale: locale => dispatch(setLocale(locale))
});



 


class Ad extends Component{ 	
	constructor(){
		super()
		this.state = {
			lguages:'1005',
			qcinfo:{},List:[]
		}
		//---
		this.getrandomad=()=>{
			let lang_id=this.state.lguages
			let param={lang_id:lang_id}
			agent.home.getrandomad2(param).then(data => {
				//console.log(data)
				if(data.result === 'good'){
					this.setState({qcinfo:data.info})
				}
			})
		}
		//---
		this.goAdViews=this.goAdViews.bind(this)
		//---
	}
	goAdViews(ev){
		let id=$(ev.target).attr("dataid")
		/*let title=$(ev.target).attr("title")
		window.localStorage.setItem( 'ad_user_id', id);
		window.localStorage.setItem( 'ad_title', title);
		//console.log(id,title)
		window.location.href='/ad-list' */
		window.location.href='/new-info/'+id
	}
	componentWillMount(){	
		//-----
		
		let idLanguage=window.localStorage.getItem(LANGUAGE_KEY)
		if(idLanguage!='' && idLanguage!='null' && idLanguage!=null){
			this.setState({lguages:idLanguage},()=>{
				this.getrandomad();
			})
			//this.getbookMaker(idLanguage)
		}
		//console.log(idLanguage)
		//-----
	}
	 componentDidMount() {
       // document.addEventListener("click", this.closeMenu);
	   //console.log(this.state.lguages)
	   this.getrandomad();
	   this.getbookMaker(this.state.lguages)
    }
	componentWillReceiveProps(nextProps){
		//console.log(nextProps)
		if(nextProps.languageKey && nextProps.languageKey!='' && nextProps.languageKey!=null && nextProps.languageKey!='null'){
			this.setState({lguages:nextProps.languageKey},()=>{
				this.getrandomad()
				this.getbookMaker(nextProps.languageKey)
			});
			
		}
	}
	
	shouldComponentUpdate(nextProps, nextState){
		return nextState;
	}	
	getbookMaker(lang_id){
		agent.ads.bookMaker({lang_id:lang_id}).then((datas) => {
		//console.log(datas)
		if(datas.result=='good'){
			this.setState({List:datas.list})
		}else{
			this.setState({List:[]})
		}
	  })	
	}
	goAdView(ev){
		let id=$(ev.target).attr("dataid")
		let title=$(ev.target).attr("title")
		window.localStorage.setItem( 'ad_user_id', id);
		window.localStorage.setItem( 'ad_title', title);
		//console.log(id,title)
		this.props.history.push("/ad-list")
		//this.props.onTabClick("ad-list")
	}
	render(){   
		//---
		const list=this.state.List;
		const qcinfo=this.state.qcinfo;
		let title=''
		let imageurl=''
		let url=''
		let description=''
		if(qcinfo!='' && qcinfo!=null && qcinfo!='null'){
			 title=qcinfo.ad_title?qcinfo.ad_title:'';
			 imageurl=qcinfo.ad_logo_url?qcinfo.ad_logo_url:'https://back-end.coopbet.com/gf/ywqBBMhT0U0022_no-logo.png';
			 url=qcinfo.ad_url?qcinfo.ad_url:'';
			 description=qcinfo.ad_description?qcinfo.ad_description:'';
			 description=(description.length<=85)?description:description.substr(0,85)+'...'
		}	
		//---
		//console.log(this.state.homebetList)
        return (
			<div id="body">   	
			{
				(qcinfo && qcinfo!=null && qcinfo!='')?
				<Row className="randomad">
					<Col md={4} xs={4} sm={4} className="pd0">
						<div className="img-adv">
							<a href={url} target="_blank" className="rand-logo">
								<img alt="m88" src={imageurl} width="100" height="50"/>
							</a>
						</div>
					</Col>
					<Col md={8} xs={8} sm={8} className="pd0">
						<div className="desc-adv">									
						{
							ReactHtmlParser(description)
						}
							<span>
							{
								(qcinfo!='' && qcinfo!=null)?
								<a onClick={this.goAdViews.bind(this)} dataid={qcinfo.id} title={title} className="v-more">{localizeKey(Lqhome)('viewmore', this.state.lguages)}</a>:''
							}
							</span>
						</div>
					</Col>
				</Row>:''
			}
				
				<Row className="mrgt7 mgbt3">
					<Col md={12} xs={12} sm={12} className="add-title active">
						SPORTBOOK NEWS
					</Col>
					
				</Row>
				<Row className="line-ct"></Row>
				
				<Row className="mg0 mrgt7 mgbt3">
					<Col md={12}>
						{
							(list!='')?list.map((it,idx)=>{
									let urlUser=(it.aduser_url!='' && it.aduser_url!=null && it.aduser_url!='null')?it.aduser_url:''
									return(
										<Col md={6} className="brth-box pdlr5" key={idx}>
											<Row className="row-ad">
												<Col md={4} xs={4} sm={4} className="pd0">
													<img src={it.aduser_logo_url} className="logo-ad"/>
												</Col>
												<Col md={8} xs={8} sm={8} className="txtcenter">
													<h3 className="caption-ad">{it.first_name}</h3>
													<a href={it.aduser_url} className="lnk-note-ad">{(urlUser.length<=30)?urlUser:urlUser.substr(0,30)+'...'} </a>
												</Col>
											</Row>
											<Row style={{'margin':'0'}}>
												<Col md={12} className="desc-ad heig50">{(it.aduser_introduction.length<=160)?it.aduser_introduction:it.aduser_introduction.substr(0,160)+'...'}</Col>
												<Col md={12} style={{'padding':'0px'}}>
													<Col md={4} className="creat-by-ad"><span style={{'display':'none'}}>Create/Update: {it.CreatUpdateDate}</span></Col>
													<Col md={8} className="note-to" >
														<span style={{'display':'none'}}>100% up to $200</span>
														<a href="" onClick={this.goAdView.bind(this)} dataid={it.user_id} title={it.first_name}>view ads</a>
													</Col>
												</Col>
											</Row>
										</Col>
									)
								}):''
						}
					</Col>
				</Row>
				
				
			</div>
        );
    }
}

//export default connect(mapStateToProps, mapDispatchToProps)(Ad)
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Ad));