import React from 'react';
import { Link} from 'react-router-dom'
import { connect } from 'react-redux'
import agent from '../../agent'
import {Col, Row,FormControl, Form, FormGroup, ControlLabel, Button} from 'react-bootstrap'
import '../../styles/App.css'
import '../../styles/Friends.css'
import $ from 'jquery';
import {TOKEN_NAME, REDIRECT,LANGUAGE_KEY,CHANGE_TAB} from '../../constants/actionTypes'
//import ModalErorr from '../common/ModalErorr'
import { localize, localizeKey,setLocale, getLocale } from 'react-redux-localization';
import { Cookies } from 'react-cookie' 
import translations from '../common/captionhome.json';

const mapStateToProps = state => (
    Object.assign({}, state, {
		paramsSave: state.home,
		token: state.common.token,
		tab: state.common.tab,
		languageKey: state.common.languageKey,
		languages:state.common.languageslist,
		//active: state.common.active,
		//user_fiend: state.common.user_fiend,
	})      
);

const mapDispatchToProps = dispatch => ({               
	
	onRedirect: () =>
		dispatch({ type: REDIRECT }),
	
	onTabClick: (tab) => 
		dispatch({ type: CHANGE_TAB, tab }),
	
});

class MyFriends extends React.Component {
	constructor(){
		super()
		this.state = {
			url:'https://www.coopbet.com/media/user_none.jpg',
			list:[],isOpenModal:false,msgLogin:'',user_id:'',lguages:'1005',id_user_log:'',
			follow_list:[],number_of_follows:0,number_of_friends:0,fllow:false,
		}
		//---
		this.close=()=>{
			this.setState({isOpenModal:false})
		}
		//----
		this.viewFriend=()=>{
			this.setState({fllow:false})
		}
		this.viewFollow=()=>{
			this.setState({fllow:true})
		}
		//----
		
		
	}
	
	
	//---------------------------
    
	componentWillReceiveProps(nextProps) {
		//console.log(nextProps)
		if(nextProps){
			//this.friendlistNew(nextProps.user_id)
			this.setState({list:nextProps.user_fiend})
		}
		if(nextProps.languageKey){
			this.setState({lguages:nextProps.languageKey});
		}
	}	

	componentWillMount() {
		//---
		const cookies = new Cookies()
		let language=(cookies.get('language')!='' && cookies.get('language') !=null && cookies.get('language')!== 'undefined')?cookies.get('language'):'1005';
		this.setState({'lguages':language})
		//----
		let login=window.localStorage.getItem(TOKEN_NAME);
		 
		if(login!='' && login!='null' && login!=null){
			 login=JSON.parse(login)
			 this.setState({id_user_log:login.UserInfo.id})
		}
		let user_id=window.localStorage.getItem('user_id')
		if(user_id>0){
			this.getMobileUserStatisticFriends(user_id)
			this.setState({user_id:user_id})
		}
	}	
	componentDidMount() {
		
	}
	
	
	showBoxFriend(ev){
		let $this=ev.target.closest(".g-btn")
		$($this).find(".dr-fr").show();
		//console.log($this)
	}
	hideBoxFriend(ev){
		let $this=ev.target.closest(".g-btn")
		$($this).find(".dr-fr").hide();
	}
	deleteFriend(ev){
		let $this=ev.target
		let id=$($this).attr("data-id")
		agent.friends.deleteFriend({friend_user_id:id}).then((datas) => {
			//console.log(datas)
			if(datas.result=='good'){
				let newList=this.state.list.map(item=>{
					if(item!='' && item!='null' && item!=null && item.user_id != id){
						return item;
					}else{
						return null;
					}
				})
				this.setState({list:newList})
			}
			
		  })	
	}
	removeFollow(ev){
		let thiss=ev.target;
		let id=$(thiss).attr("data-id");
		agent.friends.removeFollow({followed_user_id:id}).then(data => {
			if(data.result=="good"){
				
			}
		})
	}
	getMobileUserStatisticFriends(user_id){
		agent.home.mobileUserStatisticFriends({user_id:user_id}).then(data => {
			//console.log(data)
			if(data.result === 'good'){
				this.setState({list:data.list,follow_list:data.follow_list,number_of_friends:data.number_of_friends,
				               number_of_follows:data.number_of_follows})
			}
		})
	}
	myFunction() {
	  $("#myDropdown").addClass("show");
	}
	createrequest(ev){
		
		let thiss=ev.target.closest('.add-friend');
		$(thiss).find('.loading').show();
		const to_user_id=$(thiss).attr("data-id")
		agent.friends.createrequest({to_user_id:to_user_id}).then(data => {
			//console.log(data)
			if(data.result === 'good'){
				$(thiss).find('.loading').hide();
				const list=this.state.list.map(item=>{
					if(item.user_id==to_user_id){
						item.FriendStatusWithLoginUser='Pending'
					}
					return item;
				})
				this.setState({isOpenModal:false,msgLogin:'',list:list})
			}else{
				this.setState({isOpenModal:true,msgLogin:data.message})
				$(thiss).find('.loading').hide();
			}
		})
	}
	goProfile(id,e){
		window.localStorage.setItem('user_id',id )
		//this.props.history.push("/profile")
		window.location.reload();
	}
	render() {
		$(".body").css({'background':'#e9ebee'})
		$(window).hover(function(e){
			if($(e.target).closest(".g-btn").length==0 && $(e.target).closest(".dr-fr").length==0 ){
				$(".dr-fr").hide();
			}
		})
		let url=this.state.url
		const list=this.state.list
		const user_id=this.state.id_user_log
		const profile_id=this.state.user_id
		const number_of_follows=this.state.number_of_follows
		const number_of_friends=this.state.number_of_friends
		const follow_list=this.state.follow_list
		const fllow=this.state.fllow
		//console.log(user_id)
		//---------
			return (					
						
						<Row className="">
						{
							(profile_id==user_id)?
							<Col md={12} className="pdlr16px">
								<Col md={6} xs={6} sm={6} className="left-btn">
									<button className="btn btn-a" onClick={this.viewFriend}>
										<i className="fas fa-user-friends icon-friend"></i>
										<span className={(fllow==false)?"txt-red":""}>Friends </span>({this.state.number_of_friends})
									</button>
								</Col>
								<Col md={6} xs={6} sm={6} className="right-btn">
									<button className="btn btn-a" onClick={this.viewFollow}>
										<span className={(fllow==true)?"txt-red":""}>Followings </span>({this.state.number_of_follows})
									</button>
								</Col>
							</Col>:
							<Col md={12} className="pdlr16px">
								<Col md={12} xs={12} sm={12} className="left-btn">
								<button className="btn btn-a" onClick={this.viewFriend}>
									<i className="fas fa-user-friends icon-friend"></i>
									<span className={(fllow==false)?"txt-red":""}>Friends </span>({this.state.number_of_friends})
								</button>
								</Col>
							</Col>
						}
							
							<Col md={12} className="friend-body" id="fr-body">
								<div className="fiend-box-top" id="friend-request">
									<div className="frd-top">
										<div className="ft-items">
										{(fllow==false)?
											<Col md={12} className="ctn">
												{
													(list!='' && list!='null' && list!=null)?list.map((data,index)=>{
														if(data!='' && data!='null' && data!=null){
															return(
																<Col md={6} className="ft-item ft-item-0 ft-item-bst" key={index}>
																	<figure className="pull-left"><img src={(data.imageurl!='' && data.imageurl!=null)?data.imageurl:url} width="50" height="50" className="_rv"/></figure>
																	<div className="fr-it-body">	
																		<div className="name name-fr pull-left">
																			<span className="fwb" onClick={this.goProfile.bind(this,data.user_id)}>{data.tipster_name}</span>
																			<div className="count-fr">{data.friendcount} {(data.friendcount>1)?'friends':'friend'}</div>
																		</div>
																		
																		{
																				(data.user_id==user_id)?'':
																				(data.FriendStatusWithLoginUser=="Confirmed")?
																				<div className="g-btn pull-right mrgt5px">
																					<a className="btn-2 pull-right tfd-delete" onMouseOver={this.showBoxFriend.bind(this)}><i className="fa fa-angle-down fa-it"></i>Friends</a>
																					<ul role="menu" className="dropdown-menu dr-fr" onMouseLeave={this.hideBoxFriend.bind(this)}>
																						<li><a data-id={data.user_id} onClick={this.deleteFriend.bind(this)}>Unfriend</a></li>
																						<li><a data-id={data.user_id} onClick={this.removeFollow.bind(this)}>Unfollowing</a></li>
																					</ul>
																				</div>:
																				<div className="g-btn pull-right mrgt5px">
																					<a className="btn-2 pull-right add-friend" data-id={data.user_id} onClick={this.createrequest.bind(this)}>
																						<i className="fas fa-user-plus mrgr3"></i>
																						{(data.FriendStatusWithLoginUser=="Pending")?'Friend Request Sent':(data.FriendStatusWithLoginUser=="PendingYou")?'Waiting you confirm':localizeKey(translations)('AF', this.state.lguages)}
																						<img className="loading" src="https://www.coopbet.com/media/loading.gif" width="20" style={{'display':'none'}}/>
																					</a>
																				</div>
																			}
									
																	</div>
																</Col>
															)
														}
														
													}):<div></div>
												}
												
												
													
											</Col>:
											<Col md={12} className="ctn">
												{
													(follow_list!='' && follow_list!='null' && follow_list!=null)?follow_list.map((data,index)=>{
														if(data!='' && data!='null' && data!=null){
															return(
																<Col md={6} className="ft-item ft-item-0 ft-item-bst" key={index}>
																	<figure className="pull-left"><img src={(data.imageurl!='' && data.imageurl!=null)?data.imageurl:url} width="50" height="50" className="_rv"/></figure>
																	<div className="fr-it-body">	
																		<div className="name name-fr pull-left">
																			<span className="fwb" onClick={this.goProfile.bind(this,data.user_id)}>{data.tipster_name}</span>
																			<div className="count-fr">{data.friendcount} {(data.friendcount>1)?'friends':'friend'}</div>
																		</div>
																		
																		{
																				(data.user_id==user_id)?'':
																				(data.FriendStatusWithLoginUser=="Confirmed")?
																				<div className="g-btn pull-right mrgt5px">
																					<a className="btn-2 pull-right tfd-delete" onMouseOver={this.showBoxFriend.bind(this)}><i className="fa fa-angle-down fa-it"></i>Friends</a>
																					<ul role="menu" className="dropdown-menu dr-fr" onMouseLeave={this.hideBoxFriend.bind(this)}>
																						<li><a data-id={data.user_id} onClick={this.deleteFriend.bind(this)}>Unfriend</a></li>
																						<li><a data-id={data.user_id} onClick={this.removeFollow.bind(this)}>Unfollowing</a></li>
																					</ul>
																				</div>:
																				<div className="g-btn pull-right mrgt5px">
																					<a className="btn-2 pull-right add-friend" data-id={data.user_id} onClick={this.createrequest.bind(this)}>
																						<i className="fas fa-user-plus mrgr3"></i>
																						{(data.FriendStatusWithLoginUser=="Pending")?'Friend Request Sent':(data.FriendStatusWithLoginUser=="PendingYou")?'Waiting you confirm':localizeKey(translations)('AF', this.state.lguages)}
																						<img className="loading" src="https://www.coopbet.com/media/loading.gif" width="20" style={{'display':'none'}}/>
																					</a>
																				</div>
																			}
									
																	</div>
																</Col>
															)
														}
														
													}):<div></div>
												}
												
												
													
											</Col>
										}
										</div>
									</div>
								</div>
							</Col>
						</Row>
						
						
			)	
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(MyFriends)
