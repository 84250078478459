import React, {Component} from 'react'
import { connect } from 'react-redux'
import { setLocale, getLocale, localizeKey } from 'react-redux-localization';
import { Cookies } from 'react-cookie' 
import '../../styles/App.css'
import '../../styles/home.css'
import {Col, Row,FormControl, Form, FormGroup, ControlLabel, Button, Tabs,Tab,Table,Radio} from 'react-bootstrap'
import { createElementsFromText } from 'html-text-to-react';
import ReactHtmlParser from 'react-html-parser';
import $ from 'jquery';
import {TOKEN_NAME, REDIRECT,HOME_PAGE_LOADED, APP_LOAD,LANGUAGE_KEY} from '../../constants/actionTypes'
import agent from '../../agent'
import menu from '../common/menu.json'
import Lqhome from '../common/home.json'
import ModalErorr from '../common/ModalErorr'
import DatePicker from 'react-datepicker';
import SummaryTips from './summaryTips'
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

const mapStateToProps = state => (
    Object.assign({}, state, {
		tab: state.common.tab,
		updateProps: state.common.updateProps,
		locale: getLocale(state),
		languageKey: state.common.languageKey
	}) 

);

const mapDispatchToProps = dispatch => ({               
	onLoad: (data) => {
		dispatch({ type: 'HOME_PAGE_LOADED', data})  
	},
	chooseLocale: locale => dispatch(setLocale(locale))
});



 


class TipsInvestment extends Component{ 	
	constructor(){
		super()
		this.state = {
			lguages:'1005',
			qcinfo:{},idUser:'',isOpenModal:false,msg:'',
			startDate:new Date(),month:'',buyer_open_order_list:[],
			isOpen:false,order_id:'',buyer_paid_order_list:[],IsOpenReport:false,
			order_number:'',keyTab:'key1'
		}
		//---
		this.getrandomad=()=>{
			let lang_id=this.state.lguages
			let param={lang_id:lang_id}
			agent.home.getrandomad2(param).then(data => {
				//console.log(data)
				if(data.result === 'good'){
					this.setState({qcinfo:data.info})
				}
			})
		}
		//---
		this.goAdView=this.goAdView.bind(this)
		//---
		this.close=()=>{
			this.setState({isOpenModal:false})
		}
		
		this.getListForBuyer=()=>{
			let month = this.state.month
			agent.orders.getListForBuyer({month:month}).then(data => {
				//console.log(data)
				if(data.result === 'good'){
					let buyer_open_order_list=data.buyer_open_order_list
					let buyer_paid_order_list=data.buyer_paid_order_list
					this.setState({buyer_open_order_list:buyer_open_order_list,
								   buyer_paid_order_list:buyer_paid_order_list})
				}
			})
		}
		this.vieImg=(id,e) => {
			
			let letms=this.state.buyer_open_order_list
			letms.map(it=>{
				if(it.order_id===id){
					it.isOpenIM=true
				}
				return it;
			})
			
			//console.log(letms)
			this.setState({buyer_open_order_list:letms})
			
		}
		this.hideImg=(id,e) => {
			
			let letms=this.state.buyer_open_order_list
			letms.map(it=>{
				if(it.order_id===id){
					it.isOpenIM=false
				}
				return it;
			})
			
			//console.log(letms)
			this.setState({buyer_open_order_list:letms})
			
		}
		
	}
	goAdView(ev){
		let id=$(ev.target).attr("dataid")
		/*let title=$(ev.target).attr("title")
		window.localStorage.setItem( 'ad_user_id', id);
		window.localStorage.setItem( 'ad_title', title);
		//console.log(id,title)
		window.location.href='/ad-list' */
		window.location.href='/new-info/'+id
	}
	componentWillMount(){	
		//-----
		
		let idLanguage=window.localStorage.getItem(LANGUAGE_KEY)
		if(idLanguage!='' && idLanguage!='null' && idLanguage!=null){
			this.setState({lguages:idLanguage},()=>{
				this.getrandomad();
			})
		}
		let login=window.localStorage.getItem(TOKEN_NAME);
		if(login!='' && login!='null' && login!=null){
			login=JSON.parse(login)
			let info=login.UserInfo
			//console.log(login.UserInfo)
			this.setState({idUser:info.id},()=>{
				//this.getbankrolls(info.id)
			})				
		}
		let dates=new Date();
		let mth=dates.getMonth()+1
		let year=dates.getFullYear()
		let month=mth+"/"+year
		this.setState({month:month},()=>{
			this.getListForBuyer();
		})
		
		//-----
	}
	componentDidMount(){
		this.getrandomad();
	}
	componentWillReceiveProps(nextProps){
		//console.log(nextProps)
		if(nextProps.languageKey && nextProps.languageKey!='' && nextProps.languageKey!=null && nextProps.languageKey!='null'){
			this.setState({lguages:nextProps.languageKey},()=>{
				this.getrandomad()
			});
			
		}
	}
	
	shouldComponentUpdate(nextProps, nextState){
		return nextState;
	}	
	
	handleChangeDate(date){
		let dates=date.getDate();
		var month = date.getMonth() + 1;
		let year=date.getFullYear();
		let mth=month+"/"+year
		this.setState({startDate:date,month:mth,offsetM:0,selected:0},()=>{
			this.getListForBuyer();
		})
	}
	
	numberFormat(x) {
		return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
	}
	render(){   
		//---
		const qcinfo=this.state.qcinfo;
		let title=''
		let imageurl=''
		let url=''
		let description=''
		if(qcinfo!='' && qcinfo!=null && qcinfo!='null'){
			 title=qcinfo.ad_title?qcinfo.ad_title:'';
			 imageurl=qcinfo.ad_logo_url?qcinfo.ad_logo_url:'https://back-end.coopbet.com/gf/ywqBBMhT0U0022_no-logo.png';
			 url=qcinfo.ad_url?qcinfo.ad_url:'';
			 description=qcinfo.ad_description?qcinfo.ad_description:'';
			 description=(description.length<=85)?description:description.substr(0,85)+'...'
		}	
	
		//---
		const listOpen=this.state.buyer_open_order_list
		const listPaid=this.state.buyer_paid_order_list
		//console.log(this.state.startDate)
        return (
			<div id="body"> 
			{
				(qcinfo && qcinfo!=null && qcinfo!='')?
				<Row className="randomad">
					<Col md={4} xs={4} sm={4} className="pd0">
						<div className="img-adv">
							<a href={url} target="_blank" className="rand-logo">
								<img alt="m88" src={imageurl} width="100" height="50"/>
							</a>
						</div>
					</Col>
					<Col md={8} xs={8} sm={8} className="pd0">
						<div className="desc-adv">									
						{
							ReactHtmlParser(description)
						}
							<span>
							{
								(qcinfo!='' && qcinfo!=null)?
								<a onClick={this.goAdView.bind(this)} dataid={qcinfo.id} title={title} className="v-more">{localizeKey(Lqhome)('viewmore', this.state.lguages)}</a>:''
							}
							</span>
						</div>
					</Col>
				</Row>:''
			}
				<Row className="mg0 mrgt7 mgbt7">
					
					<Col md={7} xs={7} sm={7}>
						<i className="far fa-list-alt " style={{'fontSize':'20px','verticalAlign':'top'}}></i>
						<div className="rigister-title" style={{'textTransform':'uppercase'}}>TIPS INVESTMENT</div>
					</Col>
					<Col md={5} xs={5} sm={5} className="rank-mth date-tips">
						<i className="fa fa-caret-down icon-carets"></i>
						<DatePicker
						  selected={this.state.startDate}
						  onChange={this.handleChangeDate.bind(this)}
						  dateFormat="MM/yyyy"
						  showMonthYearPicker
						  
						/>
						
					</Col>
									
				</Row>
				<Row className="line-ct" style={{'marginBottom':'10px'}}></Row>
				<Row className="mg0 " id="div-tips" style={{'marginTop':'0'}}>
					<Tabs defaultActiveKey="key1" id="tab-tips">
						<Tab eventKey="key1" title="Open" >
							
							<Row className="mg1">
								<Col md={12} style={{'padding':'0'}}>
								<Table className="tbl-tipters" style={{'marginBottom':'0px'}}>
									<thead>
										<tr>
											<th colSpan="1" style={{'width':'10%'}}>No.</th>
											<th colSpan="1" style={{'width':'40%'}}>Mã đơn hàng</th>
											<th colSpan="1" style={{'width':'25%'}}>Tipster</th>
											<th colSpan="1" style={{'width':'25%','textAlign':'center'}}>Invoice file</th>
										</tr>
									</thead>
									<tbody>
									{
										(listOpen!=='')?listOpen.map((dt,idx)=>{
											let orderDate=dt.order_datetime
											orderDate=orderDate.split(" ")
											let file=dt.proof_of_payment_url
											var res = (file!==null)?'...'+file.substring(file.length-12):'';
											return(
											<tr key={idx}>
												<td>0{idx+1}</td>
												<td className="lin-in"><a href={"/invoice-tips-info/"+dt.order_id} className="lnk-inv">{dt.order_number}</a></td>
												<td>{dt.seller_tipster_name}</td>
												<td style={{'textAlign':'center'}}>
													{
														(res!=='')?<div>
														<a className="cusor" onClick={this.vieImg.bind(this,dt.order_id)} style={{'color':'#c90a0a'}}>{res}</a>
														{
															dt.isOpenIM && (
															  <Lightbox
																mainSrc={dt.proof_of_payment_url}
															   onCloseRequest={this.hideImg.bind(this,dt.order_id)}
																
															  />
														
															)
														}
														</div>:
															
														<a className="cusor" href={"/upload-receipt/"+dt.order_id}><i className="fas fa-paperclip"></i></a>
													}
												</td>
											</tr>	
											)
										}):''
										
									}												
									</tbody>
								</Table>
								</Col>
								
							</Row>
							
						</Tab>
						<Tab eventKey="key2" title="Paid" >
							<Row className="mg1" >
								<Col md={12} className="pd0">
									<Table className="tbl-tipters" style={{'marginBottom':'0px'}}>
										<thead>
											<tr>
												<th colSpan="1" style={{'width':'10%'}}>No.</th>
												<th colSpan="1" style={{'width':'30%'}}>Mã đơn hàng</th>
												<th colSpan="1" style={{'width':'30%'}}>Tipster</th>	
												<th colSpan="1" style={{'width':'30%'}}>End Date</th>
												
											</tr>
										</thead>
										<tbody>
										{
											(listPaid!=='')?listPaid.map((dt,idx)=>{
												let orderDate=dt.order_enddate
												orderDate=orderDate.split(" ")
												let file=dt.proof_of_payment_url
												var res = (file!==null)?'...'+file.substring(file.length-12):'';
												return(
												<tr key={idx}>
													<td>0{idx+1}</td>
													<td className="lin-in"><a href={"/invoice-tips-info/"+dt.order_id} className="lnk-inv">{dt.order_number}</a></td>
													<td>{dt.seller_tipster_name}</td>
													<td>														
														{orderDate[0]}
															
													</td>
													
												</tr>	
												)
											}):''
										}												
										</tbody>
									</Table>
								</Col>
								
							</Row>
							
						</Tab>
						<Tab eventKey="key3" title="Tips" >
							<SummaryTips  month={this.state.month}/>
							
						</Tab>
						
					</Tabs>
				</Row>
				
				<ModalErorr isOpen={this.state.isOpenModal} close={this.close} text={this.state.msg}/>
				
			</div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TipsInvestment)