import React, {Component} from 'react'
import { connect } from 'react-redux'
import { setLocale, getLocale, localizeKey } from 'react-redux-localization';
import { Cookies } from 'react-cookie' 
import '../../styles/App.css'
import '../../styles/home.css'
import {Col, Row,FormControl, Form, FormGroup, ControlLabel, Button, Tabs,Tab,Table,Radio} from 'react-bootstrap'
import { createElementsFromText } from 'html-text-to-react';
import ReactHtmlParser from 'react-html-parser';
import $ from 'jquery';
import {TOKEN_NAME, REDIRECT,HOME_PAGE_LOADED, APP_LOAD,LANGUAGE_KEY} from '../../constants/actionTypes'
import agent from '../../agent'
import menu from '../common/menu.json'
import Lqhome from '../common/home.json'
import ImageUploader from 'react-images-upload';
import ModalErorr from '../common/ModalErorr'


const mapStateToProps = state => (
    Object.assign({}, state, {
		tab: state.common.tab,
		updateProps: state.common.updateProps,
		locale: getLocale(state),
		languageKey: state.common.languageKey
	}) 

);

const mapDispatchToProps = dispatch => ({               
	onLoad: (data) => {
		dispatch({ type: 'HOME_PAGE_LOADED', data})  
	},
	chooseLocale: locale => dispatch(setLocale(locale))
});



 


class InVoiceTip extends Component{ 	
	constructor(){
		super()
		this.state = {
			lguages:'1005',
			qcinfo:{},idUser:'',listBank:[],buyer_bank_type_id:1,order_id:'',pictures:[],
			fileurl:'',isOpenModal:false,msg:''
		}
		//---
		this.getrandomad=()=>{
			let lang_id=this.state.lguages
			let param={lang_id:lang_id}
			agent.home.getrandomad2(param).then(data => {
				//console.log(data)
				if(data.result === 'good'){
					this.setState({qcinfo:data.info})
				}
			})
		}
		//---
		this.goAdView=this.goAdView.bind(this)
		//---
		this.onDrop=this.onDrop.bind(this)
		//---
		this.getBankTypes=()=>{
			agent.home.getBankTypes({}).then(data => {
				if(data.result=="good"){					
					this.setState({listBank:data.list})
				}else{
				}
			})
		}
		this.uploadProofofpayment=()=>{
			let order_id=this.state.order_id
			let picture=this.state.pictures
			agent.auth.uploadProofofpayment({order_id:order_id,picture:picture}).then(data => {
				if(data.result=="good"){					
					this.setState({fileurl:data.fileurl})
				}else{
					this.setState({msg:data.message,isOpenModal:true})
				}
			})
		}
		this.orderProofofpayment=()=>{
			let order_id=this.state.order_id
			let buyer_bank_type_id=this.state.buyer_bank_type_id
			let proof_of_payment_url=this.state.fileurl
			agent.home.orderProofofpayment({order_id:order_id,proof_of_payment_url:proof_of_payment_url,
											buyer_bank_type_id:buyer_bank_type_id}).then(data => {
				if(data.result=="good"){
					window.location="/tips-investment"
				}else{
					this.setState({msg:data.message,isOpenModal:true})
				}
			})
		}
		this.goBack=()=>{
			window.location='/invoice-tips/'+this.state.order_id
		}
		this.close=()=>{
			this.setState({isOpenModal:false})
		}
	}
	goAdView(ev){
		let id=$(ev.target).attr("dataid")
		/*let title=$(ev.target).attr("title")
		window.localStorage.setItem( 'ad_user_id', id);
		window.localStorage.setItem( 'ad_title', title);
		//console.log(id,title)
		window.location.href='/ad-list' */
		window.location.href='/new-info/'+id
	}
	
	componentWillMount(){	
		//-----
		
		let idLanguage=window.localStorage.getItem(LANGUAGE_KEY)
		if(idLanguage!='' && idLanguage!='null' && idLanguage!=null){
			this.setState({lguages:idLanguage},()=>{
				this.getrandomad();
			})
		}
		let login=window.localStorage.getItem(TOKEN_NAME);
		if(login!='' && login!='null' && login!=null){
			login=JSON.parse(login)
			let info=login.UserInfo
			//console.log(login.UserInfo)
			this.setState({idUser:info.id},()=>{
				//this.getbankrolls(info.id)
			})				
		}
		
		let id=this.props.match.params.id
		this.setState({order_id:id})
		this.getBankTypes();
		//-----
	}
	componentDidMount(){
		this.getrandomad();
	}
	componentWillReceiveProps(nextProps){
		//console.log(nextProps)
		if(nextProps.languageKey && nextProps.languageKey!='' && nextProps.languageKey!=null && nextProps.languageKey!='null'){
			this.setState({lguages:nextProps.languageKey},()=>{
				this.getrandomad()
			});
			
		}
	}
	
	shouldComponentUpdate(nextProps, nextState){
		return nextState;
	}	
	
	onDrop(picture) {
		//console.log(picture[0])
        this.setState({			
           // pictures: picture
            pictures: picture[0]
        },()=>{
			this.uploadProofofpayment();
		});
		
		//agent.user.uploadMedias({medias :picture[0]}).then((datas) => {			
		//	console.log(datas)
		//})
    }
	handleChangeField(feild,e){
		this.setState({[feild]:e.target.value})
	}
	render(){   
		//---
		const qcinfo=this.state.qcinfo;
		let title=''
		let imageurl=''
		let url=''
		let description=''
		if(qcinfo!='' && qcinfo!=null && qcinfo!='null'){
			 title=qcinfo.ad_title?qcinfo.ad_title:'';
			 imageurl=qcinfo.ad_logo_url?qcinfo.ad_logo_url:'https://back-end.coopbet.com/gf/ywqBBMhT0U0022_no-logo.png';
			 url=qcinfo.ad_url?qcinfo.ad_url:'';
			 description=qcinfo.ad_description?qcinfo.ad_description:'';
			 description=(description.length<=85)?description:description.substr(0,85)+'...'
		}	
	
		//---
		let listBank=this.state.listBank
        return (
			<div id="body"> 
			{
				(qcinfo && qcinfo!=null && qcinfo!='')?
				<Row className="randomad">
					<Col md={4} xs={4} sm={4} className="pd0">
						<div className="img-adv">
							<a href={url} target="_blank" className="rand-logo">
								<img alt="m88" src={imageurl} width="100" height="50"/>
							</a>
						</div>
					</Col>
					<Col md={8} xs={8} sm={8} className="pd0">
						<div className="desc-adv">									
						{
							ReactHtmlParser(description)
						}
							<span>
							{
								(qcinfo!='' && qcinfo!=null)?
								<a onClick={this.goAdView.bind(this)} dataid={qcinfo.id} title={title} className="v-more">{localizeKey(Lqhome)('viewmore', this.state.lguages)}</a>:''
							}
							</span>
						</div>
					</Col>
				</Row>:''
			}
				<Row className="mg0 mrgt7 mgbt7">
					
					<Col md={12} xs={12} sm={12}>
						<i className="far fa-list-alt " style={{'fontSize':'20px','verticalAlign':'top'}}></i>
						<div className="rigister-title" >GỬI BẰNG CHỨNG THANH TOÁN</div>
					</Col>
					
									
				</Row>
				<Row className="line-ct" style={{'marginBottom':'10px'}}></Row>
				
				<Row className="mg0 " >
					<Col md={12}>
						<label className="lb14">Trả qua</label>
						<select placeholder="select" className="form-control" id="selec-tt" name="time_zone" value={this.state.buyer_bank_type_id} id="selec-tt" onChange={this.handleChangeField.bind(this,'buyer_bank_type_id')}>
							{
								(listBank!=='')?listBank.map((dt,idx)=>{
									return(
									<option value={dt.id} key={idx}> {dt.bank_type_name} </option>
									)
								}):''
							}
						 </select>
						
					</Col>
					<Col md={12} style={{'marginTop':'10px'}}>
						<label className="lb14">Tải lên bằng chứng</label>
						 <ImageUploader
							withIcon={true}
							buttonText='chọn file'
							onChange={this.onDrop}
							//imgExtension={['.jpg', '.gif', '.png', '.gif']}
							withPreview={true}
							//maxFileSize={5242880}
						/>
						<div>
							<i className="fas fa-exclamation-circle ic-exa"></i>
							<span>Hình ảnh cần phải rõ nét. Sử dụng ảnh chụp màn hình nếu bạn có thể.</span>
						</div>
					</Col>
					<Col md={12} className="line-1px"></Col>
					<Col md={12} className="iv-ft" style={{'marginBottom':'10px'}}>
						<button type="button" className="btn btn-closed" onClick={this.goBack}>Cancel</button>
						<button type="button" className="btn btn-send-i" onClick={this.orderProofofpayment.bind(this)} disabled={(this.state.fileurl!=='')?false:true}>Gửi</button>
					</Col>
				</Row>
				<ModalErorr isOpen={this.state.isOpenModal} close={this.close} text={this.state.msg}/>
			</div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(InVoiceTip)