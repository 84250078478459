import React, {Component} from 'react'
import { connect } from 'react-redux'
import { setLocale, getLocale } from 'react-redux-localization';
import { Cookies } from 'react-cookie' 
import '../../styles/App.css'
import '../../styles/home.css'
import {Col, Row,FormControl, Form, FormGroup, ControlLabel, Button} from 'react-bootstrap'
import { createElementsFromText } from 'html-text-to-react';
import ReactHtmlParser from 'react-html-parser';
import $ from 'jquery';
import {TOKEN_NAME, REDIRECT,HOME_PAGE_LOADED, APP_LOAD,LANGUAGE_KEY} from '../../constants/actionTypes'
import agent from '../../agent'
import Captcha from './formCaptcha.js'
import ModalErorr from '../common/ModalErorr'
import Lqhome from '../common/home.json'
import { localize, localizeKey } from 'react-redux-localization';

const mapStateToProps = state => (
    Object.assign({}, state, {
		tab: state.common.tab,
		updateProps: state.common.updateProps,
		locale: getLocale(state),
		languageKey: state.common.languageKey
	}) 

);

const mapDispatchToProps = dispatch => ({               
	onLoad: (data) => {
		dispatch({ type: 'HOME_PAGE_LOADED', data})  
	},
	chooseLocale: locale => dispatch(setLocale(locale))
});



 


class AddTips extends Component{ 	
	constructor(){
		super()
		this.state = {
			lguages:'1005',
			qcinfo:{},fullName:'',Company:'',address:'',phone:'',email:'',website:'',message:'',dorepas:true,isOpenModal:false
		}
		//---
		this.getrandomad=()=>{
			let lang_id=this.state.lguages
			let param={lang_id:lang_id}
			agent.home.getrandomad2(param).then(data => {
				//console.log(data)
				if(data.result === 'good'){
					this.setState({qcinfo:data.info})
				}
			})
		}
		//---
		this.close=()=>{
			this.setState({isOpenModal:false})
		}
		this.updateCaptcha=this.updateCaptcha.bind(this) 
		this.sendContact=()=>{
			let state=this.state;
			agent.auth.contactCreate({
				full_name:state.fullName,company:state.Company,
				address:state.address,phone:state.phone,
				email:state.email,website:state.website,message:state.message,
			}).then((datas) => {
			//console.log(datas)
				if(datas.result==='good'){
					let msg='Thank you ! We will contact you soon.'
					let thiss=this;
					this.setState({msgRigister:msg,isOpenModal:true,									
							       fullName:'',Company:'',address:'',phone:'',email:'',
							       website:'',message:''},()=>{
						setTimeout(function(){
							thiss.setState({msgRigister:'',isOpenModal:false})
						},9000);
					})
				}else{
					this.setState({msgRigister:datas.message,isOpenModal:true})
				}
		   })
		}
		//---
		this.goAdView=this.goAdView.bind(this)
	}
	goAdView(ev){
		let id=$(ev.target).attr("dataid")
		/*let title=$(ev.target).attr("title")
		window.localStorage.setItem( 'ad_user_id', id);
		window.localStorage.setItem( 'ad_title', title);
		//console.log(id,title)
		window.location.href='/ad-list' */
		window.location.href='/new-info/'+id
	}
	componentWillMount(){	
		//-----
		
		let idLanguage=window.localStorage.getItem(LANGUAGE_KEY)
		if(idLanguage!='' && idLanguage!='null' && idLanguage!=null){
			this.setState({lguages:idLanguage},()=>{
				this.getrandomad();
			})
		}
		
		//-----
	}
	componentDidMount(){
		this.getrandomad();
	}
	componentWillReceiveProps(nextProps){
		//console.log(nextProps)
		if(nextProps.languageKey && nextProps.languageKey!='' && nextProps.languageKey!=null && nextProps.languageKey!='null'){
			this.setState({lguages:nextProps.languageKey},()=>{
				this.getrandomad()
			});
			
		}
	}
	
	shouldComponentUpdate(nextProps, nextState){
		return nextState;
	}	
	
	handleChangeFiled(feild,ev){
		//console.log(ev.target.value)
		this.setState({[feild]:ev.target.value})
	}
	updateCaptcha(vale){
		//console.log(vale)  
		this.setState({captcharValue:vale})
		if(vale!='' && vale!=null && vale!="null"){
			this.setState({dorepas:false})
		}else{
			this.setState({dorepas:true})
		}
	}
	render(){   
		//---
		const qcinfo=this.state.qcinfo;
		let title=''
		let imageurl=''
		let url=''
		let description=''
		if(qcinfo!='' && qcinfo!=null && qcinfo!='null'){
			 title=qcinfo.ad_title?qcinfo.ad_title:'';
			 imageurl=qcinfo.ad_logo_url?qcinfo.ad_logo_url:'https://back-end.coopbet.com/gf/ywqBBMhT0U0022_no-logo.png';
			 url=qcinfo.ad_url?qcinfo.ad_url:'';
			 description=qcinfo.ad_description?qcinfo.ad_description:'';
			 description=(description.length<=85)?description:description.substr(0,85)+'...'
		}	
		//---
		//console.log(this.state.homebetList)
        return (
			<div id="body">  
			{
				(qcinfo && qcinfo!=null && qcinfo!='')?
				<Row className="randomad">
					<Col md={4} xs={4} sm={4} className="pd0">
						<div className="img-adv">
							<a href={url} target="_blank" className="rand-logo">
								<img alt="m88" src={imageurl} width="100" height="50"/>
							</a>
						</div>
					</Col>
					<Col md={8} xs={8} sm={8} className="pd0">
						<div className="desc-adv">									
						{
							ReactHtmlParser(description)
						}
							<span>
							{
								(qcinfo!='' && qcinfo!=null)?
								<a onClick={this.goAdView.bind(this)} dataid={qcinfo.id} title={title} className="v-more">{localizeKey(Lqhome)('viewmore', this.state.lguages)}</a>:''
							}
							</span>
						</div>
					</Col>
				</Row>:''
			}
				<Row className="mg0 mrgt7 mgbt7">
					<Col md={12} xs={12} sm={12} className="">
						<i className="fa fa-info" style={{'fontSize': '20px'}}></i>
						<div className="rigister-title">CONTACT</div>
					</Col>					
				</Row>
				<Row className="line-ct"></Row>
				
				<Row className="mg0 mrgt7">
					<Col md={12} xs={12} sm={12} className="txt-gr">Please fill the form below.</Col>					
				</Row>
				<Row className="mg0 mrgt7">
					<Col md={2} xs={12} sm={12}><label className="lbl-contact">Full Name:</label></Col>
					<Col md={10} xs={12} sm={12}><input name="Name" type="text" placeholder="" className="form-control input-contact" value={this.state.fullName} onChange={this.handleChangeFiled.bind(this,'fullName')}/></Col>
				</Row>
				<Row className="mg0">
					<Col md={2} xs={12} sm={12}><label className="lbl-contact">Company:</label></Col>
					<Col md={10} xs={12} sm={12}><input name="Company" type="text" placeholder="" className="form-control input-contact" value={this.state.Company} onChange={this.handleChangeFiled.bind(this,'Company')}/></Col>
				</Row>
				<Row className="mg0">
					<Col md={2} xs={12} sm={12}><label className="lbl-contact">Address:</label></Col>
					<Col md={10} xs={12} sm={12}><input name="address" type="text" placeholder="" className="form-control input-contact" value={this.state.address} onChange={this.handleChangeFiled.bind(this,'address')}/></Col>
				</Row>
				<Row className="mg0">
					<Col md={2} xs={12} sm={12}><label className="lbl-contact">Phone:</label></Col>
					<Col md={10} xs={12} sm={12}><input name="phone" type="text" placeholder="" className="form-control input-contact" value={this.state.phone} onChange={this.handleChangeFiled.bind(this,'phone')}/></Col>
				</Row>
				<Row className="mg0">
					<Col md={2} xs={12} sm={12}><label className="lbl-contact">Email:</label></Col>
					<Col md={10} xs={12} sm={12}><input name="email" type="text" placeholder="" className="form-control input-contact" value={this.state.email} onChange={this.handleChangeFiled.bind(this,'email')}/></Col>
				</Row>
				<Row className="mg0">
					<Col md={2} xs={12} sm={12}><label className="lbl-contact">Website:</label></Col>
					<Col md={10} xs={12} sm={12}><input name="website" type="text" placeholder="" className="form-control input-contact" value={this.state.website} onChange={this.handleChangeFiled.bind(this,'website')}/></Col>
				</Row>
				<Row className="mg0">
					<Col md={2} xs={12} sm={12}><label className="lbl-contact">Message:</label></Col>
					<Col md={10} xs={12} sm={12}><textarea name="message" rows="5" placeholder="" className="form-control" value={this.state.message} onChange={this.handleChangeFiled.bind(this,'message')}/></Col>
				</Row>
				<Row className="mg0 mrgt7 mgbt7">
					<Col md={2} xs={12} sm={12}></Col>
					<Col md={10} xs={10} sm={10}>
						<Captcha captcha={this.updateCaptcha} />
					</Col>
				</Row>
				<Row className="mg0">
					<Col md={12} xs={12} sm={12} style={{'textAlign':'right','marginTop':'15px'}}>
						<input type="submit" className="btn-sigin" value="SEND" disabled={this.state.dorepas}  style={{'fontSize':'14px'}} onClick={this.sendContact}/>
					</Col>
				</Row>
				<ModalErorr isOpen={this.state.isOpenModal} close={this.close} text={this.state.msgRigister}/>
			</div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddTips)