import React from 'react';
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import agent from '../../agent'
import {Col, Row,FormControl, Form, FormGroup, ControlLabel, Button} from 'react-bootstrap'
import { localize, localizeKey,setLocale, getLocale } from 'react-redux-localization';
import '../../styles/App.css'
import '../../styles/home.css'
import $ from 'jquery';
import {RESET_DASHBOARD, TOKEN_NAME, REDIRECT, APP_LOAD,LANGUAGE_KEY,
CHANGE_TAB,LANGUAGE_LIST,BASE_IMAGE,MEBER_NAME} from '../../constants/actionTypes'
import TextareaAutosize from 'react-textarea-autosize';
import ModalEditComment from './ModalEditComment';
import ReactHtmlParser from 'react-html-parser';

import Lhome from '../common/home.json';
import '@fortawesome/fontawesome-free/css/fontawesome.css'
import '@fortawesome/fontawesome-free/css/brands.css'
import '@fortawesome/fontawesome-free/css/solid.css'

const mapStateToProps = state => (
    Object.assign({}, state, {
		paramsSave: state.home,
		token: state.common.token,
		tab: state.common.tab,
		languageKey: state.common.languageKey,
		languages:state.common.languageslist,
		active: state.common.active,
		member_name: state.common.member_name,
	})      
);

const mapDispatchToProps = dispatch => ({               
	onResetDashboard: (data) => {
		dispatch({ type: RESET_DASHBOARD, data})  
	},
	onRedirect: () =>
		dispatch({ type: REDIRECT }),
	onLoad: (access_token) =>
		dispatch({ type: APP_LOAD, access_token }),	
	onResetLanguesKey: (data) => {
		dispatch({ type: LANGUAGE_KEY, data})  
	},
	onloadLangues: (data) => {
		dispatch({ type: LANGUAGE_LIST, data})  
	},	
	onTabClick: (tab) => 
		dispatch({ type: CHANGE_TAB, tab }),
	
});

class ReplyList extends React.Component {
	constructor(props){
		super(props)
		this.state = {
			url:'https://www.coopbet.com/media/avartar_cm.jpg',
			img_default:'https://www.coopbet.com/media/avartar_cm.jpg',
			url_like:'https://www.coopbet.com/media/likec.png',
			url_liked:'https://www.coopbet.com/media/likedc.png',
			url_dislike:'https://www.coopbet.com/media/dislikec.png',
			url_disliked:'https://www.coopbet.com/media/dislikedc.png',
			//icon_send:"https://m.coopbet.com/img/send_16.png",
			//icon_error:"https://m.coopbet.com/img/error.png",
			icon_send:"https://m.coopbet.com/img/send_32.png",
			icon_error:"https://m.coopbet.com/img/cancel.png",
			CommentList:[],parent_id:'',for_pick_code:'',member_name:'',
			limit:5,backoffset:'0',numCM:0,UserInfoId:'',show_pre:true,
			user_id_login:'',lguages:'1005',isOpenModalEditCm:false,txt_comment:'',
		}
		//---
		this.close=()=>{
			this.setState({isOpenModalEditCm:false})
		}
		//----
		this.handleClickTab = menu => e => {
			e.preventDefault()
			this.props.onTabClick(menu)
			
		}
		//----
		this.showModalEditCM=(comment_id,for_pick_code,comment)=>{
			this.setState({isOpenModalEditCm:true,comment_id:comment_id,
						   txt_comment:comment,for_pick_code:for_pick_code,txt_title:'Edit Comment'})
		}
		this.updateCommentList=(comment_id,comment)=>{
			let CommentList=this.state.CommentList.map(dt=>{
				if(dt.comment_id==comment_id){
					dt.comment=comment
					dt.edit_cm=1
				}
				return dt;
			})
			this.setState({CommentList:CommentList})
		}
		
	}
	
	
	//---------------------------
    
	componentWillReceiveProps(nextProps) {
		//console.log(nextProps)
		/*this.setState({url:nextProps.imageurl,
			for_pick_code:nextProps.for_pick_code,
			member_name:nextProps.member_name,
			UserInfoId:nextProps.UserInfoId,
			parent_id:nextProps.parent_id},()=>{
				this.listComment(this.state.for_pick_code,this.state.parent_id)
			})*/
		if(nextProps.languageKey && nextProps.languageKey!='' && nextProps.languageKey!=null && nextProps.languageKey!='null'){
			this.setState({lguages:nextProps.languageKey});
		}
	}	

	componentWillMount() {
		//---
		//console.log(this.props.UserInfoId,this.props.for_pick_code,this.props.parent_id)
		this.setState({url:this.props.imageurl,
		for_pick_code:this.props.for_pick_code,
		UserInfoId:this.props.UserInfoId,
		user_id_login:this.props.user_id_login,
		parent_id:this.props.parent_id},()=>{
			this.listComment(this.state.for_pick_code,this.state.parent_id)
		})
		//----
		let idLanguage=window.localStorage.getItem(LANGUAGE_KEY)
		if(idLanguage!='' && idLanguage!='null' && idLanguage!=null){
			this.setState({lguages:idLanguage})
		}
		
		
	}	
	componentDidMount() {
		
	}
	doKeycomment(e){
		let thiss=e.target.closest(".pd10_0")
		$(thiss).find(".bg-green-haze").addClass("active")
		if(e.key=='Enter'){
			
		}
		
		//
	}
	changeCOmment(e){
		let thiss=e.target.closest(".pd10_0")
		$(thiss).find(".ot-btn").show()
	}
	hideComment(e){
		let thiss=e.target.closest(".pd10_0")
		$(thiss).find(".ot-btn").hide()
		$(thiss).find(".textarea-cm").val('')
		$(thiss).find(".bg-green-haze").removeClass("active")
	}
	handleRely(e){
		let thiss=e.target.closest(".ct-cm-reply")
		$(thiss).find(".t-reply").show();
	}
	
	hideRyply(e){
		let thiss=e.target.closest(".t-reply")
		$(thiss).hide();
	}
	hideRyplyChild(e){
		let thiss=e.target.closest(".t-reply-child")
		$(thiss).hide();
	}
	handleRelyChild(e){
		let thiss=e.target.closest(".ct-cm-reply")
		$(thiss).find(".t-reply-child").show();
	}
	createCommentChild(parent_id,for_pick_code,e){
		let thiss=e.target.closest(".t-reply")
		let comment=$(thiss).find(".area-cmt").val();
		let UserInfoId=this.state.UserInfoId
		//---
		//$(thiss).find('.do_cm').hide()
		//$(thiss).find('.ot-btn').hide()
		//$(thiss).find('.loading_dad').show()
		//---
		agent.comment.createComment({for_pick_code:for_pick_code,comment:comment,parent_id:parent_id}).then(data => {
			//console.log(data)
			if(data.result=="good"){
				this.props.listComment(for_pick_code,0)
				//this.listComment(for_pick_code,parent_id)
				this.listComment(this.state.for_pick_code,this.state.parent_id)
				$(thiss).hide()
				//$(thiss).find('.do_cm').show()
				//$(thiss).find('.loading_dad').hide()
				$(thiss).find(".area-cmt").val('');
				window.location.href="./comment/"+UserInfoId+"_"+for_pick_code+"_"+this.state.parent_id;
			}
		})
	}
	listComment(for_pick_code,parent_id){
		const limit=this.state.limit
		let backoffset=this.state.backoffset
		agent.comment.listComment({for_pick_code:for_pick_code,parent_id:parent_id,backoffset:backoffset,limit:limit}).then(data => {
			//console.log(data)
			if(data.result=="good"){
				this.setState({CommentList:data.CommentList,numCM:data.TotalComments})
				//this.props.updateTotalCM(data.TotalComments)
			}
		})
	}
	togglelike(comment_id,ev){
		agent.comment.togglelike({comment_id:comment_id}).then(data => {
			console.log(data)
			if(data.result=="good"){
				const CommentList=this.state.CommentList.map(item=>{
					if(item.comment_id==comment_id){
						item.LikedNum=data.LikedNum
						item.IsLiked=data.IsLiked
						item.IsDisliked=data.IsDisliked
					}
					return item
				})
				this.setState({CommentList:CommentList})
			}
		})
	}
	toggledislike(comment_id,ev){
		agent.comment.toggledislike({comment_id:comment_id}).then(data => {
			console.log(data)
			if(data.result=="good"){
				const CommentList=this.state.CommentList.map(item=>{
					if(item.comment_id==comment_id){
						item.LikedNum=data.LikedNum
						item.IsDisliked=data.IsDisliked
						item.IsLiked=data.IsLiked
					}
					return item
				})
				this.setState({CommentList:CommentList})
			}
		})
	}
	viewReply(for_pick_code){
		const limit=this.state.limit
		let backoffset=(parseInt(this.state.backoffset+5))
		//console.log(backoffset,this.state.backoffset)
		let parent_id=this.state.parent_id
		agent.comment.listComment({for_pick_code:for_pick_code,parent_id:parent_id,backoffset:'',limit:''}).then(data => {
			//console.log(data)
			if(data.result=="good"){
				this.setState({CommentList:data.CommentList,numCM:data.TotalComments,show_pre:false})
				//this.setState({CommentList:data.CommentList,numCM:data.TotalComments,backoffset:backoffset})
				//this.props.updateTotalCM(data.TotalComments)
			}
		})
	}
	goProfile(id,e){
		window.localStorage.setItem('user_id',id )
		//this.props.history.push("/profile")
		window.location.href='./profile'
	}
	deleteInOwnTopic(comment_id,for_pick_code,e){
		agent.comment.deleteInOwnTopic({for_pick_code:for_pick_code,comment_id:comment_id}).then(data => {
			//console.log(this.state.CommentList)
			if(data.result=="good"){
				let CommentList=this.state.CommentList.map(dt=>{
					if(dt!=null && dt.comment_id!=comment_id){
						return dt;
					}
				})
				this.setState({CommentList:CommentList})
				//this.listComment(this.state.for_pick_code,this.state.parent_id)
				this.props.updateTotalRely(this.state.parent_id)
			}else{
				this.props.callModalError(data.message)
			}
			$(".dr-fr").hide();
		})
	}
	blockUser(blocked_user_id,for_pick_code,e){
		agent.running.blockUser({blocked_user_id:blocked_user_id}).then(data => {
			//console.log(data)
			if(data.result=="good"){
				this.listComment(this.state.for_pick_code,this.state.parent_id)
			}else{
				this.props.callModalError(data.message)
			}
			$(".dr-fr").hide();
		})
	}
	showBoxFriend(ev){
		$(".dr-fr").hide();
		let $this=ev.target.closest(".div-block-rl")
		$($this).find(".dr-fr").show();
		//console.log($this)
	}
	hideBoxFriend(ev){
		let $this=ev.target.closest(".div-block-rl")
		$($this).find(".dr-fr").hide();
	}
	render() {
		let url=this.state.url
		let url_like=this.state.url_like
		let url_liked=this.state.url_liked
		let url_dislike=this.state.url_dislike
		let url_disliked=this.state.url_disliked
		let member_name=this.state.member_name
		let CommentList=this.state.CommentList
		let for_pick_code=this.state.for_pick_code
		let numCM=this.state.numCM
		let UserInfoId=this.state.UserInfoId
		let user_id_login=this.state.user_id_login
		//console.log(numCM)
		//---------
			return (
				<Row className="mg0 pd0" style={{'width':'100%'}}>
				{
					//(numCM>5 && CommentList!='' && parseInt(CommentList.length)>4)?
					(numCM>5 && CommentList!='' && this.state.show_pre==true)?
						<Col md={12} className="pd0 mrgt5">
							<a className="view-more" onClick={this.viewReply.bind(this,for_pick_code)}>View previous reply</a>
						</Col>:''
				}
				{
					(CommentList!='')?CommentList.map((data,index)=>{
						if(data!=null && data!=''){
							var html = data.comment;
							html=html.replace(/(?:\r\n|\r|\n)/g, '<br />')
							var div = document.createElement("div");
							div.innerHTML = html;
							var textmessge = html;
							return(
								<Row className="mg0 pd5_0 m_f100" key={index}>								
									<Col md={2} xs={2} sm={2} className="pd0 fit-rely1" style={{'maxWidth':'14%','flex':'0 0 14%'}}>
										<figure>
											<a className="" href="">
												<img src={(data.imageurl!='' && data.imageurl!=null && data.imageurl!='null')?data.imageurl:this.state.img_default} width="24" height="24" className="img-icon-sm30"/>
											</a>
										</figure>
									</Col>
									<Col md={10} xs={10} sm={10} className="pd0 w-95p ct-cm-reply mgl-10 fit-rely2" style={{'maxWidth':'86%','flex':'0 0 86%'}}>
										<Row className="mg0">
											<h4 className="name-cmt mgbt0">
												<span onClick={this.goProfile.bind(this,data.user_id)}>{(data.blocked==true)?<i className="fa fa-ban" style={{'color':'red','paddingRight':'3px'}}></i>:''}{data.tipster_name} </span>
												<span className="time-cmt">{data.str_comment_time}</span>
											</h4>
										</Row>
										<Row className="mg0 pd0">
											{
												//data.comment
												ReactHtmlParser(textmessge)
											}
										</Row>
										<Row className="pd0">
											<div className="r-like-reply">
												<img src={(data.IsLiked==true)?url_liked:url_like} 
													width="35" height="35" className="img-icon-like"
													onClick={this.togglelike.bind(this,data.comment_id)}
												/>
												<span className="count-like">{(data.LikedNum>0)?data.LikedNum:''}</span>
												<img src={(data.IsDisliked==true)?url_disliked:url_dislike}
													width="35" height="35" className="img-icon-dislike"
													onClick={this.toggledislike.bind(this,data.comment_id)}
												/>										
												<a className="reply-cmt" onClick={this.handleRely.bind(this)}>{localizeKey(Lhome)('reply', this.state.lguages)}</a>
												{
													(UserInfoId==user_id_login || user_id_login==data.user_id)?
													<div className="g-btn mrgt5px div-block-rl" style={{'display':'inline','marginLeft':'20px'}}>
														<a className="btn-2 tfd-delete" onMouseOver={this.showBoxFriend.bind(this)}>MORE</a>
														<ul role="menu" className="dropdown-menu dr-fr" onMouseLeave={this.hideBoxFriend.bind(this)}>
															{
																(user_id_login==data.user_id)?
																<li><a className="text-cmt-tl" onClick={this.showModalEditCM.bind(this,data.comment_id,data.for_pick_code,data.comment)}><i className="fas fa-pencil-alt"></i> edit comment</a></li>:''
															}
															<li><a className="text-cmt-tl" onClick={this.deleteInOwnTopic.bind(this,data.comment_id,data.for_pick_code)}><i className="far fa-trash-alt"></i> delete comment</a></li>
															{
																(user_id_login!=data.user_id && data.blocked!=true)?
																<li><a className="text-cmt-tl" onClick={this.blockUser.bind(this,data.user_id,data.for_pick_code)}><i className="fa fa-minus-circle"></i> block user</a></li>	:''
															}	
														</ul>
													</div>	:''
													
												}
											</div>
										</Row>
										
										{
											//---reply form
										}
										<Row className="pd0 t-reply" style={{'display':'none'}}>
											{
												//<textarea name="txt-comment" className="txt-comment form-control lh50 area-cmt" placeholder="Add a public reply..." ></textarea>
											}
											<TextareaAutosize className="txt-comment form-control lh50 area-cmt" placeholder="Add a public reply..." />
											<img src={this.state.icon_send} className="post-reply-img" onClick={this.createCommentChild.bind(this,data.comment_id,data.for_pick_code)}/>
											<img src={this.state.icon_error} style={{'display':'none'}} className="post-cancel-img" onClick={this.hideRyply.bind(this)}/>
										</Row>
										{
										/*<Row className="mg0 t-reply-child cm-child">
											<Col md={12} className="pd10_0">
												<Col md={1} className="pdL0 w-5p">
													<img src={url} width="24" height="24" className="img-icon-sm24"/>
												</Col>
												<Col md={11} className="pd0 mgt10 w-95p">
													<div className="do_cm">
														<FormControl className="textarea-cm pd0"
															componentclass="textarea"
															placeholder="Add a public reply..." rows={1}
															maxLength={10000}
															onKeyPress={this.doKeycomment.bind(this)} 
															onClick={this.changeCOmment.bind(this)}
														/>
													</div>
												</Col>
												<Col md={12} className="t-right ot-btn" style={{'display':'block','marginTop':'10px','padding':'0px'}}>
													<a className="bg-grey-steel" onClick={this.hideRyplyChild.bind(this)}>cancel</a>
													<a className="bg-green-haze" onClick={this.createCommentChild.bind(this,data.comment_id,data.for_pick_code)}>Reply</a>
												</Col>
											</Col>
										</Row>*/
										}
										{
											//--end
										}
									</Col>
									<ModalEditComment isOpen={this.state.isOpenModalEditCm} close={this.close}
										txt_comment={this.state.txt_comment} PickCode={this.state.for_pick_code}
										txt_title={this.state.txt_title} callModalError={this.props.callModalError}
										updateCommentList={this.updateCommentList} comment_id={this.state.comment_id}/>	
									
								</Row>
							)
						}
					}):''
				}
					
				</Row>
			)	
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(ReplyList)
