import React, {Component} from 'react'
import { connect } from 'react-redux'
import { Cookies } from 'react-cookie' 
import '../../styles/App.css'
import '../../styles/home.css'
import {Col, Row,FormControl, Form, FormGroup, ControlLabel, Button} from 'react-bootstrap'
import { createElementsFromText } from 'html-text-to-react';
import ReactHtmlParser from 'react-html-parser';
import $ from 'jquery';
import {TOKEN_NAME, REDIRECT,HOME_PAGE_LOADED, APP_LOAD,LANGUAGE_KEY,UINFO} from '../../constants/actionTypes'
import agent from '../../agent'
import HomeList from './homeList.js'
import MyFriends from './MyFriends'
import ListBets from './ListBet'
import Block from './block'
import { Link} from 'react-router-dom'
import { localize, localizeKey,setLocale, getLocale } from 'react-redux-localization';
import translations from '../common/captionhome.json';
import Lqhome from '../common/home.json'
import ModalErorr from '../common/ModalErorr'

const mapStateToProps = state => (
    Object.assign({}, state, {
		dashboardData: state.common.dashboardData,
		tab: state.common.tab,
		updateProps: state.common.updateProps,
		locale: getLocale(state),
		languageKey: state.common.languageKey
	}) 

);

const mapDispatchToProps = dispatch => ({               
	onLoad: (data) => {
		dispatch({ type: 'HOME_PAGE_LOADED', data})  
	},
	setUInfo: (data) => {
		dispatch({ type: 'UINFO', data})  
	},
	chooseLocale: locale => dispatch(setLocale(locale))
});



 


class Home extends Component{ 	
	constructor(){
		super()
		this.state = {
			lguages:'1005',
			qcinfo:{},member_name:'',type:1,
			homebetList:[],UserInfo:{},SummaryBet:{},idUserLogin:'',
			img_default:'https://m.coopbet.com/img/no_avartar.jpg',
			offset:0,limit:15,user_id:'',isLoading:false,price:0,isOpenModal:false,msg:''
		}
		//---
		this.getrandomad=()=>{
			let lang_id=this.state.lguages
			let param={lang_id:lang_id}
			agent.home.getrandomad2(param).then(data => {
				//console.log(data)
				if(data.result === 'good'){
					this.setState({qcinfo:data.info})
				}
			})
		}
		this.loadMore=()=>{
			let offset = parseInt(this.state.offset) + this.state.limit
			this.setState({isLoading:true,offset},()=>{
				this.getMobileBetList(this.state.user_id);
			})
			
		}
		this.priceMonthSave=()=>{
			let price=this.state.price
			let date=new Date();
			let mt=date.getMonth()+1;
			let year=date.getFullYear();
			let month=mt+"/"+year;
			agent.home.priceMonthSave({price:price,month :month }).then(data => {
			//console.log(data)
				if(data.result === 'good'){
					
				}else{
					this.setState({isOpenModal:true,msg:data.message})
				}
			})
		}
		this.close=()=>{
			this.setState({isOpenModal:false})
		}
		this.goBuy=(id,e)=>{
			window.location="/buy-tip/"+id
		}
		//---
		this.goAdView=this.goAdView.bind(this)
		//---
	}
	noGoBuy(PriceTips,e){
		if(PriceTips>0){
			let message="The seller sold tips to the buyer in the month."
			this.setState({isOpenModal:true,msg:message})
		}else{
			let message="Invalid price."
			this.setState({isOpenModal:true,msg:message})
		}
		
	}
	goAdView(ev){
		let id=$(ev.target).attr("dataid")
		/*let title=$(ev.target).attr("title")
		window.localStorage.setItem( 'ad_user_id', id);
		window.localStorage.setItem( 'ad_title', title);
		//console.log(id,title)
		window.location.href='/ad-list'*/
		window.location.href='/new-info/'+id
	}
	componentWillMount(){	
		//-----
		
		let idLanguage=window.localStorage.getItem(LANGUAGE_KEY)
		if(idLanguage!='' && idLanguage!='null' && idLanguage!=null){
			this.setState({lguages:idLanguage},()=>{
				this.getrandomad();
			})
		}
		let user_id=window.localStorage.getItem('user_id')
		if(user_id>0){
			this.getMobileBetList(user_id)
			this.getMobileUserInfo(user_id)
			//this.getMobileUserStatistic(user_id)
			//this.getMobileUserStatisticMonth(user_id)
			//this.getMobileUserStatisticQuarter(user_id)
			//this.getMobileUserStatisticYear(user_id)
			//this.getMobileUserStatisticFriends(user_id)
			this.setState({user_id:user_id})
		}
		let login=window.localStorage.getItem(TOKEN_NAME);
		 
		if(login!='' && login!='null' && login!=null){
			 login=JSON.parse(login)
			 this.setState({idUserLogin:login.UserInfo.id,member_name:login.UserInfo.member_name})
		}
		//-----
	}
	componentDidMount(){
		this.getrandomad();
	}
	componentWillReceiveProps(nextProps){
		//console.log(nextProps)
		if(nextProps.languageKey && nextProps.languageKey!='' && nextProps.languageKey!=null && nextProps.languageKey!='null'){
			this.setState({lguages:nextProps.languageKey},()=>{
				this.getrandomad()
			});
			
		}
	}
	
	shouldComponentUpdate(nextProps, nextState){
		return nextState;
	}
	getMobileBetList(user_id){
		let limit=this.state.limit
		let offset=this.state.offset
		agent.home.mobileBetList({user_id:user_id,limit:limit,offset:offset}).then(data => {
			//console.log(data)
			if(data.result === 'good'){
				const list = data.list.concat(this.state.homebetList);
				this.setState({homebetList:list,isLoading:false})
				//this.setState({homebetList:data.list})
			}
		})
	}
	getMobileUserInfo(user_id){
		agent.home.mobileUserInfo({user_id:user_id}).then(data => {
			//console.log(data)
			if(data.result === 'good'){
				this.setState({UserInfo:data.info,SummaryBet:data.info.SummaryBet,price:data.info.PriceTips})
				this.props.setUInfo(data.info);
			}
		})
	}
	getMobileUserStatisticMonth(user_id){
		agent.home.mobileUserStatisticMonth({user_id:user_id}).then(data => {
			//console.log(data)
			if(data.result === 'good'){
				//this.setState({homebetList:data.list})
			}
		})
	}
	getMobileUserStatistic(user_id){
		agent.home.mobileUserStatistic({user_id:user_id}).then(data => {
			console.log(data)
			if(data.result === 'good'){
				//this.setState({homebetList:data.list})
			}
		})
	}
	getMobileUserStatisticQuarter(user_id){
		agent.home.mobileUserStatisticQuarter({user_id:user_id}).then(data => {
			console.log(data)
			if(data.result === 'good'){
				//this.setState({homebetList:data.list})
			}
		})
	}
	getMobileUserStatisticYear(user_id){
		agent.home.mobileUserStatisticYear({user_id:user_id}).then(data => {
			console.log(data)
			if(data.result === 'good'){
				//this.setState({homebetList:data.list})
			}
		})
	}
	
	createrequest(ev){
		
		let thiss=ev.target.closest('.add-friend');
		$(thiss).find('.loading').show();
		const to_user_id=$(thiss).attr("data-id")
		agent.friends.createrequest({to_user_id:to_user_id}).then(data => {
			//console.log(data)
			if(data.result === 'good'){
				$(thiss).find('.loading').hide();
				
				let profileInfo=this.state.UserInfo
				if(profileInfo.id==to_user_id){
					profileInfo.FriendStatusWithLoginUser='Pending'
				}
				
				this.setState({UserInfo:profileInfo})
			}else{
				this.setState({isOpenModal:true,msgLogin:data.message})
				$(thiss).find('.loading').hide();
			}
		})
	}
	createFollow(ev){
		let thiss=ev.target;
		let id=$(thiss).attr("data-id");
		agent.friends.createFollow({followed_user_id:id}).then(data => {
			if(data.result=="good"){
				let profileInfo=this.state.UserInfo
				if(profileInfo.id==id){
					profileInfo.LoginUserFollowStatus="Following"
					profileInfo.FollowNumbers=data.FollowNumbers
				}
				
				this.setState({UserInfo:profileInfo})
			}
		})
	}
	removeFollow(ev){
		let thiss=ev.target;
		let id=$(thiss).attr("data-id");
		agent.friends.removeFollow({followed_user_id:id}).then(data => {
			if(data.result=="good"){
				let profileInfo=this.state.UserInfo
				if(profileInfo.id==id){
					profileInfo.LoginUserFollowStatus=""
					profileInfo.FollowNumbers=data.FollowNumbers
				}
					
				
				this.setState({UserInfo:profileInfo})
			}
		})
	}
	goProfileType(type,e){
		//let thiss=(e.target)
		this.setState({type:type})
	}
	handleChangeFiled(e){
		let val=e.target.value.split(',').join('');	
		//console.log(e.target.value)
		this.setState({price: val})
	}
	numberFormat(x) {
		return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
	}
	render(){   
		//---
		const qcinfo=this.state.qcinfo;
		let title=''
		let imageurl=''
		let url=''
		let description=''
		if(qcinfo!='' && qcinfo!=null && qcinfo!='null'){
			 title=qcinfo.ad_title?qcinfo.ad_title:'';
			 imageurl=qcinfo.ad_logo_url?qcinfo.ad_logo_url:'https://back-end.coopbet.com/gf/ywqBBMhT0U0022_no-logo.png';
			 url=qcinfo.ad_url?qcinfo.ad_url:'';
			 description=qcinfo.ad_description?qcinfo.ad_description:'';
			 description=(description.length<=85)?description:description.substr(0,85)+'...'
		}	
		//---
		let SummaryBet=this.state.SummaryBet
		let UserInfo=this.state.UserInfo
		let idUserLogin=this.state.idUserLogin
		let member_name=this.state.member_name
		let type=this.state.type
		//console.log(this.state.lguages)
        return (
			<div id="body">   	
			{	
				(qcinfo && qcinfo!=null && qcinfo!='')?		
				<Row className="randomad mgbt5">
					<Col md={4} xs={4} sm={4} className="pd0">
						<div className="img-adv">
							<a href={url} target="_blank" className="rand-logo">
								<img alt="m88" src={imageurl} width="100" height="50"/>
							</a>
						</div>
					</Col>
					<Col md={8} xs={8} sm={8} className="pd0">
						<div className="desc-adv">									
						{
							ReactHtmlParser(description)
						}
							<span>
							{
								(qcinfo!='' && qcinfo!=null)?
								<a onClick={this.goAdView.bind(this)} dataid={qcinfo.id} title={title} className="v-more">{localizeKey(Lqhome)('viewmore', this.state.lguages)}</a>:''
							}
							</span>
						</div>
					</Col>
				</Row>:''
			}
				<Row className="line-ct" style={{'display':(qcinfo && qcinfo!=null && qcinfo!='')?'':'none'}}></Row>
				<div className="profile-content mgbt5">
				{
					(UserInfo.role_id==3)?<div>
						<Row className="title-user-info ">
							<Col md={3} sm={3} xs={3} className="pd0">
								<figure>
									<a className="" href="">
										<img src={(UserInfo.imageurl!='' && UserInfo.imageurl!=null && UserInfo.imageurl!='null')?UserInfo.imageurl:this.state.img_default} width="60" height="60"/>
									</a>
								</figure>
							</Col>
							<Col md={6} sm={6} xs={6} className="pd0">
								<h4 className="name">{UserInfo.tipster_name}</h4>
								<div>{UserInfo.tipster_title}</div>
							</Col>
							<Col md={3} sm={3} xs={3} className="txtR pdt34"><span className="ctn">{UserInfo.FollowNumbers}</span></Col>
						</Row>
						<Row className="linect"></Row>
						<Row className="mg0">
							<Col md={12} sm={12} xs={12} className="img-baner-ad pd0">	
								<a href={UserInfo.aduser_url} target="_blank"><img src={UserInfo.bannermobileadurl}/></a>
							</Col>
						</Row>
						<Row className="linect"></Row>
						
						<Row className="linect" style={{display:(idUserLogin==UserInfo.id)?"none":''}}></Row>
						<div className="mrgt7 mgbt3 mrgLR1">
							{
								(idUserLogin==UserInfo.id)?
								<Row className="mrgt7 mgbt3 mrgLR1">
									<Col md={3} xs={3} sm={3} className={(type==1)?"tool-ball tool-profile active":"tool-ball tool-profile"} onClick={this.goProfileType.bind(this,1)}>
										<div className="tool-profile-title">Bài viết</div>
									</Col>
									<Col md={3} xs={3} sm={3} className={(type==2)?"tool-ball tool-profile brdlr active":"tool-ball tool-profile brdlr"} onClick={this.goProfileType.bind(this,2)}>
										<div className="tool-profile-title">Bạn Bè</div>
									</Col>
									<Col md={3} xs={3} sm={3} className={(type==3)?"tool-ball tool-profile active":"tool-ball tool-profile"} onClick={this.goProfileType.bind(this,3)}>							
										<div className="tool-profile-title">Thống kê</div>
									</Col>
									<Col md={3} xs={3} sm={3} className={(type==4)?"tool-ball tool-profile active brdlr":"tool-ball tool-profile brdlr"} onClick={this.goProfileType.bind(this,4)} style={{'borderRight':'0'}}>							
										<div className="tool-profile-title">Chặn</div>
									</Col>
								</Row>:
								<Row className="mrgt7 mgbt3 mrgLR1">
									<Col md={4} xs={4} sm={4} className={(type==1)?"tool-ball tool-profile active":"tool-ball tool-profile"} onClick={this.goProfileType.bind(this,1)}>
										<div className="tool-profile-title">Bài viết</div>
									</Col>
									<Col md={4} xs={4} sm={4} className={(type==2)?"tool-ball tool-profile brdlr active":"tool-ball tool-profile brdlr"} onClick={this.goProfileType.bind(this,2)}>
										<div className="tool-profile-title">Bạn Bè</div>
									</Col>
									<Col md={4} xs={4} sm={4} className={(type==3)?"tool-ball tool-profile active":"tool-ball tool-profile"} onClick={this.goProfileType.bind(this,3)}>							
										<div className="tool-profile-title">Thống kê</div>
									</Col>
								</Row>
							}
						</div>
					</div>:
					<div>
						<Row className="title-user-info ">
							<Col md={2} sm={3} xs={3} className="pd0">
								<figure>
									<a className="" href="">
										<img src={(UserInfo.imageurl!='' && UserInfo.imageurl!=null && UserInfo.imageurl!='null')?UserInfo.imageurl:this.state.img_default} width="60" height="60"/>
									</a>
								</figure>
							</Col>
							<Col md={7} sm={5} xs={5} className="pd0">
								<h4 className="name">{UserInfo.tipster_name}</h4>
								<div>{UserInfo.tipster_title}</div>
							</Col>
							<Col md={3} sm={4} xs={4} className="txtR" style={{'paddingTop':'15px','paddingLeft':'0px','paddingRight':'0px'}}>
								{
									(idUserLogin!==UserInfo.id && UserInfo.HasChargedTips === true && UserInfo.PriceTips>0)?											
									<div className="left-ft-admth ct-ft-charge" onClick={(UserInfo.SoldMeTipsInMonth === false && UserInfo.PriceTips>0)?this.goBuy.bind(this,UserInfo.id):this.noGoBuy.bind(this,UserInfo.PriceTips)}>
										<span className="price-charge">{UserInfo.PriceTips}$/MONTH</span>
										<span className="sym-charge"></span>
										<i className="fas fa-shopping-cart"></i>
									</div>:''
								}
								<span className="ctn" style={{'marginRight':'3px'}}>{UserInfo.FollowNumbers}</span>
								
							</Col>
						</Row>
						<Row className="linect"></Row>
						<Row className="mg0 lai">
							<Col md={6} xs={6} sm={6}>
								<div><b>{localizeKey(translations)('Yield', this.state.lguages)}:</b> {(SummaryBet.profitpercentage!=null)?SummaryBet.profitpercentage:''}</div>
								<div><b>{localizeKey(translations)('Profit', this.state.lguages)}:</b> {(SummaryBet.profit!=null)?SummaryBet.profit:''}</div>
							</Col>
							<Col md={6} xs={6} sm={6}>
								<div><b>{localizeKey(translations)('NOtips', this.state.lguages)}:</b> {(SummaryBet.NumberOfTips!=null)?SummaryBet.NumberOfTips:''}</div>
								<div><b>{localizeKey(translations)('Wrate', this.state.lguages)}:</b> {(SummaryBet.IswonPercentage!=null)?SummaryBet.IswonPercentage:''}</div>
							</Col>
						</Row>
						<Row className="linect"></Row>
						<Row style={{display:(idUserLogin==UserInfo.id)?"none":''}}>
							<Col md={12} xs={12} sm={12} className="friends">
								{
									(UserInfo.FriendStatusWithLoginUser=="Confirmed")?
									<a className="add-friended">
										Friend
									</a>:
									<a className="add-friend" data-id={UserInfo.id} onClick={this.createrequest.bind(this)}>
										{(UserInfo.FriendStatusWithLoginUser=="Pending")?localizeKey(translations)('requestSend', this.state.lguages):(UserInfo.FriendStatusWithLoginUser=="PendingYou")?'Waiting you confirm':localizeKey(translations)('AF', this.state.lguages)}
										<img className="loading" src="https://www.coopbet.com/media/loading.gif" width="20" style={{'display':'none'}}/>
									</a>
								}
								{
									(UserInfo.FriendStatusWithLoginUser!="Confirmed")?
									<a className="following" className={(UserInfo.LoginUserFollowStatus!='')?'following flwg':'following'} data-id={UserInfo.id} onClick={(member_name!='')?((UserInfo.LoginUserFollowStatus!='')?this.removeFollow.bind(this):this.createFollow.bind(this)):this.handleClickTab('login')}>
										{(UserInfo.LoginUserFollowStatus!='')?localizeKey(translations)('followings', this.state.lguages):localizeKey(translations)('Follow', this.state.lguages)}
									</a>:''
								}
							</Col>
						</Row>
						<Row style={{display:(idUserLogin==UserInfo.id)?"":'none'}} >
							<Col md={12} xs={12} sm={12} className="friends">
								<div className="left-ft-admth ct-ft-charge fit-idex" style={{'width':'40%','display':'inline-block'}}>
									<input id="price_mth" name="price" type="text" style={{'width':'50px'}} className="form-control" value={this.numberFormat(this.state.price)} onChange={this.handleChangeFiled.bind(this)} />
									<span className="price-charge">$/MONTH</span>
									<span className="sym-charge"></span>
									<i className="fas fa-shopping-cart"></i>
								</div>
								<input type="button" className="btn-save-tp fit-idex" value="SAVE" onClick={this.priceMonthSave.bind(this)}/>
							</Col>
						</Row>
						<Row className="linect" style={{display:(idUserLogin==UserInfo.id)?"":''}}></Row>
						{
							(idUserLogin==UserInfo.id)?
							<Row className="mrgt7 mgbt3 mrgLR1">
								<Col md={3} xs={3} sm={3} className={(type==1)?"tool-ball tool-profile active":"tool-ball tool-profile"} onClick={this.goProfileType.bind(this,1)}>
									<div className="tool-profile-title">{localizeKey(translations)('timeline', this.state.lguages)}</div>
								</Col>
								<Col md={3} xs={3} sm={3} className={(type==2)?"tool-ball tool-profile brdlr active":"tool-ball tool-profile brdlr"} onClick={this.goProfileType.bind(this,2)}>
									<div className="tool-profile-title">{localizeKey(translations)('frdlist', this.state.lguages)}</div>
								</Col>
								<Col md={3} xs={3} sm={3} className={(type==3)?"tool-ball tool-profile active":"tool-ball tool-profile"} onClick={this.goProfileType.bind(this,3)}>							
									<div className="tool-profile-title">{localizeKey(translations)('statistics', this.state.lguages)}</div>
								</Col>
								<Col md={3} xs={3} sm={3} className={(type==4)?"tool-ball tool-profile active brdlr":"tool-ball tool-profile brdlr"} onClick={this.goProfileType.bind(this,4)} style={{'borderRight':'0'}}>							
									<div className="tool-profile-title">{localizeKey(translations)('block', this.state.lguages)}</div>
								</Col>
							</Row>:
							<Row className="mrgt7 mgbt3 mrgLR1">
							<Col md={4} xs={4} sm={4} className={(type==1)?"tool-ball tool-profile active":"tool-ball tool-profile"} onClick={this.goProfileType.bind(this,1)}>
								<div className="tool-profile-title">{localizeKey(translations)('timeline', this.state.lguages)}</div>
							</Col>
							<Col md={4} xs={4} sm={4} className={(type==2)?"tool-ball tool-profile brdlr active":"tool-ball tool-profile brdlr"} onClick={this.goProfileType.bind(this,2)}>
								<div className="tool-profile-title">{localizeKey(translations)('frdlist', this.state.lguages)}</div>
							</Col>
							<Col md={4} xs={4} sm={4} className={(type==3)?"tool-ball tool-profile active":"tool-ball tool-profile"} onClick={this.goProfileType.bind(this,3)}>							
								<div className="tool-profile-title">{localizeKey(translations)('statistics', this.state.lguages)}</div>
							</Col>
						</Row>
						}
						
					</div>
				}
					
				</div>
				<Row className="line-ct"></Row>
				<div style={{'display':(type==1)?'':'none'}} id="profile-time-line">
					<HomeList homebetList={this.state.homebetList} UserInfo={this.state.UserInfo}/>
					{
						(this.state.homebetList!='' && this.state.homebetList!=null &&  this.state.homebetList.length>14)?
						<Row className="mg0">
							<Col md={12} className="div-load-more">
								<Button
									id="load-more"
									onClick={!this.state.isLoading ? this.loadMore : null}>
									{this.state.isLoading ? <span style={{'fontSize':'10px'}}><i className='fa fa-spinner fa-spin '></i> LOAD MORE ...</span> :<span>LOAD MORE </span>}
								 </Button>
							</Col>
						</Row>:''
					}
				</div>
				<div style={{'display':(type==2)?'':'none'}} id="profile-friend">
					<MyFriends />
				</div>
				<div style={{'display':(type==3)?'':'none'}} id="profile-statistic">
					<ListBets />
				</div>
				<div style={{'display':(type==4)?'':'none'}} id="profile-block">
					<Block />
				</div>
				<ModalErorr isOpen={this.state.isOpenModal} close={this.close} text={this.state.msg}/>
			</div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Home)