import React from 'react'
import ReactDOM from 'react-dom';
import {FormControl, Row, Col, Button, ControlLabel, FormGroup, Radio, Checkbox } from 'react-bootstrap';
//import InputMask from 'react-input-mask'; 
import $ from 'jquery';
import { Link } from 'react-router-dom'
import agent from '../../agent'


import '../../styles/modal.css'; 

import Modal from 'react-bootstrap-modal';
import { connect } from 'react-redux';
import { 
  LANGUAGE_KEY
  
}from '../../constants/actionTypes'; 
import { localize, localizeKey } from 'react-redux-localization';
import Lqhome from '../common/home.json'
import cahome from '../common/captionhome.json';
import menu from '../common/menu.json';

const mapStateToProps = state => (
    Object.assign({}, state, {
		languages:state.common.languageslist,
	
    })      
);
const mapDispatchToProps = dispatch => ({
  /*loadCustomers: (data) => {
      dispatch({ type: CUSTOMER_BUILDING_LOADED, payload: data })  
  },
  */
});

class ModalConfirmBlock extends React.Component {
	
    constructor() {
        super();
		
        this.state = {			
			isOpen: false,user_id:'',lguages:'1005'
			
			
        }
		
		this.hideModal = ev => {
		  this.setState({
			isOpen: false,
		  });
		 this.props.close();
			
		};
		
		this.blockUser=()=>{
			agent.running.blockUser({blocked_user_id:this.state.user_id}).then(data => {
				//console.log(data)
				if(data.result=="good"){
					this.hideModal();
					this.props.blockedusers();
				}else{
					this.props.callModalError(data.message)
				}
			})
		}
		//---
				
    } 
	
	
	
    componentWillReceiveProps(nextProps) {
		//console.log(nextProps)
		if(nextProps){
			this.setState({isOpen:nextProps.isOpen,user_id:nextProps.user_id,
			});
			
		}
		if(nextProps.languageKey){
			this.setState({lguages:nextProps.languageKey});
		}
		
		
    }	
	componentWillUpdate(nextProps, nextState){
		//console.log(nextState)
	}
	componentDidMount() {
		
		
	}
	componentWillMount() {
		let idLanguage=window.localStorage.getItem(LANGUAGE_KEY)
		if(idLanguage!='' && idLanguage!='null' && idLanguage!=null){
			this.setState({lguages:idLanguage})
		}
	}
	
	render() {
        return (
			<Modal
			  show={this.state.isOpen}
			  onHide={this.hideModal}
			  aria-labelledby="ModalHeader"
			  id="small"
			  className="w3-animate-zoom"
			>
			  <Modal.Header closeButton>
				<Modal.Title id='ModalHeader'>
					<div className="modal-title-cap">
						<span className="modal-detail-order-title"><span style={{'color':'#b81900'}}>{localizeKey(menu)('message', this.state.lguages)}</span></span>
						
					</div>
				</Modal.Title>
			  </Modal.Header>
			  <Modal.Body className="">
					<Row id="modal-like-list">	
						<div className="friens-top"  tabIndex={0} id="test">
							<div className="frd-top">
								<div className="ft-items">
									<h4 style={{'color':'#b81900','fontSize':'14px'}}>{localizeKey(Lqhome)('txttitlecblck', this.state.lguages)}</h4>
									<Row className="ctn mg0">
										<Col md={2} xs={2} sm={2} className="pd0"><i className="fa fa-warning"></i></Col>
										<Col md={10} xs={10} sm={10} className="pd0">
											<p>{localizeKey(Lqhome)('usernotbe', this.state.lguages)}</p>
											<div className="cnt-block">
												<div>{localizeKey(Lqhome)('notbe1', this.state.lguages)}</div>
												<div>{localizeKey(Lqhome)('notbe2', this.state.lguages)}</div>
												<div>{localizeKey(Lqhome)('notbe3', this.state.lguages)}</div>
											</div>
										</Col>
									</Row>
								</div>
							</div>
						</div>
						<Col md={12} className="col-lg-12 lblMessage" id="lblMessage" style={{'color':'red'}}></Col>
					</Row>
			  </Modal.Body>
			  <Modal.Footer style={{'display':''}}>
					<div className="col-lg-12" style={{'textAlign':'right','padding':'0'}}> 						
						<button type="button" className="btn btn-primary" onClick={this.blockUser}>{localizeKey(cahome)('block', this.state.lguages)}</button>
						<button type="button" className="btn btn-primary" onClick={this.props.close}>{localizeKey(Lqhome)('cancel', this.state.lguages)}</button>
					</div>
			  </Modal.Footer>
			</Modal>
        ); 
	 //}
	//return null;
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(ModalConfirmBlock); 