import React, {Component} from 'react'
import { connect } from 'react-redux'
import { setLocale, getLocale, localizeKey } from 'react-redux-localization';
import { Cookies } from 'react-cookie' 
import '../../styles/App.css'
import '../../styles/home.css'
import {Col, Row,FormControl, Form, FormGroup, ControlLabel, Button, Tabs,Tab,Table,Radio} from 'react-bootstrap'
import { createElementsFromText } from 'html-text-to-react';
import ReactHtmlParser from 'react-html-parser';
import $ from 'jquery';
import {TOKEN_NAME, REDIRECT,HOME_PAGE_LOADED, APP_LOAD,LANGUAGE_KEY} from '../../constants/actionTypes'
import agent from '../../agent'
import menu from '../common/menu.json'
import Lqhome from '../common/home.json'
import ModalErorr from '../common/ModalErorr'

const mapStateToProps = state => (
    Object.assign({}, state, {
		tab: state.common.tab,
		updateProps: state.common.updateProps,
		locale: getLocale(state),
		languageKey: state.common.languageKey
	}) 

);

const mapDispatchToProps = dispatch => ({               
	onLoad: (data) => {
		dispatch({ type: 'HOME_PAGE_LOADED', data})  
	},
	chooseLocale: locale => dispatch(setLocale(locale))
});



 


class AddTips extends Component{ 	
	constructor(){
		super()
		this.state = {
			lguages:'1005',
			qcinfo:{},idUser:'',BankRolls:[],BitcoinRolls:[],PaypalRolls:[],keyTab:'key1',
			isOpenModal:false,msgRigister:''
		}
		//---
		this.getrandomad=()=>{
			let lang_id=this.state.lguages
			let param={lang_id:lang_id}
			agent.home.getrandomad2(param).then(data => {
				//console.log(data)
				if(data.result === 'good'){
					this.setState({qcinfo:data.info})
				}
			})
		}
		//---
		this.goAdView=this.goAdView.bind(this)
		//---
		this.close=()=>{
			this.setState({isOpenModal:false})
		}
	}
	goAdView(ev){
		let id=$(ev.target).attr("dataid")
		/*let title=$(ev.target).attr("title")
		window.localStorage.setItem( 'ad_user_id', id);
		window.localStorage.setItem( 'ad_title', title);
		//console.log(id,title)
		window.location.href='/ad-list' */
		window.location.href='/new-info/'+id
	}
	componentWillMount(){	
		//-----
		
		let idLanguage=window.localStorage.getItem(LANGUAGE_KEY)
		if(idLanguage!='' && idLanguage!='null' && idLanguage!=null){
			this.setState({lguages:idLanguage},()=>{
				this.getrandomad();
			})
		}
		let login=window.localStorage.getItem(TOKEN_NAME);
		if(login!='' && login!='null' && login!=null){
				login=JSON.parse(login)
				let info=login.UserInfo
				//console.log(login.UserInfo)
				this.setState({idUser:info.id},()=>{
					//this.getbankrolls(info.id)
				})
				this.getbankrolls(info.id)
			}
		let keyTab=window.localStorage.getItem( 'keytab');
		if(keyTab && keyTab!='' && keyTab!=null){
			this.setState({keyTab:keyTab})
		}
		//-----
	}
	componentDidMount(){
		this.getrandomad();
	}
	componentWillReceiveProps(nextProps){
		//console.log(nextProps)
		if(nextProps.languageKey && nextProps.languageKey!='' && nextProps.languageKey!=null && nextProps.languageKey!='null'){
			this.setState({lguages:nextProps.languageKey},()=>{
				this.getrandomad()
			});
			
		}
	}
	
	shouldComponentUpdate(nextProps, nextState){
		return nextState;
	}	
	
	handleChange(userbankid,userid,e){
		console.log(userbankid,userid)
		this.setDefaultBankRoll(userbankid,userid)
	}
	setDefaultBankRoll(userbankid,userid){
		agent.auth.setDefaultBankRoll({userid:userid,userbankid:userbankid}).then(data => {
			//console.log(data)
			if(data.result=="good"){
				this.getbankrolls(userid)
			}else{
				this.setState({msgRigister:data.message,isOpenModal:true})
			}
		})
	}
	addbankroll(type,e){
		if(type==1){
			window.location.href="/ad-bankroll"
		}else if(type==2){
			window.location.href="/ad-paypal"
		}else if(type==3){
			window.location.href="/ad-bitcoin"
		}
	}
	getbankrolls(userid){
		agent.auth.getbankrolls({userid:userid}).then(data => {
			//console.log(data)
			if(data.result=="good"){
				let UserBankRolls=data.UserBankRolls
				this.setState({BankRolls:UserBankRolls.BankRolls,
							  BitcoinRolls:UserBankRolls.BitcoinRolls,
							  PaypalRolls:UserBankRolls.PaypalRolls})
			}
		})
	}
	changeTab(tab,e){
		this.setState({keyTab:tab})
		window.localStorage.setItem( 'keytab', tab);
	}
	render(){   
		//---
		const qcinfo=this.state.qcinfo;
		let title=''
		let imageurl=''
		let url=''
		let description=''
		if(qcinfo!='' && qcinfo!=null && qcinfo!='null'){
			 title=qcinfo.ad_title?qcinfo.ad_title:'';
			 imageurl=qcinfo.ad_logo_url?qcinfo.ad_logo_url:'https://back-end.coopbet.com/gf/ywqBBMhT0U0022_no-logo.png';
			 url=qcinfo.ad_url?qcinfo.ad_url:'';
			 description=qcinfo.ad_description?qcinfo.ad_description:'';
			 description=(description.length<=85)?description:description.substr(0,85)+'...'
		}	
		const BankRolls=this.state.BankRolls
		const BitcoinRolls=this.state.BitcoinRolls
		const PaypalRolls=this.state.PaypalRolls
		//---
		//console.log(this.state.homebetList)
        return (
			<div id="body"> 
			{
				(qcinfo && qcinfo!=null && qcinfo!='')?
				<Row className="randomad">
					<Col md={4} xs={4} sm={4} className="pd0">
						<div className="img-adv">
							<a href={url} target="_blank" className="rand-logo">
								<img alt="m88" src={imageurl} width="100" height="50"/>
							</a>
						</div>
					</Col>
					<Col md={8} xs={8} sm={8} className="pd0">
						<div className="desc-adv">									
						{
							ReactHtmlParser(description)
						}
							<span>
							{
								(qcinfo!='' && qcinfo!=null)?
								<a onClick={this.goAdView.bind(this)} dataid={qcinfo.id} title={title} className="v-more">{localizeKey(Lqhome)('viewmore', this.state.lguages)}</a>:''
							}
							</span>
						</div>
					</Col>
				</Row>:''
			}
				<Row className="mg0 mrgt7 mgbt7">
					<Col md={12} xs={12} sm={12} className="">
						<i className="fas fa-money-check-alt iconmoney" style={{'marginRight':'5px'}}></i>
						<div className="rigister-title" style={{'textTransform':'uppercase'}}>Bankroll</div>
					</Col>					
				</Row>
				<Row className="line-ct"></Row>
				
				<Row className="mg0 mrgt7 " id="div-bankroll">
					<Tabs defaultActiveKey={this.state.keyTab} id="tab-bankroll">
						<Tab eventKey="key1" title="ngân hàng" onClick={this.changeTab.bind(this,'key1')}>
							<Row className="mg1">
								<Col md={12} style={{'marginTop':'5px'}}>
								{
									(BankRolls!='')?BankRolls.map((data,idx)=>{
										return(
											<Row className={(idx%2==0)?"row-bankboll":"row-bankboll bckrgrey"} key={idx}>
												<Col md={10} xs={10} sm={10} className="pdlr0">
													<Row>
														<Col md={5} sm={5} xs={5} className="_pd0 txtct txtUpc">{data.bank_name}</Col>
														<Col md={7} sm={7} xs={7} className="_pd0 txtct txtUpc">{data.bank_user}</Col>
													</Row>
													<Row>
														<Col md={5} sm={5} xs={5} className="_pd0 txtct txtUpc">{data.create_date}</Col>
														<Col md={7} sm={7} xs={7} className="_pd0 txtct txtUpc">{data.bank_no}</Col>
													</Row>
												</Col>
												<Col md={2} xs={2} sm={2} className="bdl">
													<div className="custom-control custom-radio inl">						
														<input
															type="radio"
															className="custom-control-input"
															id="customControlValidation1"
															name="radio-stacked"
															checked={(data.is_default==1)?true:false}
															onChange={this.handleChange.bind(this,data.userbankid,data.userid)}
															
														/>
														
													</div>
												 
												</Col>
											</Row>
										)
									}):''
								}
									
									
									
								</Col>
								<Col md={12} style={{'marginTop':'20px'}} className="txtct"><input type="button" className="bnt-ad-bank" value="THÊM TÀI KHOẢN" onClick={this.addbankroll.bind(this,1)} /></Col>
							</Row>
						</Tab>
						<Tab eventKey="key2" title="Bitcoin" onClick={this.changeTab.bind(this,'key2')}>
							<Row className="mg1" id="ct-bitcoin">
								<Col md={12} style={{'marginTop':'5px'}}>
								{
									(BitcoinRolls!='')?BitcoinRolls.map((data,idx)=>{
										return(
											<Row className={(idx%2==0)?"row-bankboll":"row-bankboll bckrgrey"} key={idx}>
												<Col md={10} xs={10} sm={10} className="pdlr0">													
													<Row>
														<Col md={4} sm={4} xs={4} className="_pd0 txtct txtUpc">{data.create_date}</Col>
														<Col md={8} sm={8} xs={8} className="_pd0 txtct">{data.bank_no}</Col>
													</Row>
												</Col>
												<Col md={2} xs={2} sm={2} className="bdl" style={{'padding':'0'}}>
													<div className="custom-control custom-radio inl">						
														<input
															type="radio"
															className="custom-control-input"
															id="customControlValidation2"
															name="radio-stacked2"
															value={1}
															checked={(data.is_default==1)?true:false}
															onChange={this.handleChange.bind(this,data.userbankid,data.userid)}
														/>
														
													</div>
												 
												</Col>
											</Row>
										)
									}):''
								}
									
									
									
								</Col>
								<Col md={12} style={{'marginTop':'20px'}} className="txtct"><input type="button" className="bnt-ad-bank" value="THÊM TÀI KHOẢN" onClick={this.addbankroll.bind(this,3)} /></Col>
							</Row>
						</Tab>
						<Tab eventKey="key3" title="Paypal" onClick={this.changeTab.bind(this,'key3')}>
							<Row className="mg1" id="ct-bitcoin">
								<Col md={12} style={{'marginTop':'5px'}}>
								{
									(PaypalRolls!='')?PaypalRolls.map((data,idx)=>{
										return(
											<Row className={(idx%2==0)?"row-bankboll":"row-bankboll bckrgrey"} key={idx}>
												<Col md={10} xs={10} sm={10} className="pdlr0">													
													<Row>
														<Col md={4} sm={4} xs={4} className="_pd0 txtct txtUpc">{data.create_date}</Col>
														<Col md={8} sm={8} xs={8} className="_pd0 txtct">{data.bank_no}</Col>
													</Row>
												</Col>
												<Col md={2} xs={2} sm={2} className="bdl">
													<div className="custom-control custom-radio inl">						
														<input
															type="radio"
															className="custom-control-input"
															id="customControlValidation3"
															name="radio-stacked3"
															checked={(data.is_default==1)?true:false}
															onChange={this.handleChange.bind(this,data.userbankid,data.userid)}
														/>
														
													</div>
												 
												</Col>
											</Row>
										)
									}):''
								}
									
									
									
								</Col>
								<Col md={12} style={{'marginTop':'20px'}} className="txtct"><input type="button" className="bnt-ad-bank" value="THÊM TÀI KHOẢN" onClick={this.addbankroll.bind(this,2)} /></Col>
							</Row>
						</Tab>
					</Tabs>
				</Row>
				
				<ModalErorr isOpen={this.state.isOpenModal} close={this.close} text={this.state.msgRigister}/>	
				
				
			</div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddTips)