import React from 'react';
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import agent from '../../agent'
import {Col, Row,FormControl, Form, FormGroup, ControlLabel, Button} from 'react-bootstrap'
import '../../styles/App.css'
import '../../styles/Friends.css'
import $ from 'jquery';
import {TOKEN_NAME, REDIRECT,LANGUAGE_KEY,CHANGE_TAB} from '../../constants/actionTypes'
import {withRouter, history} from 'react-router-dom'
import { localize, localizeKey,setLocale, getLocale } from 'react-redux-localization';
import { Cookies } from 'react-cookie' 
import translations from '../common/captionhome.json';
import ModalBlock from './ModalBlock'
import ModalErorr from '../common/ModalErorr'
import ModalConfirmUnBlock from './ModalConfirmUnBlock'
import Lqhome from '../common/home.json'

const mapStateToProps = state => (
    Object.assign({}, state, {
		paramsSave: state.home,
		token: state.common.token,
		tab: state.common.tab,
		languageKey: state.common.languageKey,
		languages:state.common.languageslist,
		//active: state.common.active,
		//user_fiend: state.common.user_fiend,
	})      
);

const mapDispatchToProps = dispatch => ({               
	
	onRedirect: () =>
		dispatch({ type: REDIRECT }),
	
	onTabClick: (tab) => 
		dispatch({ type: CHANGE_TAB, tab }),
	
});

class Block extends React.Component {
	constructor(){
		super()
		this.state = {
			url:'https://www.coopbet.com/media/user_none.jpg',
			list:[],isOpenModal:false,msgLogin:'',user_id_log:'',blockList:[],
			lguages:'1005',user_block:'',isOpenModalEror:false,isOpenModalConfirmUnblock:false
		}
		//---
		this.close=()=>{
			this.setState({isOpenModal:false,isOpenModalEror:false,isOpenModalConfirmUnblock:false})
		}
		//----
		this.handleChangeFiled=this.handleChangeFiled.bind(this)
		//----
		this.callModalError=(msgerror)=>{
			this.setState({isOpenModal:false,isOpenModalConfirmUnblock:false,isOpenModalEror:true,msgerror:msgerror})
		}
		//----
		this.showModalBolock=()=>{
			this.setState({isOpenModal:true,isOpenModalEror:false,isOpenModalConfirmUnblock:false})
		}
		this.showModalUnblock=(user_id)=>{
			this.setState({isOpenModal:false,isOpenModalEror:false,isOpenModalConfirmUnblock:true,user_id:user_id})
		}
		this.blockedusers=()=>{
			agent.running.blockedusers({}).then(data => {
				//console.log(data)
				if(data.result=="good"){
					this.setState({blockList:data.list})
				}
			})
		}
	}
	
	
	//---------------------------
    
	componentWillReceiveProps(nextProps) {
		//console.log(nextProps)
		if(nextProps.languageKey){
			this.setState({lguages:nextProps.languageKey});
		}
	}	

	componentWillMount() {
		//---
		let idLanguage=window.localStorage.getItem(LANGUAGE_KEY)
		if(idLanguage!='' && idLanguage!='null' && idLanguage!=null){
			this.setState({lguages:idLanguage})
		}
		//----
		let login=window.localStorage.getItem(TOKEN_NAME);
		 
		if(login!='' && login!='null' && login!=null){
			 login=JSON.parse(login)
			 this.setState({user_id_log:login.UserInfo.id})
			 this.blockedusers(); 
		}
		
		
		
	}	
	componentDidMount() {
		
	}
	
	handleChangeFiled(ev){
		this.setState({user_block:ev.target.value})
	}
	
	render() {
		let blockList=this.state.blockList
		
		//---------
			return (
					<Row >
						<div className="mgbtlr">
							<Col md={12} sm={12} xs={12} className="block-title">
								<h4>{localizeKey(Lqhome)('blockuser', this.state.lguages)}</h4>
							</Col>
						</div>
						<div className="cont-bl">
							{localizeKey(Lqhome)('txtasybl', this.state.lguages)}
						</div>
						<Row className="cont-bl mgt10 ">
							<Col md={2}></Col>
							<Col md={5} sm={9} xs={9} className="pd0">
								<input id="user_block" name="user_block" type="text" placeholder={localizeKey(Lqhome)('usname', this.state.lguages)} className="form-control" value={this.state.user_block} onChange={this.handleChangeFiled}/>
								{
									(blockList.length<1)?
									<span className="note">{localizeKey(Lqhome)('txtnoaddlist', this.state.lguages)}</span>:''
								}
							</Col>
							<Col md={1} sm={3} xs={3} style={{'lineHeight':'36px'}}>
								<Button className="btn-sigin bt-block" onClick={this.showModalBolock}>{localizeKey(translations)('block', this.state.lguages)}</Button>
							</Col>
							<Col md={4}></Col>
						</Row>
						<Row className="mg0" style={{'marginTop':'20px','width':'100%'}}>
							<Col md={2}></Col>
							<Col md={8}>
								<Row className="mg0">
								{
									(blockList!='' && blockList!=null)?blockList.map((dt,idx)=>{
										return(
											<Col md={6} key={idx}>												
												<label className="lb-name"><i className="far fa-square icon-bl"></i> {dt.tipster_name}</label>
												<a className="a-block" onClick={this.showModalUnblock.bind(this,dt.user_id)}>{localizeKey(Lqhome)('unblocking', this.state.lguages)}</a>
											</Col>
										)
									}):''
								}
									
								</Row>
							</Col>
							<Col md={2}></Col>
						</Row>
						<ModalBlock isOpen={this.state.isOpenModal} close={this.close} search={this.state.user_block} blockedusers={this.blockedusers}/>
						<ModalErorr isOpen={this.state.isOpenModalEror} close={this.close} text={this.state.msgerror} callModalError={this.callModalError}/>
						<ModalConfirmUnBlock isOpen={this.state.isOpenModalConfirmUnblock} close={this.close} user_id={this.state.user_id}
							callModalError={this.callModalError} blockedusers={this.blockedusers}/>
					</Row>
			)	
	}
}

//export default connect(mapStateToProps, mapDispatchToProps)(LikeList)
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Block));
