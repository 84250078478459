import React, { Component } from "react";
//import { MDBCol, MDBSelectInput, MDBSelect, MDBSelectOptions, MDBSelectOption } from "mdbreact";

class SearchPage extends Component {

getValueOfSelectOne = value => {
  console.log(value);
}

render() {
    return (
      <div className="form-group form-md-line-input has-info">
		<select className="form-control" id="form_control_1">
			<option value="">ENG</option>
			<option value="1">VN</option>
		</select>
		
	</div>
    );
  }
}

export default SearchPage;