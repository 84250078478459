import React from 'react';
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import agent from '../../agent'
import {Col, Row,FormControl, Form, FormGroup, ControlLabel, Button} from 'react-bootstrap'
import '../../styles/App.css'
import '../../styles/Friends.css'
import $ from 'jquery';
import {TOKEN_NAME, REDIRECT,LANGUAGE_KEY,USERID_NF,
CHANGE_TAB,LANGUAGE_LIST,BASE_IMAGE} from '../../constants/actionTypes'
import ModalErorr from '../common/ModalErorr'
import Datetime from "react-datetime"
//import Moment from "moment";

//import YearPicker from "react-year-picker";
import MonthPickerInput from 'react-month-picker-input';
//require('react-month-picker-input/dist/react-month-picker-input.css');

const mapStateToProps = state => (
    Object.assign({}, state, {
		paramsSave: state.home,
		token: state.common.token,
		tab: state.common.tab,
		//user_statistic: state.common.user_statistic,
		
	})      
);

const mapDispatchToProps = dispatch => ({               
	
	onRedirect: () =>
		dispatch({ type: REDIRECT }),
	
	onTabClick: (tab) => 
		dispatch({ type: CHANGE_TAB, tab }),
	
});

class ListBet extends React.Component {
	constructor(){
		super()
		this.state = {
			lguages:'1005',year:'',month:'',
			AlltimeData:{},MonthData:{},QuarterData:{},YearData:{},
			value:'',user_id:''
		}
		//---
		this.close=()=>{
			this.setState({isOpenModal:false})
		}
		//----
		this.onChange = this.onChange.bind(this);
		this.getMobileUserStatistic = this.getMobileUserStatistic.bind(this);
		//----
		
		
	}
	
	 onChange(e) {
		this.setState({ value: e });
	  }
	//---------------------------
    
	componentWillReceiveProps(nextProps) {
		//console.log(nextProps)
		//if(nextProps.user_statistic){
			//let data=nextProps.user_statistic;
			//this.setState({YearData:data.YearData,QuarterData:data.QuarterData,MonthData:data.MonthData,AlltimeData:data.AlltimeData})
		//}
	}	

	componentWillMount() {
		//---
		let date = new Date();
		let dateFormat=parseInt(date.getFullYear())+'-'+parseInt(date.getMonth()+1)+"-"+parseInt(date.getDate())
		
		
		//this.setState({year:parseInt(date.getFullYear()),month:parseInt(date.getMonth())})
		this.setState({year:parseInt(date.getFullYear()),month:3})
		let user_id=window.localStorage.getItem('user_id')
		if(user_id>0){
			this.getMobileUserStatistic(user_id)
			this.setState({user_id:user_id})
		}
		
	}	
	CollapseList(ev){
		//console.log(ev.target.closest('.bet-items'))
		let thiss=ev.target.closest('.bet-items')
		$(thiss).find('.sh-snap-menu').hide();
		$(thiss).find('.collapse').hide();
		$(thiss).find('.expand').show();
	}
	ExpandList(ev){
		//console.log(ev.target.closest('.bet-items'))
		let thiss=ev.target.closest('.bet-items')
		$(thiss).find('.sh-snap-menu').show();
		$(thiss).find('.collapse').show();
		$(thiss).find('.expand').hide();
	}
	handleChangeMonth(date){
		this.setState({ value: date.valueOf() });
		let dates=date.toDate();
		let month=dates.getMonth() + 1;
		let year=dates.getFullYear();
		var mth=month+"/"+year;
		//this.props.statisticMonth(mth)
		this.getMobileUserStatisticMonth(mth)
		//console.log(mth)
		
	}
	handleChangeYear(date){
		let dates=date.toDate();
		let year=dates.getFullYear();
		this.getMobileUserStatisticYear(year)
		//this.props.statisticYear(year);
		//console.log(year)
		
	}
	handleChangeQuater(date){
		let dates=date.toDate();
		var month = dates.getMonth() + 1;
		var quarter=Math.ceil(month / 3);
		let year=dates.getFullYear();
		let Qa=quarter+"/"+year;
		//console.log(Qa)
		//this.props.statisticQuater(Qa)
		this.getMobileUserStatisticQuarter(Qa)
	}
	
	getMobileUserStatistic(user_id){
		agent.home.mobileUserStatistic({user_id:user_id}).then(data => {
			//console.log(data)
			if(data.result === 'good'){
				this.setState({AlltimeData:data.AlltimeData,MonthData:data.MonthData,QuarterData:data.QuarterData,
							   YearData:data.YearData})
			}
		})
	}
	getMobileUserStatisticMonth(month){
		let user_id=this.state.user_id
		agent.home.mobileUserStatisticMonth({user_id:user_id,month:month}).then(data => {
			//console.log(data)
			if(data.result === 'good'){
				this.setState({MonthData:data.MonthData})
			}
		})
	}
	
	getMobileUserStatisticQuarter(quarter){
		let user_id=this.state.user_id
		agent.home.mobileUserStatisticQuarter({user_id:user_id,quarter:quarter}).then(data => {
			console.log(data)
			if(data.result === 'good'){
				this.setState({QuarterData:data.QuarterData})
			}
		})
	}
	getMobileUserStatisticYear(year){
		let user_id=this.state.user_id
		agent.home.mobileUserStatisticYear({user_id:user_id,year:year}).then(data => {
			//console.log(data)
			if(data.result === 'good'){
				this.setState({YearData:data.YearData})
			}
		})
	}
	
	render() {
		let MonthData=this.state.MonthData
		let QuarterData=this.state.QuarterData
		let YearData=this.state.YearData
		let AlltimeData=this.state.AlltimeData
		var date = new Date();
		//console.log(YearData)
		return(
			<Row className="mg0">
			{
				//---month
			}
				<Col md={6} xs={6} sm={6} className=" bet-items profile-box pd10_5" id="list-month">
					<div className="bet-title sh-item">
						<a className="league-link" href="javascript:;">Month: </a>
						<div className="div-month">
						
							
							<Datetime dateFormat="MM/YYYY" timeFormat={false} 
								//defaultValue={new Date('2018-12-01')}
								defaultValue={new Date()}
								onChange={this.handleChangeMonth.bind(this)}
							/>
						</div>
						<i className="collapse" id="focus-mb" onClick={this.CollapseList.bind(this)}>&nbsp; </i>
						<i className="expand" id="focus-mb" onClick={this.ExpandList.bind(this)} style={{'display':'none'}}>&nbsp; </i>
					</div>
					<Row className="data sh-snap-menu">
						<Col md={12} sm={12} xs={12} className="pd0 btt1">
							<Col md={5} sm={5} xs={5} className="mwI"><span className="label">Rank</span></Col>
							<Col md={7} sm={7} xs={7} className="mwI"><span className="num">{MonthData.Rank}</span></Col>
						</Col>
						<Col md={12} sm={12} xs={12} className="pd0 btt1">
							<Col md={5} sm={5} xs={5} className="mwI"><span className="label">Picked</span></Col>
							<Col md={7} sm={7} xs={7} className="mwI"><span className="num">{MonthData.Picked}</span></Col>
						</Col>
						<Col md={12} className="pd0 btt1">
							<Col md={5} sm={5} xs={5} className="mwI"><span className="label"><span className="won">Win</span></span></Col>
							<Col md={7} sm={7} xs={7} className="mwI"><span className="num">{MonthData.Win}</span></Col>
						</Col>
						<Col md={12} className="pd0 btt1">
							<Col md={5} sm={5} xs={5} className="mwI"><span className="label"><span className="draw">Draw</span></span></Col>
							<Col md={7} sm={7} xs={7} className="mwI"><span className="num">{MonthData.Draw}</span></Col>
						</Col>
						<Col md={12} className="pd0 btt1">
							<Col md={5} sm={5} xs={5} className="mwI"><span className="label"><span className="lost">Lose</span></span></Col>
							<Col md={7} sm={7} xs={7} className="mwI"><span className="num">{MonthData.Lost}</span></Col>
						</Col>
						<Col md={12} className="pd0 btt1">
							<Col md={5} sm={5} xs={5} className="mwI"><span className="label">Amount</span></Col>
							<Col md={7} sm={7} xs={7} className="mwI"><span className="num">{MonthData.Amount}</span></Col>
						</Col>
						<Col md={12} className="pd0 btt1">
							<Col md={5} sm={5} xs={5} className="mwI"><span className="label">Profit</span></Col>
							<Col md={7} sm={7} xs={7} className="mwI"><span className="num">{MonthData.Profit}</span></Col>
						</Col>
						<Col md={12} className="pd0 btt1">
							<Col md={5} sm={5} xs={5} className="mwI"><span className="label">Yield</span></Col>
							<Col md={7} sm={7} xs={7} className="mwI"><span className="num">{MonthData.ProfitPercentage2}</span></Col>
						</Col>
					</Row>
				</Col>
				{
					//--- quarter
				} 
				<Col md={6} xs={6} sm={6}  className=" bet-items profile-box pd10_5" id="list-quarter">
					<div className="bet-title sh-item">
						<a className="league-link" href="javascript:;">Quarter: </a>
						<div className="div-quarter">
							<Datetime dateFormat="Q" timeFormat={false} 
								//defaultValue={new Date('2018-12-01')}
								defaultValue={new Date()}
								onChange={this.handleChangeQuater.bind(this)}
							/>
						</div>
						<i className="collapse" id="focus-mb" onClick={this.CollapseList.bind(this)}>&nbsp; </i>
						<i className="expand" id="focus-mb" onClick={this.ExpandList.bind(this)} style={{'display':'none'}}>&nbsp; </i>
					</div>
					<Row className="data sh-snap-menu">
						<Col md={12} className="pd0 btt1">
							<Col md={5} sm={5} xs={5} className="mwI"><span className="label">Rank</span></Col>
							<Col md={7} sm={7} xs={7} className="mwI" ><span className="num">{QuarterData.Rank}</span></Col>
						</Col>
						<Col md={12} className="pd0 btt1">
							<Col md={5} sm={5} xs={5} className="mwI"><span className="label">Picked</span></Col>
							<Col md={7} sm={7} xs={7} className="mwI" ><span className="num">{QuarterData.Picked}</span></Col>
						</Col>
						<Col md={12} className="pd0 btt1">
							<Col md={5} sm={5} xs={5} className="mwI" ><span className="label"><span className="won">Win</span></span></Col>
							<Col md={7} sm={7} xs={7} className="mwI" ><span className="num">{QuarterData.Win}</span></Col>
						</Col>
						<Col md={12} className="pd0 btt1">
							<Col md={5} sm={5} xs={5} className="mwI" ><span className="label"><span className="draw">Draw</span></span></Col>
							<Col md={7} sm={7} xs={7} className="mwI" ><span className="num">{QuarterData.Draw}</span></Col>
						</Col>
						<Col md={12} className="pd0 btt1">
							<Col md={5} sm={5} xs={5} className="mwI" ><span className="label"><span className="lost">Lose</span></span></Col>
							<Col md={7} sm={7} xs={7} className="mwI" ><span className="num">{QuarterData.Lost}</span></Col>
						</Col>
						<Col md={12} className="pd0 btt1">
							<Col md={5} sm={5} xs={5} className="mwI" ><span className="label">Amount</span></Col>
							<Col md={7} sm={7} xs={7} className="mwI" ><span className="num">{QuarterData.Amount}</span></Col>
						</Col>
						<Col md={12} className="pd0 btt1">
							<Col md={5} sm={5} xs={5} className="mwI" ><span className="label">Profit</span></Col>
							<Col md={7} sm={7} xs={7} className="mwI"><span className="num">{QuarterData.Profit}</span></Col>
						</Col>
						<Col md={12} className="pd0 btt1">
							<Col md={5} sm={5} xs={5} className="mwI" ><span className="label">Yield</span></Col>
							<Col md={7} sm={7} xs={7} className="mwI" ><span className="num">{QuarterData.ProfitPercentage2}</span></Col>
						</Col>
					</Row>
				</Col>
				{
					//--- year
				}
				<Col md={6} xs={6} sm={6}  className=" bet-items profile-box pd10_5" id="list-year">
					<div className="bet-title sh-item">
						<a className="league-link" href="javascript:;">Year: </a>
						<div className="div-year">
						
							<Datetime dateFormat="YYYY" 
								//defaultValue = {new Date('2018-01-01')}
								defaultValue = {new Date()}
								onChange={this.handleChangeYear.bind(this)}
							/>
						</div>
						<i className="collapse" id="focus-mb" onClick={this.CollapseList.bind(this)}>&nbsp; </i>
						<i className="expand" id="focus-mb" onClick={this.ExpandList.bind(this)} style={{'display':'none'}}>&nbsp; </i>
					</div>
					<Row className="data sh-snap-menu">
						<Col md={12} className="pd0 btt1">
							<Col md={5} sm={5} xs={5} className="mwI" ><span className="label">Rank</span></Col>
							<Col md={7} sm={7} xs={7} className="mwI"><span className="num">{YearData.Rank}</span></Col>
						</Col>
						<Col md={12} className="pd0 btt1">
							<Col md={5} sm={5} xs={5} className="mwI" ><span className="label">Picked</span></Col>
							<Col md={7} sm={7} xs={7} className="mwI" ><span className="num">{YearData.Picked}</span></Col>
						</Col>
						<Col md={12} className="pd0 btt1">
							<Col md={5} sm={5} xs={5} className="mwI" ><span className="label"><span className="won">Win</span></span></Col>
							<Col md={7} sm={7} xs={7} className="mwI" ><span className="num">{YearData.Win}</span></Col>
						</Col>
						<Col md={12} className="pd0 btt1">
							<Col md={5} sm={5} xs={5} className="mwI" ><span className="label"><span className="draw">Draw</span></span></Col>
							<Col md={7} sm={7} xs={7} className="mwI" ><span className="num">{YearData.Draw}</span></Col>
						</Col>
						<Col md={12} className="pd0 btt1">
							<Col md={5} sm={5} xs={5} className="mwI" ><span className="label"><span className="lost">Lose</span></span></Col>
							<Col md={7} sm={7} xs={7} className="mwI"><span className="num">{YearData.Lost}</span></Col>
						</Col>
						<Col md={12} className="pd0 btt1">
							<Col md={5} sm={5} xs={5} className="mwI"><span className="label">Amount</span></Col>
							<Col md={7} sm={7} xs={7} className="mwI" ><span className="num">{YearData.Amount}</span></Col>
						</Col>
						<Col md={12} className="pd0 btt1">
							<Col md={5} sm={5} xs={5} className="mwI" ><span className="label">Profit</span></Col>
							<Col md={7} sm={7} xs={7} className="mwI" ><span className="num">{YearData.Profit}</span></Col>
						</Col>
						<Col md={12} className="pd0 btt1">
							<Col md={5} sm={5} xs={5} className="mwI" ><span className="label">Yield</span></Col>
							<Col md={7} sm={7} xs={7} className="mwI" ><span className="num">{YearData.ProfitPercentage2}</span></Col>
						</Col>
					</Row>
				</Col>
				{
					//--- all time
				}
				<Col md={6} xs={6} sm={6}  className=" bet-items profile-box pd10_5" id="list-year">
					<div className="bet-title sh-item">
						<a className="league-link" href="javascript:;">All time: </a>
						
						
						<i className="collapse" id="focus-mb" onClick={this.CollapseList.bind(this)}>&nbsp; </i>
						<i className="expand" id="focus-mb" onClick={this.ExpandList.bind(this)} style={{'display':'none'}}>&nbsp; </i>
					</div>
					<Row className="data sh-snap-menu">
						<Col md={12} className="pd0 btt1">
							<Col md={5} sm={5} xs={5} className="mwI" ><span className="label">Rank</span></Col>
							<Col md={7} sm={7} xs={7} className="mwI" ><span className="num">{AlltimeData.Rank}</span></Col>
						</Col>
						<Col md={12} className="pd0 btt1">
							<Col md={5} sm={5} xs={5} className="mwI" ><span className="label">Picked</span></Col>
							<Col md={7} sm={7} xs={7} className="mwI" ><span className="num">{AlltimeData.Picked}</span></Col>
						</Col>
						<Col md={12} className="pd0 btt1">
							<Col md={5} sm={5} xs={5} className="mwI" ><span className="label"><span className="won">Win</span></span></Col>
							<Col md={7} sm={7} xs={7} className="mwI" ><span className="num">{AlltimeData.Win}</span></Col>
						</Col>
						<Col md={12} className="pd0 btt1">
							<Col md={5} sm={5} xs={5} className="mwI" ><span className="label"><span className="draw">Draw</span></span></Col>
							<Col md={7} sm={7} xs={7} className="mwI" ><span className="num">{AlltimeData.Draw}</span></Col>
						</Col>
						<Col md={12} className="pd0 btt1">
							<Col md={5} sm={5} xs={5} className="mwI" ><span className="label"><span className="lost">Lose</span></span></Col>
							<Col md={7} sm={7} xs={7} className="mwI"><span className="num">{AlltimeData.Lost}</span></Col>
						</Col>
						<Col md={12} className="pd0 btt1">
							<Col md={5} sm={5} xs={5} className="mwI" ><span className="label">Amount</span></Col>
							<Col md={7} sm={7} xs={7} className="mwI"><span className="num">{AlltimeData.Amount}</span></Col>
						</Col>
						<Col md={12} className="pd0 btt1">
							<Col md={5} sm={5} xs={5} className="mwI" ><span className="label">Profit</span></Col>
							<Col md={7} sm={7} xs={7} className="mwI" ><span className="num">{AlltimeData.Profit}</span></Col>
						</Col>
						<Col md={12} className="pd0 btt1">
							<Col md={5} sm={5} xs={5} className="mwI" ><span className="label">Yield</span></Col>
							<Col md={7} sm={7} xs={7} className="mwI" ><span className="num">{AlltimeData.ProfitPercentage2}</span></Col>
						</Col>
					</Row>
				</Col>
				{
					//---
				}
			</Row>
		)
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(ListBet)
