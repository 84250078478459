import {
  TOKEN_NAME,APP_LOAD,REDIRECT,RESET_DASHBOARD,HOME_PAGE_LOADED,
  LOGIN,LOGOUT,PAGE_NOT_FOUND_LOADED,SEARCH_KEY,
  LANGUAGE_KEY,CHANGE_TAB,LANGUAGE_LIST,MEBER_NAME,IMG_USER,RUNNING_LOADER,
  ONLY_FIENDS,NUMBER_NOTIFICATIONS,COMMENT_REALTIME,UINFO
} from '../constants/actionTypes';

const defaultState = {
	appName: 'coopbet',
	tab: 'home',
	dashboardData: [],
	updateProps: true, 
	token: null,
	active: 'home'
};

export default (state = defaultState, action) => {
	switch (action.type) {
		case APP_LOAD:
			return  Object.assign({}, state, {
				appLoaded: true,
				token: action.access_token || null				
			});  
		case HOME_PAGE_LOADED:
			return  Object.assign({}, state, {
				dashboardData: action.data,
				tab: 'home',
				updateProps: true,
				active:'home'
			});	
		
		case RUNNING_LOADER:
			return  Object.assign({}, state, {
				tab: 'running',
				updateProps: true,
				active:'running'
			});	
		
			
		case RESET_DASHBOARD:
			return  Object.assign({}, state, {
				dashboardData: action.data,
				updateProps: true
			});	
		case LOGIN:
			return Object.assign({}, state, {
				redirectTo: action.redirectTo, 
				token: action.token,
				tab: 'baseline'
			})
		case REDIRECT:                   
			return  Object.assign({}, state, {redirectTo: null }); 	
		
		case TOKEN_NAME:
			return Object.assign({}, state, { token: action.data,updateProps: true})		
		case PAGE_NOT_FOUND_LOADED:
			return Object.assign({}, state, {tab: action.tab})
		case LOGOUT:
			return Object.assign({}, state, {tab: '', token: '', redirectTo: 'results'})
		case LANGUAGE_KEY:
			return  Object.assign({}, state, {
				languageKey: action.data,
				//tab: 'home',
				updateProps: true
			});
		case LANGUAGE_LIST:
			return  Object.assign({}, state, {
				languageslist: action.data,
				//tab: 'home',
				updateProps: true
			});
		case MEBER_NAME:
			return Object.assign({}, state, {
				member_name: action.data,
				updateProps: true
			});
		
		case SEARCH_KEY:
			return Object.assign({}, state, {
				search_key: action.data,
				updateProps: true
			});
		
		
		case IMG_USER:
			return Object.assign({}, state, {
				img_user: action.data,
				updateProps: true
			});
		case ONLY_FIENDS:
			return Object.assign({}, state, {
				only_friend: action.data,
				updateProps: true
			});
		case NUMBER_NOTIFICATIONS:
			return Object.assign({}, state, {
				number_notifications: action.data,
				updateProps: true
			});
		case UINFO:
			return Object.assign({}, state, {
				user_info: action.data,
				updateProps: true
			});
		case COMMENT_REALTIME:
			return Object.assign({}, state, {
				comment_real: action.data,
				updateProps: true
			});
		case CHANGE_TAB:                   
			return  Object.assign({}, state, {tab: action.tab, redirectTo: action.tab, refresh: true,active:action.tab }); 
			
		default:
			return Object.assign({}, state)
  }
};
