import React, {Component} from 'react'
import { connect } from 'react-redux'
import { localize, localizeKey,setLocale, getLocale } from 'react-redux-localization';
import { Cookies } from 'react-cookie' 
import '../../styles/App.css'
import '../../styles/home.css'
import {Col, Row, Button, OverlayTrigger, Tooltip} from 'react-bootstrap'
//import { createElementsFromText } from 'html-text-to-react';
//import ReactHtmlParser from 'react-html-parser';
import $ from 'jquery';
import {TOKEN_NAME,LANGUAGE_KEY,BASE_IMAGE} from '../../constants/actionTypes'
import agent from '../../agent'
import translations from '../common/captionhome.json';
import ReplyList from './ReplyList'

const mapStateToProps = state => (
    Object.assign({}, state, {
		dashboardData: state.common.dashboardData,
		tab: state.common.tab,
		updateProps: state.common.updateProps,
		locale: getLocale(state),
		languageKey: state.common.languageKey
	}) 

);

const mapDispatchToProps = dispatch => ({               
	
	chooseLocale: locale => dispatch(setLocale(locale))
});



 


class Comment extends Component{ 	
	constructor(){
		super()
		this.state = {
			lguages:'1005',
			
			CommentList:[],TotalComments:'',numCM:'',PickCode:'',
			url:'https://www.coopbet.com/media/avartar_cm.jpg',
			img_default:'https://www.coopbet.com/media/avartar_cm.jpg',
			url_like:'https://www.coopbet.com/media/likec.png',
			url_liked:'https://www.coopbet.com/media/likedc.png',
			url_dislike:'https://www.coopbet.com/media/dislikec.png',
			url_disliked:'https://www.coopbet.com/media/dislikedc.png',
			icon_send:"https://m.coopbet.com/img/send_16.png",
			icon_error:"https://m.coopbet.com/img/error.png",
			for_pick_code:'',parent_id:'',backoffset:0,numlike:'',likeList:[],id_user:'',
			PickLikedNum:'',PickLikedUserList:[],PickLikedUserIDList:[],PickCodeRunning:''
		}
		//---
		this.listComment=this.listComment.bind(this)
		this.createCommentChild=this.createCommentChild.bind(this)
		this.createComment=this.createComment.bind(this)
		
		//---
		this.updateTotalCM=(TotalComments)=>{
			this.setState({TotalComments:TotalComments})
		}
		//---
	}
	
	componentWillMount(){	
		//-----
		//setTimeout(() => {
		  //console.log(thiss.state.PickCode)
		//}, 2000);	
		
		//-----
		let TotalComments=this.props.TotalComments
		let PickCode=this.props.PickCode
		const url=(this.props.imageurl!='' && this.props.imageurl!=null && this.props.imageurl!='null')?this.props.imageurl:this.state.url
		const PickLikedNum=this.props.PickLikedNum;
		const PickLikedUserList=this.props.PickLikedUserList;
		const PickLikedUserIDList=this.props.PickLikedUserIDList;
		const id_user=this.props.id_user;
		const PickCodeRunning=this.props.PickCodeRunning;
		this.setState({TotalComments:TotalComments,PickCode:PickCode,url:url,PickLikedNum:PickLikedNum,
						PickLikedUserList:PickLikedUserList,PickLikedUserIDList:PickLikedUserIDList,
						id_user:id_user,PickCodeRunning:PickCodeRunning})
	}
	componentDidMount(){
		
		//console.log(this.props.PickCode)
	}
	
	componentWillReceiveProps(nextProps){
		//console.log(nextProps)
		if(nextProps.languageKey && nextProps.languageKey!='' && nextProps.languageKey!=null && nextProps.languageKey!='null'){
			this.setState({lguages:nextProps.languageKey});
		}
		if(nextProps.PickCode!='' && nextProps.PickCode!=null && nextProps.PickCode!=this.state.PickCode){
			this.setState({PickCode:nextProps.PickCode},(e)=>{
				//this.listComment();
			})
		}
		
	}
	
	shouldComponentUpdate(nextProps, nextState){
		//console.log(nextProps, nextState)
		return nextState;
	}	
	componentDidUpdate(prevProps, prevStat) {
		//console.log(prevProps, prevStat)
	}
	listComment(for_pick_code,parent_id){
		//let for_pick_code=this.state.PickCode
		//let parent_id=0;
		let backoffset=parseInt(this.state.backoffset+5);
		agent.comment.listComment({for_pick_code:for_pick_code,parent_id:parent_id,limit:5,offset:0}).then(data => {
			//console.log(data)
			if(data.result=="good"){
				this.setState({CommentList:data.CommentList,TotalComments:data.TotalComments,numCM:data.TotalComments,backoffset:0})
			}
		})
	}
	docm(ev){
		//ev.preventDefault();
		let thiss=ev.target.closest(".commet-tp")
		let pickcode=$(ev.target).attr("pickcode")
		//console.log(pickcode)
		let ot=$(thiss).attr("option")
		if(ot==0){
			$(ev.target).closest(".commet-tp").find(".cmt-list").hide();
			$(thiss).attr("option",1)
		}else{
			$(ev.target).closest(".commet-tp").find(".cmt-list").show();
			$(thiss).attr("option",0)
			this.listComment(pickcode,0)
		}
		if(this.state.for_pick_code!=pickcode){
			this.setState({for_pick_code:pickcode})
		}
		//
	}
	handleRely(e){
		let thiss=e.target.closest(".ct-cmt")
		$(thiss).find(".t-reply").show();
	}
	togglelike(comment_id,ev){
		agent.comment.togglelike({comment_id:comment_id}).then(data => {
			//console.log(data)
			if(data.result=="good"){
				const CommentList=this.state.CommentList.map(item=>{
					if(item.comment_id==comment_id){
						item.LikedNum=data.LikedNum
						item.IsLiked=data.IsLiked
						item.IsDisliked=data.IsDisliked
					}
					return item
				})
				this.setState({CommentList:CommentList})
			}
		})
	}
	toggledislike(comment_id,ev){
		agent.comment.toggledislike({comment_id:comment_id}).then(data => {
			//console.log(data)
			if(data.result=="good"){
				const CommentList=this.state.CommentList.map(item=>{
					if(item.comment_id==comment_id){
						item.LikedNum=data.LikedNum
						item.IsDisliked=data.IsDisliked
						item.IsLiked=data.IsLiked
					}
					return item
				})
				this.setState({CommentList:CommentList})
			}
		})
	}
	hideCM(for_pick_code,parent_id,e){
		let thiss=e.target.closest(".reply-List");
		$(thiss).find(".view-cm").hide();
		$(thiss).find(".hide-cm").show();
		this.setState({for_pick_code:for_pick_code,parent_id:parent_id})
	}
	viewCM(e){
		let thiss=e.target.closest(".reply-List");
		$(thiss).find(".view-cm").show();
		$(thiss).find(".hide-cm").hide();
		//this.setState({for_pick_code:'',parent_id:''})
	}
	hideRyply(e){
		let thiss=e.target.closest(".t-reply")
		$(thiss).hide();
	}
	createCommentChild(parent_id,for_pick_code,e){
		let thiss=e.target.closest(".t-reply")
		let comment=$(thiss).find(".area-cmt").val();
		//---
		//$(thiss).find('.do_cm').hide()
		//$(thiss).find('.ot-btn').hide()
		//$(thiss).find('.loading_dad').show()
		//---
		agent.comment.createComment({for_pick_code:for_pick_code,comment:comment,parent_id:parent_id}).then(data => {
			//console.log(data)
			if(data.result=="good"){
				//this.props.listComment(for_pick_code,0)
				this.listComment(for_pick_code,0)
				$(thiss).hide()
				$(thiss).find(".area-cmt").val('');
			}
		})
	}
	createComment(parent_id,e){
		let thiss=e.target.closest(".row")
		let comment=$(thiss).find(".txt-comment").val();
		let for_pick_code=this.state.PickCode
		//---
		//$(thiss).find('.do_cm').hide()
		//$(thiss).find('.ot-btn').hide()
		//$(thiss).find('.loading_dad').show()
		//---
		agent.comment.createComment({for_pick_code:for_pick_code,comment:comment,parent_id:parent_id}).then(data => {
			//console.log(data)
			if(data.result=="good"){
				this.listComment(for_pick_code,0)
				//$(thiss).find('.do_cm').show()
				//$(thiss).find('.loading_dad').hide()
				$(thiss).find(".txt-comment").val('');
			}
		})
	}
	viewComment(for_pick_code){
		let backoffset=parseInt(this.state.backoffset+5);
		agent.comment.listComment({for_pick_code:for_pick_code,parent_id:0,limit:5,offset:backoffset}).then(data => {
			//console.log(data)
			if(data.result=="good"){
				let cm_list=this.state.CommentList.concat(data.CommentList)
				this.setState({CommentList:cm_list,TotalComments:data.TotalComments,numCM:data.TotalComments,backoffset:backoffset})
			}
		})
	}
	togglelikepick(ev){
		let pick_code=$(ev.target).attr("pick_code")
		agent.home.togglelikepick({pick_code:pick_code}).then(data => {
			//console.log(data)
			if(data.result=="good"){
				let PickLikedNum=data.PickLikedNum;
				let PickLikedUserIDList=data.PickLikedUserIDList;
				let PickLikedUserList=data.PickLikedUserList;
				this.setState({PickLikedNum:PickLikedNum,PickLikedUserIDList:PickLikedUserIDList,PickLikedUserList:PickLikedUserList})
			}
		})
	}
	
	render(){   
		//---
		let CommentList=this.state.CommentList
		let TotalComments=this.state.TotalComments
		let url_like=this.state.url_like
		let url_liked=this.state.url_liked
		let url_dislike=this.state.url_dislike
		let url_disliked=this.state.url_disliked
		let url=this.state.url
		//---
		let PickLikedNum=this.state.PickLikedNum
		let PickLikedUserList=this.state.PickLikedUserList
		let PickLikedUserIDList=this.state.PickLikedUserIDList
		let PickCodeRunning=this.state.PickCodeRunning
		//---------
		var likeLst=this.state.PickLikedUserList;
		var pesons='';
		var _status='';
		for( let i=0;i<likeLst.length;i++){
			if(i==likeLst.length-1){
				pesons+=likeLst[i].tipster_name;
			}else{
				pesons+=likeLst[i].tipster_name+', ';
			}
			if(likeLst[i].user_id==this.state.id_user){
				_status="liked"
			}
			
		}
		//---------
		//console.log(this.state.PickCode)
        return (
		<div>
			<Row className="rw-like">
				<Col md={4} sm={4} xs={4} className="pd0">
					<a className="like-bet" data-id="2191" title="Like" style={{'display':'none'}}></a>
					<span className="num-like" style={{'display':'none'}}>Like (3)</span>
					<a className={(PickLikedNum>0 && _status=='liked')?"like-bet-active":"like-bet"} title="Like" pick_code={this.state.PickCode} onClick={this.togglelikepick.bind(this)}>														
						<span className="num-like">Like ({(PickLikedNum>0)?PickLikedNum:0})</span>
					</a>
				</Col>
				<Col md={4} sm={4} xs={4} className="pd0 txtct">
				{
					(PickCodeRunning==1)?
					<a>
						<img src={BASE_IMAGE['running.png']} width="16" title="Runnung"/>
						<span className="num-like">Running</span>
					</a>:''
				}
				</Col>
				<Col md={4} sm={4} xs={4} className="pd0 fl-r">
					<span className="num-like" pickcode={this.state.PickCode} option={1} onClick={this.docm.bind(this)}>Comments ({this.state.TotalComments})</span>
				</Col>
			</Row>
			<Row className="linect pd0"></Row>
			<Row>
				<input type="text" name="txt-comment" className="txt-comment form-control lh50" placeholder="write a comments..." />
				<button type="button" className="btn-cmt" onClick={this.createComment.bind(this,0)}>Post Comment</button>
			</Row>
			<div className="cmt-list"  style={{'display':'none'}}>
			{
				(TotalComments>0 && CommentList!='' && CommentList!=null)?CommentList.map((data,idx)=>{
				return(
				<Row key={idx}>
					<Col md={3} xs={3} sm={3} className="pd0" style={{'maxWidth':'18%','flex':'0 0 18%'}}>
						<figure>
							<a className="" href="">
								<img src={data.imageurl} width="50" height="50" className="img-icon-sm"/>
							</a>
						</figure>
					</Col>
					<Col md={9} xs={9} sm={9} className="pd0 ct-cmt" style={{'maxWidth':'82%','flex':'0 0 82%'}}>
						<Row className="mg0 pd0">
							<h4 className="name-cmt">
								<span>{data.tipster_name}</span>
								<span className="time-cmt">{data.str_comment_time}</span>
							</h4>
						</Row>
						<Row className="mg0 pd0">{data.comment}</Row>
						<Row className="pd0">
							<div className="r-like-reply">
								<img src={(data.IsLiked==true)?url_liked:url_like} 
									width="35" height="35" className="img-icon-like"
									onClick={this.togglelike.bind(this,data.comment_id)}
								/>
								<span className="count-like">{(data.LikedNum>0)?data.LikedNum:''}</span>
								<img src={(data.IsDisliked==true)?url_disliked:url_dislike}
									width="35" height="35" className="img-icon-dislike"
									onClick={this.toggledislike.bind(this,data.comment_id)}
								/>										
								<a className="reply-cmt" onClick={this.handleRely.bind(this)}>REPLY</a>
								
							</div>
						</Row>
						<Row className="pd0 t-reply" style={{'display':'none'}}>
							<textarea name="txt-comment" className="txt-comment form-control lh50 area-cmt" placeholder="Add a public reply..." ></textarea>
							<img src={this.state.icon_send} className="post-reply-img" onClick={this.createCommentChild.bind(this,data.comment_id,data.for_pick_code)}/>
							<img src={this.state.icon_error} className="post-cancel-img" onClick={this.hideRyply.bind(this)}/>
						</Row>
					{
					//---Reply list
					}
					{
						(data.total_replies>0)?
						<Row className="mg0 reply-List pd10_0">
							<h4 className="name-cmt view-cm"  onClick={this.hideCM.bind(this,data.for_pick_code,data.comment_id)}>
								<span>View {data.total_replies} replies</span>
								<i className="fa fa-chevron-down"></i>
							</h4>
							<h4 className="name-cmt hide-cm mgbt0" onClick={this.viewCM.bind(this)} style={{'display':'none'}}>
								<span>Hide replies</span>
								<i className="fa fa-chevron-up"></i>
							</h4>
							<Row className="pd0 hide-cm" style={{'display':'none'}}>
								<ReplyList 
									imageurl={url} 
									for_pick_code={this.state.for_pick_code} 
									parent_id={this.state.parent_id}
									listComment={this.listComment}
									member_name={this.state.member_name}
									updateTotalCM={this.updateTotalCM}
								/>
							</Row>
							
						</Row>:''
					}
					
					{
						//---end
					}
					</Col>
				</Row>
				)
				}):''
				
			}
			{
				(TotalComments>5 && CommentList!='' && parseInt(CommentList.length)>4)?
				<Row className="mg0 pd_t_bt0">
					<Col md={12}>
						<a className="view-more" onClick={this.viewComment.bind(this,this.state.for_pick_code)}>View more</a>
					</Col> 
				</Row>:''
			}	
			</div>
			
			
		</div>	
			
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Comment)