import React from "react";
import { MDBRow, MDBCol, MDBBtn } from "mdbreact";
import { connect } from 'react-redux'
import agent from '../../agent'
import $ from 'jquery';
import Captcha from './formCaptcha.js'
import '../../styles/home.css'
import {TOKEN_NAME,LANGUAGE_KEY} from '../../constants/actionTypes'
import menu from '../common/menu.json'
import { localize, localizeKey} from 'react-redux-localization';
import ModalErorr from '../common/ModalErorr'
const mapStateToProps = state => (
    Object.assign({}, state, {
		tab: state.common.tab,
		updateProps: state.common.updateProps,
		//locale: getLocale(state),
		languageKey: state.common.languageKey
	}) 

);

class FormsPage extends React.Component {
	constructor(){
		super()
		this.state = {
			fname: "",
			lname: "",
			email: "",
			password: "",
			confirmpassword: "",
			tiptername: "",time_zone:'',country_id:'',
			imgCheck:"",textCheck:'',timezoneList:[],countryList:[],
			captcharValue:'',doregis:true,timezone_id:'',lguages:'1005',
			isOpenModal:false,msgRigister:''
		}	
		this.check_url=this.check_url.bind(this) 
		this.updateCaptcha=this.updateCaptcha.bind(this) 
		this.signup=this.signup.bind(this) 
	}

  submitHandler = event => {
    event.preventDefault();
   // event.target.className += " was-validated";
	let captcharValue=this.state.captcharValue
	if(captcharValue!='' && captcharValue!=null && captcharValue!='null'){
		this.signup()
	}
	
	//console.log(captcharValue)
  };

  changeHandler = event => {
    this.setState({ [event.target.name]: event.target.value });
	if(event.target.name=='tiptername'){
		var url="coopbet.com/"+event.target.value.toLowerCase();
		this.check_url(url);
		
	}
  };
  check_url(url_username){
	$(".ch_U").show()
	agent.profile.check_url({url_username:url_username}).then((datas) => {
		//console.log(datas)
		if(datas.result=='good'){
			this.setState({imgCheck:'fa fa-check',textCheck:'Username url is available.'})
		}else{
			this.setState({imgCheck:'fa fa-times',textCheck:'Username url is not available.'})
		}
		$(".ch_U").hide()
	})
  }
	//---
	getTimezone(){
		agent.auth.timezone().then((datas) => {
		//console.log(datas)
		if(datas.result=='good'){
			this.setState({timezoneList:datas.list})
		}
	  })	
	}
	//---
	getCountry(){
		agent.auth.country().then((datas) => {
		//console.log(datas)
		if(datas.result=='good'){
			this.setState({countryList:datas.list})
		}
	  })	
	}
  //---
	defaultcountryandtimezone(){
		agent.auth.defaultcountryandtimezone().then((datas) => {
		console.log(datas)
		if(datas.result=='good'){
			let data=datas.data
			let country_id=data.country_id
			let timezone_id=data.timezone_id
			this.setState({timezone_id:timezone_id,country_id:country_id})
		}
	  })	
	}
  //---
  updateCaptcha(vale){
	//console.log(vale)  
	this.setState({captcharValue:vale})
	if(vale!='' && vale!=null && vale!="null"){
		this.setState({doregis:false})
	}else{
		this.setState({doregis:true})
	}
  }
  //---
  signup(){	
		let first_name=this.state.fname
		let last_name=this.state.lname
		let email=this.state.email
		let tipster_name=this.state.tiptername
		let time_zone=$(".time_zone option:selected").val();
		let country_id=$(".country_id option:selected").val();
		let password=this.state.password
		let password_confirmation=this.state.confirmpassword
		agent.auth.signup({
			first_name:first_name,last_name:last_name,email:email,tipster_name:tipster_name,time_zone:time_zone,
			country_id:country_id,password:password,password_confirmation:password_confirmation
		}).then((datas) => {
			//console.log(datas)
			if(datas.result==='good'){
				//window.localStorage.setItem( TOKEN_NAME, JSON.stringify(datas) );					
				//this.props.setToken(JSON.stringify(datas.data))
				
				$(".red-text").html('')
				let thiss=this
				let msg='Thank you for signing up with Coopbet. Please check email to active your account.'
				this.setState({msgRigister:msg,isOpenModal:true},()=>{
					setTimeout(function(){
						thiss.setState({msgRigister:'',isOpenModal:false})
						//window.location.href='home'
						window.location.href='login'
					},5000);
				})
			}else{
				window.localStorage.setItem( TOKEN_NAME, '');
				//$(".red-text").html(datas.message)
				this.setState({msgRigister:datas.message,isOpenModal:true})
				setTimeout(function(){
					$(".red-text").html('')
				},9000);
			}
			
	   })	
	}
	//---
  //---
  componentWillMount(){		
		let idLanguage=window.localStorage.getItem(LANGUAGE_KEY)
		if(idLanguage!='' && idLanguage!='null' && idLanguage!=null){
			this.setState({lguages:idLanguage})
		}
		this.getTimezone();
		this.getCountry();
		this.defaultcountryandtimezone();
		
  }
  componentWillReceiveProps(nextProps){
		//console.log(nextProps)
		if(nextProps.languageKey && nextProps.languageKey!='' && nextProps.languageKey!=null && nextProps.languageKey!='null'){
			this.setState({lguages:nextProps.languageKey});
			
		}
	}
  HandleSelect(field,ev){
	  this.setState({[field]:ev.target.value})
  }
  render() {
	let urlname=this.state.tiptername.split(' ').join('');
	const timezoneList=this.state.timezoneList;
	const countryList=this.state.countryList;
    return (
      <div>
        <form
          className="needs-validation"
          onSubmit={this.submitHandler}
          noValidate
        >
          <MDBRow>
            <MDBCol md="4" className="mb-3">
              <label
                htmlFor="defaultFormRegisterNameEx"
                className="grey-text"
              >
                {localizeKey(menu)('firstName', this.state.lguages)}:
              </label>
              <input
                value={this.state.fname}
                name="fname"
                onChange={this.changeHandler}
                type="text"
                id="defaultFormRegisterNameEx"
                className="form-control"
                placeholder={localizeKey(menu)('firstName', this.state.lguages)}
                required
              />
              <div className="valid-feedback">Looks good!</div>
            </MDBCol>
            <MDBCol md="4" className="mb-3">
              <label
                htmlFor="defaultFormRegisterEmailEx2"
                className="grey-text"
              >
                {localizeKey(menu)('lastName', this.state.lguages)}:
              </label>
              <input
                value={this.state.lname}
                name="lname"
                onChange={this.changeHandler}
                type="text"
                id="defaultFormRegisterEmailEx2"
                className="form-control"
                placeholder={localizeKey(menu)('lastName', this.state.lguages)}
                required
              />
              <div className="valid-feedback">Looks good!</div>
            </MDBCol>
            <MDBCol md="4" className="mb-3">
              <label
                htmlFor="defaultFormRegisterConfirmEx3"
                className="grey-text"
              >
                {localizeKey(menu)('emailTips', this.state.lguages)}:
              </label>
              <input
                value={this.state.email}
                onChange={this.changeHandler}
                type="email"
                id="defaultFormRegisterConfirmEx3"
                className="form-control"
                name="email"
                placeholder="Email"
				required
              />
            </MDBCol>
          </MDBRow>
          <MDBRow>
            <MDBCol md="4" className="mb-3">
              <label
                htmlFor="defaultFormRegisterPasswordEx4"
                className="grey-text"
              >
                {localizeKey(menu)('password', this.state.lguages)}:
              </label>
              <input
                value={this.state.password}
                onChange={this.changeHandler}
                type="password"
                id="defaultFormRegisterPasswordEx4"
                className="form-control"
                name="password"
                placeholder={localizeKey(menu)('password', this.state.lguages)}
                required
              />
               <small id="emailHelp" className="form-text text-muted">
                {localizeKey(menu)('txt_password', this.state.lguages)}
              </small>
            </MDBCol>
            <MDBCol md="4" className="mb-3">
              <label
                htmlFor="defaultFormRegisterPasswordEx4"
                className="grey-text"
              >
                {localizeKey(menu)('confirmPass', this.state.lguages)}:
              </label>
              <input
                value={this.state.confirmpassword}
                onChange={this.changeHandler}
                type="password"
                id="defaultFormRegisterPasswordEx4"
                className="form-control"
                name="confirmpassword"
                placeholder={localizeKey(menu)('confirmPass', this.state.lguages)}
                required
              />
              
            </MDBCol>
            <MDBCol md="4" className="mb-3">
              <label
                htmlFor="defaultFormRegisterPasswordEx4"
                className="grey-text"
              >
                {localizeKey(menu)('tipname', this.state.lguages)}:
              </label>
              <input
                value={this.state.tiptername}
                onChange={this.changeHandler}
                type="text"
                id="defaultFormRegisterPasswordEx4"
                className="form-control"
                name="tiptername"
                placeholder={localizeKey(menu)('tipname', this.state.lguages)}
                required
              />
            </MDBCol>
			<MDBCol md="4" className="mb-3" style={{'display':'none'}}>
              <label
                htmlFor="defaultFormRegisterPasswordEx4"
                className="grey-text"
              >
                Url Username:
              </label>
              {
				(this.state.tiptername!='')?
					<div className="rigister">
						<span className="url-user">coopbet.com/{urlname.toLowerCase()}</span>
						<img className="ch_U" src="https://www.coopbet.com/media/loading_dots.gif" style={{'width':'35px'}}/>
						<i className={this.state.imgCheck}></i>
						<span>{this.state.textCheck}</span>
					</div>:''
					
				}
            </MDBCol>
			 <MDBCol md="4" className="mb-3">
				<label
					htmlFor="defaultFormRegisterPasswordEx4"
					className="grey-text"
				>
                {localizeKey(menu)('timezone', this.state.lguages)}:
                </label>
                <select placeholder="select" className="form-control time_zone" name="time_zone" value={this.state.timezone_id} onChange={this.HandleSelect.bind(this,'timezone_id')}>
					{
						(timezoneList)?timezoneList.map(data=>{
							return (<option value={data.id} key={data.id}> {data.desc} </option>)
						}):''
					}
				</select>
            </MDBCol>
			<MDBCol md="4" className="mb-3">
				<label
					htmlFor="defaultFormRegisterPasswordEx4"
					className="grey-text"
				>
                {localizeKey(menu)('selectCtr', this.state.lguages)}:
                </label>
                <select placeholder="select" className="form-control country_id" name="country_id" value={this.state.country_id} onChange={this.HandleSelect.bind(this,'country_id')} >
					<option value=""> Select Country </option>
					{
						(countryList)?countryList.map(data=>{
							return(<option value={data.id} key={data.id}> {data.title} </option>)
						}):''
					}
				</select>
            </MDBCol>
			<MDBCol md="4" className="mb-3">
				<label
					htmlFor="defaultFormRegisterPasswordEx4"
					className="grey-text"
				>
                By clicking Sign Up, you are 18 years old and agree to our Teams, Data Policy and Cookies Policy.
                </label>
                <Captcha captcha={this.updateCaptcha}/>
            </MDBCol>
          </MDBRow>
          
		  
          <MDBBtn color="primary" type="submit" className="doRegister" disabled={this.state.doregis}>
            {localizeKey(menu)('sigUp', this.state.lguages)}
          </MDBBtn>
		  <MDBRow>
			  <MDBCol md="12" className="mb-12">
					<label
						htmlFor="defaultFormRegisterPasswordEx4"
						className="red-text"
					>
					</label>
					
				</MDBCol>
          </MDBRow>
        </form>
		<ModalErorr isOpen={this.state.isOpenModal} close={this.close} text={this.state.msgRigister}/>
      </div>
    );
  }
}

//export default FormsPage;
export default connect(mapStateToProps, null)(FormsPage)