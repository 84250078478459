import React, {Component} from 'react'
import { connect } from 'react-redux'
import { setLocale, getLocale, localizeKey } from 'react-redux-localization';
import { Cookies } from 'react-cookie' 
import '../../styles/App.css'
import '../../styles/home.css'
import {Col, Row,FormControl, Form, FormGroup, ControlLabel, Button, Tabs,Tab,Table,Radio} from 'react-bootstrap'
import { createElementsFromText } from 'html-text-to-react';
import ReactHtmlParser from 'react-html-parser';
import $ from 'jquery';
import {TOKEN_NAME, REDIRECT,HOME_PAGE_LOADED, APP_LOAD,LANGUAGE_KEY} from '../../constants/actionTypes'
import agent from '../../agent'
import menu from '../common/menu.json'
import Lqhome from '../common/home.json'


const mapStateToProps = state => (
    Object.assign({}, state, {
		tab: state.common.tab,
		updateProps: state.common.updateProps,
		locale: getLocale(state),
		languageKey: state.common.languageKey
	}) 

);

const mapDispatchToProps = dispatch => ({               
	onLoad: (data) => {
		dispatch({ type: 'HOME_PAGE_LOADED', data})  
	},
	chooseLocale: locale => dispatch(setLocale(locale))
});



 


class InVoiceTip extends Component{ 	
	constructor(){
		super()
		this.state = {
			lguages:'1005',
			qcinfo:{},idUser:'',order_id:'',order_info:{},SellerBankInfo:{},
		}
		//---
		this.getrandomad=()=>{
			let lang_id=this.state.lguages
			let param={lang_id:lang_id}
			agent.home.getrandomad2(param).then(data => {
				//console.log(data)
				if(data.result === 'good'){
					this.setState({qcinfo:data.info})
				}
			})
		}
		//---
		this.goAdView=this.goAdView.bind(this)
		//---
		this.getOrderInfo=(id)=>{
			agent.home.getOrderInfo({order_id:id,}).then(data => {
				if(data.result=="good"){					
					this.setState({order_info:data.order_info,SellerBankInfo:data.order_info.SellerBankInfo})
				}else{
					
				}
			})
		}
		this.goUploadrecept=()=>{
			window.location='/upload-receipt/'+this.state.order_id
		}
		
	}
	goAdView(ev){
		let id=$(ev.target).attr("dataid")
		/*let title=$(ev.target).attr("title")
		window.localStorage.setItem( 'ad_user_id', id);
		window.localStorage.setItem( 'ad_title', title);
		//console.log(id,title)
		window.location.href='/ad-list' */
		window.location.href='/new-info/'+id
	}
	
	componentWillMount(){	
		//-----
		
		let idLanguage=window.localStorage.getItem(LANGUAGE_KEY)
		if(idLanguage!='' && idLanguage!='null' && idLanguage!=null){
			this.setState({lguages:idLanguage},()=>{
				this.getrandomad();
			})
		}
		let login=window.localStorage.getItem(TOKEN_NAME);
		if(login!='' && login!='null' && login!=null){
			login=JSON.parse(login)
			let info=login.UserInfo
			//console.log(login.UserInfo)
			this.setState({idUser:info.id},()=>{
				//this.getbankrolls(info.id)
			})				
		}
		
		let id=this.props.match.params.id
		this.setState({order_id:id})
		this.getOrderInfo(id)
		//-----
	}
	componentDidMount(){
		this.getrandomad();
	}
	componentWillReceiveProps(nextProps){
		//console.log(nextProps)
		if(nextProps.languageKey && nextProps.languageKey!='' && nextProps.languageKey!=null && nextProps.languageKey!='null'){
			this.setState({lguages:nextProps.languageKey},()=>{
				this.getrandomad()
			});
			
		}
	}
	
	shouldComponentUpdate(nextProps, nextState){
		return nextState;
	}	
	
	numberFormat(x) {
		if(x>0){
			return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
		}else return;
	}
	render(){   
		//---
		const qcinfo=this.state.qcinfo;
		let title=''
		let imageurl=''
		let url=''
		let description=''
		if(qcinfo!='' && qcinfo!=null && qcinfo!='null'){
			 title=qcinfo.ad_title?qcinfo.ad_title:'';
			 imageurl=qcinfo.ad_logo_url?qcinfo.ad_logo_url:'https://back-end.coopbet.com/gf/ywqBBMhT0U0022_no-logo.png';
			 url=qcinfo.ad_url?qcinfo.ad_url:'';
			 description=qcinfo.ad_description?qcinfo.ad_description:'';
			 description=(description.length<=85)?description:description.substr(0,85)+'...'
		}	
	
		//---
		let order_info=this.state.order_info
		let SellerBankInfo=this.state.SellerBankInfo
        return (
			<div id="body"> 
			{
				(qcinfo && qcinfo!=null && qcinfo!='')?
				<Row className="randomad">
					<Col md={4} xs={4} sm={4} className="pd0">
						<div className="img-adv">
							<a href={url} target="_blank" className="rand-logo">
								<img alt="m88" src={imageurl} width="100" height="50"/>
							</a>
						</div>
					</Col>
					<Col md={8} xs={8} sm={8} className="pd0">
						<div className="desc-adv">									
						{
							ReactHtmlParser(description)
						}
							<span>
							{
								(qcinfo!='' && qcinfo!=null)?
								<a onClick={this.goAdView.bind(this)} dataid={qcinfo.id} title={title} className="v-more">{localizeKey(Lqhome)('viewmore', this.state.lguages)}</a>:''
							}
							</span>
						</div>
					</Col>
				</Row>:''
			}
				<Row className="mg0 mrgt7 mgbt7">
					
					<Col md={7} xs={7} sm={7}>
						<i className="far fa-list-alt " style={{'fontSize':'20px','verticalAlign':'top'}}></i>
						<div className="rigister-title" >BUY TIPS</div>
					</Col>
					<Col md={5} xs={5} sm={5} className="rank-mth date-tips">
						
						
					</Col>
									
				</Row>
				<Row className="line-ct" style={{'marginBottom':'10px'}}></Row>
				
				<Row className="mg0 " >
					<Col md={12} className="txt-al-ct">
						<h4 className="inv-title">Invoice</h4>
						<div className="inv-no">No.: {order_info.order_number}</div>
					</Col>
					<Col md={12} className="line-1px"></Col>
					<Col md={12}>
						<Row className="ct-r-1">
							<Col md={2} className="w20"></Col>
							<Col md={6} className="w80">
								<div className="txt-b">Thông tin chuyển khoản</div>
								<Row className="ct-name-pson txt-ct-ti">
									<Col md={4} xs={4} sm={4} className="txt-tk-l">Tên tài khoản</Col>
									<Col md={8} xs={8} sm={8} className="txt-tk-r">
										<i className="far fa-copy ic-cpi"></i>
										<label className="name-per-iv">{(SellerBankInfo!==null && SellerBankInfo!=='')?SellerBankInfo.bank_user:''}</label>
									</Col>
								</Row>
								<Row className="ct-name-pson">
									<Col md={4} xs={4} sm={4} className="txt-tk-l">Số tài khoản</Col>
									<Col md={8} xs={8} sm={8} className="txt-tk-r">
										<i className="far fa-copy ic-cpi"></i>
										<label className="name-per-iv">{(SellerBankInfo!==null && SellerBankInfo!=='')?SellerBankInfo.bank_no:''}</label>
									</Col>
								</Row>
								<Row className="ct-name-pson">
									<Col md={4} xs={4} sm={4} className="txt-tk-l">Ngân hàng</Col>
									<Col md={8} xs={8} sm={8} className="txt-tk-r">
										<i className="far fa-copy ic-cpi"></i>
										<label className="name-per-iv">{(SellerBankInfo!==null && SellerBankInfo!=='')?SellerBankInfo.bank_name:''}</label>
									</Col>
								</Row>
								<Row className="ct-name-pson">
									<Col md={4} xs={4} sm={4} className="txt-tk-l">Số tiền</Col>
									<Col md={8} xs={8} sm={8} className="txt-tk-r">
										<i className="far fa-copy ic-cpi"></i>
										<label className="name-per-iv">{this.numberFormat(order_info.PriceTips_VND)} vnd</label>
									</Col>
								</Row>
								<Row className="ct-name-pson">
									<Col md={4} xs={4} sm={4} className="txt-tk-l">Mã chuyển tiền</Col>
									<Col md={8} xs={8} sm={8} className="txt-tk-r">
										<i className="far fa-copy ic-cpi"></i>
										<label className="name-per-iv">{order_info.order_number}</label>
									</Col>
								</Row>
							</Col>
						</Row>
					</Col>
					<Col md={12} className="line-1px"></Col>
					<Col md={12} className="iv-ft">
						<Button
							id="btn-send-iv"
							bsstyle="primary"
							onClick={this.goUploadrecept.bind(this)}
						>
							<span>Gửi bằng chứng thanh toán</span>	
						</Button>
					</Col>
				</Row>
				
			</div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(InVoiceTip)