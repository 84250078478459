import React, {Component} from 'react'
import { connect } from 'react-redux'
import ReactDOM from 'react-dom';
import { setLocale, getLocale } from 'react-redux-localization';
import '../../styles/main.css'
import '../../styles/App.css'
import '../../styles/login.css'
import {Col, Row, FormControl, FormGroup, Radio, OverlayTrigger, Popover, Button} from 'react-bootstrap'
import agent from '../../agent'
import {TOKEN_NAME, CHANGE_TAB,LANGUAGE_KEY} from '../../constants/actionTypes'
import $ from 'jquery';
import { Link} from 'react-router-dom'
import ReactHtmlParser from 'react-html-parser';
//import languages from '../../languages'
//import translations from '../common/captionhome.json';


const mapStateToProps = state => (
    Object.assign({}, state, {
		
		languageKey: state.common.languageKey
	}) 

);

const mapDispatchToProps = dispatch => ({               
	
	chooseLocale: locale => dispatch(setLocale(locale)),
	onTabClick: (tab) => 
		dispatch({ type: CHANGE_TAB, tab }),
});



 


class Forgot extends Component{ 	
	constructor(){
		super()
		this.state = {
			lguages:'1005',qcinfo:{}
			
		}
		//---
		this.getrandomad=()=>{
			let lang_id=this.state.lguages
			let param={lang_id:lang_id}
			agent.home.getrandomad2(param).then(data => {
				//console.log(data)
				if(data.result === 'good'){
					this.setState({qcinfo:data.info})
				}
			})
		}
		//---
  }
	//---
	
	componentWillMount(){		
		this.getrandomad();
		let idLanguage=window.localStorage.getItem(LANGUAGE_KEY)
		if(idLanguage!='' && idLanguage!='null' && idLanguage!=null){
			this.setState({lguages:idLanguage})
		}
	}
	
	componentWillReceiveProps(nextProps){
		//console.log(nextProps.languageKey);
		if(nextProps.languageKey){
			this.setState({lguages:nextProps.languageKey});
		}
	}
	
	//shouldComponentUpdate(nextProps, nextState){
	 
	//}	
	
	render(){   
		const qcinfo=this.state.qcinfo;
		let title=''
		let imageurl=''
		let url=''
		let description=''
		if(qcinfo!='' && qcinfo!=null && qcinfo!='null'){
			 title=qcinfo.ad_title?qcinfo.ad_title:'';
			 imageurl=qcinfo.ad_logo_url?qcinfo.ad_logo_url:'https://back-end.coopbet.com/gf/ywqBBMhT0U0022_no-logo.png';
			 url=qcinfo.ad_url?qcinfo.ad_url:'';
			 description=qcinfo.ad_description?qcinfo.ad_description:'';
			 description=(description.length<=70)?description:description.substr(0,70)+'...'
		}	
        return (
			<div id="body">   
				<Row className="randomad">
					<Col md={4} xs={4} sm={4} className="pd0">
						<div className="img-adv">
							<a href={url} target="_blank" className="rand-logo">
								<img alt="m88" src={imageurl} width="100" height="50"/>
							</a>
						</div>
					</Col>
					<Col md={8} xs={8} sm={8} className="pd0">
						<div className="desc-adv">									
						{
							ReactHtmlParser(description)
						}
						</div>
					</Col>
				</Row>
				
				<Row className="mg0 mrgt7 mgbt7">
					<Col md={12} xs={12} sm={12} className="">
						<div className="rigister-title">RESET PASSWORD</div>
					</Col>					
				</Row>
				<Row className="line-ct"></Row>
				<Row className="mg0 email-div">
					<Col md={12}>
						<p className="styiN">You have reset your password.</p>
						<p className="styiN">Please click <Link to="./login" className="link">here</Link> to login.</p>
						
					</Col>
				</Row>
			</div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Forgot)