import React, {Component} from 'react'
import { connect } from 'react-redux'
import { localize, localizeKey,setLocale, getLocale } from 'react-redux-localization';
import { Cookies } from 'react-cookie' 
import '../../styles/App.css'
import '../../styles/home.css'
import {Col, Row, Button} from 'react-bootstrap'
//import { createElementsFromText } from 'html-text-to-react';
//import ReactHtmlParser from 'react-html-parser';
import $ from 'jquery';
import {TOKEN_NAME,LANGUAGE_KEY,BASE_IMAGE} from '../../constants/actionTypes'
import agent from '../../agent'
import translations from '../common/captionhome.json';
import Comment from './comment.js'
import {withRouter, history} from 'react-router-dom'
import ModalErorr from '../common/ModalErorr'
import ReactHtmlParser from 'react-html-parser';

const mapStateToProps = state => (
    Object.assign({}, state, {
		dashboardData: state.common.dashboardData,
		tab: state.common.tab,
		updateProps: state.common.updateProps,
		locale: getLocale(state),
		languageKey: state.common.languageKey
	}) 

);

const mapDispatchToProps = dispatch => ({               
	
	chooseLocale: locale => dispatch(setLocale(locale))
});



 


class Home extends Component{ 	
	constructor(){
		super()
		this.state = {
			lguages:'1005',
			homebetList:[],userInfo:{},
			url:'https://www.coopbet.com/media/avartar_cm.jpg',
			img_default:'https://m.coopbet.com/img/no_avartar.jpg',
			url_like:'https://www.coopbet.com/media/likec.png',
			url_liked:'https://www.coopbet.com/media/likedc.png',
			url_dislike:'https://www.coopbet.com/media/dislikec.png',
			url_disliked:'https://www.coopbet.com/media/dislikedc.png',
			CommentList:[],TotalComments:'',numCM:'',pickcode_do:'',imageurl:'',
			PickDatetimeSTR:'',parent_id:'',isOpenModal:false,msgLogin:'',
		}
		//---
		this.close=()=>{
			this.setState({isOpenModal:false})
		}
		//---
		this.callModalError=(message)=>{
			this.setState({isOpenModal:true,msgLogin:message})
		}
		//---
		//---
		
		//---
	}
	
	componentWillMount(){	
		//-----
		let idLanguage=window.localStorage.getItem(LANGUAGE_KEY)
		if(idLanguage!='' && idLanguage!='null' && idLanguage!=null){
			this.setState({lguages:idLanguage})
		}
		
		let login=window.localStorage.getItem(TOKEN_NAME);
		 
		if(login!='' && login!='null' && login!=null){
			 login=JSON.parse(login)
			 this.setState({member_name:login.UserInfo.member_name,id_user:login.UserInfo.id,imageurl:login.UserInfo.imageurl})
		}
		
		//-----
	}
	
	componentWillReceiveProps(nextProps){
		//console.log(nextProps)
		let userInfo=this.state.userInfo
		let homebetList=this.state.homebetList
		let PickDatetimeSTR=this.state.PickDatetimeSTR
		let parent_id=this.state.parent_id
		if(nextProps.languageKey && nextProps.languageKey!='' && nextProps.languageKey!=null && nextProps.languageKey!='null'){
			this.setState({lguages:nextProps.languageKey});
		}
		if(nextProps.homebetList && nextProps.homebetList!='' && nextProps.homebetList!=null && nextProps.homebetList!='null'){
			homebetList=nextProps.homebetList
		}
		if(nextProps.userInfo && nextProps.userInfo!='' && nextProps.userInfo!=null && nextProps.userInfo!='null'){
			userInfo=nextProps.userInfo
		}
		if(nextProps.PickDatetimeSTR && nextProps.PickDatetimeSTR!='' && nextProps.PickDatetimeSTR!=null && nextProps.PickDatetimeSTR!='null'){
			PickDatetimeSTR=nextProps.PickDatetimeSTR
		}
		if(nextProps.parent_id!='' && nextProps.parent_id!=null){
			parent_id=nextProps.parent_id
		}
		this.setState({homebetList:homebetList,userInfo:userInfo,PickDatetimeSTR:PickDatetimeSTR,parent_id:parent_id});
	}
	
	shouldComponentUpdate(nextProps, nextState){
		return nextState;
	}	
	componentDidMount() {
		window.onclick = function(event) {
		  
		  if (!event.target.matches('.tfd-delete')) {
			var dropdowns = document.getElementsByClassName("dropdown-menu");
			var i;
			for (i = 0; i < dropdowns.length; i++) {
			  $(".dr-fr").hide();
			}
		  }
		}
	}
	goProfile(id,e){
		window.localStorage.setItem('user_id',id )
		this.props.history.push("/profile")
	}
	render(){   
		//---
		let userInfo=this.state.userInfo
		let data=this.state.homebetList
		let CommentList=this.state.CommentList
		let TotalComments=this.state.TotalComments
		let betlist=data.BetList
		let PickDatetimeSTR=this.state.PickDatetimeSTR
		//---
		let profitpercentage=0;
		if(userInfo.SummaryBet!='' && userInfo.SummaryBet!=null){
			profitpercentage=userInfo.SummaryBet.profitpercentage
		}
		var html = data.PickComment;
		if(html!='' && html!=null){
			html=html.replace(/(?:\r\n|\r|\n)/g, '<br />')
		}
		var div = document.createElement("div");
		div.innerHTML = html;
		var textmessge = html;
		//---
		//console.log(data)
		//console.log(localizeKey(translations)('Win', this.state.lguages))
        return (
			<div className="home-list">
			{
				(userInfo!='' && userInfo!=null && userInfo!='null')?
				<div>
					<Row className="title-user-info">
						<Col md={2} sm={3} xs={3} className="pd0 fit-bl-2">
							<figure>
								<a href="javascript:void(0);" onClick={this.goProfile.bind(this,userInfo.id)}>
									<img src={(userInfo.imageurl!='' && userInfo.imageurl!=null && userInfo.imageurl!='null')?userInfo.imageurl:this.state.img_default} width="60" height="60"/>
								</a>
							</figure>
						</Col>
						<Col md={7} sm={6} xs={6} className="pd0">
							<h4 className="name" onClick={this.goProfile.bind(this,userInfo.id)}>{userInfo.tipster_name}</h4>
							<div>{localizeKey(translations)('Yield', this.state.lguages)}: {profitpercentage}</div>
						</Col>
						<Col md={3} sm={3} xs={3} className="pd0 txtR timetip fit-bl-3">{PickDatetimeSTR}</Col>
					</Row>
					<Row className="linect"></Row>
				</div>:''
			}
			{
				(data!='')?
					<div>							
						<Row className="tipct">
						{
							(betlist!='')?betlist.map((dt,idx)=>{
								
								return (
									<Row className="list-tp" key={idx}>
										<Col md={12} xs={12} sm={12} className="tip-name">{dt.hometeam_name} - {dt.awayteam_name}</Col>
										<Col md={12} xs={12} sm={12} className="tiso">
											<span>{dt.tip_desc}</span>
											<button className="btn-gr">{parseInt(dt.amount)}/10</button>
											<button className="btn-ora">
											{
												(dt.home_goal!=null && dt.home_goal!='null')?
												<span>{dt.home_goal} - {dt.away_goal}</span>:
												<span> ? </span>
											}
											</button>
											
											{	//<span className="status won" title="Won">Win</span>
												(dt.bet_status=='WIN')?
												<span className="status won" title="Won">
													{localizeKey(translations)('Win', this.state.lguages)}
												</span>:(dt.bet_status=='DRAW')?
												<span className="status draw" title="Draw">
													{localizeKey(translations)('Draw', this.state.lguages)}
												</span>:(dt.bet_status=='WAIT')?
												<span className="status pending" title="Pending">
													{localizeKey(translations)('wait', this.state.lguages)}
												</span>:(dt.bet_status=='LOST')?
												<span className="status lost" title="Lost">
													{localizeKey(translations)('lost', this.state.lguages)}
												</span>:''
											}
										</Col>
										<Col md={12} xs={12} sm={12} className="time-tip">
											<img src={dt.sporttip_imageurl} style={{'display':'none'}}/>
											{
												(dt.sportid==1)?<i className="fas fa-basketball-ball icon-sport" aria-hidden="true"></i>:(dt.sportid==2)?<i className="far fa-futbol icon-sport" aria-hidden="true"></i>:<i className="fas fa-baseball-ball icon-sport" aria-hidden="true"></i>
											}
											<span className="txt-tm-tp">{dt.league_name} - {dt.start_date} {dt.start_time}</span>
										</Col>
									</Row>
								)
							}):''
						}
						</Row>
						
						<Row className="descp-tp">
							{
								//data.PickComment
								ReactHtmlParser(textmessge)
							}
						</Row>
						<Row className="linect"></Row>
						<div className="commet-tp">
							
							{//====comment====
							<Comment 
								TotalComments={data.TotalComments} 
								imageurl={this.state.imageurl} 
								PickCode={data.PickCode}
								//PickCode={this.state.pickcode_do}
								id_user={this.state.id_user}
								PickLikedNum={data.PickLikedNum}
								PickLikedUserList={data.PickLikedUserList}
								PickLikedUserIDList={data.PickLikedUserIDList}
								PickCodeRunning={data.PickCodeRunning}
								PickCodeComingNumber={data.PickCodeComingNumber}
								PickCodeComingUsers={data.PickCodeComingUsers}
								parent_id_cm={this.state.parent_id}
								userInfoId={userInfo.id}
								callModalError={this.callModalError}
								/>
							
							//====end comment
							}
							
							
						</div>
						<Row className="line-ct" style={{'display':'none'}}></Row>
					</div>:''
			}
			<ModalErorr isOpen={this.state.isOpenModal} close={this.close} text={this.state.msgLogin}/>
			</div>
        );
    }
}

//export default connect(mapStateToProps, mapDispatchToProps)(Home)
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Home));