import React, {Component} from 'react'
import { connect } from 'react-redux'
import { Cookies } from 'react-cookie' 
import '../../styles/login.css'

import {Col, Row,FormControl, Form, FormGroup, ControlLabel, Button} from 'react-bootstrap'
import { createElementsFromText } from 'html-text-to-react';
import ReactHtmlParser from 'react-html-parser';
import $ from 'jquery';
import {TOKEN_NAME, REDIRECT,HOME_PAGE_LOADED, APP_LOAD,LANGUAGE_KEY} from '../../constants/actionTypes'
import agent from '../../agent'
import { localize, localizeKey,setLocale, getLocale } from 'react-redux-localization';
//import languages from '../languages'
import menu from '../common/menu.json'
import header from '../common/header.json'
import GoogleLogin from 'react-google-login';
import { Link } from 'react-router-dom'  
import ModalErorr from '../common/ModalErorr.js'

const mapStateToProps = state => (
    Object.assign({}, state, {
		tab: state.common.tab,
		updateProps: state.common.updateProps,
		locale: getLocale(state),
		languageKey: state.common.languageKey
	}) 

);

const mapDispatchToProps = dispatch => ({               
	onLoad: (data) => {
		dispatch({ type: 'HOME_PAGE_LOADED', data})  
	},
	chooseLocale: locale => dispatch(setLocale(locale))
});



 


class Home extends Component{ 	
	constructor(){
		super()
		this.state = {
			lguages:'1005',isOpenModal:false,msgLogin:'',
			
		}
		//---
		this.signinG = this.signinG.bind(this);
		this.login = this.login.bind(this);
		//---
		this.updateField=feild=>ev=>{
			const state = this.state;
			const newState = Object.assign({}, state, { [feild]: ev.target.value });
			this.setState(newState);
		}
		this.close=()=>{
			this.setState({isOpenModal:false})
		}
		//---
		this.handleLogin=(e)=>{
			if (e.key == 'Enter') {
				this.login()
			}
		}
	}
	
	componentWillMount(){	
		//-----
		//-----
	}
	
	componentWillReceiveProps(nextProps){
		//console.log(nextProps)
		if(nextProps.languageKey!='' && nextProps.languageKey!='null' && nextProps.languageKey!=null){
			this.setState({lguages:nextProps.languageKey})
		}
	}
	
	shouldComponentUpdate(nextProps, nextState){
		return nextState;
	}	
	signinG(res) {
		let postData;
		//console.log(res)
		/*if (!res.error && res.w3.U3) {
		    postData = {
			    name: res.w3.ig,
			    email: res.w3.U3,
			    provider: 'google',
			    provider_id: res.El,
			    provider_token: res.Zi.access_token,
			    provider_pic: res.w3.Paa
		    };
		}*/
		
		if (!res.error) {
		    postData = {
			    name: res.profileObj.name,
			    email: res.profileObj.email,
			    provider: 'google',
			    provider_id: res.profileObj.googleId,
			   // provider_token: res.tc.access_token,
			    provider_token: res.accessToken,
			    provider_pic: res.Ca
		    };
		}

		if (postData) {
            //console.log(postData);
			agent.auth.gsignin(postData).then(data => {
                //console.log(data);                
				if(data.result==='good'){
					window.localStorage.setItem(TOKEN_NAME, JSON.stringify(data));
					let UserInfo=data.UserInfo;
					window.location.href = 'home'
				} else{
					window.localStorage.setItem(TOKEN_NAME, '');
					//this.setState({showlogout:false}) 
					this.setState({showlogout:false,msgLogin:data.message,isOpenModal:true}) 
				}
			})
		} else {

        }
		
    }
	login(){
		let param={'user_name':this.state.user_name,'password':this.state.password}
		agent.auth.doLogin(param).then(data => {
			if(data.result==='good'){
				window.localStorage.setItem( TOKEN_NAME, JSON.stringify(data) );
				let UserInfo=data.UserInfo;				
				window.location.href = 'home'
			}else{
				window.localStorage.setItem( TOKEN_NAME, '');
				//$(".login-error").html(data.message)
				setTimeout(() => {
				 $(".login-error").html('')				 
				}, 2000);
				this.setState({showlogout:false,msgLogin:data.message,isOpenModal:true}) 
			}
		})
		//
	}
	render(){   
		const responseGoogle = (response) => {		    
			try{
				//console.log(response);
				this.signinG(response);
			}catch(e){
			}
		}
		//---
        return (
			<div id="body">   
				<Row className="mg0">
					<Col md={3} xs={12} sm={12}></Col>
					<Col md={6} xs={12} sm={12}>
						<div className="box-login">
							<h1>
								<span className="txt">{localizeKey(menu)('login', this.state.lguages)}</span>
							</h1>
							<div className="content">   
								<div className="input-group">
									<span className="input-group-addon">
										<i className="fa fa-user"></i>
									</span>
									<input className="form-control" placeholder={localizeKey(header)('email', this.state.lguages)} type="text" id="user_name" onChange={this.updateField('user_name')}/>
								</div>
								<div className="input-group">
									<span className="input-group-addon">
										<i className="fa fa-key"></i>
									</span>
									<input className="form-control" placeholder={localizeKey(header)('password', this.state.lguages)} type="password" name="pass_word" id="pass_word" onChange={this.updateField('password')} onKeyPress={this.handleLogin}/>
								</div>
								<div className="input-group">
									<div className="checkbox">
										<input type="checkbox" id="signin_remember" name="signin_remember"/>
										<span>{localizeKey(menu)('rememnerMe', this.state.lguages)}</span>
									</div>
								</div>
								<div className="Row">
									<button id="btn_login" className="btn btn-sm" onClick={this.login}>{localizeKey(menu)('login', this.state.lguages)}</button>
								</div>
								<div className="Row" style={{'textAlign':'center','marginTop':'5px'}}>
									<Link to="/forgot-password">{localizeKey(menu)('forgotpass', this.state.lguages)}</Link>
								</div>
								<div className="Row">
									<div className="externall-login">
										
										<div className="item-ex">										
											<a className="login-google">
												<span className="fa fa-face">g<b>+</b> </span>
												<GoogleLogin
													clientId="297127343422-18fj0lh0i70ep2du87k7sa211bldql3f.apps.googleusercontent.com"
													buttonText={localizeKey(menu)('lgGg', this.state.lguages)}
													onSuccess={responseGoogle}
													onFailure={responseGoogle}
												/>
											</a>
										</div>
									</div>
								</div>
								
								<div className="Row">
									<Link to="/register"><button id="btn_sign" className="btn btn-sm">{localizeKey(header)('sigin', this.state.lguages)}</button></Link>
								</div>
								<div className="Row">
									<Link to="/contact"><button id="btn_contact" className="btn btn-sm">{localizeKey(menu)('contact', this.state.lguages)}</button></Link>
								</div>
								
								<div className="Row">
									<span className="login-error"></span>
									<ModalErorr isOpen={this.state.isOpenModal} close={this.close} text={this.state.msgLogin}/>
								</div>
								
							</div>
						</div>
					</Col>
					
				</Row>
				
			</div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Home)