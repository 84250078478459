import React, { Component } from "react";
import { MDBCol, MDBFormInline, MDBIcon } from "mdbreact";
import agent from '../../agent'
import $ from 'jquery';
import { connect } from 'react-redux'
import { withRouter, history} from 'react-router-dom'
import { SEARCH_KEY,TOKEN_NAME } from '../../constants/actionTypes'

const mapDispatchToProps = dispatch => ({               
	onLoad: (data) => 
		dispatch({ type: SEARCH_KEY, data}) 
});	

class SearchPage extends Component {
	constructor(){
		super()
		this.state = {
			listLookup:[],search:''
		}
		this.doSearchHome=e=>{
			//console.log(e.target.value)
			this.tipLookup();
			if (e.key == 'Enter') {
				this.searchHome()
			}else{
				
			}
		}
		this.handleSearch=e=>{
			//this.tipLookup(e.target.value);
			this.setState({search:e.target.value})
		}
		this.handleFocus=e=>{
			//console.log(e.target.value)
		}
		this.tipLookup=this.tipLookup.bind(this)
		this.searchHome=this.searchHome.bind(this)
	}
	getValueOfSelectOne = value => {
	  console.log(value);
	}
	tipLookup(){
			let search=$(".txt-search").val();
			//console.log(search)
			agent.home.tipLookup({search:search}).then(data => {
				//console.log(data)
				if(data.result === 'good'){
					this.setState({listLookup:data.list})
				}else{
					
				}
			})
		}
	searchHome(){
		let search=$(".search-value").val();
		//window.localStorage.setItem('searchPR',search )
		this.props.onLoad(search)
		this.props.history.push("/home")
		
	}
	onSubmit (event) {
	  event.preventDefault();

	  // custom form handling here
	}
render() {
  let listLookup=this.state.listLookup
 
  return (
       <MDBCol className="pd0">
		  <MDBFormInline className="md-form" onSubmit={this.onSubmit}>
			<MDBIcon icon="search" onClick={this.searchHome} id="ic-search"/>
			<input className="form-control form-control-sm ml-3 txt-search search-value"
				   type="text" placeholder="Search" list="browsers" 
				   onKeyPress={this.doSearchHome}
				   onChange={this.handleSearch}
				   style={{'width':'100%'}}
				   />
			<datalist id="browsers" open="open">
				{
					(listLookup!='' && listLookup!='null'&& listLookup!=null)?listLookup.map((data,idx)=>{
						//return (<option id="" value={data} label="" key={idx}></option>)
						return (<option value={data} key={idx} />)
					}):''
				}				
			</datalist>
			
		  </MDBFormInline>
		</MDBCol>
    );
  }
}
//export default SearchPage;
export default withRouter(connect(null,mapDispatchToProps)(SearchPage));