import React, {Component} from 'react'
import { connect } from 'react-redux'
import { setLocale, getLocale, localizeKey } from 'react-redux-localization';
import { Cookies } from 'react-cookie' 
import '../../styles/App.css'
import '../../styles/home.css'
import {Col, Row,FormControl, Form, FormGroup, ControlLabel, Button} from 'react-bootstrap'

import { createElementsFromText } from 'html-text-to-react';
import ReactHtmlParser from 'react-html-parser';
import $ from 'jquery';
import {TOKEN_NAME, REDIRECT,HOME_PAGE_LOADED, APP_LOAD,LANGUAGE_KEY} from '../../constants/actionTypes'
import agent from '../../agent'
import { Link,withRouter, history} from 'react-router-dom'
import NumericInput from 'react-numeric-input';
import menu from '../common/menu.json'
import Lqhome from '../common/home.json'

const mapStateToProps = state => (
    Object.assign({}, state, {
		tab: state.common.tab,
		updateProps: state.common.updateProps,
		locale: getLocale(state),
		languageKey: state.common.languageKey
	}) 

);

const mapDispatchToProps = dispatch => ({               
	onLoad: (data) => {
		dispatch({ type: 'HOME_PAGE_LOADED', data})  
	},
	chooseLocale: locale => dispatch(setLocale(locale))
});



 


class Tips extends Component{ 	
	constructor(){
		super()
		this.state = {
			lguages:'1005',
			qcinfo:{},NUMTIP:'',TITLETIP:'',stake:5,sport_tips:2,bet_type:'',odd_id:'',
			odd_info:{},randomad:{},tiplist:[],waittocreatebetlist:[],isOpenModal:false,
			url_befor:'',isLoading:false,is_charged_tip:0
		}
		//---
		this.getrandomad=()=>{
			let lang_id=this.state.lguages
			let param={lang_id:lang_id}
			agent.home.getrandomad2(param).then(data => {
				//console.log(data)
				if(data.result === 'good'){
					this.setState({qcinfo:data.info})
				}
			})
		}
		//---
		this.deletebet=this.deletebet.bind(this)
		this.createtips=this.createtips.bind(this)
		this.goAdView=this.goAdView.bind(this)
		//---
	}
	goAdView(ev){
		let id=$(ev.target).attr("dataid")
		/*let title=$(ev.target).attr("title")
		window.localStorage.setItem( 'ad_user_id', id);
		window.localStorage.setItem( 'ad_title', title);
		//console.log(id,title)
		window.location.href='/ad-list'*/
		window.location.href='/new-info/'+id	
	}
	componentWillMount(){	
		//-----
		
		let idLanguage=window.localStorage.getItem(LANGUAGE_KEY)
		if(idLanguage!='' && idLanguage!='null' && idLanguage!=null){
			this.setState({lguages:idLanguage},()=>{
				this.getrandomad();
			})
		}
		
		//-----
		let TITLETIP=window.localStorage.getItem('TITLETIP');
		let NUMTIP=window.localStorage.getItem('NUMTIP');
		if(TITLETIP!=null && TITLETIP!='null' && TITLETIP!=''){
			this.setState({TITLETIP:TITLETIP,NUMTIP:NUMTIP})
		}
		//-----
		let oddVal=window.localStorage.getItem("ODDPARAMS")
		if(oddVal!='' && oddVal!=null){
			const odd = JSON.parse(oddVal)
			this.setState({odd_id:odd.odd_id,bet_type:odd.bet_type,sport_tips:odd.sport_tips},()=>{
				this.infotobet();
			})
		}
		
		//-----
		let urlbf=window.localStorage.getItem("url_befor")
		if(urlbf!='' && urlbf!=null){
			this.setState({url_befor:urlbf})
		}
		//-----
	}
	componentDidMount(){
		this.getrandomad();
	}
	componentWillReceiveProps(nextProps){
		//console.log(nextProps)
		if(nextProps.languageKey && nextProps.languageKey!='' && nextProps.languageKey!=null && nextProps.languageKey!='null'){
			this.setState({lguages:nextProps.languageKey},()=>{
				this.getrandomad()
			});
			
		}
	}
	
	shouldComponentUpdate(nextProps, nextState){
		return nextState;
	}	
	infotobet(){
		let sport_tips=this.state.sport_tips
		let bet_type=(this.state.bet_type>0)?this.state.bet_type:"0"
		let odd_id=this.state.odd_id
		
		agent.tips.infotobet({odd_id:odd_id,bet_type:bet_type,sport_tips:sport_tips}).then(data => {
			//console.log(data)
			if(data.result === 'good'){
				const odd_info=data.odd_info
				const randomad=data.randomad
				const tiplist=data.tiplist
				const waittocreatebetlist=data.waittocreatebetlist
				this.setState({odd_info:odd_info,randomad:randomad,tiplist:tiplist,waittocreatebetlist:waittocreatebetlist})
			}
		})
	}
	handleChangTextNum(ev){
		let val=ev 
		if(val==10){
			this.setState({is_maxbet:1,stake:val})
		}else{
			this.setState({is_maxbet:0,stake:val})
		}
	}
	handleChangeField(field,ev){
		//console.log(field,ev)
		let val=''
		if(field=='is_maxbet'){
			val=(ev.target.checked)?1:0	
			if(ev.target.checked){
				this.setState({stake:10})
			}else{
				this.setState({stake:5})
			}
		}else if(field=="comment"){
			val=ev.target.value
		}else if(field=="stake"){
			val=ev
			if(val==10){
				this.setState({is_maxbet:1})
			}else{
				this.setState({is_maxbet:0})
			}
		}else if(field=="running"){
			val=(ev.target.checked)?1:0	
			
			
		}
		this.setState({[field]:val})
		//console.log(this.state.stake)
	}
	deletebet(ev){
		//console.log(ev.target.closest(".remove"))
		let thiss=ev.target.closest(".remove")
		//$(thiss).find(".remove-tip").hide();
		//$(thiss).find(".loadern").show();
		let bet_id=$(thiss).attr("dataid")
		agent.tips.deletebet({bet_id:bet_id}).then(data => {
			//console.log(data)
			if(data.result == 'good'){
				//this.setState({isOpenModal:false,msgLogin:''})
				this.infotobet();
				//$(".remove-tip").show();
			}else{
				//this.setState({isOpenModal:true,msgLogin:data.message})
				$(".message-error").html(data.message)
			}
			
		})
	}
	createtips(){
		this.setState({isLoading: true});
		let betids=''
		$("#mybet-list").find(".remove").each(function(){
			//console.log($(this).attr("dataid"))
			betids=betids+$(this).attr("dataid")+"|"
		})
		betids=betids.substring(0,betids.length-1)
		let comment=this.state.comment
		let running=this.state.running
		const sport_tips=this.state.sport_tips
		//console.log(running)
		agent.tips.createtips({betids:betids,comment:comment,running:running,sport_tips:sport_tips}).then(data => {
			//console.log(data)
			if(data.result == 'good'){
				$("#tipster-box").hide();
				this.setState({isOpenModal:false,msgLogin:'',waittocreatebetlist:data.waittocreatebetlist,randomad:data.randomad,betlist:data.betlist,tiplist:data.tiplist})
				this.setState({isLoading: false});
			}else{
				//this.setState({isOpenModal:true,msgLogin:data.message})
				$(".message-error").html(data.message)
			}
		})
	}
	showcomment(ev){
		
		let count=parseInt(this.state.count)+1;	
		this.setState({count:count})
		let thiss=ev.target.closest(".comments")
		$(thiss).find(".note-comment").show();
		$(thiss).find(".fa-power-off").show();
		$(thiss).find(".fa-list-alt").hide();
	}
	hidecomment(ev){
		let thiss=ev.target.closest(".comments")
		$(thiss).find(".note-comment").hide();
		$(thiss).find(".fa-power-off").hide();
		$(thiss).find(".fa-list-alt").show();
		
	}
	choosetobet(ev){
		let thiss=ev.target.closest(".tipster-box")
		let odd_id=$(thiss).find('.match-name').attr("dataid")
		let bet_type=$(thiss).find('.match-name').attr("type")
		let amount=this.state.stake
		let is_maxbet=this.state.is_maxbet
		let comment=this.state.comment
		const sport_tips=this.state.sport_tips
		let is_charged_tip=(this.state.is_charged_tip>0)?this.state.is_charged_tip:'0'
		agent.tips.choosetobet({odd_id:odd_id,bet_type:bet_type,amount:amount,is_maxbet:is_maxbet,
						        comment:comment,sport_tips:sport_tips,is_charged_tip:is_charged_tip}).then(data => {
			//console.log(data)
			if(data.result == 'good'){
				$("#tipster-box").hide();
				this.setState({isOpenModal:false,msgLogin:'',waittocreatebetlist:data.waittocreatebetlist,randomad:data.randomad})
			}else{
				//this.setState({isOpenModal:true,msgLogin:data.message})
				$(".message-error").html(data.message)
			}
		})
	}
	closepet(ev){
		//$("#tipster-box").hide();
		window.location.href="./"+this.state.url_befor;
	}
	goBackPage(e){
		window.location.href="./add-tip";
	}
	changeOption(sport_tips,e){
		//console.log(sport_tips)
		$(".tool-ball").removeClass("active")
		window.localStorage.setItem('SPORT', sport_tips);
		$(e.target).closest(".tool-ball").addClass("active")
		this.props.history.push("/add-tip")
	}
	noEnterkey(e){
		 e.preventDefault();
		 return false;
	}
	changeOption1(){
	}
	handleCharged(type,e){
		//console.log(type,e)
		this.setState({is_charged_tip:type},()=>{
			
		})
	}
	render(){   
		//---
		const qcinfo=this.state.qcinfo;
		let title=''
		let imageurl=''
		let url=''
		let description=''
		if(qcinfo!='' && qcinfo!=null && qcinfo!='null'){
			 title=qcinfo.ad_title?qcinfo.ad_title:'';
			 imageurl=qcinfo.ad_logo_url?qcinfo.ad_logo_url:'https://back-end.coopbet.com/gf/ywqBBMhT0U0022_no-logo.png';
			 url=qcinfo.ad_url?qcinfo.ad_url:'';
			 description=qcinfo.ad_description?qcinfo.ad_description:'';
			 description=(description.length<=85)?description:description.substr(0,85)+'...'
		}	
		//---		
		const odd_info=this.state.odd_info
		const randomad=this.state.randomad
		const tiplist=this.state.tiplist
		const waittocreatebetlist=this.state.waittocreatebetlist
		let payout=0
		if(odd_info.odds>0){
			payout=(parseFloat(this.state.stake))*parseFloat(odd_info.odds)
		}else{
			payout= (parseInt(this.state.stake))*1
		}
		payout= Math.round(payout* 100) / 100;
		//---
		//console.log(this.state.qcinfo)
		let isLoading=this.state.isLoading
        return (
			<div id="body"> 
			{
				(qcinfo && qcinfo!=null && qcinfo!='')?
				<Row className="randomad">
					<Col md={4} xs={4} sm={4} className="pd0">
						<div className="img-adv">
							<a href={url} target="_blank" className="rand-logo">
								<img alt="m88" src={imageurl} width="100" height="50"/>
							</a>
						</div>
					</Col>
					<Col md={8} xs={8} sm={8} className="pd0">
						<div className="desc-adv">									
						{
							ReactHtmlParser(description)
						}
							<span>
							{
								(qcinfo!='' && qcinfo!=null)?
								<a onClick={this.goAdView.bind(this)} dataid={qcinfo.id} title={title} className="v-more">{localizeKey(Lqhome)('viewmore', this.state.lguages)}</a>:''
							}
							</span>
						</div>
					</Col>
				</Row>:''
			}	
				<Row className="mrgt7 mgbt3 mrgLR1">
					<Col md={4} xs={4} sm={4} className={(this.state.sport_tips==2)?"tool-ball active":"tool-ball"} onClick={this.changeOption.bind(this,2)}>
						<i className="far fa-futbol icon-tool" ></i>
						<div className="tool-ball-title">{localizeKey(menu)('soccer', this.state.lguages)}</div>						
					</Col>
					<Col md={4} xs={4} sm={4} className={(this.state.sport_tips==1)?"tool-ball active":"tool-ball"} onClick={this.changeOption.bind(this,1)}>
						<i className="fas fa-basketball-ball icon-tool"></i>
						<div className="tool-ball-title">{localizeKey(menu)('basketball', this.state.lguages)}</div>
					</Col>
					<Col md={4} xs={4} sm={4} className="tool-ball" className={(this.state.sport_tips==3)?"tool-ball active":"tool-ball"} onClick={this.changeOption1.bind(this,3)}>
						<i className="fas fa-baseball-ball icon-tool"></i>
						<div className="tool-ball-title">{localizeKey(menu)('tennis', this.state.lguages)}</div>
					</Col>
				</Row>
				<Row className="line-ct"></Row>
				
				<Row className="mrgt7 mgbt3 tipster-box" id="tipster-box">
					<Row className="mg0 width100 mrgLR1 mrgbt5">
						<Col md={12} className="title-chose-tip">{odd_info.hometeam_name} - {odd_info.awayteam_name}</Col>
					</Row>
					<Row className="mg0 mrgt7 width100 mrgLR1 div-chose">
						<Col md={12} className="mgbt3 match-name"  dataid={odd_info.odd_id} type={odd_info.bet_type}>{odd_info.odd_name}</Col>
						<Col md={12} className="mgbt3">
							<span className="tips-type">
								<span className="nameH">{odd_info.tipDesc1}</span> @ 
								<strong className="odd kcl">{odd_info.tipDesc2}</strong>
							</span>
						</Col>
						<Row className="mg0 mgbt3">
							<Col md={2} xs={2} sm={2}><span className="lhei35">{localizeKey(menu)('stake', this.state.lguages)}:</span></Col>
							<Col md={4} xs={4} sm={4}>
								<FormGroup className="mrgbt0">									
									<NumericInput min={1} max={10} value={this.state.stake} 
										className="state-tips" onChange={this.handleChangTextNum.bind(this)}
										//onKeyDown={this.noEnterkey.bind(this)}
										onKeyDown={this.noEnterkey.bind(this)}
										
									/>
									
								</FormGroup>
							</Col>
							<Col md={2} xs={2} sm={2}><span className="lhei35">Payout:</span></Col>
							<Col md={4} md={4} xs={4} sm={4}>
								<FormGroup className="mrgbt0">									
									<FormControl className="ip-tips rt" type="text" value={payout} onChange={this.handleChangeField.bind(this,'payout')}/>
								</FormGroup>
							</Col>
						</Row>
						<Col md={12} className="mgbt3">
							<Form.Check 
								custom
								type="checkbox"
								id={`custom-checkbox`}
								label={`Max Bet (10)`}
								onChange={this.handleChangeField.bind(this,'is_maxbet')}
								checked={(this.state.is_maxbet==1)?true:false}
							  />
						</Col>
						<Col md={12} className="bgct">
							<Form.Check
							  custom
							  type="radio"
							  label="FREE TIPS"
							  name="formHorizontalRadios"
							  id="formHorizontalRadios1"
							  value={0} 
							  onChange={this.handleCharged.bind(this,0)}
							  checked={(this.state.is_charged_tip===0)?true:false}
							/>
							<Form.Check
							  custom
							  type="radio"
							  label="CHARGE TIPS (ONLY TIPTER)"
							  name="formHorizontalRadios"
							  id="formHorizontalRadios2"
							  style={{'marginLeft':'15px'}}
							  onChange={this.handleCharged.bind(this,1)}
							  checked={(this.state.is_charged_tip===1)?true:false}
							/>
						</Col>
						<Col md={12} className="dibtn">
							<a className="btn-blue" onClick={this.choosetobet.bind(this)}>{localizeKey(menu)('choose', this.state.lguages)}</a>
							<a className="btn-blue fl_r" onClick={this.closepet.bind(this)}>{localizeKey(menu)('close', this.state.lguages)}</a>
						</Col>
					</Row>
				</Row>
				
				<Row className="mrgt7 mgbt3 mrgLR1" id="mybet-list-box">
					<Row className="mg0 width100 mrgLR1">
						<Col md={12} xs={12} sm={12} className="message-error"></Col>
					</Row>
					<Row className="mg0 width100 mrgLR1 box-header">
						<Col md={12} xs={12} sm={12} className="title-chose-tip">
							<Row className="mg0">
								<Col md={6} sm={6} xs={6} className="pd0">{localizeKey(menu)('bestlist', this.state.lguages)}</Col>
								<Col md={6} sm={6} xs={6} className="pd0 txtr">
									<i className="fas fa-arrow-left icback" aria-hidden="true" onClick={this.goBackPage.bind(this)}></i>
								</Col>
							</Row>
						</Col>
					</Row>
					<Row className="mg0 width100 mrgLR1 box-content clear-fix">
						<Col md={12} id="mybet-list" className="pdlr10">
						{
							(waittocreatebetlist!='')?waittocreatebetlist.map((data,index)=>{
								let btype=data.tip_desc
								btype=btype.split("@")
								
								return(
									<div className="bet-item clear-fix" key={index}> 
										<div>
											<div className="match"> 
												<span className="match-name">{data.hometeam_name} - {data.awayteam_name}</span>
												<span className="bet-type">{btype[0]} @ <strong className="odd">{btype[1]}</strong></span>
											</div>
											<div className="bet-act">
												<span className="amount">{data.amount}</span>
												<span className="remove" dataid={data.bet_id} onClick={this.deletebet.bind(this)}>
													<i className="fa fa-times remove-tip" title="click to remove"></i>
													<div className="loadern" style={{'display':'none'}}></div>
												</span>
											</div>
										</div>
										{
											(data.comment!=" " && data.comment!='' && data.comment!=null)?
											<div className="comments">
												<i className="fa fa-list-alt" style={{'paddingLeft':'3px','cursor':'pointer'}} onClick={this.showcomment.bind(this)}></i>
												<div className="note-comment" style={{'display':'none'}}>{data.comment}</div>
											</div>:''
										}
										
									</div>
								)
							}):''
						}
							
						</Col>
						<div id="create_pick" className="create-pick">
						{
							(waittocreatebetlist!='')?
							<Row className="mg0">
								<Col md={12} className="pdlr10">
									<FormGroup>
										<FormControl as="textarea" maxLength={250} placeholder={localizeKey(menu)('txtdecript', this.state.lguages)} rows="5" onChange={this.handleChangeField.bind(this,'comment')} value={this.state.comment}/>
									</FormGroup>
								</Col>
								<Col md={12} className="pdlr10">
									<Form.Check 
										custom
										type="checkbox"
										id={`custom-checkbox1`}
										label={`Running`}
										onChange={this.handleChangeField.bind(this,'running')}
									/>
								</Col>
							</Row>:''
						}
							<Col md={12} className="pdlr10">
								{
									(randomad && randomad!=null && randomad!='')?
									<a href={randomad.url} target="_blank" className="rand-logo">
										<img alt={randomad.title} src={randomad.imageurl} width="80" height="30"/>
									</a>:''
								}
								{
									//(waittocreatebetlist!='')?<a className="btn" onClick={this.createtips} style={{'marginLeft':'10px'}}>{localizeKey(menu)('createtip', this.state.lguages)}</a>:''
									(waittocreatebetlist!='')?
									//<a className="btn" onClick={this.createtips} style={{'marginLeft':'10px'}}>{localizeKey(menu)('createtip', this.state.lguages)}</a>:''
									<Button
										id="btn-create"
										//bsStyle=""
										disabled={isLoading}
										onClick={!isLoading ? this.createtips : null}>
										{isLoading ? <span style={{'fontSize':'10px'}}><i className='fa fa-spinner fa-spin '></i> {localizeKey(menu)('createtip', this.state.lguages)} ...</span> :<span>{localizeKey(menu)('createtip', this.state.lguages)} </span>}
									 </Button>:''
								}
							</Col>
						</div>
						<Col md={12} className="pdlr10" id="mybet-list-success">
						{
							(tiplist)?tiplist.map((data,index)=>{
								let BetList=data.BetList
								return (
									<Row className="mrgLR1 clear-fix" key={index} id="tip-item"> 
										{
											(BetList!='')?BetList.map((dt,i)=>{
													let btype=dt.tip_desc
													btype=btype.split("@")
													//console.log(BetList.length-1,i%2)
													return(
														<Col md={12} className={((i%2)==1)?'bet-item clear-fix br-bt':'bet-item clear-fix'} key={i}> 
															<div>
																<div className="match"> 
																	<span className="match-name">{dt.hometeam_name} - {dt.awayteam_name}</span>
																	<span className="bet-type">{btype[0]} @ <strong className="odd">{btype[1]}</strong></span>
																</div>
																<span className="amount">{dt.amount}</span>
																	{//<span className="checked"></span>
																	}
															</div>
															
														</Col>
													)
											}):''
										}
										{
											(data.PickComment!=" " && data.PickComment!='' && data.PickComment!=null)?
											<div className="comments">
												<i className="fa fa-list-alt" style={{'paddingLeft':'3px','cursor':'pointer'}} onClick={this.showcomment.bind(this)}></i>
												<i className="fa fa-power-off" style={{'paddingLeft':'3px','cursor':'pointer','display':'none'}} onClick={this.hidecomment.bind(this)}></i>
												<div className="note-comment" style={{'display':'none'}}>{data.PickComment}</div>
											</div>:''
										}
									</Row>
								)
								
								
								
							}):''
						}
						</Col>
					
					
					
						
					</Row>
				</Row>
					
				
				
			</div>
        );
    }
}

//export default connect(mapStateToProps, mapDispatchToProps)(Tips)
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Tips));