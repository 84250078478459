import React, {Component} from 'react'
import { connect } from 'react-redux'
import { setLocale, getLocale, localizeKey } from 'react-redux-localization';
import { Cookies } from 'react-cookie' 
import '../../styles/App.css'
import '../../styles/home.css'
import {Col, Row,FormControl, Form, FormGroup, ControlLabel, Button, Tabs,Tab,Table,Radio} from 'react-bootstrap'
import { createElementsFromText } from 'html-text-to-react';
import ReactHtmlParser from 'react-html-parser';
import $ from 'jquery';
import {TOKEN_NAME, REDIRECT,HOME_PAGE_LOADED, APP_LOAD,LANGUAGE_KEY} from '../../constants/actionTypes'
import agent from '../../agent'
import menu from '../common/menu.json'
import Lqhome from '../common/home.json'
import ModalErorr from '../common/ModalErorr'
import DatePicker from 'react-datepicker';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

const mapStateToProps = state => (
    Object.assign({}, state, {
		tab: state.common.tab,
		updateProps: state.common.updateProps,
		locale: getLocale(state),
		languageKey: state.common.languageKey
	}) 

);

const mapDispatchToProps = dispatch => ({               
	onLoad: (data) => {
		dispatch({ type: 'HOME_PAGE_LOADED', data})  
	},
	chooseLocale: locale => dispatch(setLocale(locale))
});



 


class TipsManagement extends Component{ 	
	constructor(){
		super()
		this.state = {
			lguages:'1005',
			qcinfo:{},idUser:'',
			startDate:new Date(),price:0,seller_open_order_list:[],
			order_id:'',seller_paid_order_list:[],keyTab:'key1',isOpenModal:false,msg:''
		}
		//---
		this.getrandomad=()=>{
			let lang_id=this.state.lguages
			let param={lang_id:lang_id}
			agent.home.getrandomad2(param).then(data => {
				//console.log(data)
				if(data.result === 'good'){
					this.setState({qcinfo:data.info})
				}
			})
		}
		//---
		this.goAdView=this.goAdView.bind(this)
		//---
		this.close=()=>{
			this.setState({isOpenModal:false})
		}
		
		this.sellerapprove=(order_id)=>{
			agent.orders.sellerapprove({order_id:order_id}).then(data => {
				//console.log(data)
				if(data.result === 'good'){
					this.setState({keyTab:'key2'},()=>{
						this.getListForSellerAgain();
					})
					
				}
			})
			//this.setState({keyTab:'key2'})
			//$("#tab-buy-tab-key2").click();
		}
		this.getListForSellerAgain=()=>{
			let month = this.state.month
			agent.orders.getListForSeller({month:month}).then(data => {
				//console.log(data)
				if(data.result === 'good'){
					let PriceTips=data.PriceTips
					let seller_open_order_list=data.seller_open_order_list
					let seller_paid_order_list=data.seller_paid_order_list
					this.setState({seller_open_order_list:seller_open_order_list,
								   seller_paid_order_list:seller_paid_order_list,
								   price:PriceTips
								  })
				}
			})
		}
		this.getListForSeller=()=>{
			let month = this.state.month
			agent.orders.getListForSeller({month:month}).then(data => {
				//console.log(data)
				if(data.result === 'good'){
					let PriceTips=data.PriceTips
					let seller_open_order_list=data.seller_open_order_list
					let seller_paid_order_list=data.seller_paid_order_list
					this.setState({seller_open_order_list:seller_open_order_list,
								   seller_paid_order_list:seller_paid_order_list,
								   price:PriceTips
								   })
				}
			})
		}
		this.priceMonthSave=()=>{
			let price=this.state.price
			let date=new Date();
			let mt=date.getMonth()+1;
			let year=date.getFullYear();
			let month=mt+"/"+year;
			agent.home.priceMonthSave({price:price,month :month }).then(data => {
			//console.log(data)
				if(data.result === 'good'){
					
				}else{
					this.setState({isOpenModal:true,msg:data.message})
				}
			})
		}
		this.vieImg=(id,e) => {
			
			let letms=this.state.seller_open_order_list
			letms.map(it=>{
				if(it.order_id===id){
					it.isOpenIM=true
				}
				return it;
			})
			
			//console.log(letms)
			this.setState({seller_open_order_list:letms})
			
		}
		this.hideImg=(id,e) => {
			
			let letms=this.state.seller_open_order_list
			letms.map(it=>{
				if(it.order_id===id){
					it.isOpenIM=false
				}
				return it;
			})
			
			//console.log(letms)
			this.setState({seller_open_order_list:letms})
			
		}
		this.vieImgP=(id,e) => {
			
			let letms=this.state.seller_paid_order_list
			letms.map(it=>{
				if(it.order_id===id){
					it.isOpenIM=true
				}
				return it;
			})
			
			//console.log(letms)
			this.setState({seller_paid_order_list:letms})
			
		}
		this.hideImgP=(id,e) => {
			
			let letms=this.state.seller_paid_order_list
			letms.map(it=>{
				if(it.order_id===id){
					it.isOpenIM=false
				}
				return it;
			})
			
			//console.log(letms)
			this.setState({seller_paid_order_list:letms})
			
		}
		
	}
	goAdView(ev){
		let id=$(ev.target).attr("dataid")
		/*let title=$(ev.target).attr("title")
		window.localStorage.setItem( 'ad_user_id', id);
		window.localStorage.setItem( 'ad_title', title);
		//console.log(id,title)
		window.location.href='/ad-list' */
		window.location.href='/new-info/'+id
	}
	componentWillMount(){	
		//-----
		
		let idLanguage=window.localStorage.getItem(LANGUAGE_KEY)
		if(idLanguage!='' && idLanguage!='null' && idLanguage!=null){
			this.setState({lguages:idLanguage},()=>{
				this.getrandomad();
			})
		}
		let login=window.localStorage.getItem(TOKEN_NAME);
		if(login!='' && login!='null' && login!=null){
			login=JSON.parse(login)
			let info=login.UserInfo
			//console.log(login.UserInfo)
			this.setState({idUser:info.id},()=>{
				//this.getbankrolls(info.id)
			})				
		}
		let dates=new Date();
		let mth=dates.getMonth()+1
		let year=dates.getFullYear()
		let month=mth+"/"+year
		this.setState({month:month},()=>{
			this.getListForSeller();
		})
		
		//-----
	}
	componentDidMount(){
		this.getrandomad();
	}
	componentWillReceiveProps(nextProps){
		//console.log(nextProps)
		if(nextProps.languageKey && nextProps.languageKey!='' && nextProps.languageKey!=null && nextProps.languageKey!='null'){
			this.setState({lguages:nextProps.languageKey},()=>{
				this.getrandomad()
			});
			
		}
	}
	
	shouldComponentUpdate(nextProps, nextState){
		return nextState;
	}	
	
	handleChangeDate(date){
		let dates=date.getDate();
		var month = date.getMonth() + 1;
		let year=date.getFullYear();
		let mth=month+"/"+year
		this.setState({startDate:date,month:mth,offsetM:0,selected:0},()=>{
			this.getListForSeller();
		})
	}
	handleChangeFiled(e){
		let val=e.target.value.split(',').join('');	
		//console.log(e.target.value)
		this.setState({price: val})
	}
	numberFormat(x) {
		return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
	}
	render(){   
		//---
		const qcinfo=this.state.qcinfo;
		let title=''
		let imageurl=''
		let url=''
		let description=''
		if(qcinfo!='' && qcinfo!=null && qcinfo!='null'){
			 title=qcinfo.ad_title?qcinfo.ad_title:'';
			 imageurl=qcinfo.ad_logo_url?qcinfo.ad_logo_url:'https://back-end.coopbet.com/gf/ywqBBMhT0U0022_no-logo.png';
			 url=qcinfo.ad_url?qcinfo.ad_url:'';
			 description=qcinfo.ad_description?qcinfo.ad_description:'';
			 description=(description.length<=85)?description:description.substr(0,85)+'...'
		}	
	
		//---
		const listOpen=this.state.seller_open_order_list
		const listPaid=this.state.seller_paid_order_list
		//console.log(this.state.startDate)
        return (
			<div id="body"> 
			{
				(qcinfo && qcinfo!=null && qcinfo!='')?
				<Row className="randomad">
					<Col md={4} xs={4} sm={4} className="pd0">
						<div className="img-adv">
							<a href={url} target="_blank" className="rand-logo">
								<img alt="m88" src={imageurl} width="100" height="50"/>
							</a>
						</div>
					</Col>
					<Col md={8} xs={8} sm={8} className="pd0">
						<div className="desc-adv">									
						{
							ReactHtmlParser(description)
						}
							<span>
							{
								(qcinfo!='' && qcinfo!=null)?
								<a onClick={this.goAdView.bind(this)} dataid={qcinfo.id} title={title} className="v-more">{localizeKey(Lqhome)('viewmore', this.state.lguages)}</a>:''
							}
							</span>
						</div>
					</Col>
				</Row>:''
			}
				<Row className="mg0 mrgt7 mgbt7">
					
					<Col md={7} xs={7} sm={7}>
						<i className="far fa-list-alt " style={{'fontSize':'20px','verticalAlign':'top'}}></i>
						<div className="rigister-title" style={{'textTransform':'uppercase'}}>TIPS MANAGEMENT</div>
					</Col>
					<Col md={5} xs={5} sm={5} className="rank-mth date-tips">
						<i className="fa fa-caret-down icon-carets"></i>
						<DatePicker
						  selected={this.state.startDate}
						  onChange={this.handleChangeDate.bind(this)}
						  dateFormat="MM/yyyy"
						  showMonthYearPicker
						  
						/>
						
					</Col>
									
				</Row>
				<Row className="line-ct" style={{'marginBottom':'10px'}}></Row>
				<Row className="mg0" style={{'float':'right','marginBottom':'-30px'}}>
					<div className="left-ft-admth ct-ft-charge fit-idex" >
						<input id="price_mth" name="price" type="text" className="form-control" value={this.numberFormat(this.state.price)} onChange={this.handleChangeFiled.bind(this)} />
						<span className="price-charge">$/MONTH</span>
						<span className="sym-charge"></span>
						<i className="fas fa-shopping-cart"></i>
					</div>
					<input type="button" className="btn-save-tp fit-idex" value="SAVE" onClick={this.priceMonthSave.bind(this)}/>
				</Row>
				<Row className="mg0 " id="div-tips" style={{'marginTop':'-3px'}}>
					<Tabs defaultActiveKey="key1" id="tab-tips">
						<Tab eventKey="key1" title="Open" >
							
							<Row className="mg1">
								<Col md={12} style={{'padding':'0'}}>
								<Table className="tbl-tipters" style={{'marginBottom':'0px'}}>
									<thead>
										<tr>
											<th colSpan="1" style={{'width':'10%'}}>No.</th>
											<th colSpan="1" style={{'width':'40%'}}>Mã đơn hàng</th>
											<th colSpan="1" style={{'width':'25%','textAlign':'center'}}>Invoice file</th>
											<th colSpan="1" style={{'width':'25%','textAlign':'center'}}>Approve</th>
										</tr>
									</thead>
									<tbody>
									{
										(listOpen!=='')?listOpen.map((dt,idx)=>{
											let orderDate=dt.order_datetime
											orderDate=orderDate.split(" ")
											let file=dt.proof_of_payment_url
											var res = (file!==null)?'...'+file.substring(file.length-12):'';
											return(
											<tr key={idx}>
												<td>0{idx+1}</td>
												<td className="lin-in"><a href={"/invoice-tips-info/"+dt.order_id} className="lnk-inv">{dt.order_number}</a></td>
												<td style={{'textAlign':'center','color':'#c90a0a'}}>													
													<a className="cusor" onClick={this.vieImg.bind(this,dt.order_id)} style={{'color':'#c90a0a'}}>{res}</a>
													{
														dt.isOpenIM && (
														  <Lightbox
															mainSrc={dt.proof_of_payment_url}
														   onCloseRequest={this.hideImg.bind(this,dt.order_id)}
															
														  />
													
														)
													}												
												</td>
												<td style={{'textAlign':'center'}}><input type="button" className="btn-search btn-approve" value="Approve" onClick={this.sellerapprove.bind(this,dt.order_id)}/></td>
											</tr>	
											)
										}):''
										
									}												
									</tbody>
								</Table>
								</Col>
								
							</Row>
							
						</Tab>
						<Tab eventKey="key2" title="Paid" >
							<Row className="mg1" >
								<Col md={12} className="pd0">
									<Table className="tbl-tipters" style={{'marginBottom':'0px'}}>
										<thead>
											<tr>
												<th colSpan="1" style={{'width':'10%'}}>No.</th>
												<th colSpan="1" style={{'width':'30%'}}>Mã đơn hàng</th>
												<th colSpan="1" style={{'width':'30%'}}>Khách hàng</th>	
												<th colSpan="1" style={{'width':'30%','textAlign':'center'}}>Invoice file</th>
												
											</tr>
										</thead>
										<tbody>
										{
											(listPaid!=='')?listPaid.map((dt,idx)=>{
												let file=dt.proof_of_payment_url
												var res = (file!==null)?'...'+file.substring(file.length-12):'';
												return(
												<tr key={idx}>
													<td>0{idx+1}</td>
													<td className="lin-in"><a href={"/invoice-tips-info/"+dt.order_id} className="lnk-inv">{dt.order_number}</a></td>
													<td>{dt.buyer_tipster_name}</td>
													<td style={{'textAlign':'center','color':'#c90a0a'}}>														
														<a className="cusor" onClick={this.vieImgP.bind(this,dt.order_id)} style={{'color':'#c90a0a'}}>{res}</a>
														{
															dt.isOpenIM && (
															  <Lightbox
																mainSrc={dt.proof_of_payment_url}
															   onCloseRequest={this.hideImgP.bind(this,dt.order_id)}
																
															  />
														
															)
														}	
													</td>
													
												</tr>	
												)
											}):''
										}												
										</tbody>
									</Table>
								</Col>
								
							</Row>
							
						</Tab>
						
					</Tabs>
				</Row>
				
				<ModalErorr isOpen={this.state.isOpenModal} close={this.close} text={this.state.msg}/>
				
			</div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TipsManagement)