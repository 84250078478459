import React from 'react';
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import agent from '../../agent'
import {Col, Row,FormControl, Form, FormGroup, ControlLabel, Button} from 'react-bootstrap'
import '../../styles/App.css'
import '../../styles/Friends.css'
import $ from 'jquery';
import {TOKEN_NAME, REDIRECT,LANGUAGE_KEY,CHANGE_TAB} from '../../constants/actionTypes'
import {withRouter, history} from 'react-router-dom'
import { localize, localizeKey,setLocale, getLocale } from 'react-redux-localization';
import { Cookies } from 'react-cookie' 
import translations from '../common/captionhome.json';

const mapStateToProps = state => (
    Object.assign({}, state, {
		paramsSave: state.home,
		token: state.common.token,
		tab: state.common.tab,
		languageKey: state.common.languageKey,
		languages:state.common.languageslist,
		//active: state.common.active,
		//user_fiend: state.common.user_fiend,
	})      
);

const mapDispatchToProps = dispatch => ({               
	
	onRedirect: () =>
		dispatch({ type: REDIRECT }),
	
	onTabClick: (tab) => 
		dispatch({ type: CHANGE_TAB, tab }),
	
});

class LikeList extends React.Component {
	constructor(){
		super()
		this.state = {
			url:'https://www.coopbet.com/media/user_none.jpg',
			list:[],isOpenModal:false,msgLogin:'',user_id:'',PickLikedNum:'',pick_code:'',url_back:'',
			lguages:'1005'
		}
		//---
		this.close=()=>{
			this.setState({isOpenModal:false})
		}
		//----
		
		//----
		
		
	}
	
	
	//---------------------------
    
	componentWillReceiveProps(nextProps) {
		//console.log(nextProps)
		if(nextProps.languageKey){
			this.setState({lguages:nextProps.languageKey});
		}
	}	

	componentWillMount() {
		//---
		const cookies = new Cookies()
		let language=(cookies.get('language')!='' && cookies.get('language') !=null && cookies.get('language')!== 'undefined')?cookies.get('language'):'1005';
		this.setState({'lguages':language})
		//----
		let login=window.localStorage.getItem(TOKEN_NAME);
		 
		if(login!='' && login!='null' && login!=null){
			 login=JSON.parse(login)
			 this.setState({id_user:login.UserInfo.id})
		}
		
		let pick_code=window.localStorage.getItem('pick_code') 
		let PickLikedNum=window.localStorage.getItem('PickLikedNum') 
		let url_back=window.localStorage.getItem('url_back') 
		let PickLikedUserIDList=window.localStorage.getItem('PickLikedUserIDList') 
		let list = JSON.parse(PickLikedUserIDList)
		//url_back=url_back.split('/').join('');
		url_back = url_back.substr(1, url_back.length-1);
		this.setState({list:list,PickLikedNum:PickLikedNum,pick_code:pick_code,url_back:url_back})
		//console.log(url_back)
		
	}	
	componentDidMount() {
		
	}
	
	
	showBoxFriend(ev){
		let $this=ev.target.closest(".g-btn")
		$($this).find(".dr-fr").show();
		//console.log($this)
	}
	hideBoxFriend(ev){
		let $this=ev.target.closest(".g-btn")
		$($this).find(".dr-fr").hide();
	}
	deleteFriend(ev){
		let $this=ev.target
		let id=$($this).attr("data-id")
		agent.friends.deleteFriend({friend_user_id:id}).then((datas) => {
			//console.log(datas)
			if(datas.result=='good'){
				let newList=this.state.list.map(item=>{
					if(item!='' && item!='null' && item!=null && item.user_id != id){
						return item;
					}else{
						return null;
					}
				})
				this.setState({list:newList})
			}
			
		  })	
	}
	removeFollow(ev){
		let thiss=ev.target;
		let id=$(thiss).attr("data-id");
		agent.friends.removeFollow({followed_user_id:id}).then(data => {
			if(data.result=="good"){
				
			}
		})
	}
	
	myFunction() {
	  $("#myDropdown").addClass("show");
	}
	togglelikepick(ev){
		let pick_code=$(ev.target).attr("pick_code")
		agent.home.togglelikepick({pick_code:pick_code}).then(data => {
			//console.log(data)
			if(data.result=="good"){
				let PickLikedNum=data.PickLikedNum;
				let PickLikedUserIDList=data.PickLikedUserIDList;
				let PickLikedUserList=data.PickLikedUserList;
				this.setState({PickLikedNum:PickLikedNum,PickLikedUserIDList:PickLikedUserIDList,list:PickLikedUserList})
				window.localStorage.setItem('PickLikedUserIDList',JSON.stringify(PickLikedUserList) ) 
				window.localStorage.setItem('PickLikedNum',PickLikedNum )
			}
		})
	}
	goBackPage(e){
		window.location.href="./"+this.state.url_back;
	}
	goProfile(id,e){
		window.localStorage.setItem('user_id',id )
		this.props.history.push("/profile")
	}
	createrequest(ev){
		
		let thiss=ev.target.closest('.add-friend');
		$(thiss).find('.loading').show();
		const to_user_id=$(thiss).attr("data-id")
		agent.friends.createrequest({to_user_id:to_user_id}).then(data => {
			//console.log(data)
			if(data.result === 'good'){
				$(thiss).find('.loading').hide();
				const list=this.state.list.map(item=>{
					if(item.user_id==to_user_id){
						item.FriendStatusWithLoginUser='Pending'
					}
					return item;
				})
				this.setState({isOpenModal:false,msgLogin:'',list:list})
			}else{
				this.setState({isOpenModal:true,msgLogin:data.message})
				$(thiss).find('.loading').hide();
			}
		})
	}
	render() {
		$(".body").css({'background':'#e9ebee'})
		$(window).hover(function(e){
			if($(e.target).closest(".g-btn").length==0 && $(e.target).closest(".dr-fr").length==0 ){
				$(".dr-fr").hide();
			}
		})
		
		let url=this.state.url
		const PickLikedNum=this.state.PickLikedNum
		const list=this.state.list
		var likeLst=this.state.list;
		var pesons='';
		var _status='';
		let pick_code=this.state.pick_code
		for( let i=0;i<likeLst.length;i++){
			//pick_code=likeLst[0].pick_code
			if(i==likeLst.length-1){
				pesons+=likeLst[i].tipster_name;
			}else{
				pesons+=likeLst[i].tipster_name+', ';
			}
			if(likeLst[i].user_id==this.state.id_user){
				_status="liked"
			}
			
		}
		console.log(list)
		//---------
			return (
				
					
						<Row>
							<Col md={12}>
								<Row className="mg0 mgt15">
									<Col md={6} xs={6} sm={6}>
										<a className={(PickLikedNum>0 && _status=='liked')?"like-bet-active":"like-bet"} title="Like" pick_code={this.state.PickCode} >	
											{
												(PickLikedNum>0 && _status=='liked')?<i className="fas fa-thumbs-up" pick_code={pick_code} onClick={this.togglelikepick.bind(this)}></i>:<i className="far fa-thumbs-up" pick_code={pick_code} onClick={this.togglelikepick.bind(this)}></i>
											}
											<span className="num-like">{(PickLikedNum>0)?PickLikedNum:0}</span>
										</a>
									</Col>
									<Col md={6} xs={6} sm={6} className="txtr">
										<i className="fas fa-arrow-left icback" onClick={this.goBackPage.bind(this)}></i>
									</Col>
								</Row>
							</Col>
							<Col md={12} className="friend-body">
								<div className="fiend-box-top" id="friend-request">
									<div className="frd-top">
										<div className="ft-items">
											<Col md={12} className="ctn">
												{
													(list!='' && list!='null' && list!=null)?list.map((data,index)=>{
														if(data!='' && data!='null' && data!=null){
															return(
																<Col md={6} className="ft-item ft-item-0 ft-item-bst" key={index}>
																	<figure className="pull-left"><img src={(data.imageurl!='' && data.imageurl!=null)?data.imageurl:url} width="50" height="50" className="_rv"/></figure>
																	<div className="fr-it-body">	
																		<div className="name name-fr pull-left">
																			<span className="fwb" onClick={this.goProfile.bind(this,data.user_id)}>{data.tipster_name}</span>
																			<div className="count-fr">{data.friendcount} {(data.friendcount>1)?'friends':''}</div>
																		</div>
																		
																		{
																			(data.user_id==this.state.id_user)?'':
																			(data.FriendStatusWithLoginUser=="Confirmed")?
																			<div className="g-btn pull-right mrgt5px">
																				<a className="btn-2 pull-right tfd-delete" onMouseOver={this.showBoxFriend.bind(this)}><i className="fa fa-angle-down fa-it"></i>Friends</a>
																				<ul role="menu" className="dropdown-menu dr-fr" onMouseLeave={this.hideBoxFriend.bind(this)}>
																					<li><a data-id={data.user_id} onClick={this.deleteFriend.bind(this)}>Unfriend</a></li>
																					<li><a data-id={data.user_id} onClick={this.removeFollow.bind(this)}>Unfollowing</a></li>
																				</ul>
																			</div>:
																			<div className="g-btn pull-right mrgt5px">
																				<a className="btn-2 pull-right add-friend" data-id={data.user_id} onClick={this.createrequest.bind(this)}>
																					<i className="fas fa-user-plus mrgr3"></i>
																					{(data.FriendStatusWithLoginUser=="Pending")?'Friend Request Sent':(data.FriendStatusWithLoginUser=="PendingYou")?'Waiting you confirm':localizeKey(translations)('AF', this.state.lguages)}
																					<img className="loading" src="https://www.coopbet.com/media/loading.gif" width="20" style={{'display':'none'}}/>
																				</a>
																			</div>
																		}
																		
																		
									
																	</div>
																</Col>
															)
														}
														
													}):<div></div>
												}
												
												
													
											</Col>
										</div>
									</div>
								</div>
							</Col>
						</Row>
						
						
			)	
	}
}

//export default connect(mapStateToProps, mapDispatchToProps)(LikeList)
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LikeList));
