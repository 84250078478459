import React from "react";
import { MDBRow, MDBCol, MDBBtn } from "mdbreact";
import agent from '../../agent'
import $ from 'jquery';
import '../../styles/home.css'
//import UploadImage from '../common/UploadImage'
import {TOKEN_NAME, LANGUAGE_KEY} from '../../constants/actionTypes'
import { setLocale, getLocale, localizeKey } from 'react-redux-localization';
import menu from '../common/menu.json'
import ModalErorr from '../common/ModalErorr'

const mapStateToProps = state => (
    Object.assign({}, state, {
		tab: state.common.tab,
		updateProps: state.common.updateProps,
		locale: getLocale(state),
		languageKey: state.common.languageKey
	}) 

);
class FormsPage extends React.Component {
	constructor(){
		super()
		this.state = {
			timezoneList:[],countryList:[],sporttipList:[],time_zone:'',country_id:'',
			sport_tips:'',odds_formart:'',lguages:'1005',isOpenModal:false,msg:'',
		}	
		this.close=()=>{
			this.setState({isOpenModal:false,msg:''})
		}
	}

  submitHandler = event => {
    event.preventDefault();
    //event.target.className += " was-validated";
	this.editBetting()
  };

  
	
  //---
 
	editBetting(){
		let state=this.state;
		const country_id=this.state.country_id		
		const time_zone=this.state.time_zone		
		const odds_formart=this.state.odds_formart		
		const sport_tips=this.state.sport_tips		
		agent.auth.editBetting({
			sport_tips:sport_tips,time_zone:time_zone,odds_formart:odds_formart,country_id:country_id
		}).then((datas) => {
		console.log(datas)
			if(datas.result==='good'){
				/*$(".red-text").html('')
				$(".green-text").html("Saved sucessfully.")
				setTimeout(function(){
					$(".green-text").html("")
				},9000);*/
				this.setState({isOpenModal:true,msg:"Saved sucessfully."})
			}else{
				/*$(".green-text").html("")
				$(".red-text").html(datas.message)
				setTimeout(function(){
					$(".red-text").html('')
				},9000);*/
				this.setState({isOpenModal:true,msg:datas.message})
			}
	   })	
	}
	//---
	
  //---
 
  //---
  selectBirthday(feild,ev){
	this.setState({[feild]:ev.target.value})
  }
  //---
  getProfile(){
		agent.auth.getProfile().then((datas) => {
		//console.log(datas)
			if(datas.result=='good'){
				const data=datas.data
				const time_zone=(data.time_zone!='')?data.time_zone:this.state.time_zone
				const country_id=(data.country_id!='')?data.country_id:this.state.country_id
				const sport_tips=(data.sport_tips!='')?data.sport_tips:this.state.sport_tips
				const odds_formart=(data.odds_formart!='')?data.odds_formart:this.state.odds_formart
				this.setState({time_zone:time_zone,country_id:country_id,sport_tips:sport_tips,
							   odds_formart:odds_formart,
							})
			}
	  })	
	}
  //---
	getTimezone(){
		agent.auth.timezone().then((datas) => {
		//console.log(datas)
		if(datas.result=='good'){
			this.setState({timezoneList:datas.list})
		}
	  })	
	}
	//---
	getCountry(){
		agent.auth.country().then((datas) => {
		//console.log(datas)
		if(datas.result=='good'){
			this.setState({countryList:datas.list})
		}
	  })	
	}
	getSporttip(){
		agent.auth.sporttip().then((datas) => {
		//console.log(datas)
		if(datas.result=='good'){
			this.setState({sporttipList:datas.list})
		}
	  })	
	}
  //---
	selectSportTips(ev){
		this.setState({sport_tips:ev.target.value})
	}
	handleSelect(feild,ev){
		this.setState({[feild]:ev.target.value})
	}
  //---
  handleCheck(e){
	this.setState({odds_formart:e.target.value})
  }
  //---
  componentWillMount(){	
	this.getTimezone();
	this.getCountry();
	this.getSporttip();
	//-------
	this.getProfile();
	
	let idLanguage=window.localStorage.getItem(LANGUAGE_KEY)
	if(idLanguage!='' && idLanguage!='null' && idLanguage!=null){
		this.setState({lguages:idLanguage})
	}
  }
  componentWillReceiveProps(nextProps){
		//console.log(nextProps)
		if(nextProps.languageKey && nextProps.languageKey!='' && nextProps.languageKey!=null && nextProps.languageKey!='null'){
			this.setState({lguages:nextProps.languageKey});
			
		}
  }
  render() {
	const timezoneList=this.state.timezoneList;
	const countryList=this.state.countryList;
	const sporttipList=this.state.sporttipList;
	//console.log(this.state.avatar)
    return (
      <div>
        <form
          className="needs-validation"
          onSubmit={this.submitHandler}
          noValidate
        >
          <MDBRow>
            <MDBCol md="4" className="mb-3">
              <label
                htmlFor="defaultFormRegisterNameEx"
                className="grey-text"
              >
                {localizeKey(menu)('timezone', this.state.lguages)}:
              </label>
              <select placeholder="select" className="form-control time_zone" name="time_zone" value={this.state.time_zone} onChange={this.handleSelect.bind(this,'time_zone')}>
				{
					(timezoneList)?timezoneList.map(data=>{
						return (<option value={data.id} key={data.id}> {data.desc} </option>)
					}):''
				}
			  </select>
            </MDBCol>
            <MDBCol md="4" className="mb-3">
              <label
                htmlFor="defaultFormRegisterEmailEx2"
                className="grey-text"
              >
                {localizeKey(menu)('selectCtr', this.state.lguages)}:
              </label>
              <select placeholder="select" className="form-control country_id" name="country_id" value={this.state.country_id} onChange={this.handleSelect.bind(this,'country_id')}>
					<option value=""> {localizeKey(menu)('selectCtr', this.state.lguages)}</option>
					{
						(countryList)?countryList.map(data=>{
							return(<option value={data.id} key={data.id}> {data.title} </option>)
						}):''
					}
				</select>
            </MDBCol>
            <MDBCol md="4" className="mb-3">
              <label
                htmlFor="defaultFormRegisterConfirmEx3"
                className="grey-text"
              >
                {localizeKey(menu)('porttip', this.state.lguages)}:
              </label>
               <select placeholder="select" className="form-control sport_tips" value={this.state.sport_tips} onChange={this.selectSportTips.bind(this)}>
					{
						(sporttipList)?sporttipList.map(data=>{
							return( <option value={data.id} key={data.id}> {data.title} </option> )
						}):''
					}
				</select>
            </MDBCol>
          </MDBRow>
		   <MDBRow>
				<MDBCol md="4" className="mb-3">
					<label
						htmlFor="defaultFormRegisterPasswordEx4"
						className="grey-text display_block"
					>
					{localizeKey(menu)('oddFm', this.state.lguages)}:
					</label>
					 <div className="custom-control custom-radio inl">
						<input
							type="radio"
							className="custom-control-input"
							id="customControlValidation2"
							name="radio-stacked"
							value={1} 
							checked={(this.state.odds_formart==1)?true:false}
							onChange={this.handleCheck.bind(this)}
						/>
						<label
							className="custom-control-label"
							htmlFor="customControlValidation2"
						>
							Decimal 2.5
						</label>					    
					</div>
					 <div className="custom-control custom-radio mrgl20 inl">						
					    <input
							type="radio"
							className="custom-control-input"
							id="customControlValidation3"
							name="radio-stacked"
							value={2} 
							checked={(this.state.odds_formart==2)?true:false}
							onChange={this.handleCheck.bind(this)}
							disabled={true} 
						/>
						<label
							className="custom-control-label"
							htmlFor="customControlValidation3"
					    >
						FRACTIONAL 3/2
					    </label>
					</div>
				</MDBCol>
          </MDBRow>
		   <MDBRow className="r-btn-s">
			  <MDBBtn color="primary" type="submit" className="doRegister save-setting">
				{localizeKey(menu)('save', this.state.lguages)}
			  </MDBBtn>
			</MDBRow>
		  <MDBRow>
			  <MDBCol md="12" className="mb-12">
					<label
						htmlFor="defaultFormRegisterPasswordEx4"
						className="red-text"
					>
					</label>
					<label
						htmlFor="defaultFormRegisterPasswordEx4"
						className="green-text"
					>
					</label>
				</MDBCol>
          </MDBRow>
        </form>
		<ModalErorr isOpen={this.state.isOpenModal} close={this.close} text={this.state.msg}/>
      </div>
    );
  }
}

export default FormsPage;