import React, {Component} from 'react'
import { connect } from 'react-redux'
import { setLocale, getLocale, localizeKey } from 'react-redux-localization';
import { Cookies } from 'react-cookie' 
import '../../styles/App.css'
import '../../styles/home.css'
import {Col, Row,FormControl, Form, FormGroup, ControlLabel, Button, Tabs,Tab,Table,Radio} from 'react-bootstrap'
//import { createElementsFromText } from 'html-text-to-react';
//import ReactHtmlParser from 'react-html-parser';
import $ from 'jquery';
import {TOKEN_NAME} from '../../constants/actionTypes'
import agent from '../../agent'
import translations from '../common/captionhome.json';


const mapStateToProps = state => (
    Object.assign({}, state, {
		tab: state.common.tab,
		updateProps: state.common.updateProps,
		locale: getLocale(state),
		languageKey: state.common.languageKey
	}) 

);

const mapDispatchToProps = dispatch => ({               
	onLoad: (data) => {
		dispatch({ type: 'HOME_PAGE_LOADED', data})  
	},
	chooseLocale: locale => dispatch(setLocale(locale))
});



 


class SummaryTips extends Component{ 	
	constructor(){
		super()
		this.state = {
			lguages:'1005',month:'',list:[]
		}
		//---
	}
	getBuyertips(){
		let month=this.state.month
		agent.orders.getBuyertips({month:month}).then(data => {
			if(data.result === "good"){	
				//console.log(data)
				this.setState({list:data.list})
			}else{
				
			}
		})
	}	
	componentWillMount(){	
		//-----
		
		
		//-----
	}
	componentDidMount(){
		
	}
	componentWillReceiveProps(nextProps){
		//console.log(nextProps)
		if(nextProps.month !=='' && nextProps.month !== null){
			this.setState({month:nextProps.month},()=>{
				this.getBuyertips();
			})
		}
		
	}
	
	shouldComponentUpdate(nextProps, nextState){
		return nextState;
	}	
	
	numberFormat(x) {
		if(x>0){
			return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
		}else return;
	}
	render(){   
		let list=this.state.list
		//---
	
        return (
			<Row className="mg0">
			{
				(list!=='' && list!==null)?list.map((dt,idx)=>{
					let userinfo=dt.UserInfo
					let betlist=dt.BetList
					//console.log(idx,list.length)
					return(
					<div key={idx} style={{'width':'100%'}}>
						{
							(idx>0)?
							<Row className="line-ct"></Row>:''
						}
						<Row className="title-user-info">
							<Col md={2} sm={3} xs={3} className="pd0 fit-bl-2">
								<figure>
									<a href="javascript:void(0);">
										<img src={(userinfo.imageurl!='' && userinfo.imageurl!=null && userinfo.imageurl!='null')?userinfo.imageurl:this.state.img_default} width="60" height="60"/>
									</a>
								</figure>
							</Col>
							<Col md={7} sm={5} xs={5} className="pd0">
								<h4 className="name" >{userinfo.tipster_name}</h4>
								<div>{localizeKey(translations)('Yield', this.state.lguages)}: {userinfo.profitpercentage}</div>
							</Col>
							<Col md={3} sm={4} xs={4} className="pd0 txtR fit-bl-3">
								<div className="timetip" style={{'paddingTop':'34px'}}>{dt.PickDatetimeSTR}</div>
								
							</Col>
						</Row>
						<Row className="linect"></Row>
						<Row className="tipct">
						{
							(betlist!=='' && betlist!==null)?betlist.map((dt,idx)=>{
								return (
									<Row className="list-tp" key={idx}>
										
										<Col md={12} xs={12} sm={12} className="tip-name">{dt.hometeam_name} - {dt.awayteam_name}</Col>
										<Col md={12} xs={12} sm={12} className="tiso">
											<span style={{'color':(dt.is_charged_tip===1)?'#42b1a9':''}}>{dt.tip_desc}</span>
											<button className="btn-gr">{parseInt(dt.amount)}/10</button>
											<button className="btn-ora">
											{
												(dt.home_goal!=null && dt.home_goal!='null')?
												<span>{dt.home_goal} - {dt.away_goal}</span>:
												<span> ? </span>
											}
											</button>
											
											{	//<span className="status won" title="Won">Win</span>
												(dt.bet_status=='WIN')?
												<span className="status won" title="Won">
													{localizeKey(translations)('Win', this.state.lguages)}
												</span>:(dt.bet_status=='DRAW')?
												<span className="status draw" title="Draw">
													{localizeKey(translations)('Draw', this.state.lguages)}
												</span>:(dt.bet_status=='WAIT')?
												<span className="status pending" title="Pending">
													{localizeKey(translations)('wait', this.state.lguages)}
												</span>:(dt.bet_status=='LOST')?
												<span className="status lost" title="Lost">
													{localizeKey(translations)('lost', this.state.lguages)}
												</span>:''
											}
										</Col>
										<Col md={12} xs={12} sm={12} className="time-tip">
											<img src={dt.sporttip_imageurl} style={{'display':'none'}}/>
											{
												(dt.sportid==1)?<i className="fas fa-basketball-ball icon-sport" aria-hidden="true"></i>:(dt.sportid==2)?<i className="far fa-futbol icon-sport" aria-hidden="true"></i>:<i className="fas fa-baseball-ball icon-sport" aria-hidden="true"></i>
											}
											<span className="txt-tm-tp">{dt.league_name} - {dt.start_date} {dt.start_time}</span>
										</Col>
									</Row>
								)
							}):''
						}
						</Row>
					</div>
					)
				}):''
			
			}
				
			</Row>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SummaryTips)