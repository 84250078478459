import React from 'react'
import ReactDOM from 'react-dom';
import {FormControl, Row, Col, Button, ControlLabel, FormGroup, Radio, Checkbox } from 'react-bootstrap';
//import InputMask from 'react-input-mask'; 
import $ from 'jquery';

import agent from '../../agent'


import '../../styles/modal.css'; 

import Modal from 'react-bootstrap-modal';
import { connect } from 'react-redux';
import { 
  LANGUAGE_KEY
  
}from '../../constants/actionTypes'; 
import menu from '../common/menu.json'
import { setLocale, getLocale, localizeKey } from 'react-redux-localization';

const mapStateToProps = state => (
    Object.assign({}, state, {
		languageKey: state.common.languageKey
	
    })      
);
const mapDispatchToProps = dispatch => ({
  /*loadCustomers: (data) => {
      dispatch({ type: CUSTOMER_BUILDING_LOADED, payload: data })  
  },
  */
});

class ModalErorr extends React.Component {
	
    constructor() {
        super();
		
        this.state = {			
			isOpen: false,
			text:'',lguages:'1005'
			
			
        }
		
		this.hideModal = ev => {
		  this.setState({
			isOpen: false,
		  });
		 this.props.close();
			
		};
		
		
		//---
				
    } 
	
	
	
    componentWillReceiveProps(nextProps) {
		//console.log(nextProps)
		if(nextProps){
			this.setState({isOpen:nextProps.isOpen,
				text:nextProps.text
			});
			
		}
		if(nextProps.languageKey && nextProps.languageKey!='' && nextProps.languageKey!=null && nextProps.languageKey!='null'){
			this.setState({lguages:nextProps.languageKey});
			
		}
		
    }	
	componentWillUpdate(nextProps, nextState){
		
		
		
	}
	componentDidMount() {
		
		
		
		
	}
	componentWillMount() {
		
		let idLanguage=window.localStorage.getItem(LANGUAGE_KEY)
		if(idLanguage!='' && idLanguage!='null' && idLanguage!=null){
			this.setState({lguages:idLanguage})
		}
		
	}
 
  render() {
	  // const oderInfo=this.props.orderParams;//console.log(oderInfo)
	
        return (
			<Modal
			  show={this.state.isOpen}
			  onHide={this.hideModal}
			  aria-labelledby="ModalHeader"
			  id="small"
			>
			  <Modal.Header closeButton>
				<Modal.Title id='ModalHeader'>
					<div className="modal-worker-title">
						<span className="modal-detail-order-title"><span style={{'color':'#b81900'}}>{localizeKey(menu)('message', this.state.lguages)}</span></span>
					</div>
				</Modal.Title>
			  </Modal.Header>
			  <Modal.Body>
					<div className="row">		
						<div className="col-md-12 mg5">{this.state.text}</div>
						
						<div className="col-lg-12 lblMessage" id="lblMessage" style={{'color':'red'}}></div>
					</div>
			  </Modal.Body>
			  <Modal.Footer>
					<div className="col-lg-12" style={{'textAlign':'left','padding':'0'}}> 						
						<button type="button" className="btn btn-primary" onClick={this.props.close}>{localizeKey(menu)('close', this.state.lguages)}</button>
					</div>
			  </Modal.Footer>
			</Modal>
        ); 
	 //}
	//return null;
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(ModalErorr); 
//export default ModalComfirmOrder