import React from 'react';
import { Provider } from 'react-redux';
//import { localeReducer } from 'react-localize-redux';
import ReactDOM from 'react-dom';
import { Switch, Route, BrowserRouter, Redirect } from 'react-router-dom';
import store from './store';
import { TOKEN_NAME } from './constants/actionTypes'
import { Cookies } from 'react-cookie'

import Home from './components/Home'
import Running from './components/Running'
import Comment from './components/Comment'
import AddTips from './components/AddTips'
import DetailTipToday from './components/AddTips/detailTipToday'
import DetailTipLeagues from './components/AddTips/detailTipLegues'
import ChoseTip from './components/AddTips/choseTip'
import Setting from './components/Setting'
import Notification from './components/Setting/notification'
import Login from './components/Login'
import Register from './components/Login/register'
import App from './components/App'
import AppNone from './components/AppNone'
import Ad from './components/Ad'
import AdListDetail from './components/Ad/adListDetail'
import AdNewInfo from './components/Ad/adNewInfo'
import Profile from './components/Profile'
import LikeTopic from './components/Profile/likeTopic'
import ForgotPass from './components/Login/forgotPass'
import ResetPass from './components/Login/resetPass'
import RunningTopic from './components/Comment/runningTopic'
import FriendsRequest from './components/Friends'
import Contact from './components/Setting/contact'
import Bankroll from './components/Setting/bankroll'
import AddBankroll from './components/Setting/addBankroll'
import AddBitcoin from './components/Setting/addBitcoin'
import AddPaypal from './components/Setting/addPaypal'
import Tipster from './components/Setting/tipster'
import TipsterContest from './components/Setting/tipsterContest'
import TipsManagement from './components/Setting/tipsManagement'
import InVoiceTip from './components/Setting/inVoiceTip'
import TipsInvestment from './components/Setting/tipsInvestment'
import BuyTip from './components/Setting/buyTip'
import InVoiceTipUp from './components/Setting/inVoiceTipUp'
import UploadInvoice from './components/Setting/uploadInvoice'
import Active from './components/common/Active';



const Router = () =>{ 
	//const cookies = new Cookies()
	//const token = cookies.get(TOKEN_NAME)	
	const token = window.localStorage.getItem(TOKEN_NAME);	
	//const locale=  localeReducer;
	//console.log(token)
	return (            
        <div>
			<Route component={(token)?App:AppNone} />
		    <Switch>
				
				<Route exact path='/' render={() => (
					token ? (
						<Redirect to="/home" />
					) : (                        
						<Redirect to="/login" />
					)
				)} /> 
               
				<Route path='/home' render={() => (
                      token ? (
						<Home /> 
                      ) : (
                        <Redirect to="/login" />
                      )
                )} />	
					
				
				<Route path='/add-tip' render={() => (
                      token ? (
						<AddTips /> 
                      ) : (
                        <Redirect to="/login" />
                      )
                )} />
				<Route path='/detail-tip-today' render={() => (
                      token ? (
						<DetailTipToday /> 
                      ) : (
                        <Redirect to="/login" />
                      )
                )} />
				<Route path='/detail-tip-leagues' render={() => (
                      token ? (
						<DetailTipLeagues /> 
                      ) : (
                        <Redirect to="/login" />
                      )
                )} />
				<Route path='/chose-tip' render={() => (
                      token ? (
						<ChoseTip /> 
                      ) : (
                        <Redirect to="/login" />
                      )
                )} />
				<Route path='/setting' render={() => (
                      token ? (
						<Setting /> 
                      ) : (
                        <Redirect to="/login" />
                      )
                )} />
				
				<Route path='/bookmakers' render={() => (
                      token ? (
						<Ad /> 
                      ) : (
                        <Redirect to="/login" />
                      )
                )} />
				<Route path='/ad-list' render={() => (
                      token ? (
						<AdListDetail /> 
                      ) : (
                        <Redirect to="/login" />
                      )
                )} />
				
				<Route path='/profile' render={() => (
                      token ? (
						<Profile /> 
                      ) : (
                        <Redirect to="/login" />
                      )
                )} />
				<Route path='/notifications' render={() => (
                      token ? (
						<Notification /> 
                      ) : (
                        <Redirect to="/login" />
                      )
                )} />	
				<Route path='/like-list' render={() => (
                      token ? (
						<LikeTopic /> 
                      ) : (
                        <Redirect to="/login" />
                      )
                )} />	
				<Route path='/running-list' render={() => (
                      token ? (
						<RunningTopic /> 
                      ) : (
                        <Redirect to="/login" />
                      )
                )} />	
				<Route path='/friends-request' render={() => (
                      token ? (
						<FriendsRequest /> 
                      ) : (
                        <Redirect to="/login" />
                      )
                )} />	
				<Route path='/tipster' render={() => (
                      token ? (
						<Tipster /> 
                      ) : (
                        <Redirect to="/login" />
                      )
                )} />
				<Route path='/tipster-contest' render={() => (
                      token ? (
						<TipsterContest /> 
                      ) : (
                        <Redirect to="/login" />
                      )
                )} />	
				<Route path='/bankroll' render={() => (
                      token ? (
						<Bankroll /> 
                      ) : (
                        <Redirect to="/login" />
                      )
                )} />	
				<Route path='/ad-bankroll' render={() => (
                      token ? (
						<AddBankroll /> 
                      ) : (
                        <Redirect to="/login" />
                      )
                )} />	
				<Route path='/ad-bitcoin' render={() => (
                      token ? (
						<AddBitcoin /> 
                      ) : (
                        <Redirect to="/login" />
                      )
                )} />	
				<Route path='/ad-paypal' render={() => (
                      token ? (
						<AddPaypal /> 
                      ) : (
                        <Redirect to="/login" />
                      )
                )} />	
				<Route path='/tips-management' render={() => (
                      token ? (
						<TipsManagement /> 
                      ) : (
                        <Redirect to="/login" />
                      )
                )} />	
				<Route path='/tips-investment' render={() => (
                      token ? (
						<TipsInvestment /> 
                      ) : (
                        <Redirect to="/login" />
                      )
                )} />	
				
                <Route path='/login' render={() => (
                     <Login /> 
                )} />  
				<Route path='/contact' render={() => (
                     <Contact /> 
                )} /> 
				
				<Route path='/register' render={() => (
                     <Register /> 
                )} />
				<Route path='/forgot-password' render={() => (
                     <ForgotPass /> 
                )} />
				<Route path='/reset-password' render={() => (
                     <ResetPass /> 
                )} />
				{
					//<Route path='/running/:id/' component={Running} />
				}
				<Route path='/invoice-tips-info/:id/' component={InVoiceTip} />
				<Route path='/invoice-tips/:id/' component={InVoiceTipUp} />
				<Route path='/upload-receipt/:id/' component={UploadInvoice} />
				<Route path='/buy-tip/:id/' component={BuyTip} />
				<Route path='/new-info/:id/' component={AdNewInfo} />
				<Route path='/comment/:id/' component={Comment} />
				<Route path='/activeaccount/:id/' component={Active} />
				
		    </Switch>	
			{
				//<Route component={Footer} />
			}
			
        </div>
	);
}
//basename='/coopbet'
const Root = () => {
  return (
        <Provider store={store}>
	      <BrowserRouter basename='/'> 
		    <Router />  
	      </BrowserRouter>
      </Provider>
  );
}


ReactDOM.render(<Root />, document.getElementById('root'));