import React, {Component} from 'react'
import { connect } from 'react-redux'
import { Cookies } from 'react-cookie' 
import '../../styles/App.css'
import '../../styles/home.css'
import {Col, Row,FormControl, Form, FormGroup, ControlLabel, Button} from 'react-bootstrap'
import { createElementsFromText } from 'html-text-to-react';
import ReactHtmlParser from 'react-html-parser';
import $ from 'jquery';
import {TOKEN_NAME, REDIRECT,LANGUAGE_KEY} from '../../constants/actionTypes'
import agent from '../../agent'
import { Link} from 'react-router-dom'
import { localize, localizeKey,setLocale, getLocale } from 'react-redux-localization';
//import translations from '../common/captionhome.json';
import FriendsKnow from './FriendsKnow'
import FriendsRequest from './FriendsRequest'
import menu from '../common/menu.json'
import Lqhome from '../common/home.json'

const mapStateToProps = state => (
    Object.assign({}, state, {
		dashboardData: state.common.dashboardData,
		tab: state.common.tab,
		updateProps: state.common.updateProps,
		locale: getLocale(state),
		languageKey: state.common.languageKey
	}) 

);

const mapDispatchToProps = dispatch => ({               
	
	chooseLocale: locale => dispatch(setLocale(locale))
});



 


class Home extends Component{ 	
	constructor(){
		super()
		this.state = {
			lguages:'1005',
			qcinfo:{},member_name:'',idUserLogin:'',type:2,
			img_default:'https://m.coopbet.com/img/no_avartar.jpg',
		}
		//---
		this.getrandomad=()=>{
			let lang_id=this.state.lguages
			let param={lang_id:lang_id}
			agent.home.getrandomad2(param).then(data => {
				//console.log(data)
				if(data.result === 'good'){
					this.setState({qcinfo:data.info})
				}
			})
		}
		//---
		this.goAdView=this.goAdView.bind(this)
		//---
	}
	goAdView(ev){
		let id=$(ev.target).attr("dataid")
		/*let title=$(ev.target).attr("title")
		window.localStorage.setItem( 'ad_user_id', id);
		window.localStorage.setItem( 'ad_title', title);
		//console.log(id,title)
		window.location.href='/ad-list' */
		window.location.href='/new-info/'+id
	}
	componentWillMount(){	
		//-----
		
		let idLanguage=window.localStorage.getItem(LANGUAGE_KEY)
		if(idLanguage!='' && idLanguage!='null' && idLanguage!=null){
			this.setState({lguages:idLanguage},()=>{
				this.getrandomad();
			})
		}
		
		let login=window.localStorage.getItem(TOKEN_NAME);
		 
		if(login!='' && login!='null' && login!=null){
			 login=JSON.parse(login)
			 this.setState({idUserLogin:login.UserInfo.id,member_name:login.UserInfo.member_name})
		}
		//-----
	}
	componentDidMount(){
		this.getrandomad();
	}
	componentWillReceiveProps(nextProps){
		//console.log(nextProps)
		if(nextProps.languageKey && nextProps.languageKey!='' && nextProps.languageKey!=null && nextProps.languageKey!='null'){
			this.setState({lguages:nextProps.languageKey},()=>{
				this.getrandomad()
			});
			
		}
	}
	
	shouldComponentUpdate(nextProps, nextState){
		return nextState;
	}
	goProfileType(type,e){
		this.setState({type:type})
	}
	render(){   
		//---
		const qcinfo=this.state.qcinfo;
		let title=''
		let imageurl=''
		let url=''
		let description=''
		if(qcinfo!='' && qcinfo!=null && qcinfo!='null'){
			 title=qcinfo.ad_title?qcinfo.ad_title:'';
			 imageurl=qcinfo.ad_logo_url?qcinfo.ad_logo_url:'https://back-end.coopbet.com/gf/ywqBBMhT0U0022_no-logo.png';
			 url=qcinfo.ad_url?qcinfo.ad_url:'';
			 description=qcinfo.ad_description?qcinfo.ad_description:'';
			 description=(description.length<=85)?description:description.substr(0,85)+'...'
		}	
		//---
		let idUserLogin=this.state.idUserLogin
		let type=this.state.type
        return (
			<div id="body">   
			{
				(qcinfo && qcinfo!=null && qcinfo!='')?				
				<Row className="randomad mgbt5">
					<Col md={4} xs={4} sm={4} className="pd0">
						<div className="img-adv">
							<a href={url} target="_blank" className="rand-logo">
								<img alt="m88" src={imageurl} width="100" height="50"/>
							</a>
						</div>
					</Col>
					<Col md={8} xs={8} sm={8} className="pd0">
						<div className="desc-adv">									
						{
							ReactHtmlParser(description)
						}
						<span>
						{
							(qcinfo!='' && qcinfo!=null)?
							<a onClick={this.goAdView.bind(this)} dataid={qcinfo.id} title={title} className="v-more">{localizeKey(Lqhome)('viewmore', this.state.lguages)}</a>:''
						}
						</span>
						</div>
					</Col>
				</Row>:''
			}
				<div className="profile-content mgbt5">		
					<Row className="mrgt7 mgbt3 mrgLR1">
						<Col md={6} xs={6} sm={6} className={(type==1)?"tool-ball tool-profile active":"tool-ball tool-profile"} onClick={this.goProfileType.bind(this,1)}>
							<div className="tool-profile-title">Your Friend Request</div>
						</Col>
						<Col md={6} xs={6} sm={6} className={(type==2)?"tool-ball tool-profile brdlr active":"tool-ball tool-profile brdlr"} onClick={this.goProfileType.bind(this,2)}>
							<div className="tool-profile-title">People You May Know</div>
						</Col>
					</Row>
				</div>
				<Row className="line-ct"></Row>
				<div style={{'display':(type==1)?'':'none'}} id="profile-time-line">
					<FriendsRequest/>
				</div>
				<div style={{'display':(type==2)?'':'none'}} id="profile-friend">
					<FriendsKnow/>
				</div>
			</div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Home)