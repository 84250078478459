import React, {Component} from 'react'
import { connect } from 'react-redux'
import { setLocale, getLocale, localizeKey } from 'react-redux-localization';
import { Cookies } from 'react-cookie' 
import '../../styles/App.css'
import '../../styles/home.css'
import {Col, Row,FormControl, Form, FormGroup, ControlLabel, Button, Tabs,Tab,Table,Radio} from 'react-bootstrap'
import { createElementsFromText } from 'html-text-to-react';
import ReactHtmlParser from 'react-html-parser';
import $ from 'jquery';
import {TOKEN_NAME, REDIRECT,HOME_PAGE_LOADED, APP_LOAD,LANGUAGE_KEY} from '../../constants/actionTypes'
import agent from '../../agent'
import menu from '../common/menu.json'
import Lqhome from '../common/home.json'
//import ModalErorr from '../common/ModalErorr'
import ReactPaginate from 'react-paginate';
import DatePicker from 'react-datepicker';
import Avatar from 'react-avatar';

const mapStateToProps = state => (
    Object.assign({}, state, {
		tab: state.common.tab,
		updateProps: state.common.updateProps,
		locale: getLocale(state),
		languageKey: state.common.languageKey
	}) 

);

const mapDispatchToProps = dispatch => ({               
	onLoad: (data) => {
		dispatch({ type: 'HOME_PAGE_LOADED', data})  
	},
	chooseLocale: locale => dispatch(setLocale(locale))
});



 


class AddTips extends Component{ 	
	constructor(){
		super()
		this.state = {
			lguages:'1005',
			qcinfo:{},idUser:'',
			offset: 0,limit:30, pageCount: Math.ceil(35 / 10),
			startDate:new Date(),search:'',
			qcinfo:{},month:'',limitM:30,offsetM:0,listM:[],totalcountM:0,
			selected:0,limitY:30,offsetY:0,listY:[],totalcountY:0,
			selectedY:0
		}
		//---
		this.getrandomad=()=>{
			let lang_id=this.state.lguages
			let param={lang_id:lang_id}
			agent.home.getrandomad2(param).then(data => {
				//console.log(data)
				if(data.result === 'good'){
					this.setState({qcinfo:data.info})
				}
			})
		}
		//---
		this.goAdView=this.goAdView.bind(this)
		//---
		this.close=()=>{
			this.setState({isOpenModal:false})
		}
		this.handlePageClick = data => {			
			//console.log(data)
			let selected = data.selected;
			let offset = Math.ceil(selected * this.state.limitM);

			this.setState({ offsetM: offset,selected:selected }, () => {
			  this.tipsterListIncontest();
			});
		}
		this.handlePageYClick = data => {			
			//console.log(data)
			let selected = data.selected;
			let offset = Math.ceil(selected * this.state.limitY);

			this.setState({ offsetY: offset,selectedY:selected }, () => {
			  this.getTipsterListInYear();
			});
		}
		this.tipsterListIncontest=()=>{
			let param={
			           limit:this.state.limitM,
					   offset:this.state.offsetM}
			agent.admin.tipsterListIncontest(param).then(data => {
				//console.log(data)
				if(data.result === 'good'){
					//let listM=this.state.listM.concat(data.list)
					let listM=data.list
					this.setState({listM:listM,totalcountM:data.totalcount})
					//console.log(data)
				}
			})
		}
		
		this.goBuy=(id,e)=>{
			window.location="/buy-tip/"+id
		}
	}
	noGoBuy(PriceTips,e){
		if(PriceTips>0){
			let message="The seller sold tips to the buyer in the month."
			this.setState({isOpenModal:true,msgLogin:message})
		}else{
			let message="Invalid price."
			this.setState({isOpenModal:true,msgLogin:message})
		}
		
	}
	goAdView(ev){
		let id=$(ev.target).attr("dataid")
		/*let title=$(ev.target).attr("title")
		window.localStorage.setItem( 'ad_user_id', id);
		window.localStorage.setItem( 'ad_title', title);
		//console.log(id,title)
		window.location.href='/ad-list' */
		window.location.href='/new-info/'+id
	}
	componentWillMount(){	
		//-----
		
		let idLanguage=window.localStorage.getItem(LANGUAGE_KEY)
		if(idLanguage!='' && idLanguage!='null' && idLanguage!=null){
			this.setState({lguages:idLanguage},()=>{
				this.getrandomad();
			})
		}
		let login=window.localStorage.getItem(TOKEN_NAME);
		if(login!='' && login!='null' && login!=null){
			login=JSON.parse(login)
			let info=login.UserInfo
			//console.log(login.UserInfo)
			this.setState({idUser:info.id},()=>{
				//this.getbankrolls(info.id)
			})				
		}
		
		this.tipsterListIncontest();
		
		//-----
	}
	componentDidMount(){
		this.getrandomad();
	}
	componentWillReceiveProps(nextProps){
		//console.log(nextProps)
		if(nextProps.languageKey && nextProps.languageKey!='' && nextProps.languageKey!=null && nextProps.languageKey!='null'){
			this.setState({lguages:nextProps.languageKey},()=>{
				this.getrandomad()
			});
			
		}
	}
	
	shouldComponentUpdate(nextProps, nextState){
		return nextState;
	}	
	handleChangeField(feild,e){
		this.setState({[feild]:e.target.value})
	}
	handleChangeDate(date){
		let dates=date.getDate();
		var month = date.getMonth() + 1;
		let year=date.getFullYear();
		let mth=month+"/"+year
		this.setState({startDate:date,month:mth,offsetM:0,selected:0},()=>{
			this.getTipsterListInmonth();
		})
	}
	generateColor () {
		return '#' +  Math.random().toString(16).substr(-6);
	}
	goProfile(id,e){
		window.localStorage.setItem('user_id',id )
		window.location.href='/profile'
	}
	render(){   
		//---
		const qcinfo=this.state.qcinfo;
		let title=''
		let imageurl=''
		let url=''
		let description=''
		if(qcinfo!='' && qcinfo!=null && qcinfo!='null'){
			 title=qcinfo.ad_title?qcinfo.ad_title:'';
			 imageurl=qcinfo.ad_logo_url?qcinfo.ad_logo_url:'https://back-end.coopbet.com/gf/ywqBBMhT0U0022_no-logo.png';
			 url=qcinfo.ad_url?qcinfo.ad_url:'';
			 description=qcinfo.ad_description?qcinfo.ad_description:'';
			 description=(description.length<=85)?description:description.substr(0,85)+'...'
		}	
		let listM=this.state.listM 	
		let totalcountM=this.state.totalcountM 
		let limitM=this.state.limitM 
		let pageCountM= Math.ceil(parseInt(totalcountM) /parseInt(limitM))
		
		let listY=this.state.listY 	
		let totalcountY=this.state.totalcountY 
		let limitY=this.state.limitY 
		let pageCountY= Math.ceil(parseInt(totalcountY) /parseInt(limitY))
		//---
		//console.log(this.state.idUser)
        return (
			<div id="body"> 
			{
				(qcinfo && qcinfo!=null && qcinfo!='')?
				<Row className="randomad">
					<Col md={4} xs={4} sm={4} className="pd0">
						<div className="img-adv">
							<a href={url} target="_blank" className="rand-logo">
								<img alt="m88" src={imageurl} width="100" height="50"/>
							</a>
						</div>
					</Col>
					<Col md={8} xs={8} sm={8} className="pd0">
						<div className="desc-adv">									
						{
							ReactHtmlParser(description)
						}
							<span>
							{
								(qcinfo!='' && qcinfo!=null)?
								<a onClick={this.goAdView.bind(this)} dataid={qcinfo.id} title={title} className="v-more">{localizeKey(Lqhome)('viewmore', this.state.lguages)}</a>:''
							}
							</span>
						</div>
					</Col>
				</Row>:''
			}
				
				<Row className="mg0 mrgt7 mgbt7">
					<Col md={12} xs={12} sm={12} className="">
						<i className="far fa-user iconmoney" style={{'fontSize':'20px'}}></i>
						<div className="rigister-title" style={{'textTransform':'uppercase'}}>TIPSTERS</div>
					</Col>					
				</Row>
				<Row className="line-ct"></Row>
				<Row className="mg0" style={{'padding':'10px 0','background':'#dee2e6'}}>
					<Col md={12} xs={12} sm={12} className="">
						
						<div className="rigister-title" style={{'textTransform':'uppercase','fontSize':'16px'}}>Euro 2021</div>
					</Col>					
				</Row>
				<Row className="mg0" id="div-bankroll" style={{'display':'block'}}>
					<div id="tab-bankroll">
							<Row className="mg1">
								<Col md={12} style={{'padding':'0'}}>
									<Table className="tbl-tipters" >
										<thead>
											<tr>												
												<th colSpan="1" style={{'width':'45%'}}>Tipster</th>
												<th colSpan="1" style={{'width':'18%'}}>Picked</th>
												<th colSpan="1" style={{'width':'18%'}}>Profit</th>	
												<th colSpan="1" style={{'width':'19%'}}>Yeild</th>
												
											</tr>
										</thead>
										<tbody>
											
											{
												(listM!='')?listM.map((dt,idx)=>{
													
													return(
														<tr key={idx}>															
															<td>
																<Row style={{'margin':'0'}}>
																	<Col md={2} className="pd0">
																		{(dt.imageurl!=null && dt.imageurl!='')?
																			<img src={dt.imageurl} className="img-tipster" onClick={this.goProfile.bind(this,dt.user_id)} />:
																			<Avatar size="50" round={true} className="avatar-title" 
																				color={this.generateColor()} name={dt.tipster_name} onClick={this.goProfile.bind(this,dt.user_id)}/> 
																		}	
																	</Col>
																	<Col md={10} className="pd6_0">
																		<div className="name-tip"><a onClick={this.goProfile.bind(this,dt.user_id)}>{dt.tipster_name}</a></div>
																		<div className="verifi-tip">{(dt.verified==1)?'verified':'none'}</div>
																	</Col>
																</Row>
															</td>
															<td>{dt.PickedTips}</td>
															<td>{dt.Profit}</td>
															<td>{dt.ProfitPercentage2}%</td>
															
														</tr>
													)
												}):<tr><td colSpan="5"></td></tr>
											}
										</tbody>
									</Table>
								</Col>
								
							</Row>
							<Row className="mg0">
								<Col md={12} xs={12} sm={12} style={{'display':(totalcountM>0)?'':'none'}}>
									<ReactPaginate
									  //previousLabel={'previous'}
									  previousLabel={'<'}
									  //nextLabel={'next'}
									  nextLabel={'>'}
									  breakLabel={'...'}
									  breakClassName={'break-me'}
									  pageCount={pageCountM}
									  marginPagesDisplayed={2}
									  pageRangeDisplayed={5}
									  onPageChange={this.handlePageClick}
									  containerClassName={'pagination'}
									  subContainerClassName={'pages pagination'}
									  activeClassName={'active'}
									  forcePage={this.state.selected}
									/>
								</Col>
								
							</Row>
						
					</div>
				</Row>
				
				
				
			</div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddTips)