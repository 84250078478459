import React, {Component} from 'react'
import { connect } from 'react-redux'
import { localize, localizeKey,setLocale, getLocale } from 'react-redux-localization';
import { Cookies } from 'react-cookie' 
import '../../styles/App.css'
import '../../styles/home.css'
import {Col, Row, Button} from 'react-bootstrap'
//import { createElementsFromText } from 'html-text-to-react';
//import ReactHtmlParser from 'react-html-parser';
import $ from 'jquery';
import {TOKEN_NAME,LANGUAGE_KEY,BASE_IMAGE} from '../../constants/actionTypes'
import agent from '../../agent'
import translations from '../common/captionhome.json';

import Comment from './comment.js'
import {withRouter, history} from 'react-router-dom'
import ModalErorr from '../common/ModalErorr'
import { DropdownMenu, MenuItem } from 'react-bootstrap-dropdown-menu';
import '@fortawesome/fontawesome-free/css/fontawesome.css'
import '@fortawesome/fontawesome-free/css/brands.css'
import '@fortawesome/fontawesome-free/css/solid.css'
import '@fortawesome/fontawesome-free/css/all.css'
import ReactHtmlParser from 'react-html-parser';

const mapStateToProps = state => (
    Object.assign({}, state, {
		dashboardData: state.common.dashboardData,
		tab: state.common.tab,
		updateProps: state.common.updateProps,
		locale: getLocale(state),
		languageKey: state.common.languageKey,
		search_key: state.common.search_key
	}) 

);

const mapDispatchToProps = dispatch => ({               
	
	chooseLocale: locale => dispatch(setLocale(locale))
});



 


class Home extends Component{ 	
	constructor(){
		super()
		this.state = {
			lguages:'1005',
			homebetList:[],
			url:'https://www.coopbet.com/media/avartar_cm.jpg',
			img_default:'https://m.coopbet.com/img/no_avartar.jpg',
			url_like:'https://www.coopbet.com/media/likec.png',
			url_liked:'https://www.coopbet.com/media/likedc.png',
			url_dislike:'https://www.coopbet.com/media/dislikec.png',
			url_disliked:'https://www.coopbet.com/media/dislikedc.png',
			CommentList:[],TotalComments:'',numCM:'',pickcode_do:'',imageurl:'',textResuilt:'',
			isOpenModal:false,isOpenModalEditDes:false,
			txt_description:'',PickCode:'',txt_title:'',id_user:'',
		}
		//---
		this.close=()=>{
			this.setState({isOpenModal:false,isOpenModalLike:false,isOpenModalEditDes:false})
		}
		//---
		this.callModalError=(message)=>{
			this.setState({isOpenModal:true,msgLogin:message,isOpenModalLike:false,isOpenModalEditDes:false})
		}
		//---
		this.showModalEditDescr=(PickCode,txt_description)=>{
			this.setState({isOpenModal:false,isOpenModalLike:false,isOpenModalEditDes:true,
						   txt_description:txt_description,PickCode:PickCode,txt_title:'Edit Description'})
		}
		this.updateHomeLis=(PickCode,PickComment)=>{
			const homebetList=this.state.homebetList.map(dt=>{
				if(dt.PickCode==PickCode){
					dt.PickComment=PickComment
					dt.edit_decrpt=1
				}
				return dt;
			});
			this.setState({homebetList:homebetList})
		}
		this.goBuy=(id,e)=>{
			window.location="/buy-tip/"+id
		}
	}
	noGoBuy(PriceTips,e){
		if(PriceTips>0){
			let message="The seller sold tips to the buyer in the month."
			this.setState({isOpenModal:true,msgLogin:message})
		}else{
			let message="Invalid price."
			this.setState({isOpenModal:true,msgLogin:message})
		}
		
	}
	emptyPickComment(PickCode,ev){
		agent.admin.emptyPickComment({PickCode:PickCode}).then(data => {
			if(data.result=="good"){
				let homebetList=this.state.homebetList.map(dt=>{
					if(dt!=null && dt.PickCode==PickCode){
						dt.PickComment='';
					}
					return dt;
				})
				this.setState({homebetList:homebetList})
			}else{
				this.setState({isOpenModal:true,msgLogin:data.message})
			}
		})
	}
	componentWillMount(){	
		//-----
		const cookies = new Cookies()
		let language=(cookies.get('language')!='' && cookies.get('language') !=null && cookies.get('language')!== 'undefined')?cookies.get('language'):'1005';
		this.setState({'lguages':language})
		
		let login=window.localStorage.getItem(TOKEN_NAME);
		 
		if(login!='' && login!='null' && login!=null){
			 login=JSON.parse(login)
			 this.setState({member_name:login.UserInfo.member_name,id_user:login.UserInfo.id,imageurl:login.UserInfo.imageurl})
		}
		
		//-----
	}
	
	componentWillReceiveProps(nextProps){
		//console.log(nextProps)
		if(nextProps.languageKey && nextProps.languageKey!='' && nextProps.languageKey!=null && nextProps.languageKey!='null'){
			this.setState({lguages:nextProps.languageKey});
		}
		if(nextProps.homebetList && nextProps.homebetList!=null && nextProps.homebetList!='null'){
			this.setState({homebetList:nextProps.homebetList});
		}
		if(nextProps.homebetList && nextProps.homebetList=='' && nextProps.search_key!=null){
			this.setState({textResuilt:'No data.'})
		}
	}
	componentDidMount() {
		/*window.onclick = function(event) {
		  
		  if (!event.target.matches('.tfd-delete')) {
			var dropdowns = document.getElementsByClassName("dropdown-menu");
			var i;
			for (i = 0; i < dropdowns.length; i++) {
			  $(".dr-fr").hide();
			}
		  }
		}*/
	}
	shouldComponentUpdate(nextProps, nextState){
		return nextState;
	}	
	
	goProfile(id,e){
		window.localStorage.setItem('user_id',id )
		this.props.history.push("/profile")
	}
	render(){   
		//---
		let homebetList=this.state.homebetList
		let CommentList=this.state.CommentList
		let TotalComments=this.state.TotalComments
		const id_user=this.state.id_user;
		//---
		//console.log(homebetList)
		//console.log(localizeKey(translations)('Win', this.state.lguages))
        return (
			<div className="home-list">
				<Row>
					<Col md={12} style={{'textAlign':'center'}}>
						<img className="imgload" src="https://www.coopbet.com/media/SpinnerLoading.gif" style={{'display':'none','position':'absolute','width':'70px'}}/>
					</Col>
				</Row>
				{
					(homebetList!='')?homebetList.map((data,index)=>{
						let userinfo=data.UserInfo
						let betlist=data.BetList
						var html = data.PickComment;
						if(html!='' && html!=null){
							html=html.replace(/(?:\r\n|\r|\n)/g, '<br />')
						}
						var div = document.createElement("div");
						div.innerHTML = html;
						var textmessge = html;
						return(
							<div key={index}>
							{
								(userinfo.role_id!=3)?
								<Row className="title-user-info">
									<Col md={2} sm={3} xs={3} className="pd0 fit-bl-2">
										<figure>
											<a href="javascript:void(0);" onClick={this.goProfile.bind(this,userinfo.id)}>
												<img src={(userinfo.imageurl!='' && userinfo.imageurl!=null && userinfo.imageurl!='null')?userinfo.imageurl:this.state.img_default} width="60" height="60"/>
											</a>
										</figure>
									</Col>
									<Col md={7} sm={5} xs={5} className="pd0">
										<h4 className="name" onClick={this.goProfile.bind(this,userinfo.id)}>{userinfo.tipster_name}</h4>
										<div>{localizeKey(translations)('Yield', this.state.lguages)}: {userinfo.profitpercentage}</div>
									</Col>
									<Col md={3} sm={4} xs={4} className="pd0 txtR fit-bl-3">
										<div className="timetip" style={{'paddingTop':(userinfo.HasChargedTips === true)?'10px':'34px'}}>{data.PickDatetimeSTR}</div>
										{
											(id_user!==userinfo.id && userinfo.HasChargedTips === true && userinfo.PriceTips>0)?											
											<div className="left-ft-admth ct-ft-charge" onClick={(userinfo.SoldMeTipsInMonth === false && userinfo.PriceTips>0)?this.goBuy.bind(this,userinfo.id):this.noGoBuy.bind(this,userinfo.PriceTips)}>
												<span className="price-charge">{userinfo.PriceTips}$/MONTH</span>
												<span className="sym-charge"></span>
												<i className="fas fa-shopping-cart"></i>
											</div>:''
										}
									</Col>
								</Row>:
								<Row className="">
									<Col md={12} sm={12} xs={12} className="">		
										<h4 className="name-ad" onClick={this.goProfile.bind(this,userinfo.id)}>{userinfo.tipster_name}</h4>
									</Col>
									<Col md={12} sm={12} xs={12} className="img-baner-ad">	
										<a href={userinfo.aduser_url} target="_blank"><img className="img-baner" src={userinfo.bannermobileadurl} /></a>
									</Col>
								</Row>
							}
								
								<Row className="linect"></Row>
								<Row className="tipct">
								{
									(betlist!='')?betlist.map((dt,idx)=>{
										return (
											<Row className="list-tp" key={idx}>
												{
													(dt.is_charged_tip === 1)?
													<div className="triangle-top-left-charge">
														<div className="triangle-text-charge ">Tips</div>
													</div>:''
												}
												<Col md={12} xs={12} sm={12} className="tip-name">{dt.hometeam_name} - {dt.awayteam_name}</Col>
												<Col md={12} xs={12} sm={12} className="tiso">
													<span style={{'color':(dt.is_charged_tip===1)?'#42b1a9':''}}>{dt.tip_desc}</span>
													<button className="btn-gr">{parseInt(dt.amount)}/10</button>
													<button className="btn-ora">
													{
														(dt.home_goal!=null && dt.home_goal!='null')?
														<span>{dt.home_goal} - {dt.away_goal}</span>:
														<span> ? </span>
													}
													</button>
													
													{	//<span className="status won" title="Won">Win</span>
														(dt.bet_status=='WIN')?
														<span className="status won" title="Won">
															{localizeKey(translations)('Win', this.state.lguages)}
														</span>:(dt.bet_status=='DRAW')?
														<span className="status draw" title="Draw">
															{localizeKey(translations)('Draw', this.state.lguages)}
														</span>:(dt.bet_status=='WAIT')?
														<span className="status pending" title="Pending">
															{localizeKey(translations)('wait', this.state.lguages)}
														</span>:(dt.bet_status=='LOST')?
														<span className="status lost" title="Lost">
															{localizeKey(translations)('lost', this.state.lguages)}
														</span>:''
													}
												</Col>
												<Col md={12} xs={12} sm={12} className="time-tip">
													<img src={dt.sporttip_imageurl} style={{'display':'none'}}/>
													{
														//(dt.sportid==1)?<i className="fas fa-basketball-ball icon-sport" aria-hidden="true"></i>:(dt.sportid==2)?<i className="far fa-futbol icon-sport" aria-hidden="true"></i>:<i className="fas fa-baseball-ball icon-sport" aria-hidden="true"></i>
														<img src="/img/24x25_KTO-Logo.png" style={{'width':'26px'}}/>
													}
													<span className="txt-tm-tp">{dt.league_name} - {dt.start_date} {dt.start_time}</span>
												</Col>
											</Row>
										)
									}):''
								}
								</Row>
								
								<Row className="descp-tp">
									{
										//data.PickComment
										ReactHtmlParser(textmessge)
									}
									
								</Row>
								<Row className="linect"></Row>
								<div className="commet-tp">
									
									{//====comment====
									<Comment 
										TotalComments={data.TotalComments} 
										imageurl={this.state.imageurl} 
										PickCode={data.PickCode}
										//PickCode={this.state.pickcode_do}
										id_user={this.state.id_user}
										PickLikedNum={data.PickLikedNum}
										PickLikedUserList={data.PickLikedUserList}
										PickLikedUserIDList={data.PickLikedUserIDList}
										PickCodeRunning={data.PickCodeRunning}
										PickCodeComingNumber={data.PickCodeComingNumber}
										PickCodeComingUsers={data.PickCodeComingUsers}
										UserInfoId={data.UserInfo.id}
										callModalError={this.callModalError}
										/>
									
									//====end comment
									}
									
									
								</div>
								<Row className="line-ct"></Row>
								<ModalErorr isOpen={this.state.isOpenModal} close={this.close} text={this.state.msgLogin}/>
							</div>
						)
					}):<Row className=""><Col md={12}>{this.state.textResuilt}</Col></Row>
				}
				
			</div>
        );
    }
}

//export default connect(mapStateToProps, mapDispatchToProps)(Home)
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Home));