export const APP_LOAD = 'APP_LOAD';
export const REDIRECT = 'REDIRECT';
export const HOME_PAGE_LOADED = 'HOME_PAGE_LOADED'
export const HOME_PAGE_UNLOADED = 'HOME_PAGE_UNLOADED'
export const BASE_API_IP = 'http://139.162.119.227/apicoopbet/public/api/'
//export const BASE_API = 'https://back-end.coopbet.com/api/'
//export const BASE_API = 'https://back1.coopbet.com/api/'
export const BASE_API = 'https://back-end.coopbet.com/api/'
export const LOGOUT = 'LOGOUT'
export const TOKEN_NAME = 'M_COOBPET'
export const RESET_DASHBOARD = 'RESET_DASHBOARD'
export const LOGIN = 'LOGIN'
export const PAGE_NOT_FOUND_LOADED = 'PAGE_NOT_FOUND_LOADED'
export const LANGUAGE_KEY = 'LANGUAGE_KEY'
export const CHANGE_TAB = 'CHANGE_TAB'
export const LANGUAGE_LIST = 'LANGUAGE_LIST'
export const MEBER_NAME = 'MEBER_NAME'
export const USERID_NF = 'USERID_NF'
export const RUNNING_LOADER = 'RUNNING_LOADER'
export const SEARCH_KEY = 'SEARCH_KEY'
export const IMG_USER = 'IMG_USER'
export const ONLY_FIENDS = 'ONLY_FIENDS'
export const NUMBER_NOTIFICATIONS = 'NUMBER_NOTIFICATIONS'
export const COMMENT_REALTIME = 'COMMENT_REALTIME'
export const UINFO = 'UINFO'
export const BASE_IMAGE = importAll(require.context('../images', false, /\.(png|jpe?g|svg)$/));

function importAll(r) {
 let images = {};
  r.keys().map((item, index) => { images[item.replace('./', '')] = r(item); });
  return images;
}