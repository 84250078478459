import React from "react";
import { connect } from 'react-redux'
import { MDBRow, MDBCol, MDBBtn } from "mdbreact";
import agent from '../../agent'
import $ from 'jquery';
import '../../styles/home.css'
import UploadImage from '../common/UploadImage'
import ModalErorr from '../common/ModalErorr'
import {TOKEN_NAME, LANGUAGE_KEY} from '../../constants/actionTypes'
import { setLocale, getLocale, localizeKey } from 'react-redux-localization';
import menu from '../common/menu.json'

const mapStateToProps = state => (
    Object.assign({}, state, {
		tab: state.common.tab,
		updateProps: state.common.updateProps,
		locale: getLocale(state),
		languageKey: state.common.languageKey
	}) 

);
class FormsPage extends React.Component {
	constructor(){
		super()
		this.state = {
			fname: "",
			lname: "",
			email: "",
			password: "",
			confirmpassword: "",
			tiptername: "",time_zone:'',country_id:'',
			imgCheck:'fa fa-check',
			textCheck:'Username url is available.',
			captcharValue:'',doregis:true,checkPass:false,
			month:'',date:'',years:[],sex:'',year:'',
			avatar: 'https://www.coopbet.com/media/no_avatar.png',
			old_avatar: 'https://www.coopbet.com/media/no_avatar.png',
			mobile_num:'',mobile_number:'',verify_code:'',id_user:'',verify :'verify ',is_validate:0,
			isOpenModal:false,msgRigister:'',lguages:'1005',url_username:'',validated_time:'',
			suffix_url_name:''
		}	
		this.check_url=this.check_url.bind(this) 
		this.editPersonal=this.editPersonal.bind(this) 
		this.check_url_friendly=this.check_url_friendly.bind(this) 
		this.close=()=>{
			this.setState({isOpenModal:false})
		}
		this.handleGetFileUpload = (imageURL) => { 
			//console.log(imageURL)
			const avatar = (imageURL!='') ? imageURL : this.state.old_avatar				
			const newState = Object.assign({}, this.state,{avatar:avatar})
			this.setState(newState)	
		}
		this.sendotpsms=()=>{
			let id_user=this.state.id_user;
			let NumberToSend=this.state.mobile_number
			let PhoneCode =this.state.mobile_num
			//console.log(NumberToSend)
			agent.auth.sendotpsms({userid:id_user,NumberToSend:NumberToSend,PhoneCode:PhoneCode,OtpType:1}).then((datas) => {
			//console.log(datas)
				if(datas.result=='good'){
					this.setState({msgRigister:datas.otpreturn,isOpenModal:true})
				}else{
					this.setState({msgRigister:datas.otpreturn,isOpenModal:true})
				}
			})
		}
		this.validateotpsms=()=>{
			let id_user=this.state.id_user;
			let NumberToSend=this.state.mobile_number
			let PhoneCode =this.state.mobile_num
			let OtpContent=this.state.verify_code;
			let urlname=this.state.tiptername.split(' ').join('');
			//let UserUrl="coopbet.com/"+urlname.toLowerCase();
			let UserUrl=this.state.url_username;
			//console.log(NumberToSend)
			agent.auth.validateotpsms({userid:id_user,NumberToSend:NumberToSend,PhoneCode:PhoneCode,OtpType:1,OtpContent:OtpContent,UserUrl:UserUrl}).then((datas) => {
			//console.log(datas)
				if(datas.result=='good'){
					this.setState({msgRigister:datas.otpreturn,isOpenModal:true,verify:'verified',is_validate:1})
				}else{
					this.setState({msgRigister:datas.otpreturn,isOpenModal:true})
				}
			})
		}
		//---
	}

  submitHandler = event => {
    event.preventDefault();
    //event.target.className += " was-validated";
	//this.editPersonal()
  };

  changeHandler = event => {
    this.setState({ [event.target.name]: event.target.value });
	if(event.target.name=='tiptername'){
		//var url="coopbet.com/"+event.target.value.toLowerCase();
		//this.check_url(url);
		
	}
  };
  check_url(url_username){
	$(".ch_U").show()
	agent.profile.check_url({url_username:url_username}).then((datas) => {
		//console.log(datas)
		if(datas.result=='good'){
			this.setState({imgCheck:'fa fa-check',textCheck:'Username url is available.'})
		}else{
			this.setState({imgCheck:'fa fa-times',textCheck:'Username url is not available.'})
		}
		$(".ch_U").hide()
	})
  }
  check_url_friendly(){
		let suffix_url_name=this.state.suffix_url_name
		//let tipster_name=this.state.tiptername
		//tipster_name=tipster_name.split(" ").join("");
		var url_username="coopbet.com/"+suffix_url_name.toLowerCase();
		//var url_username="coopbet.com/"+tipster_name.toLowerCase();
		$(".ch_U").show()
		agent.profile.check_url_friendly({url_username:url_username}).then((datas) => {
			//console.log(datas)
			if(datas.result=='good'){
				this.setState({imgCheck:'fa fa-check',textCheck:localizeKey(menu)('urlAvailabe', this.state.lguages),url_username:url_username})
			}else{
				this.setState({imgCheck:'fa fa-times',textCheck:localizeKey(menu)('urlnotAvailabe', this.state.lguages),url_username:url_username,msgRigister:datas.message,isOpenModal:true})
			}
			$(".ch_U").hide()
	  })
	}
	
  //---
 
	editPersonal(e){
		let state=this.state;
		const edit_password=(this.state.checkPass==true)?1:0
		let date=state.date
		let month=state.month
		let year=state.year
		let first_name=state.fname
		let last_name=state.lname
		let birthday=year+"-"+month+"-"+date
		let sex=state.sex
		let email=state.email
		let tipster_name=state.tiptername
		let imageurl=state.avatar
		let password=state.password
		let password_confirmation=state.confirmpassword
		let suffix_url_name=state.suffix_url_name
		agent.auth.editPersonal({
			first_name:first_name,last_name:last_name,
			password:password,sex:sex,
			password_confirmation:password_confirmation,
			email:state.email,tipster_name:tipster_name,
			birthday:birthday,imageurl:imageurl,edit_password:edit_password,
			suffix_url_name:suffix_url_name
		}).then((datas) => {
		console.log(datas)
			if(datas.result==='good'){
				//$(".red-text").html('')
				//$(".green-text").html("Saved sucessfully.")
				
				//setTimeout(function(){
					//$(".green-text").html("")
				//},9000);
				this.setState({msgRigister:"Saved sucessfully.",isOpenModal:true})
			}else{
				//$(".green-text").html("")
				//$(".red-text").html(datas.message)
				//setTimeout(function(){
				//	$(".red-text").html('')
				//},9000);
				this.setState({msgRigister:datas.message,isOpenModal:true})
			}
	   })	
	}
	//---
	
  //---
  handleCheckPass(ev){
	let check=false;
	if(ev.target.checked){
		check=true;
	}else{
		check=false;
	}
	this.setState({checkPass:check})
  }
  //---
  selectBirthday(feild,ev){
	this.setState({[feild]:ev.target.value})
  }
  //---
  getProfile(){
		agent.auth.getProfile().then((datas) => {
		//console.log(datas)
			if(datas.result=='good'){
				const data=datas.data
				const avatar=(data.imageurl!='' && data.imageurl!='null' && data.imageurl!=null)?data.imageurl:this.state.avatar
				const tipster_name=(data.tipster_name!='')?data.tipster_name:this.state.tipster_name
				const member_name=(data.member_name!='')?data.member_name:this.state.member_name
				const email=(data.email!='')?data.email:this.state.email
				const first_name=(data.first_name!='')?data.first_name:this.state.first_name
				const last_name=(data.last_name!='')?data.last_name:this.state.last_name
				const sex=(data.sex!='')?data.sex:this.state.sex
				const birthday=(data.birthday!='')?data.birthday:this.state.birthday
				const url_username=(data.url_username!='')?data.url_username:this.state.url_username
				let year=''
					let month=''
					let date=''
				if(birthday!=''){
					let dates=new Date(birthday);
					 year=dates.getFullYear();
					 month=dates.getMonth()+1;
					 date=dates.getDate();	
					 date=(date>9)?date:'0'+date
					 //console.log(year,month,date)
				}else{
					 year=''
					 month=''
					 date=''
				}
				const phone_code=(data.phone_code!=null)?data.phone_code:this.state.mobile_num
				const otp_sort_number=(data.otp_sort_number!=null)?data.otp_sort_number:this.state.mobile_number
				const is_validate=(data.is_validate!='' && data.is_validate!=null)?data.is_validate:this.state.is_validate
				const validated_time=(data.validated_time!='' && data.validated_time!=null)?data.validated_time:this.state.validated_time
				let suffix_url_name=url_username.split("/")
				suffix_url_name=(suffix_url_name!=null && suffix_url_name!='')?suffix_url_name[1]:''
				this.setState({avatar:avatar,tiptername:tipster_name,email:email,member_name:member_name,
							   fname:first_name,lname:last_name,sex:sex,year:year,month:month,
							   date:date,mobile_num:phone_code,mobile_number:otp_sort_number,
							   is_validate:is_validate,url_username:url_username,validated_time:validated_time,
							   suffix_url_name:suffix_url_name
							})
			}
	  })	
	}
  //---
  handleSex(e){
	this.setState({sex:e.target.value})
  }
  //---
  componentWillMount(){	
	//-------
		let dates=new Date();
		let year=dates.getFullYear();
		let years=this.state.years;
		//console.log(year)
		for(let i=0;i<100;i++){
			let y=year-parseInt(i+8)
			years.push(y)
			
		}
		this.setState({years:years})
		let login=window.localStorage.getItem(TOKEN_NAME);
		 
		if(login!='' && login!='null' && login!=null){
			 login=JSON.parse(login)
			 this.setState({id_user:login.UserInfo.id})
		}
	//-------
	let idLanguage=window.localStorage.getItem(LANGUAGE_KEY)
	if(idLanguage!='' && idLanguage!='null' && idLanguage!=null){
		this.setState({lguages:idLanguage})
	}
	this.getProfile();
  }
	componentWillReceiveProps(nextProps){
		//console.log(nextProps)
		if(nextProps.languageKey && nextProps.languageKey!='' && nextProps.languageKey!=null && nextProps.languageKey!='null'){
			this.setState({lguages:nextProps.languageKey});
			
		}
	}
  render() {
	let urlname=this.state.tiptername.split(' ').join('');
	let months=[{key:1,value:1},{key:2,value:2},{key:3,value:3},{key:4,value:4},{key:5,value:5},{key:6,value:6},
			   {key:7,value:7},{key:8,value:8},{key:9,value:9},{key:10,value:10},{key:11,value:11},{key:12,value:12}]
	let days=['01','02','03','04','05','06','07','08','09','10','11','12','13','14','15','16','17','18','19','20','21','22','23','24','25','26','27','28','29','30','31']
	let years=this.state.years
	//console.log(this.state.avatar)
    return (
      <div>
        <form
          className="needs-validation"
         // onSubmit={this.submitHandler}
          noValidate
        >
          <MDBRow>
            <MDBCol md="4" className="mb-3">
              <label
                htmlFor="defaultFormRegisterNameEx"
                className="grey-text"
              >
                {localizeKey(menu)('firstName', this.state.lguages)}:
              </label>
              <input
                value={this.state.fname}
                name="fname"
                onChange={this.changeHandler}
                type="text"
                id="defaultFormRegisterNameEx"
                className="form-control"
                placeholder="First name"
                
              />
            </MDBCol>
            <MDBCol md="4" className="mb-3">
              <label
                htmlFor="defaultFormRegisterEmailEx2"
                className="grey-text"
              >
                {localizeKey(menu)('lastName', this.state.lguages)}:
              </label>
              <input
                value={this.state.lname}
                name="lname"
                onChange={this.changeHandler}
                type="text"
                id="defaultFormRegisterEmailEx2"
                className="form-control"
                placeholder="Last name"
                
              />
            </MDBCol>
            <MDBCol md="4" className="mb-3">
              <label
                htmlFor="defaultFormRegisterConfirmEx3"
                className="grey-text"
              >
                {localizeKey(menu)('emailTips', this.state.lguages)}:
              </label>
              <input
                value={this.state.email}
                onChange={this.changeHandler}
                type="email"
                id="defaultFormRegisterConfirmEx3"
                className="form-control"
                name="email"
                placeholder="Your Email address"
				
              />
            </MDBCol>
          </MDBRow>
		   <MDBRow>
			   <MDBCol md="4" className="mb-3">
				<div className="custom-control custom-checkbox pl-3 div-custom-checkbox">
				  <input
					className="custom-control-input check-edit-pass"
					type="checkbox"
					value=""
					id="invalidCheck"
					
					onChange={this.handleCheckPass.bind(this)}
				  />
				  <label className="custom-control-label" htmlFor="invalidCheck">
					 {localizeKey(menu)('changepass', this.state.lguages)}
				  </label>
				  
				</div>
			  </MDBCol>
          </MDBRow>
		  {(this.state.checkPass==true)?
          <MDBRow>
            <MDBCol md="4" className="mb-3">
              <label
                htmlFor="defaultFormRegisterPasswordEx4"
                className="grey-text"
              >
                {localizeKey(menu)('password', this.state.lguages)}:
              </label>
              <input
                value={this.state.password}
                onChange={this.changeHandler}
                type="password"
                //id="defaultFormRegisterPasswordEx4"
                className="form-control"
                name="password"
                placeholder={localizeKey(menu)('password', this.state.lguages)}
                
              />
               <small id="emailHelp" className="form-text text-muted">
               {localizeKey(menu)('txt_password', this.state.lguages)}
              </small>
            </MDBCol>
            <MDBCol md="4" className="mb-3">
              <label
                htmlFor="defaultFormRegisterPasswordEx4"
                className="grey-text"
              >
                {localizeKey(menu)('confirmPass', this.state.lguages)}:
              </label>
              <input
                value={this.state.confirmpassword}
                onChange={this.changeHandler}
                type="password"
                //id="defaultFormRegisterPasswordEx4"
                className="form-control"
                name="confirmpassword"
                placeholder={localizeKey(menu)('confirmPass', this.state.lguages)}
                
              />
              
            </MDBCol>
		 </MDBRow>:''
		 }
		 <MDBRow>
            <MDBCol md="4" className="mb-3">
              <label
                htmlFor="defaultFormRegisterPasswordEx4"
                className="grey-text"
              >
                {localizeKey(menu)('tipname', this.state.lguages)}:
              </label>
              <input
                value={this.state.tiptername}
                onChange={this.changeHandler}
                type="text"
                //id="defaultFormRegisterPasswordEx4"
                className="form-control"
                name="tiptername"
                placeholder={localizeKey(menu)('tipname', this.state.lguages)}
                
              />
			   <small id="emailHelp" className="form-text text-muted">
                {localizeKey(menu)('textTipname', this.state.lguages)}
              </small>
			  
            </MDBCol>
			<MDBCol md="6" className="mb-3">
              <label
                htmlFor="defaultFormRegisterPasswordEx4"
                className="grey-text"
              >
                {localizeKey(menu)('userUrl', this.state.lguages)}:
              </label>
              
				
			<MDBRow style={{'margin':'0','display':'block'}}>
				<span className="url-user" style={{'width':'50%'}}>https://www.coopbet.com/</span>
				 <input
					value={this.state.suffix_url_name}
					onChange={this.changeHandler}
					type="text"
					id="suffix_url_name"
					className="form-control"
					name="suffix_url_name"
					style={{'width':'55%','display':'inline','marginLeft':'1px','padding':'5px'}}
					
				  />
			</MDBRow>
					<input 
					type="button" 
					className="form-control btn-sigin bnt-send-code wdt38"
					value="Check"
					onClick={this.check_url_friendly} 
					style={{'float':'right','margin':'5px'}}
					/>
				
				{
				(this.state.suffix_url_name!='')?
				<div className="setting">
					
					<img className="ch_U" src="https://www.coopbet.com/media/loading_dots.gif" style={{'width':'35px','display':'none'}}/>
					<i className={this.state.imgCheck}></i>
					<span>{this.state.textCheck}</span>
					<small id="emailHelp" className="form-text text-muted">
						{localizeKey(menu)('txtUrl', this.state.lguages)}
					 </small>
				</div>:''
				}	
				
				
				
            </MDBCol>
          </MDBRow>
		   <MDBRow>
				 <MDBCol md="6" className="mb-3 mgi0">
					  <label
						htmlFor="defaultFormRegisterPasswordEx4"
						className="fnt-bold"
					  >
						Verify Url username:
					  </label>
				</MDBCol>
		   </MDBRow>
		  <MDBRow>
            <MDBCol md="6" className="mb-3 mgi0">              
			  <div>
					<label
						htmlFor="defaultFormRegisterPasswordEx4"
						className="fnt-smal"
						style={{'width':'100%'}}
					 >
						{localizeKey(menu)('mobileNum', this.state.lguages)}:
					</label>
				  <input
					value={this.state.mobile_num}
					onChange={this.changeHandler}
					type="text"
					//id="defaultFormRegisterPasswordEx4"
					className="form-control wdt20"
					name="mobile_num"
					placeholder=""
					maxLength="5"
				  />
				  <input
					value={this.state.mobile_number}
					onChange={this.changeHandler}
					type="text"
					//id="defaultFormRegisterPasswordEx4"
					className="form-control wdt40"
					name="mobile_number"
					placeholder=""
					maxLength="11"
					disabled={(this.state.is_validate==1)?true:false}
				  />
				  <input 
					type="button" 
					className="form-control btn-sigin bnt-send-code wdt38"
					value="Send code"
					onClick={this.sendotpsms}
					disabled={(this.state.is_validate==1)?true:false} 
					/>
			  </div>
			  <div>
					<label
						htmlFor="defaultFormRegisterPasswordEx4"
						className="wdt20"
					  >
					</label>
					<label
						htmlFor="defaultFormRegisterPasswordEx4"
						className="wdt80 txt-note"
					  >
					  {localizeKey(menu)('txtMoblie', this.state.lguages)}
					</label>
			  </div>
			  
            </MDBCol>	
			<MDBCol md="6" className="mb-3">
				<div>
					<label
						htmlFor="defaultFormRegisterPasswordEx4"
						className="fnt-smal"
						style={{'width':'100%'}}
					 >
						{localizeKey(menu)('entercodeverify', this.state.lguages)}:
					</label>				  
					  <input
						value={this.state.verify_code}
						onChange={this.changeHandler}
						type="text"
						//id="defaultFormRegisterPasswordEx4"
						className="form-control wdt61"
						name="verify_code"
						placeholder=""
						maxLength="11"
					  />
					  <input 
						type="button" 
						className="form-control btn-sigin bnt-send-code wdt38"
						value={this.state.verify} 
						onClick={this.validateotpsms}
						disabled={(this.state.is_validate==1)?true:false}
						/>
				</div>
				{(this.state.is_validate==1)?
				 <div>
					
					<label
						htmlFor="defaultFormRegisterPasswordEx4"
						className="txt-note txt-red"
					  >
					  Verify date:{this.state.validated_time}
					</label>
				</div>:''
				}
			</MDBCol>
          </MDBRow>
          <MDBRow>
				<MDBCol md="4" className="mb-3">
					<label
						htmlFor="defaultFormRegisterPasswordEx4"
						className="grey-text"
					>
					Birthday:
					</label>
					<div>
						<select className="form-control width33 inl" style={{'borderRadius':'0','padding':'3px'}} value={this.state.month} onChange={this.selectBirthday.bind(this,'month')}>
						{
							months.map((data,i)=>{
								return (
									<option value={data.key} key={i}>{data.value}</option>
								)
							})
						}
						</select>
						<select className="form-control width33 inl" style={{'borderRadius':'0','padding':'3px'}} value={this.state.date} onChange={this.selectBirthday.bind(this,'date')}>
						{
							days.map((data,i)=>{
								return(<option value={data} key={i}>{data}</option>)
							})
						}
						</select>
						<select className="form-control width33 inl" style={{'borderRadius':'0','padding':'3px'}} value={this.state.year} onChange={this.selectBirthday.bind(this,'year')}>
							{
								years.map((data,i)=>{
									return(<option value={data} key={i}>{data}</option>)
								})
							}
						</select>
					</div>
				</MDBCol>
          </MDBRow>
		  <MDBRow>
				<MDBCol md="4" className="mb-3">
					<label
						htmlFor="defaultFormRegisterPasswordEx4"
						className="grey-text lbl-sex"
					>
					Sex:
					</label>
					 <div className="custom-control custom-radio inl mrgl20">
						<input
							type="radio"
							className="custom-control-input"
							id="customControlValidation2"
							name="radio-stacked"
							value={1} onChange={this.handleSex.bind(this)} checked={(this.state.sex==1)?true:false}
						/>
						<label
							className="custom-control-label"
							htmlFor="customControlValidation2"
						>
							{localizeKey(menu)('female', this.state.lguages)}
						</label>					    
					</div>
					 <div className="custom-control custom-radio inl mrgl20">						
					    <input
							type="radio"
							className="custom-control-input"
							id="customControlValidation3"
							name="radio-stacked"value={2} onChange={this.handleSex.bind(this)} checked={(this.state.sex==2)?true:false}
						/>
						<label
							className="custom-control-label"
							htmlFor="customControlValidation3"
					    >
						{localizeKey(menu)('male', this.state.lguages)}
					    </label>
					</div>
				</MDBCol>
          </MDBRow>
		   <MDBRow>
				<MDBCol md="4" className="mb-3">
					<label
						htmlFor="defaultFormRegisterPasswordEx4"
						className="grey-text"
					>
					Your Avatar:
					</label>
					<div>
						<div className="col-md-12" style={{'padding':'0'}}>
							<div className="avatar-container">
								<figure>
									<img className="avatar1" src={this.state.avatar} alt="" />
								</figure>
							</div>	
						</div>
						<div className="col-md-12" style={{'padding':'0'}}>
							<label className="col-md-2 font-normal" style={{'padding':'0'}}></label>
							<div className="ctnavatar-container" style={{'padding':'0','marginTop':'-15px'}}>												
								<UploadImage text='Choose Avatar'  multiFiles={false} resetFile={this.state.resetFile} updateFiles={this.handleGetFileUpload} />																								
							</div>	
						</div>
					</div>
				</MDBCol>
          </MDBRow>
		  <MDBRow className="r-btn-s">
			  <MDBBtn color="primary" type="button" className="doRegister save-setting" onClick={this.editPersonal.bind(this)}>
				{localizeKey(menu)('save', this.state.lguages)}
			  </MDBBtn>
		  </MDBRow>
		  <MDBRow>
			  <MDBCol md="12" className="mb-12">
					<label
						htmlFor="defaultFormRegisterPasswordEx4"
						className="red-text"
					>
					</label>
					<label
						htmlFor="defaultFormRegisterPasswordEx4"
						className="green-text"
					>
					</label>
				</MDBCol>
          </MDBRow>
        </form>
		<ModalErorr isOpen={this.state.isOpenModal} close={this.close} text={this.state.msgRigister}/>
      </div>
    );
  }
}

//export default FormsPage;
export default connect(mapStateToProps, null)(FormsPage)