import React, {Component} from 'react'
import { connect } from 'react-redux'
import { setLocale, getLocale, localizeKey } from 'react-redux-localization';
import { Cookies } from 'react-cookie' 
import '../../styles/App.css'
import '../../styles/home.css'
import {Col, Row,FormControl, Form, FormGroup, ControlLabel, Button} from 'react-bootstrap'
import { createElementsFromText } from 'html-text-to-react';
import ReactHtmlParser from 'react-html-parser';
import $ from 'jquery';
import {TOKEN_NAME, REDIRECT,HOME_PAGE_LOADED, APP_LOAD,LANGUAGE_KEY} from '../../constants/actionTypes'
import agent from '../../agent'
import { Link,withRouter, history} from 'react-router-dom'
import menu from '../common/menu.json'
import Lqhome from '../common/home.json'


const mapStateToProps = state => (
    Object.assign({}, state, {
		tab: state.common.tab,
		updateProps: state.common.updateProps,
		locale: getLocale(state),
		languageKey: state.common.languageKey
	}) 

);

const mapDispatchToProps = dispatch => ({               
	onLoad: (data) => {
		dispatch({ type: 'HOME_PAGE_LOADED', data})  
	},
	chooseLocale: locale => dispatch(setLocale(locale))
});



 


class AddTips extends Component{ 	
	constructor(){
		super()
		this.state = {
			lguages:'1005',
			qcinfo:{},sport_tips:2,LeagueList:[],TodayCount:0,
		}
		//---
		this.getrandomad=()=>{
			let lang_id=this.state.lguages
			let param={lang_id:lang_id}
			agent.home.getrandomad2(param).then(data => {
				//console.log(data)
				if(data.result === 'good'){
					this.setState({qcinfo:data.info})
				}
			})
		}
		//---
		this.goAdView=this.goAdView.bind(this)
		//---
	}
	getMobiletipsummarylist(){
		let sport_tips=this.state.sport_tips
		let param={sport_tips:sport_tips}
		agent.home.mobiletipsummarylist(param).then(data => {
			//console.log(data)
			if(data.result === 'good'){
				this.setState({TodayCount:data.TodayCount,LeagueList:data.LeagueList})
			}
		})
	}
	goAdView(ev){
		let id=$(ev.target).attr("dataid")
		//let title=$(ev.target).attr("title")
		//window.localStorage.setItem( 'ad_user_id', id);
		//window.localStorage.setItem( 'ad_title', title);
		//console.log(id,title)
		//window.location.href='/ad-list' 
		window.location.href='/new-info/'+id
	}
	componentWillMount(){	
		//-----
		
		let idLanguage=window.localStorage.getItem(LANGUAGE_KEY)
		if(idLanguage!='' && idLanguage!='null' && idLanguage!=null){
			this.setState({lguages:idLanguage},()=>{
				this.getrandomad();
			})
		}
		let SPORT=window.localStorage.getItem('SPORT');
		//let sport_tips=(SPORT!='' && SPORT!=null)?SPORT:2
		let sport_tips=(SPORT!='' && SPORT!=null && SPORT==1)?SPORT:2
		//console.log(SPORT,sport_tips)
		this.setState({sport_tips:sport_tips},()=>{
			this.getMobiletipsummarylist();
		})
		
		//-----
	}
	componentDidMount(){
		this.getrandomad();
	}
	componentWillReceiveProps(nextProps){
		//console.log(nextProps)
		if(nextProps.languageKey && nextProps.languageKey!='' && nextProps.languageKey!=null && nextProps.languageKey!='null'){
			this.setState({lguages:nextProps.languageKey},()=>{
				this.getrandomad()
			});
			
		}
	}
	
	shouldComponentUpdate(nextProps, nextState){
		return nextState;
	}	
	goDetailTip(title,num,league_id,ev){
		window.localStorage.setItem('TITLETIP', title);
		window.localStorage.setItem('NUMTIP', num);
		window.localStorage.setItem('SPORT', this.state.sport_tips);
		window.localStorage.setItem('league_id', league_id);
		if(league_id>0){
			this.props.history.push("/detail-tip-leagues")
		}else{
			this.props.history.push("/detail-tip-today")
		}
		
	}
	changeOption(sport_tips,e){
		//console.log(sport_tips)
		$(".tool-ball").removeClass("active")
		this.setState({sport_tips:sport_tips},()=>{
			this.getMobiletipsummarylist();
		})
		$(e.target).closest(".tool-ball").addClass("active")
	}
	changeOption1(){
	}
	render(){   
		//---
		const qcinfo=this.state.qcinfo;
		let title=''
		let imageurl=''
		let url=''
		let description=''
		if(qcinfo!='' && qcinfo!=null && qcinfo!='null'){
			 title=qcinfo.ad_title?qcinfo.ad_title:'';
			 imageurl=qcinfo.ad_logo_url?qcinfo.ad_logo_url:'https://back-end.coopbet.com/gf/ywqBBMhT0U0022_no-logo.png';
			 url=qcinfo.ad_url?qcinfo.ad_url:'';
			 description=qcinfo.ad_description?qcinfo.ad_description:'';
			 description=(description.length<=85)?description:description.substr(0,85)+'...'
		}	
		//---
		let LeagueList=this.state.LeagueList
		let TodayCount=this.state.TodayCount
		//console.log(this.state.homebetList)
        return (
			<div id="body">   	
			{
				(qcinfo && qcinfo!=null && qcinfo!='')?
				<Row className="randomad">
					<Col md={4} xs={4} sm={4} className="pd0">
						<div className="img-adv">
							<a href={url} target="_blank" className="rand-logo">
								<img alt="m88" src={imageurl} width="100" height="50"/>
							</a>
						</div>
					</Col>
					<Col md={8} xs={8} sm={8} className="pd0">
						<div className="desc-adv">									
						{
							ReactHtmlParser(description)
						}
							<span>
							{
								(qcinfo!='' && qcinfo!=null)?
								<a onClick={this.goAdView.bind(this)} dataid={qcinfo.id} title={title} className="v-more">{localizeKey(Lqhome)('viewmore', this.state.lguages)}</a>:''
							}
							</span>
						</div>
					</Col>
				</Row>:''
			}
				
				<Row className="mrgt7 mgbt3 mrgLR1">
					<Col md={4} xs={4} sm={4} className={(this.state.sport_tips==2)?"tool-ball active":"tool-ball"} onClick={this.changeOption.bind(this,2)}>
						<i className="far fa-futbol icon-tool" ></i>
						<div className="tool-ball-title">{localizeKey(menu)('soccer', this.state.lguages)}</div>
					</Col>
					<Col md={4} xs={4} sm={4} className={(this.state.sport_tips==1)?"tool-ball active":"tool-ball"} onClick={this.changeOption.bind(this,1)}>
						<i className="fas fa-basketball-ball icon-tool"></i>
						<div className="tool-ball-title">{localizeKey(menu)('basketball', this.state.lguages)}</div>
					</Col>
					<Col md={4} xs={4} sm={4} className={(this.state.sport_tips==3)?"tool-ball active":"tool-ball"} onClick={this.changeOption1.bind(this,3)}>
						<img src="https://m.coopbet.com/img/tennis_ball.png" className="img_tennis" style={{'display':'none'}}/>
						<i className="fas fa-baseball-ball icon-tool"></i>
						<div className="tool-ball-title">{localizeKey(menu)('tennis', this.state.lguages)}</div>
					</Col>
				</Row>
				<Row className="line-ct"></Row>
				
				<Row className="mrgt7 mgbt3">
					<Row className="mg0 width100 mrgLR1 mrgbt5" onClick={this.goDetailTip.bind(this,'Today',62,0)}>
						<Col md={10} xs={10} sm={10} className="btn-tip">Today</Col>
						<Col md={2} xs={2} sm={2} className="btn-num-tip">{TodayCount}</Col>
					</Row>
					{
						(LeagueList!='')?LeagueList.map((data,idx)=>{
							return(
								<Row className="mg0 width100 mrgLR1 mrgbt5" onClick={this.goDetailTip.bind(this,data.league_name,data.MatchCount,data.id)} key={idx}>
									<Col md={10} xs={10} sm={10} className="btn-tip">{data.league_name.substr(0,40)}</Col>
									<Col md={2} xs={2} sm={2} className="btn-num-tip">{data.MatchCount}</Col>
								</Row>
							)
						}):''
					}
					
				</Row>
				
				
			</div>
        );
    }
}

//export default connect(mapStateToProps, mapDispatchToProps)(AddTips)
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AddTips));