import React, {Component} from 'react'
import { connect } from 'react-redux'
import { setLocale, getLocale, localizeKey } from 'react-redux-localization';
import { Cookies } from 'react-cookie' 
import '../../styles/App.css'
import '../../styles/home.css'
import {Col, Row,FormControl, Form, FormGroup, ControlLabel, Button} from 'react-bootstrap'
import { createElementsFromText } from 'html-text-to-react';
import ReactHtmlParser from 'react-html-parser';
import $ from 'jquery';
import {TOKEN_NAME, REDIRECT,HOME_PAGE_LOADED, APP_LOAD,LANGUAGE_KEY} from '../../constants/actionTypes'
import agent from '../../agent'
import FormSetting from './formSetting'
import FormBetting from './formBetting'
import menu from '../common/menu.json'
import Lqhome from '../common/home.json'

const mapStateToProps = state => (
    Object.assign({}, state, {
		tab: state.common.tab,
		updateProps: state.common.updateProps,
		locale: getLocale(state),
		languageKey: state.common.languageKey
	}) 

);

const mapDispatchToProps = dispatch => ({               
	onLoad: (data) => {
		dispatch({ type: 'HOME_PAGE_LOADED', data})  
	},
	chooseLocale: locale => dispatch(setLocale(locale))
});



 


class AddTips extends Component{ 	
	constructor(){
		super()
		this.state = {
			lguages:'1005',
			qcinfo:{},personal:true,betting:false
		}
		//---
		this.getrandomad=()=>{
			let lang_id=this.state.lguages
			let param={lang_id:lang_id}
			agent.home.getrandomad2(param).then(data => {
				//console.log(data)
				if(data.result === 'good'){
					this.setState({qcinfo:data.info})
				}
			})
		}
		//---
		this.goAdView=this.goAdView.bind(this)
		//---
	}
	goAdView(ev){
		let id=$(ev.target).attr("dataid")
		/*let title=$(ev.target).attr("title")
		window.localStorage.setItem( 'ad_user_id', id);
		window.localStorage.setItem( 'ad_title', title);
		//console.log(id,title)
		window.location.href='/ad-list' */
		window.location.href='/new-info/'+id
	}
	componentWillMount(){	
		//-----
		
		let idLanguage=window.localStorage.getItem(LANGUAGE_KEY)
		if(idLanguage!='' && idLanguage!='null' && idLanguage!=null){
			this.setState({lguages:idLanguage},()=>{
				this.getrandomad();
			})
		}
		
		//-----
	}
	componentDidMount(){
		this.getrandomad();
	}
	componentWillReceiveProps(nextProps){
		//console.log(nextProps)
		if(nextProps.languageKey && nextProps.languageKey!='' && nextProps.languageKey!=null && nextProps.languageKey!='null'){
			this.setState({lguages:nextProps.languageKey},()=>{
				this.getrandomad()
			});
			
		}
	}
	
	shouldComponentUpdate(nextProps, nextState){
		return nextState;
	}	
	
	handleSetting(control,ev){
		let personal=(control=='personal')?true:false
		let betting=(control=='betting')?true:false
		this.setState({betting:betting,personal:personal})
	}
	render(){   
		//---
		const qcinfo=this.state.qcinfo;
		let title=''
		let imageurl=''
		let url=''
		let description=''
		if(qcinfo!='' && qcinfo!=null && qcinfo!='null'){
			 title=qcinfo.ad_title?qcinfo.ad_title:'';
			 imageurl=qcinfo.ad_logo_url?qcinfo.ad_logo_url:'https://back-end.coopbet.com/gf/ywqBBMhT0U0022_no-logo.png';
			 url=qcinfo.ad_url?qcinfo.ad_url:'';
			 description=qcinfo.ad_description?qcinfo.ad_description:'';
			 description=(description.length<=85)?description:description.substr(0,85)+'...'
		}	
		//---
		//console.log(this.state.homebetList)
        return (
			<div id="body"> 
			{
				(qcinfo && qcinfo!=null && qcinfo!='')?
				<Row className="randomad">
					<Col md={4} xs={4} sm={4} className="pd0">
						<div className="img-adv">
							<a href={url} target="_blank" className="rand-logo">
								<img alt="m88" src={imageurl} width="100" height="50"/>
							</a>
						</div>
					</Col>
					<Col md={8} xs={8} sm={8} className="pd0">
						<div className="desc-adv">									
						{
							ReactHtmlParser(description)
						}
							<span>
							{
								(qcinfo!='' && qcinfo!=null)?
								<a onClick={this.goAdView.bind(this)} dataid={qcinfo.id} title={title} className="v-more">{localizeKey(Lqhome)('viewmore', this.state.lguages)}</a>:''
							}
							</span>
						</div>
					</Col>
				</Row>:''
			}
				<Row className="mg0 mrgt7 mgbt7">
					<Col md={12} xs={12} sm={12} className="">
						<img src="https://m.coopbet.com/img/register1.png" className="" />
						<div className="rigister-title" style={{'textTransform':'uppercase'}}>{localizeKey(menu)('subdrdw4', this.state.lguages)}</div>
					</Col>					
				</Row>
				<Row className="line-ct"></Row>
				<Row className="mrgt7 mgbt7">
					<Col md={6} xs={6} sm={6} className={(this.state.personal==true)?"tool-setting active":"tool-setting"} onClick={this.handleSetting.bind(this,'personal')}>
						<i className="fas fa-user-edit icon-tool" ></i>
						<div className="tool-ball-title"style={{'textTransform':'uppercase'}} >{localizeKey(menu)('personal', this.state.lguages)}</div>
					</Col>
					<Col md={6} xs={6} sm={6} className={(this.state.betting==true)?"tool-setting active":"tool-setting"} onClick={this.handleSetting.bind(this,'betting')}>
						<i className="fas fa-bold icon-tool"></i>
						<div className="tool-ball-title">BETTING</div>
					</Col>
					
				</Row>
				
				<Row className="line-ct"></Row>
				<Row className="mg0 mrgt7 ">
					<Col md={12} xs={12} sm={12} className="">
						{
							(this.state.personal==true)?<FormSetting/>:''
						}
						{
							(this.state.betting==true)?<FormBetting/>:''
						}
					</Col>
				</Row>
				
				
				
			</div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddTips)