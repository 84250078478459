import React, {Component} from 'react'
import { connect } from 'react-redux'
import { setLocale, getLocale, localizeKey } from 'react-redux-localization';
import { Cookies } from 'react-cookie' 
import '../../styles/App.css'
import '../../styles/home.css'
import {Col, Row,FormControl, Form, FormGroup, ControlLabel, Button, Tabs,Tab,Table,Radio} from 'react-bootstrap'
import { createElementsFromText } from 'html-text-to-react';
import ReactHtmlParser from 'react-html-parser';
import $ from 'jquery';
import {TOKEN_NAME, REDIRECT,HOME_PAGE_LOADED, APP_LOAD,LANGUAGE_KEY} from '../../constants/actionTypes'
import agent from '../../agent'
import menu from '../common/menu.json'
import Lqhome from '../common/home.json'
import { MDBRow, MDBCol, MDBBtn } from "mdbreact";
import ModalErorr from '../common/ModalErorr'

const mapStateToProps = state => (
    Object.assign({}, state, {
		tab: state.common.tab,
		updateProps: state.common.updateProps,
		locale: getLocale(state),
		languageKey: state.common.languageKey
	}) 

);

const mapDispatchToProps = dispatch => ({               
	onLoad: (data) => {
		dispatch({ type: 'HOME_PAGE_LOADED', data})  
	},
	chooseLocale: locale => dispatch(setLocale(locale))
});



 


class AddTips extends Component{ 	
	constructor(){
		super()
		this.state = {
			lguages:'1005',
			qcinfo:{},idUser:'',bank_no:'',isOpenModal:false,msgRigister:''
		}
		//---
		this.getrandomad=()=>{
			let lang_id=this.state.lguages
			let param={lang_id:lang_id}
			agent.home.getrandomad2(param).then(data => {
				//console.log(data)
				if(data.result === 'good'){
					this.setState({qcinfo:data.info})
				}
			})
		}
		//---
		this.goAdView=this.goAdView.bind(this)
		//---
		this.close=()=>{
			this.setState({isOpenModal:false})
		}
	}
	goAdView(ev){
		let id=$(ev.target).attr("dataid")
		/*let title=$(ev.target).attr("title")
		window.localStorage.setItem( 'ad_user_id', id);
		window.localStorage.setItem( 'ad_title', title);
		//console.log(id,title)
		window.location.href='/ad-list' */
		window.location.href='/new-info/'+id
	}
	componentWillMount(){	
		//-----
		
		let idLanguage=window.localStorage.getItem(LANGUAGE_KEY)
		if(idLanguage!='' && idLanguage!='null' && idLanguage!=null){
			this.setState({lguages:idLanguage},()=>{
				this.getrandomad();
			})
		}
		let login=window.localStorage.getItem(TOKEN_NAME);
		if(login!='' && login!='null' && login!=null){
				login=JSON.parse(login)
				let info=login.UserInfo
				//console.log(login.UserInfo)
				this.setState({idUser:info.id},()=>{
					//this.getbankrolls(info.id)
				})
				this.getbankrolls(info.id)
			}
		//-----
	}
	componentDidMount(){
		this.getrandomad();
	}
	componentWillReceiveProps(nextProps){
		//console.log(nextProps)
		if(nextProps.languageKey && nextProps.languageKey!='' && nextProps.languageKey!=null && nextProps.languageKey!='null'){
			this.setState({lguages:nextProps.languageKey},()=>{
				this.getrandomad()
			});
			
		}
	}
	
	shouldComponentUpdate(nextProps, nextState){
		return nextState;
	}	
	
	handleChange(userbankid,userid,e){
		//console.log(userbankid,userid)
		//this.setDefaultBankRoll(userbankid,userid)
	}
	handleChangeField(feild,e){
		this.setState({[feild]:e.target.value})
	}
	addbankroll(e){
		let idUser=this.state.idUser
		let bank_no=this.state.bank_no
		agent.auth.addbankroll({userid:idUser,bank_no:bank_no,bank_id:1,bank_type:3}).then(data => {
			//console.log(data)
			if(data.result=="good"){
				window.localStorage.setItem( 'keytab', 'key2');
				window.location.href="/bankroll"
				
			}else{
				this.setState({msgRigister:data.message,isOpenModal:true})
			}
		})
	}
	getbankrolls(userid){
		agent.auth.getbankrolls({userid:userid}).then(data => {
			//console.log(data)
			if(data.result=="good"){
				let UserBankRolls=data.UserBankRolls
				this.setState({BankRolls:UserBankRolls.BankRolls,
							  BitcoinRolls:UserBankRolls.BitcoinRolls,
							  PaypalRolls:UserBankRolls.PaypalRolls})
			}
		})
	}
	goBack(e){
		window.location.href="/bankroll"
	}
	render(){   
		//---
		const qcinfo=this.state.qcinfo;
		let title=''
		let imageurl=''
		let url=''
		let description=''
		if(qcinfo!='' && qcinfo!=null && qcinfo!='null'){
			 title=qcinfo.ad_title?qcinfo.ad_title:'';
			 imageurl=qcinfo.ad_logo_url?qcinfo.ad_logo_url:'https://back-end.coopbet.com/gf/ywqBBMhT0U0022_no-logo.png';
			 url=qcinfo.ad_url?qcinfo.ad_url:'';
			 description=qcinfo.ad_description?qcinfo.ad_description:'';
			 description=(description.length<=85)?description:description.substr(0,85)+'...'
		}	
		const BankRolls=this.state.BankRolls
		const BitcoinRolls=this.state.BitcoinRolls
		const PaypalRolls=this.state.PaypalRolls
		//---
		//console.log(this.state.homebetList)
        return (
			<div id="body"> 
			{
				(qcinfo && qcinfo!=null && qcinfo!='')?
				<Row className="randomad">
					<Col md={4} xs={4} sm={4} className="pd0">
						<div className="img-adv">
							<a href={url} target="_blank" className="rand-logo">
								<img alt="m88" src={imageurl} width="100" height="50"/>
							</a>
						</div>
					</Col>
					<Col md={8} xs={8} sm={8} className="pd0">
						<div className="desc-adv">									
						{
							ReactHtmlParser(description)
						}
							<span>
							{
								(qcinfo!='' && qcinfo!=null)?
								<a onClick={this.goAdView.bind(this)} dataid={qcinfo.id} title={title} className="v-more">{localizeKey(Lqhome)('viewmore', this.state.lguages)}</a>:''
							}
							</span>
						</div>
					</Col>
				</Row>:''
			}
				<Row className="mg0 mrgt7 mgbt7">
					<Col md={10} xs={10} sm={10} className="">
						<i className="fas fa-money-check-alt iconmoney" style={{'marginRight':'5px'}}></i>
						<div className="rigister-title" style={{'textTransform':'uppercase'}}>THÊM VÀO TÀI KHOẢN</div>
					</Col>	
					<Col md={2} xs={2} sm={2} style={{'paddingTop':'5px'}}>
						<i className="fas fa-arrow-left icback" aria-hidden="true" onClick={this.goBack.bind(this)}></i>
					</Col>
				</Row>
				<Row className="line-ct"></Row>
				
				<Row className="mg0 mrgt7 " id="div-bankroll">
					<Col md={12}>
						 <form
						  className="needs-validation"
						  noValidate
						>
							
							<MDBRow>
								<MDBCol md="4" className="mb-3">
								  <label
									htmlFor="defaultFormRegisterNameEx"
									className="grey-text"
								  >
									BITCOIN ADDRESS:
								  </label>
								  <input
									value={this.state.bank_no}
									name="fname"
									onChange={this.handleChangeField.bind(this,'bank_no')}
									type="text"
									id="defaultFormRegisterNameEx"
									className="form-control"
									placeholder=""
									
								  />
								</MDBCol>
							</MDBRow>
							<MDBRow className="r-btn-s">
							  <MDBBtn color="primary" type="button" className="doRegister save-setting" onClick={this.addbankroll.bind(this)}>
								THÊM TÀI KHOẢN
							  </MDBBtn>
						  </MDBRow>
						  <MDBRow>
							  <MDBCol md="12" className="mb-12">
									<label
										htmlFor="defaultFormRegisterPasswordEx4"
										className="red-text"
									>
									</label>
									<label
										htmlFor="defaultFormRegisterPasswordEx4"
										className="green-text"
									>
									</label>
								</MDBCol>
						  </MDBRow>
						</form>
					</Col>
				</Row>
				<Row style={{'margin':'0'}}>
					<Col md={12}>Note:</Col>
					<Col md={12} style={{'padding':'5px 30px'}}>
						<div>- Vui lòng kiểm tra chính xác thông tin trước khi nhấn nút thêm tài khoản</div>
						<div>- Tài khoản mặt định dùng cho việc bạn nhận tiền từ bán tips</div>
						<div>- Tài khoản chỉ thêm chứ không chỉnh sửa được</div>
						<div>- Được thêm tối đa 5 tài khoản bank, bitcoin, paypal</div>
					</Col>
				</Row>
				
				<ModalErorr isOpen={this.state.isOpenModal} close={this.close} text={this.state.msgRigister}/>	
				
				
			</div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddTips)