import React, {Component} from 'react'
import { connect } from 'react-redux'
import ReactDOM from 'react-dom';
import { setLocale, getLocale } from 'react-redux-localization';
import '../../styles/main.css'
import '../../styles/App.css'
import '../../styles/login.css'
import {Col, Row, FormControl, FormGroup, Radio, OverlayTrigger, Popover, Button} from 'react-bootstrap'
import agent from '../../agent'
import {TOKEN_NAME, CHANGE_TAB,LANGUAGE_KEY} from '../../constants/actionTypes'
import $ from 'jquery';
import ReactHtmlParser from 'react-html-parser';
import Captcha from './formCaptcha.js'
//import ModalErorr from '../common/ModalErorr'
import { withRouter, history} from 'react-router-dom'
//import languages from '../../languages'
//import translations from '../common/captionhome.json';


const mapStateToProps = state => (
    Object.assign({}, state, {
		
		languageKey: state.common.languageKey
	}) 

);

const mapDispatchToProps = dispatch => ({               
	
	chooseLocale: locale => dispatch(setLocale(locale)),
	onTabClick: (tab) => 
		dispatch({ type: CHANGE_TAB, tab }),
});



 


class Forgot extends Component{ 	
	constructor(){
		super()
		this.state = {
			lguages:'1005',email:'',number:'',newpass:'',captcharValue:'',dorepas:true,
			isOpenModal:false,msgError:'',qcinfo:{}
			
		}
		//---
		this.goSendNum=this.goSendNum.bind(this)
		this.goSendNewPass=this.goSendNewPass.bind(this)
		this.cancel=this.cancel.bind(this)
		this.updateCaptcha=this.updateCaptcha.bind(this) 
		this.newpw=this.newpw.bind(this) 
		//---
		this.close=()=>{
			this.setState({isOpenModal:false})
		}
		this.emailRecoverycode=(email)=>{			
			agent.auth.emailRecoverycode({email:email}).then(data => {
				if(data.result=="good"){
					$(".email-div").hide();
					$(".mabm-div").show();
					$(".error1").html('')
				}else{
					//this.setState({msgError:data.message,isOpenModal:true}) 
					$(".error1").html(data.message)
				}
			})			
		}
		this.checkRecoverycode=(recoverycode)=>{
			let email=this.state.email
			agent.auth.checkRecoverycode({recoverycode:recoverycode,email:email}).then(data => {
				if(data.result=="good"){
					$(".email-div").hide();
					$(".mabm-div").hide();
					$(".newpass-div").show();
					$(".error2").html('')
				}else{
					//this.setState({msgError:data.message,isOpenModal:true}) 
					$(".error2").html(data.message)
				}
			})
		}
		//---
		this.getrandomad=()=>{
			let lang_id=this.state.lguages
			let param={lang_id:lang_id}
			agent.home.getrandomad2(param).then(data => {
				//console.log(data)
				if(data.result === 'good'){
					this.setState({qcinfo:data.info})
				}
			})
		}
  }
	//---
	
	componentWillMount(){		
		this.getrandomad();
		let idLanguage=window.localStorage.getItem(LANGUAGE_KEY)
		if(idLanguage!='' && idLanguage!='null' && idLanguage!=null){
			this.setState({lguages:idLanguage})
		}
	}
	
	componentWillReceiveProps(nextProps){
		//console.log(nextProps.languageKey);
		if(nextProps.languageKey && nextProps.languageKey!='' && nextProps.languageKey!=null && nextProps.languageKey!='null'){
			this.setState({lguages:nextProps.languageKey});
			this.getrandomad()
		}
	}
	
	//shouldComponentUpdate(nextProps, nextState){
	 
	//}	
	handleValue(feild,e){
		this.setState({[feild]:e.target.value})
		$(".error1").html('')
	}
	goSendNum(e){
		let email=this.state.email;
		$(".error1").html('')
		if(email!=''){			
			this.emailRecoverycode(email)
		}else{
			$(".error1").html('The email field is required.')
		}
		
	}
	goSendNewPass(e){
		let number=this.state.number;
		$(".error2").html('')
		//if(number!=''){
			this.checkRecoverycode(number);
		//}else{
			//$(".error2").html('The recovery code field is required.')
		//}
		
	}
	cancel(e){
		this.setState({email:'',number:'',newpass:''})
		window.location.reload()
	}
	updateCaptcha(vale){
		//console.log(vale)  
		this.setState({captcharValue:vale})
		if(vale!='' && vale!=null && vale!="null"){
			this.setState({dorepas:false})
		}else{
			this.setState({dorepas:true})
		}
	}
	newpw(){
		let email=this.state.email
		let new_password=this.state.newpass
		let recoverycode=this.state.number
		agent.auth.newpw({email:email,recoverycode:recoverycode,new_password:new_password}).then(data => {
			//console.log(data)
			if(data.result=="good"){
				$(".error3").html('')
				this.props.history.push("/reset-password")
			}else{
				//this.setState({msgError:data.message,isOpenModal:true}) 
				$(".error3").html(data.message)
			}
		})
	}
	render(){   
		const qcinfo=this.state.qcinfo;
		let title=''
		let imageurl=''
		let url=''
		let description=''
		if(qcinfo!='' && qcinfo!=null && qcinfo!='null'){
			 title=qcinfo.ad_title?qcinfo.ad_title:'';
			 imageurl=qcinfo.ad_logo_url?qcinfo.ad_logo_url:'https://back-end.coopbet.com/gf/ywqBBMhT0U0022_no-logo.png';
			 url=qcinfo.ad_url?qcinfo.ad_url:'';
			 description=qcinfo.ad_description?qcinfo.ad_description:'';
			 description=(description.length<=70)?description:description.substr(0,70)+'...'
		}	
        return (
			<div id="body">   
				<Row className="randomad">
					<Col md={4} xs={4} sm={4} className="pd0">
						<div className="img-adv">
							<a href={url} target="_blank" className="rand-logo">
								<img alt="m88" src={imageurl} width="100" height="50"/>
							</a>
						</div>
					</Col>
					<Col md={8} xs={8} sm={8} className="pd0">
						<div className="desc-adv">									
						{
							ReactHtmlParser(description)
						}
						</div>
					</Col>
				</Row>
				
				<Row className="mg0 mrgt7 mgbt7">
					<Col md={12} xs={12} sm={12} className="">
						<div className="rigister-title">FORGOT PASSWORD</div>
					</Col>					
				</Row>
				<Row className="line-ct"></Row>
				<Row className="mg0 email-div">
					<Col md={12}>
						<div className="portlet box green">
							<div className="portlet-title">
								<div className="caption">Email tài khoản </div>
							</div>
							<div className="portlet-body">
								<Row className="mg0">
									<Col md={12} className="mgbt10"> Vui lòng nhập email là tài khoản đăng ký, để nhận mã bảo mật.</Col>
									<Col md={12} className="mgbt10">
										<input type="text" id="email" className="form-control" value={this.state.email}  onChange={this.handleValue.bind(this,'email')} placeholder="email"/>
									</Col>
									<Col md={12} className="mgbt10" id="capcha">
										<Captcha captcha={this.updateCaptcha} />
									</Col>
									<Col md={12} className="error1"></Col>
									<Col md={12} className="txtR">
										<button className="btn-info btn btn-default" disabled={this.state.dorepas} onClick={this.goSendNum}>Tiếp tục</button>
										 <button className="mgl5 btn btn-default" onClick={this.cancel}>Hủy</button>
									</Col>
								</Row>
							</div>
						</div>
					</Col>
				</Row>
				
				<Row className="mg0 mabm-div" style={{'display':'none'}}>
					<Col md={12}>
						<div className="portlet box green">
							<div className="portlet-title">
								<div className="caption">Nhập mã bảo mật </div>
							</div>
							<div className="portlet-body">
								<Row className="mg0">
									<Col md={12} className="mgbt10"> Vui lòng kiểm tra mã trong email của bạn. Mã này gồm 6 số.</Col>
									<Col md={12} className="mgbt10">
										<input type="text" id="" className="form-control" value={this.state.number}  onChange={this.handleValue.bind(this,'number')} placeholder="Nhập mã"/>
									</Col>
									<Col md={12} className="pdr0 mgbt10">
										<div className="txt-sm-fg">Chúng tôi đã gửi cho bạn mã đến: {this.state.email}</div>
										
									</Col>
									<Col md={12} className="error2"></Col>
									<Col md={12} className="txtR">
										<button className="btn-info btn btn-default" onClick={this.goSendNewPass}>Tiếp tục</button>
										 <button className="mgl5 btn btn-default" onClick={this.cancel}>Hủy</button>
									</Col>
								</Row>
							</div>
						</div>
					</Col>
				</Row>
				
				<Row className="mg0 newpass-div" style={{'display':'none'}}>
					<Col md={12}>
						<div className="portlet box green">
							<div className="portlet-title">
								<div className="caption">Chọn mật khẩu mới </div>
							</div>
							<div className="portlet-body">
								<Row className="mg0">
									<Col md={12} className="mgbt10"> Tạo mật khẩu mới có tối thiểu 6 ký tự. Mật khẩu mạnh là mật khẩu được kết hợp từ các ký tự, số và dấu câu.</Col>
									<Row md={12} className="mgbt10 mg0">
										<Col md={4} sm={4} xs={4} className="pdr0 lh32">Mật khẩu mới:</Col>
										<Col md={8} sm={8} xs={8} className="">
											<input type="password" id="newpass" className="form-control" value={this.state.newpass}  onChange={this.handleValue.bind(this,'newpass')} placeholder="New password"/>
										</Col>															
									</Row>
									<Col md={12} className="error3"></Col>
									<Col md={12} className="txtR">
										<button className="btn-info btn btn-default" onClick={this.newpw}>Tiếp tục</button>
										<button className="mgl5 btn btn-default" onClick={this.cancel}>Hủy</button>
									</Col>
									
								</Row>
							</div>
						</div>
					</Col>
					<Col md={2}></Col>
				</Row>
				
			</div>
        );
    }
}

//export default connect(mapStateToProps, mapDispatchToProps)(Register)
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Forgot));